import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { NavigationEnd, Router, RouterEvent } from "@angular/router"
import { filter } from "rxjs/operators"
import Utils from "../utils/utils"

@Injectable({
    providedIn: "root",
})
export class PreviousRouteService {
    previousUrl$: BehaviorSubject<string> = new BehaviorSubject("")
    currentUrl$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)
    currentQueryParams$ = new BehaviorSubject(null)

    constructor(private router: Router) {
        this.currentUrl$.next(this.router.url)
        this.router.events
            .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (Utils.isAddOnExperienceLink(window.location.pathname, "/add-on/search")) {
                    this.previousUrl$.next(this.currentUrl$.value)
                    this.currentUrl$.next(event.urlAfterRedirects)
                }
            })
    }
}
