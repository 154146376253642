import { Component, EventEmitter, Input, Output } from "@angular/core"
import { Roster } from "../../../../models/roster.model"
import { ModalRef } from "../../../modal/modal-ref"
import { AssignModalType } from "../assign-modal.component"
import { AssignLessonModalComponent } from "../../assign-lesson-modal/assign-lesson-modal.component"
import { ModalService } from "../../../modal/modal.service"
import { STATIC_IMAGES } from "../../../../models/constants"

@Component({
    selector: "app-select-class-view",
    templateUrl: "./select-class-view.component.html",
    styleUrls: ["./select-class-view.component.scss"],
})
export class SelectClassViewComponent {
    @Input() public rosters: Roster[] = []
    @Input() public assignmentCode: string = ""
    @Input() public assignmentName: string = ""
    @Input() public assignmentHasLti: boolean = false
    @Input() public type: AssignModalType = AssignModalType.any
    @Input() public activeModal: ModalRef = null
    @Output() public step: EventEmitter<number> = new EventEmitter<number>()

    public googleClassImg: string = ""
    public assignType = AssignModalType

    public constructor(private modalService: ModalService) {
        this.googleClassImg = STATIC_IMAGES.classroom_color_round
    }

    public checkIfSelected() {
        if (this.rosters) {
            return !!this.rosters.find((roster: Roster) => roster.selected)
        }
        return false
    }

    public next() {
        this.step.emit(2)
    }

    public goBack() {
        this.activeModal.close()
        this.modalService.open(AssignLessonModalComponent, {
            size: "lg",
            data: {
                code: this.assignmentCode,
                name: this.assignmentName,
                hasLti: this.assignmentHasLti,
            },
        })
    }
}
