import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core"
import { DOCUMENT } from "@angular/common"
import { fromEvent, ReplaySubject } from "rxjs"
import { distinctUntilChanged, takeUntil, throttleTime } from "rxjs/operators"

@Directive({
    selector: "[appStickyClass]",
})
export class StickyClassDirective implements OnInit, OnDestroy {
    private bottomMarker: HTMLElement
    private topMarker: HTMLElement
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)

    @Input() stickyStartId: string = "sticky-start"
    @Input() stickyBottomId: string = "sticky-bottom"
    @Input() appStickyClass: string = "sticky"

    constructor(
        @Inject(DOCUMENT)
        private document: Document,
        private element: ElementRef,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        this.bottomMarker = this.document.getElementById(this.stickyBottomId)
        let bottomPos =
            this.bottomMarker.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top
        let stopPosition = bottomPos - this.element.nativeElement.offsetHeight

        this.topMarker = this.document.getElementById(this.stickyStartId)
        let startPosition =
            this.topMarker.getBoundingClientRect().top - document.body.getBoundingClientRect().top

        fromEvent(window, "scroll")
            .pipe(takeUntil(this.destroyed$), throttleTime(10), distinctUntilChanged())
            .subscribe(() => {
                if (window.scrollY > startPosition) {
                    this.renderer.addClass(this.element.nativeElement, this.appStickyClass)

                    if (window.scrollY > stopPosition) {
                        this.renderer.setStyle(
                            this.element.nativeElement,
                            "top",
                            "" + (stopPosition - window.scrollY) + "px"
                        )
                    } else {
                        this.renderer.setStyle(this.element.nativeElement, "top", 0)
                    }
                } else {
                    this.renderer.removeClass(this.element.nativeElement, this.appStickyClass)
                }
            })
    }

    ngOnDestroy(): void {
        this.destroyed$.next()
        this.destroyed$.complete()
    }
}
