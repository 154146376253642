import { Component, Input } from "@angular/core"
import { ModalRef } from "../../modal/modal-ref"

@Component({
    selector: "app-text-display-modal",
    templateUrl: "./text-display-modal.component.html",
    styleUrls: ["./text-display-modal.component.scss"],
})
export class TextDisplayModalComponent {
    @Input() public text: string
    @Input() public role: string = ""
    @Input() public printURL: string = null

    constructor(public modalRef: ModalRef) {}

    public printText() {
        const newWindow = window.open(
            this.printURL,
            "",
            "height=700,width=1280,scrollbars=yes,resizable=yes"
        )
        if (window.focus) {
            newWindow.focus()
        }
    }
}
