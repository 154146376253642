<div (click)="closeModal()" class="close close-modal">
    <i class="pbslm-close-thin-cross close-img"></i>
</div>
<div id="assign-modal">
    <div class="modal-header">
        <h2 class="title">
            <app-icon class="assign-class-icon" type="AssignClass"></app-icon>Assign to Class
        </h2>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="step === 1">
            <app-select-class-view
                (step)="stepChange($event)"
                [activeModal]="activeModal"
                [assignmentCode]="assignmentCode"
                [assignmentHasLti]="assignmentHasLti"
                [assignmentName]="assignmentName"
                [rosters]="rosters"
                [type]="type"
            ></app-select-class-view>
        </ng-container>
        <ng-container *ngIf="step === 2">
            <app-select-student-view
                (step)="stepChange($event)"
                [rosters]="rosters"
            ></app-select-student-view>
        </ng-container>
        <ng-container *ngIf="step === 3">
            <app-loading-view
                (resendRequest)="resendRequest()"
                [error]="error"
                [loading]="loading"
            ></app-loading-view>
        </ng-container>
        <ng-container *ngIf="step === 4">
            <app-review-assignments-view
                [activeModal]="activeModal"
                [assignmentCode]="assignmentCode"
                [assignmentName]="assignmentName"
                [rosters]="rosters"
            ></app-review-assignments-view>
        </ng-container>
    </div>
</div>
