import { EventEmitter, Injectable } from "@angular/core"
import { catchError, map } from "rxjs/operators"
import { LmApiService } from "./lm-api.service"
import {
    AssignmentListItem,
    AssignmentResultsResponse,
    AssignmentResults,
    LessonBuilderStudentResult,
    StudentLessonResultResponse,
    StudentResult,
} from "../tools/teacher-assignments/models/teacherAssignments.model"
import { Observable, of, throwError } from "rxjs"
import { HttpErrorResponse } from "@angular/common/http"
import { Router } from "@angular/router"

@Injectable()
export class TeacherAssignmentsService {
    public requestRefreshAssignments = new EventEmitter<boolean>()

    constructor(private lmApiService: LmApiService, private router: Router) {}

    public viewLatestAssignedAssignments() {
        let assignedAssignmentsURL = "/tools/assignments"
        if (this.router.url === assignedAssignmentsURL) {
            this.requestRefreshAssignments.emit(true)
        } else {
            this.router.navigate([assignedAssignmentsURL])
        }
    }

    public getAssignment(code: string): Observable<AssignmentResultsResponse> {
        return this.lmApiService.get(`/api/v2/assignments/${code}/`, { responseType: "json" }).pipe(
            map((response) => {
                return {
                    assignmentResults: AssignmentResults.fromData(response),
                    error: null,
                }
            }),
            catchError((error) => of({ assignmentResults: null, error: error }))
        )
    }

    public getAssignments(
        areAssigned: boolean,
        limit: number,
        offset: number,
        ordering: string = ""
    ) {
        const params = limit !== undefined && offset !== undefined ? { limit, offset } : {}
        if (ordering) {
            if (ordering === "oldest") {
                // @ts-ignore
                params.ordering = areAssigned ? "date_assigned" : "date_created"
            } else if (ordering === "recent") {
                // @ts-ignore
                params.ordering = areAssigned ? "-date_assigned" : "-date_created"
            }
        }
        const status = areAssigned ? "assigned" : "awaiting"
        const url = `/api/v2/assignments/?status=${status}`
        return this.lmApiService.get(url, { responseType: "json", params }).pipe(
            map((response) => {
                response.assignments = response.results.map((d) =>
                    AssignmentListItem.fromData(d, areAssigned)
                )
                return response
            }),
            catchError((error) => this.lmApiService.handleErrorForPageLoading(error))
        )
    }

    public deleteAssignment(assignment: AssignmentListItem) {
        return this.lmApiService
            .delete(`/api/v2/assignments/${assignment.code}/`)
            .pipe(catchError((error) => this.lmApiService.handleErrorForDeleteItem(error)))
    }

    public deleteResult(result: StudentResult, rosterID) {
        return this.lmApiService
            .delete(`/api/v2/assignments-results/${result.id}/?roster=${rosterID}`)
            .pipe(catchError((error) => this.lmApiService.handleErrorForDeleteItem(error)))
    }

    public getAssignmentResult(
        resultId: string,
        rosterID
    ): Observable<StudentLessonResultResponse> {
        return this.lmApiService
            .get(`/api/v2/assignments-results/${resultId}/?roster=${rosterID}`, {
                responseType: "json",
            })
            .pipe(
                map((response) => {
                    return {
                        assignmentResults: LessonBuilderStudentResult.fromData(response),
                        error: null,
                    }
                }),
                catchError((error) => of({ assignmentResults: null, error: error }))
            )
    }

    public duplicateAssignment(assignmentCode: string, assignmentData) {
        return this.lmApiService
            .post(`/api/v2/assignments/${assignmentCode}/duplicate-assignment/`, assignmentData)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    const defaultMessage =
                        "Could not duplicate the assignment, please try again later."
                    this.lmApiService.showValidationErrors(err.error, defaultMessage)
                    return throwError(err)
                })
            )
    }
}
