import { Component, Input, OnInit } from "@angular/core"
import Utils from "../../utils/utils"

@Component({
    selector: "app-copy-link",
    templateUrl: "./copy-link.component.html",
    styleUrls: ["./copy-link.component.scss"],
})
export class CopyLinkComponent implements OnInit {
    @Input() shareLink: string = ""
    @Input() isButton: boolean
    @Input() label: string
    @Input() buttonClasses?: string = ""
    isCopied: boolean

    constructor() {}

    public ngOnInit() {
        this.shareLink = this.shareLink ? this.shareLink : window.location.href
    }

    public copyLink(id) {
        Utils.copyTextToClipboard(this.shareLink)
        this.isCopied = true
    }
}
