import { Component, EventEmitter, Input, Output } from "@angular/core"

@Component({
    selector: "app-loading-view",
    templateUrl: "./loading-view.component.html",
    styleUrls: ["./loading-view.component.scss"],
})
export class LoadingViewComponent {
    @Input() public loading: boolean = true
    @Input() public error: boolean = true
    @Output() public resendRequest = new EventEmitter<boolean>()

    public constructor() {}
    public tryAgain() {
        this.resendRequest.emit(true)
    }
}
