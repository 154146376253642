import { Component, Input } from "@angular/core"
import { UserService } from "../../../../services/user.service"
import { STATIC_IMAGES } from "../../../../models/constants"

@Component({
    selector: "app-resource-card-poster",
    templateUrl: "./resource-card-poster.component.html",
    styleUrls: ["./resource-card-poster.component.scss"],
})
export class ResourceCardPosterComponent {
    @Input() posterImage = null
    @Input() link = null
    @Input() duration = null
    @Input() isInline: boolean = false
    @Input() gtmPrefix: string = ""
    @Input() absoluteUrl: string = ""
    @Input() title: string = null
    @Input() mediaType: string = ""
    @Input() gtmDataCardNumber: number
    placeholderImg: string = null

    constructor(public userService: UserService) {
        this.placeholderImg = STATIC_IMAGES.placeholder_image
    }

    public getFormattedDuration(): string {
        return ResourceCardPosterComponent.formatDuration(this.duration)
    }

    public static formatDuration(duration): string {
        if (!duration) {
            return null
        }
        if (duration.substring(0, 2) === "0:") {
            duration = duration.substring(2)
        }
        if (duration && duration[0] === "0") {
            duration = duration.substring(1)
        }
        return duration
    }

    public getResourcePosterClass() {
        if (this.gtmPrefix === "gtm-results-search-card") {
            return `card-header-content gtm-results-search-card`
        } else if (this.gtmPrefix === "gtm-results-browse-card") {
            return `card-header-content gtm-results-browse-card`
        }

        return `card-header-content gtm-${this.gtmPrefix}-resource-poster`
    }
}
