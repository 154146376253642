import { Injectable } from "@angular/core"
import { AbstractDataPrivacyDropdown } from "../../abstract-dropdown.service"
import { ISchool } from "../../../../models/userDataPrivacy.model"
import { UserDataPrivacyService } from "../../../../services/user-data-privacy.service"

@Injectable()
export class SchoolDropdownService extends AbstractDataPrivacyDropdown<ISchool> {
    constructor(private userDataPrivacyService: UserDataPrivacyService) {
        super()

        const getSchoolsCallback = (...args) => this.userDataPrivacyService.getSchools(...args)
        const getDataNyNextUrlCallback = (nextUrl) =>
            this.userDataPrivacyService.getDataByNextUrl(nextUrl)

        this.registerDataStream(getSchoolsCallback)
        this.registerTypeaheadStream(getSchoolsCallback)
        this.registerOnScrollToEnd(getDataNyNextUrlCallback)
    }

    trackByFn(item): number {
        return item.id
    }
}
