import { Component, Input } from "@angular/core"

@Component({
    selector: "app-marketing-description",
    templateUrl: "./marketing-description.component.html",
    styleUrls: ["./marketing-description.component.scss"],
})
export class MarketingDescriptionComponent {
    @Input() public imageSide?: string = "right"
    @Input() public marketingImage?: string = ""

    constructor() {}
}
