<div class="modal-header">
    <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
    >
        <i class="pbslm-close-thin-cross close-img"></i>
    </button>
</div>

<div class="modal-body py-7">
    <p>No problem! We will email you a reminder before the end of the school year.</p>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="btn btn-outline-secondary py-2 px-4 position-relative float-right"
        (click)="activeModal.close()"
    >
        Close
    </button>
</div>
