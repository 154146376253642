<div
    *ngIf="featuredLearningObjects && featuredLearningObjects.length > 0"
    class="container-fluid selenium-app-featured"
>
    <div class="row-auto">
        <app-carousel2
            class="w-100"
            [itemWidth]="240"
            [alwaysShowArrows]="false"
            [innerArrows]="true"
            height="410"
            [gtmPrefix]="gtmPrefix"
        >
            <app-carousel-item2 *ngFor="let learningObject of featuredLearningObjects">
                <app-resource-featured-card
                    [headerURL]="getBrowseURL(learningObject)"
                    [learningObject]="learningObject"
                    [gtmPrefix]="gtmPrefix"
                ></app-resource-featured-card>
            </app-carousel-item2>
        </app-carousel2>
    </div>
</div>
