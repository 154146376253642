import { Component, Input } from "@angular/core"
import { LearningObject } from "../../models/learningObject.model"
import { UserService } from "../../services/user.service"
import { STATIC_IMAGES } from "../../models/constants"

@Component({
    selector: "app-card",
    template: "",
})
export class CardComponent {
    @Input() public showSubject: boolean = false
    @Input() public showAsCollection: boolean = false
    @Input() public learningObject: LearningObject = null
    @Input() public gtmPrefix: string = ""
    public placeholderImg: string = null

    constructor(public userService: UserService) {
        this.placeholderImg = STATIC_IMAGES.placeholder_image
    }

    public getAbsoluteUrl() {
        return this.learningObject.canonicalUrl
    }

    public getSubjectClass() {
        return `card-subject gtm-${this.gtmPrefix}-subject`
    }

    public getResourceBodyClass() {
        return `gtm-${this.gtmPrefix}-resource-body`
    }
}
