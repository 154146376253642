import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { CHNode } from "../../../models/node.model"
import { EduComponent } from "../../../utils/eduComponent"
import { BrowsingService } from "../../../services/browsing.service"
import { ActivatedRoute, NavigationStart, Router } from "@angular/router"
import { SubjectsDropdownComponent } from "../subjects-dropdown/subjects-dropdown.component"
import { GRADE_BANDS, STUDENT_GATE_FILTERED_PAGES } from "../../../models/constants"
import { GradesDropdownComponent } from "../grades-dropdown/grades-dropdown.component"
import { UserService } from "../../../services/user.service"
import { LMModalService } from "../../../services/lm-modal.service"
import { StudentGateService } from "../../../services/student-gate.service"
import { ModalService } from "../../modal/modal.service"
import { Observable, Subject } from "rxjs"
import { takeUntil, tap } from "rxjs/operators"
import Utils from "../../../utils/utils"
import { AddOnViewService } from "../../../services/add-on-view.service"
import { GradeBand } from "../../../models/gradeBand.model"

@Component({
    selector: "app-student-menu",
    templateUrl: "./student-menu.component.html",
    styleUrls: ["./student-menu.component.scss"],
})
export class StudentMenuComponent extends EduComponent implements OnInit, OnDestroy {
    @ViewChild(SubjectsDropdownComponent) subjectsDropdown: SubjectsDropdownComponent
    @ViewChild(GradesDropdownComponent) gradesDropdown: GradesDropdownComponent

    @Input()
    badgeCount: number = 0

    gradeBands: GradeBand[] = GRADE_BANDS
    subjects: CHNode[] = []
    queryParams: any

    previousUrl: string = window.location.pathname
    private destroyed$: Subject<void> = new Subject<void>()

    routerEventsObservable$: Observable<any> = this.router.events.pipe(
        takeUntil(this.destroyed$),
        tap((routerEvent) => {
            // @ts-ignore
            if (!!routerEvent?.url && !this.studentGateService.selectedGradeValue)
                // @ts-ignore
                this.studentGateService.isContentPage(routerEvent.url)
                    ? this.resolveParams(routerEvent)
                    : this.checkAndDisplayStudentGateModal(routerEvent["url"])
        })
    )

    constructor(
        private userService: UserService,
        private browsingService: BrowsingService,
        private router: Router,
        private studentGateService: StudentGateService,
        private activatedRoute: ActivatedRoute,
        private addOnService: AddOnViewService
    ) {
        super()
    }

    ngOnInit() {
        this.addSub(
            this.browsingService.getSubjectsTree().subscribe(
                (data: CHNode[]) => {
                    this.subjects = data
                },
                (error) => console.error(`[CUSTOM Error]${error}`)
            )
        )

        this.addSub(
            this.activatedRoute.queryParams.subscribe((value) => (this.queryParams = value))
        )
        if (
            this.userService.hasStudentExperience() &&
            !this.studentGateService.selectedGradeValue
        ) {
            this.routerEventsObservable$.subscribe()
            this.checkAndDisplayStudentGateModal(window.location.href)
        }
    }

    navigateTo(url: string[]): void {
        const params = {}
        if (this.userService.hasStudentExperience()) params["student"] = true
        this.router.navigate(url, { queryParams: params })
    }

    checkAndDisplayStudentGateModal(event) {
        let isWhitelistedPage = this.studentGateService.isPageAllowingDisplayModal(event)
        let displayModal = this.studentGateService.isStudentHomePage(event) || isWhitelistedPage
        if (
            this.isStudentGateSpecificPage(event) &&
            displayModal &&
            !this.isResourcePageFirstNavigation(event) &&
            !this.studentGateService.isLgModalDisplayed &&
            !this.studentGateService.selectedGradeValue
        ) {
            this.studentGateService.openStudentGateModal()
        }
    }

    isStudentGateSpecificPage(event: string): boolean {
        //regExp checks if the string starts with /student/ and is followed by 3
        // or more chars - the cases for all the student old FE pages
        let regExExpression = /^\/student\/.{3,}$/
        let urlPathName = event.startsWith("/") ? event : new URL(event).pathname
        let isStudentGatePage = !!STUDENT_GATE_FILTERED_PAGES.filter((value: string) => {
            if (event.startsWith("http")) {
                return new RegExp("" + value + ".{0,}$").test(event)
            }
            return (
                new RegExp("" + event + ".{0,}$").test(value) ||
                new RegExp("" + value + ".{0,}$").test(event)
            )
        }).length
        if (
            !isStudentGatePage &&
            regExExpression.test(urlPathName) &&
            window.location.search.includes("student=true")
        )
            return false
        return isStudentGatePage
    }

    isResourcePageFirstNavigation(event): boolean {
        if (Utils.testIfPathnameFitRegEx("/collection/.{3,}$")) return false
        let previousRoute = Utils.extractUrlWithoutQueryParams(document.referrer)
        let isResourcePath = Utils.testIfPathnameFitRegEx("/resource/.{3,}$")
        let isTheSamePage =
            previousRoute.includes(Utils.extractUrlWithoutQueryParams(event)) ||
            window.location.pathname.includes(Utils.extractUrlWithoutQueryParams(event)) ||
            previousRoute.length === 0
        return isTheSamePage && isResourcePath
    }

    onTogglePanel(panelName: string): void {
        panelName === "subject"
            ? (this.gradesDropdown.isGradesPanelOpened = false)
            : (this.subjectsDropdown.isSubjectsPanelOpened = false)
        if (this.userService.hasStudentExperience() && panelName !== "subject") {
            this.studentGateService.isGateActive = !this.studentGateService.isGateOpened.value
        }
    }

    public resolveParams(event) {
        let isTheSamePage: boolean = true
        let isFullStudentExperience: boolean = false
        if (!!event["url"]) {
            isTheSamePage = Utils.isTheSamePage(this.previousUrl, event["url"].toString())
            isFullStudentExperience =
                event["url"].includes("student=true") && !this.addOnService.isAddonView
        }
        if (
            event instanceof NavigationStart &&
            this.isNextPageModalDisplayRequired(
                isFullStudentExperience,
                isTheSamePage,
                event["url"]
            )
        ) {
            this.studentGateService.openStudentGateModal()
            this.studentGateService.navigationOnHold = true
        }
    }

    isNextPageModalDisplayRequired(isFullStudentExperience, isTheSamePage, url): boolean {
        if (url.includes("/search")) return false
        let isWhitelistedPage =
            STUDENT_GATE_FILTERED_PAGES.filter((value: string): boolean =>
                value.startsWith("/student")
                    ? this.studentGateService.isStudentHomePage(url)
                    : new RegExp("^" + value + ".{0,}$").test(url)
            ).length === 1
        return (
            isFullStudentExperience &&
            !this.studentGateService.selectedGrade &&
            !isTheSamePage &&
            isWhitelistedPage
        )
    }

    getTooltipText(tooltipName: string): string {
        if (this.userService.isLoggedIn()) return

        switch (tooltipName) {
            case "classes":
                return `Sign In to view your classes.
                        Enter a roster code to join a class.`
            case "favorites":
                return `Sign In to save links in your Favorites.
                        Search or browse the site without signing in.`
            case "assignments":
                return `Sign In to start or resume an assignment.
                        Enter an assignment code, search or browse the site without signing in.`
            default:
                return ""
        }
    }

    ngOnDestroy() {
        this.destroyed$.next()
        this.destroyed$.complete()
    }
}
