import { Component, forwardRef, OnDestroy, OnInit } from "@angular/core"
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms"
import { UserProfileService } from "../../services/user-profile.service"
import { Subject } from "rxjs"
import { takeUntil, tap } from "rxjs/operators"

@Component({
    selector: "app-user-profile-role-selector",
    templateUrl: "./user-profile-role-selector.component.html",
    styleUrls: ["./user-profile-role-selector.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserProfileRoleSelectorComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => UserProfileRoleSelectorComponent),
        },
    ],
})
export class UserProfileRoleSelectorComponent
    implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    private destroy$ = new Subject()

    onChange = (val) => {}
    onTouched = () => {}

    roles: string[] = []
    otherInputControl: FormControl

    roleFormGroup: FormGroup = this.fb.group({
        predefined: [""],
        other: ["", Validators.required],
    })

    isVisible = false
    roleSelected = "Choose Role"
    isDisabled: boolean = false

    constructor(private fb: FormBuilder, private userProfileService: UserProfileService) {}

    ngOnInit(): void {
        this.roles = this.userProfileService.getTeacherRoles()
        this.otherInputControl = this.fb.control("")
        this.otherInputControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                tap((data) => (this.roleSelected = data))
            )
            .subscribe((data) =>
                this.roleFormGroup.patchValue({ predefined: "Other", other: data })
            )
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }

    writeValue(role: { predefined: string; other: string }): void {
        if (role.other) {
            this.roleSelected = role.other
            this.otherInputControl.setValue(role.other)
        } else {
            this.roleSelected = role.predefined
        }
        this.roleFormGroup.setValue(role)
    }

    registerOnChange(fn: any): void {
        this.roleFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fn)
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const role = this.roleFormGroup?.value
        if (!role || ((role.predefined === "Other" || !role.predefined) && !role.other)) {
            return {
                error: {
                    message: this.validationMessage(),
                },
            }
        }
        return null
    }

    get predefinedControl(): FormControl {
        return this.roleFormGroup.get("predefined") as FormControl
    }

    get otherControl(): FormControl {
        return this.roleFormGroup.get("other") as FormControl
    }

    validationMessage(): string {
        const predefinedValue = this.predefinedControl.value
        const otherValue = this.otherControl.value
        if (predefinedValue !== "Other" && otherValue && !otherValue.match(/^[A-Za-z]/)) {
            return "Please enter a valid role name."
        } else if ((predefinedValue === "Other" || !predefinedValue) && !otherValue) {
            return "Please select a role!"
        }
        return null
    }

    onRoleClicked(role): void {
        this.otherInputControl.setValue(null)
        this.roleSelected = role
        this.roleFormGroup.setValue({ predefined: role, other: null })
        this.isVisible = false
    }

    onOtherClicked(): void {
        this.isVisible = !this.isVisible
    }
}
