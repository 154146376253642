import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { Location } from "@angular/common"
import { LmApiService } from "./lm-api.service"
import { catchError, map } from "rxjs/operators"
import { StudentAssignmentModel } from "../models/student-assignment/studentAssignment.model"
import { Question } from "../models/student-assignment/question.model"
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs"
import { redirectToError } from "../utils/error-handling"
import { URSService } from "./urs.service"

@Injectable()
export class StudentAssignmentService {
    public updateMarks: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public assignmentCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    private _modalClosed: Subject<boolean> = new Subject<boolean>()
    public readonly getModalClosed$: Observable<boolean> = this._modalClosed.asObservable()

    constructor(
        private lmApi: LmApiService,
        private router: Router,
        private location: Location,
        private ursService: URSService
    ) {}

    public setModalClosed(state: boolean) {
        this._modalClosed.next(state)
    }

    public getAssignmentByCode(code, resultId, preview = false) {
        let url = `/tools/api/v2/assignment/${code}/`
        if (resultId) {
            url += preview ? `?result_id=${resultId}&preview` : `?result_id=${resultId}`
        } else if (preview) {
            url += `?preview`
        }
        return this.lmApi.get(url).pipe(
            map((data: any) => {
                return StudentAssignmentModel.fromData(data, this.ursService)
            }),
            catchError((error) => redirectToError(this.router, this.location, error.status))
        )
    }

    public startQuickAssignment(name, code, preview = false) {
        const url = preview ? `/tools/api/preview/result/` : `/tools/api/v2/result/`
        return this.lmApi
            .post(url, {
                [url]: {},
                activity: `/tools/api/v2/activity/${code}/`,
                status: "started",
                student_name: name,
            })
            .pipe(catchError((error) => throwError(error)))
    }

    // this also resumes an assignment
    public startAssignment(resultId) {
        return this.lmApi
            .get(`/tools/api/v2/result/${resultId}/`)
            .pipe(catchError((error) => throwError(error)))
    }

    public completeAssignment(resultId, preview = false) {
        const url = preview ? `/tools/api/preview/result0/` : `/tools/api/v2/result/${resultId}/`

        return this.lmApi
            .put(url, {
                completed: true,
                status: "completed",
            })
            .pipe(catchError((error) => throwError(error)))
    }

    public submitAnswer(question: Question, resultId: number, preview: boolean = false) {
        const url = `/api/v2/questions/${question.id}/results/${
            preview ? "preview" : resultId
        }/answers/`
        const data = { choice: question.userInput || "" }

        return this.lmApi.post(url, data).pipe(
            map((res: any) => {
                const choiceId = res.choice || ""

                return preview
                    ? { correct: res.correct }
                    : {
                          text: [res.input],
                          options: [+choiceId],
                          correct: res.correct,
                      }
            }),
            catchError((error) => throwError(error))
        )
    }
}
