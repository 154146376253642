import { Component, HostBinding, Input, ViewEncapsulation } from "@angular/core"
import { ModalRef } from "./modal-ref"

@Component({
    selector: "app-modal-backdrop",
    template: ``,
    encapsulation: ViewEncapsulation.None,
})
export class ModalBackdropComponent {
    public activeModal: ModalRef
    public clickOutsideToClose: boolean
    @HostBinding("style") styleBinding = "z-index: 1050"
    @HostBinding("class") get classBinding() {
        return `modal-backdrop show ${this.backdropClass ? this.backdropClass : ""}`
    }

    // TODO: backdropClass needs implementing
    @Input() backdropClass: string
}
