import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core"
import { Title } from "@angular/platform-browser"
import { UserProfile, UserProfileAPI } from "../models/userProfile.model"
import { UserProfileService } from "../services/user-profile.service"
import { GTMService } from "../services/gtm.service"
import { ModalService } from "../shared-components/modal/modal.service"
import { PBSLM_TITLE } from "../models/constants"
import { LocalizationService } from "../services/localization.service"
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from "rxjs"
import { FormBuilder, FormControl, FormGroup } from "@angular/forms"
import { IWindowResizeEvent, WindowSizeService } from "../services/window-size.service"
import { ActivatedRoute } from "@angular/router"
import { map, shareReplay, startWith, takeUntil, tap } from "rxjs/operators"
import { Localize } from "../models/localize.model"
import { NgbAccordion } from "@ng-bootstrap/ng-bootstrap"
import { LocalizationModalComponent } from "../shared-components/modals/localization-modal/localization-modal.component"
import { UserService } from "../services/user.service"
import { getDRFErrorMessages } from "../utils/error-handling"
import { NotificationModalComponent } from "../shared-components/modals/notification-modal/notification-modal.component"
import { CookiesService } from "../services/cookies.service"
import { Cookie } from "../models/cookie.model"
import Utils from "../utils/utils"
import { NotificationsService } from "angular2-notifications"
import { USER_PROFILE_BUTTON_TYPE } from "./user-profile-action-buttons/user-profile-action-buttons.component"
import { DOCUMENT } from "@angular/common"
import { AddOnViewService } from "../services/add-on-view.service"

// TODO FIX LEFT NAVIGATION MENU STICKY

enum SECTION_FRAGMENT {
    USER_PROFILE = "userProfile",
    PROFESSIONAL_INFO = "professionalInformation",
    STUDENT_PRIVACY = "studentPrivacy",
}

@Component({
    templateUrl: "./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit, AfterViewInit, OnDestroy {
    SECTION_FRAGMENT = SECTION_FRAGMENT

    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)

    isDirty$: Observable<boolean>
    previousFormValues$ = new BehaviorSubject(null)
    localizeData$: Observable<{ localizationLogo: string; stateStandards: string }>
    isMobile$: Observable<boolean>
    isDesktop$: Observable<boolean>
    userProfile$: Observable<UserProfile>

    userProfileForm!: FormGroup

    roles: any
    grades: any
    subjects: any

    userProfileData: UserProfile | null = null

    @ViewChild("ngbAccordion") ngbAccordion: NgbAccordion

    constructor(
        @Inject(DOCUMENT) public document: Document,
        public addOnViewService: AddOnViewService,
        private windowSize: WindowSizeService,
        private activateRoute: ActivatedRoute,
        private modalService: ModalService,
        private userProfileService: UserProfileService,
        private userService: UserService,
        private localizationService: LocalizationService,
        private titleService: Title,
        private gtmService: GTMService,
        private cookieService: CookiesService,
        private notificationService: NotificationsService,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle(`Profile | ${PBSLM_TITLE}`)
        this.gtmService.pushCleanPageView()

        this.grades = this.userProfileService.getGrades()
        this.subjects = this.userProfileService.getSubjects()

        this.userProfileForm = this.initializeUserProfileForm()

        // map data to user profile model and update form
        this.userProfile$ = this.userProfileService.getUserProfile().pipe(
            shareReplay(1),
            tap((userProfileData) => {
                this.userProfileData = userProfileData

                this.userProfileForm.patchValue({
                    fullName: {
                        firstName: userProfileData.firstName,
                        lastName: userProfileData.lastName,
                    },
                    accountInfo: {
                        email: userProfileData.email,
                        password: "************",
                        hasPublicMediaAccount: userProfileData.hasPublicMediaAccount,
                        publicMediaProfileLink: userProfileData.publicMediaProfileLink,
                    },
                    grades: userProfileData.gradeRange,
                    subjects: userProfileData.preferredSubjects,
                    userRole: {
                        predefined: userProfileData.userRole,
                        other: userProfileData.userSuggestedRole,
                    },
                })

                // set initial form value for resetting the form back to it when needed
                this.previousFormValues$.next(this.userProfileForm.value)

                this.userProfileForm.updateValueAndValidity({ emitEvent: false })

                if (!this.userService.shouldShowVirginiaModal) return
                this.showVirginiaModal()
            })
        )

        // data for both components used to display localization/state standards
        this.localizeData$ = this.localizationService.localizeData.pipe(
            takeUntil(this.destroyed$),
            shareReplay(1),
            map((localizeData: Localize) => ({
                localizationLogo: localizeData.getLogo() || "",
                stateStandards: localizeData.stateStandards || "None",
            }))
        )

        // responsible for showing/hiding the side navigation
        this.isDesktop$ = this.windowSize.resize.pipe(
            takeUntil(this.destroyed$),
            map((data: IWindowResizeEvent) => !data.isTablet)
        )

        // responsible for showing/hiding  mobile view/template
        this.isMobile$ = this.windowSize.resize.pipe(
            takeUntil(this.destroyed$),
            map((data: IWindowResizeEvent) => data.isMobile)
        )
        this.isDirty$ = combineLatest([
            this.previousFormValues$,
            this.userProfileForm.valueChanges,
        ]).pipe(
            map(([a, b]) => this.isFormDirty(a, b)),
            startWith(false),
            shareReplay(1)
        )
    }

    ngAfterViewInit(): void {
        const fragment = this.activateRoute.snapshot.fragment
        this.scrollToTargetAdjusted(fragment)
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }

    get fullNameFormControl(): FormControl {
        return this.userProfileForm.get("fullName") as FormControl
    }

    get gradesFormControl(): FormControl {
        return this.userProfileForm.get("grades") as FormControl
    }

    get subjectsFormControl(): FormControl {
        return this.userProfileForm.get("subjects") as FormControl
    }

    get userRoleFormControl(): FormControl {
        return this.userProfileForm.get("userRole") as FormControl
    }

    initializeUserProfileForm(): FormGroup {
        return this.fb.group({
            fullName: [""],
            accountInfo: [""],
            grades: [[]],
            subjects: [[]],
            userRole: [[]],
        })
    }

    // central method for handling submit/cancel form changes based on buttons events
    buttonClicked(btnType): void {
        switch (btnType) {
            case USER_PROFILE_BUTTON_TYPE.CANCEL:
                this.cancelFormChanges()
                break
            case USER_PROFILE_BUTTON_TYPE.SAVE:
                this.updateUserProfileData()

                this.userProfileService
                    .updateUserProfile(this.userProfileData.reverseToApiResponse())
                    .subscribe(
                        (_) => {
                            this.openProfileUpdateConfirmationModal(true)

                            // after a successful update, add initial value again
                            this.previousFormValues$.next(this.userProfileForm.value)

                            const nextUrl: Cookie = this.cookieService.getCookie("next")
                            if (!nextUrl) return

                            this.cookieService.deleteCookie("next")
                            window.location.replace(nextUrl.value)
                        },
                        (error) => {
                            const errors = getDRFErrorMessages(error)
                            if (errors && errors.length > 0) {
                                this.openProfileUpdateConfirmationModal(false)
                            } else {
                                this.openErrorModals(error)
                            }
                        }
                    )

                break
        }
    }

    scrollToTargetAdjusted(fragment, headerOffset = 30): void {
        if (!fragment) return

        const element = document.getElementById(fragment)
        if (!element) return

        let elementPosition = element.getBoundingClientRect().top
        let offsetPosition = elementPosition + window.scrollY - headerOffset

        window.location.hash = element.id
        window.scroll({ top: offsetPosition, behavior: "smooth" })
    }

    onPanelHidden(panelId: string): void {
        if (panelId !== SECTION_FRAGMENT.USER_PROFILE) return
        this.cancelFormChanges()
    }

    onChangeStation(): void {
        // we don't allow station changes if the form has changes
        if (this.isFormDirty(this.previousFormValues$.value, this.userProfileForm.value)) {
            this.modalService.open(NotificationModalComponent, {
                data: { message: "Please save changes before changing the local station" },
            })
            return
        }

        this.modalService.open(LocalizationModalComponent, {
            size: "lg",
            windowClass: "change-station-modal",
        })
    }

    private cancelFormChanges(): void {
        this.userProfileForm.reset(this.previousFormValues$.value)
    }

    private isFormDirty(oldValue, newValue): boolean {
        return (
            oldValue.fullName.firstName.trim() !== newValue.fullName.firstName.trim() ||
            oldValue.fullName.lastName.trim() !== newValue.fullName.lastName.trim() ||
            !Utils.areEqual(oldValue.grades, newValue.grades) ||
            !Utils.areEqual(oldValue.subjects, newValue.subjects) ||
            oldValue.userRole.other !== newValue.userRole.other ||
            oldValue.userRole.predefined !== newValue.userRole.predefined
        )
    }

    private updateUserProfileData(): void {
        this.userProfileData.firstName = this.userProfileForm.value.fullName.firstName.trim()
        this.userProfileData.lastName = this.userProfileForm.value.fullName.lastName.trim()
        this.userProfileData.gradeRange = this.userProfileForm.value.grades
        this.userProfileData.preferredSubjects = this.userProfileForm.value.subjects
        this.userProfileData.userRole = this.userProfileForm.value.userRole.predefined.trim()
        this.userProfileData.userSuggestedRole = this.userProfileForm.value.userRole.other
    }

    private showVirginiaModal(): void {
        this.modalService.open(LocalizationModalComponent, {
            data: {
                virginiaLocalization: true,
                shouldShowVirginiaModal: false,
            },
        })
    }

    private openProfileUpdateConfirmationModal(profileUpdateState: boolean): void {
        this.modalService.open(NotificationModalComponent, {
            data: {
                message: profileUpdateState
                    ? "Your profile information has been saved."
                    : "Something unexpected happened and the profile changes couldn't be saved. Please try again!",
            },
        })
    }

    private openErrorModals(errors: Record<string, any>): void {
        let messages: string[] = Utils.concatenateFlattenErrors(errors.error)
        messages.forEach((message) => {
            this.notificationService.error(
                "Please fix errors first",
                message,
                Utils.alertConfiguration
            )
        })
    }
}
