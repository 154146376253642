import { Component, OnInit } from "@angular/core"
import { UserService } from "../../services/user.service"
import { Observable } from "rxjs"
import { AdminHeaderData } from "../../models/adminHeaderData.model"

@Component({
    selector: "app-admin-header",
    templateUrl: "./admin-header.component.html",
    styleUrls: ["./admin-header.component.scss"],
})
export class AdminHeaderComponent implements OnInit {
    adminHeaderData$: Observable<AdminHeaderData>

    constructor(public userService: UserService) {}

    ngOnInit(): void {
        this.adminHeaderData$ = this.userService.adminHeaderData$
    }
}
