import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { UserService } from "../../../services/user.service"
import { ModalRef } from "../../modal/modal-ref"
import { STATIC_IMAGES, studentSecretQuestionOptions } from "../../../models/constants"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { StudentSignupModalComponent } from "../student-signup-modal/student-signup-modal.component"
import { ModalService } from "../../modal/modal.service"
import { takeUntil } from "rxjs/operators"
import { StudentSignIn } from "../../../models/studentSignIn.model"
import { Subject, Subscription } from "rxjs"
import { ReCaptchaV3Service } from "ng-recaptcha"

@Component({
    selector: "app-student-sign-in-modal",
    templateUrl: "student-sign-in-modal.component.html",
    styleUrls: ["student-sign-in-modal.component.scss"],
})
export class StudentSignInModalComponent implements OnInit, OnDestroy {
    @Input() public nextURL: string = ""
    @Input() displayTitle: boolean = true

    private reCaptchaExecutionSubscription: Subscription
    private destroyed$: Subject<void> = new Subject<void>()
    googleImage: string = null
    loginRedirectLink: string
    errorLogInMessage: string = null
    secretAnswerInputType: string = "password"
    signInFormGroup = new FormGroup({
        username: new FormControl("", [Validators.required, Validators.pattern(".{3,}$")]),
        questionOptions: new FormControl("", Validators.required),
        secretQuestion: new FormControl("", [Validators.required, Validators.pattern(".{3,}$")]),
    })

    questionOptions: { id: number; name: string }[] = studentSecretQuestionOptions

    constructor(
        public activeModal: ModalRef,
        public userService: UserService,
        private modalService: ModalService,
        private reCaptchaV3Service: ReCaptchaV3Service
    ) {
        this.googleImage = STATIC_IMAGES.auth_google
    }

    ngOnInit() {
        this.loginRedirectLink = this.userService.getGoogleAuthUrl(true)
    }

    public verifyCaptcha() {
        this.unsubscribeReCaptcha()
        this.reCaptchaExecutionSubscription = this.reCaptchaV3Service
            .execute("studentSignin")
            .subscribe((token: string) => this.submitSignIn(token))
    }

    submitSignIn(token: string) {
        const data: StudentSignIn = {
            username: this.usernameFormControl.value,
            questionType: this.questionOptionsFormControl.value,
            questionAnswer: this.secretQuestionFormControl.value,
            captcha: token,
        }

        this.userService
            .studentLogIn(data, this.userService.getCurrentPath())
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                (response) => response?.authenticated && location.reload(),
                (errorResponse) => {
                    if (errorResponse.error?.nonFieldErrors) {
                        this.errorLogInMessage = errorResponse.error?.nonFieldErrors?.toString()
                    } else if (errorResponse.error?.captcha) {
                        this.errorLogInMessage = errorResponse.error?.captcha?.toString()
                    } else {
                        this.errorLogInMessage = "Something went wrong, please try again later."
                    }
                }
            )
    }

    get usernameFormControl(): FormControl {
        return this.signInFormGroup.get("username") as FormControl
    }
    get secretQuestionFormControl(): FormControl {
        return this.signInFormGroup.get("secretQuestion") as FormControl
    }

    get questionOptionsFormControl(): FormControl {
        return this.signInFormGroup.get("questionOptions") as FormControl
    }

    openStudentSignUp() {
        this.activeModal.close()
        this.modalService.open(StudentSignupModalComponent, { size: "lg" })
    }

    changeSecretAnswerInputType() {
        if (this.secretAnswerInputType === "password") {
            this.secretAnswerInputType = "text"
        } else {
            this.secretAnswerInputType = "password"
        }
    }

    toggleReCaptcha(display: boolean) {
        const element: HTMLElement = document.getElementsByClassName(
            "grecaptcha-badge"
        )[0] as HTMLElement
        if (element) {
            element.style.visibility = display ? "visible" : "hidden"
        }
    }

    private unsubscribeReCaptcha() {
        if (!!this.reCaptchaExecutionSubscription) this.reCaptchaExecutionSubscription.unsubscribe()
    }

    ngOnDestroy() {
        this.destroyed$.next()
        this.destroyed$.complete()
        this.toggleReCaptcha(false)
        this.unsubscribeReCaptcha()
    }
}
