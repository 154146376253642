import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"

@Injectable()
export class CautionFlagsService {
    private _isCautionsFlagDisplayed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    private _isCautionsTabToggled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public playMediaResource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public set cautionFlagDisplay(value: boolean) {
        this._isCautionsFlagDisplayed.next(value)
    }

    public set playMedia(value: boolean) {
        this.playMediaResource.next(value)
    }

    public get cautionFlagDisplayObservable(): Observable<boolean> {
        return this._isCautionsFlagDisplayed.asObservable()
    }

    public set cautionFlagTabToggle(value: boolean) {
        this._isCautionsTabToggled.next(value)
    }

    public get cautionFlagTabDisplayObservable(): Observable<boolean> {
        return this._isCautionsTabToggled.asObservable()
    }

    public get isContentBoxDisplayed(): boolean {
        return this._isCautionsFlagDisplayed.value
    }

    isResourceCautionFlagSkipped(dbData: [], resourceURL: string) {
        this.cautionFlagDisplay = !dbData.some(
            (item: unknown) => Object.values(item)[0] === resourceURL
        )
    }
}
