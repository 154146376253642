import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { CardMetaComponent } from "../card-meta.component"
import { AddOnViewService } from "../../../../../services/add-on-view.service"
import { ReplaySubject } from "rxjs"
import { takeUntil } from "rxjs/operators"

@Component({
    selector: "app-search-card-meta",
    templateUrl: "./search-card-meta.component.html",
    styleUrls: ["./search-card-meta.component.scss"],
})
export class SearchCardMetaComponent extends CardMetaComponent implements OnInit, OnDestroy {
    @Input() gtmDataCardNumber: number
    @Input() gtmPrefix: string
    @Input() resourceCode: string

    isAddonView: boolean
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)

    constructor(private addOnService: AddOnViewService) {
        super()
    }

    ngOnInit() {
        this.isAddonView = this.addOnService.isAddonView
    }

    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }

    classroomDirectAssign() {
        this.addOnService
            .createAttachment(this.resourceCode)
            .pipe(takeUntil(this.destroyed$))
            .subscribe()
    }
}
