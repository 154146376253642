<div class="d-flex justify-content-end my-xs-4 my-md-0">
    <button
        *ngIf="isCancelVisible"
        (click)="btnClicked(USER_PROFILE_BUTTON_TYPE.CANCEL)"
        type="button"
        class="btn btn-outline-secondary fw-bold font-size-14 px-4 py-2 me-4 selenium-cancel-edit-btn"
    >
        Cancel
    </button>
    <button
        [disabled]="isSaveDisabled"
        (click)="btnClicked(USER_PROFILE_BUTTON_TYPE.SAVE)"
        type="button"
        class="btn btn-primary fw-bold font-size-14 px-4 py-2 selenium-edit-or-save-btn gtm-profile-save-changes"
    >
        Save Changes
    </button>
</div>
