<div *ngIf="!buttonLabelText" class="d-flex justify-content-center">
    <button class="sign-in-secondary-btn me-4 selenium-sign-in" (click)="openSignInModal()">
        Sign in
    </button>
    <button
        class="selenium-sign-up"
        [class]="(isTablet && 'sign-up-btn') || 'sign-in-primary-btn'"
        (click)="openSignUpModal()"
    >
        {{ buttonLabel }}
    </button>
</div>

<button
    *ngIf="buttonLabelText"
    class="sign-in-primary-btn me-6 me-md-8"
    (click)="openSignInModal()"
>
    {{ buttonLabelText }}
</button>
