import { Component, Input } from "@angular/core"
import { Router } from "@angular/router"

import { FORM_STACK_SUPPORT_URL } from "../../../models/constants"

enum LABEL_TEXT {
    AWAITING_ASSIGNMENTS = "Go To Assignments",
    ASSIGNED_ASSIGNMENTS = "Go To Assignments",
    NEW_LESSON_BUILDER = "Build Your First Lesson",
}

enum NAVIGATE_TO_URL {
    AWAITING_ASSIGNMENTS = "/tools/assignments/tab/awaiting/",
    ASSIGNED_ASSIGNMENTS = "/tools/assignments/",
    NEW_LESSON_BUILDER = "/tools/lessonbuilder/new/",
}

enum NAVIGATE_TO {
    AWAITING_ASSIGNMENTS = "AWAITING_ASSIGNMENTS",
    ASSIGNED_ASSIGNMENTS = "ASSIGNED_ASSIGNMENTS",
    NEW_LESSON_BUILDER = "NEW_LESSON_BUILDER",
}

const NOT_SUPPLIED = "Not supplied"

@Component({
    selector: "app-data-privacy-completed",
    templateUrl: "./data-privacy-completed.component.html",
    styleUrls: ["./data-privacy-completed.component.scss"],
})
export class DataPrivacyCompletedComponent {
    label = LABEL_TEXT.AWAITING_ASSIGNMENTS

    formStackUrl = FORM_STACK_SUPPORT_URL

    schoolDistrictName = ""
    schoolName = ""

    navUrl = NAVIGATE_TO_URL[NAVIGATE_TO.AWAITING_ASSIGNMENTS]

    @Input()
    set assignments({ assignments, dataPrivacy }) {
        const navigateTo = this.getNavigateTo(assignments)

        this.label = LABEL_TEXT[navigateTo]
        this.navUrl = NAVIGATE_TO_URL[navigateTo]

        this.schoolDistrictName = Boolean(dataPrivacy?.school_district?.district_name)
            ? dataPrivacy.school_district.district_name
            : NOT_SUPPLIED

        this.schoolName = Boolean(dataPrivacy?.school_name) ? dataPrivacy.school_name : NOT_SUPPLIED
    }

    constructor(public router: Router) {}

    /**
     * Returns where to redirect the user based on assignments type
     * @param assignments
     * @private
     */
    private getNavigateTo(assignments: { awaiting: number; assigned: number }): NAVIGATE_TO {
        if (assignments.awaiting > 0) return NAVIGATE_TO.AWAITING_ASSIGNMENTS
        if (assignments.assigned > 0) return NAVIGATE_TO.ASSIGNED_ASSIGNMENTS
        return NAVIGATE_TO.NEW_LESSON_BUILDER
    }
}
