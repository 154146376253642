import { Component, Input } from "@angular/core"

@Component({
    selector: "app-button",
    templateUrl: "./button.component.html",
    styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
    @Input() public disable: boolean = false
    @Input() public gtmLabel: string = null
    @Input() public buttonClasses?: string = ""
    @Input() public btnColor?: string
    @Input() public btnHoverColor?: string
    public hover: boolean = false

    public setColors() {
        if (this.btnColor && this.btnHoverColor) {
            return {
                "border-color": this.hover ? this.btnHoverColor : this.btnColor,
                "background-color": this.hover ? this.btnHoverColor : this.btnColor,
            }
        }
    }
}
