<div class="modal-header">
    <button
        type="button"
        class="btn-close gtm-close-btn"
        aria-label="Close"
        (click)="activeModal.dismiss(DISMISS_MODAL_REASON.CLOSE)"
    >
        <i class="pbslm-close-thin-cross close-img"></i>
    </button>
</div>

<div class="modal-body">
    <ng-container *ngIf="activeModal$ | async as activeModal">
        <div
            [ngStyle]="{
                display: activeModal === DATA_PRIVACY_MODALS.GENERIC_INFO ? 'block' : 'none'
            }"
        >
            <ng-container *ngTemplateOutlet="genericInfo"></ng-container>
        </div>
        <div
            [ngStyle]="{
                display: activeModal === DATA_PRIVACY_MODALS.CLASSROOM_SETTINGS ? 'block' : 'none'
            }"
        >
            <app-classroom></app-classroom>
        </div>
        <div
            [ngStyle]="{
                display: activeModal === DATA_PRIVACY_MODALS.SIGN_ACKNOWLEDGMENT ? 'block' : 'none'
            }"
        >
            <ng-container *ngIf="(isLoading$ | async) === false; else loading">
                <app-sign-in></app-sign-in>
            </ng-container>
        </div>
    </ng-container>
</div>

<ng-template #genericInfo>
    <h4 *ngIf="firstName" class="modal-title mb-4">Hello {{ firstName }},</h4>
    <p *ngIf="hasAwaitingAssignments$ | async" class="mb-4">
        We noticed that you have lessons Awaiting Assignment.
    </p>
    <p>
        As part of PBS LearningMedia’s efforts to safeguard student data, starting Fall 2023, we’re
        asking all users who use our <b>assignment and class roster tools</b> to provide some
        additional information and acknowledge our privacy policy before continuing to use these
        tools, which share student information with PBS. Learn more about

        <a href="/help/student-privacy-notice/" target="_blank" rel="noopener noreferrer">
            <b class="text-primary">how we’re protecting student data here.</b>
        </a>
    </p>

    <div class="d-flex justify-content-between mt-6" [ngClass]="{ 'mobile-layout': isMobile }">
        <button
            type="button"
            class="btn btn-outline-secondary py-2 px-4 gtm-do-this-later-btn"
            (click)="doLater()"
        >
            Do this later
        </button>

        <button
            type="button"
            class="btn btn-primary py-2 px-4"
            (click)="dataPrivacyModalsService.nextModal()"
        >
            Provide information now &gt;
        </button>
    </div>
</ng-template>

<ng-template #loading>
    <app-preloader></app-preloader>
</ng-template>
