import { Injectable } from "@angular/core"
import { forkJoin, Observable, of } from "rxjs"
import { catchError, delay, finalize, map, shareReplay, take, tap } from "rxjs/operators"

import { LmApiService } from "./lm-api.service"
import {
    ISchool,
    ISchoolDistrict,
    IState,
    SCHOOL_TYPE_DESCRIPTION,
    UserDataPrivacyPayload,
    UserDataPrivacyResponse,
} from "../models/userDataPrivacy.model"
import { ApiResponse, IApiResponse } from "../models/apiResponse.model"
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"
import { DataPrivacySuccessComponent } from "../shared-components/data-privacy/data-privacy-select/data-privacy-success/data-privacy-success.component"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"

@Injectable({
    providedIn: "root",
})
export class UserDataPrivacyService {
    private baseUrl = "/api/v2"

    private cachedStates$: Observable<IApiResponse<IState>>
    private cachedUserDataPrivacy$: Observable<UserDataPrivacyResponse>

    constructor(private lmApi: LmApiService, private modalService: NgbModal) {}

    getSchoolDistricts(
        stateLocation = "",
        searchQuery = "",
        limit = 30
    ): Observable<IApiResponse<ISchoolDistrict>> {
        let url = `${this.baseUrl}/tools/school-district/?limit=${limit}`
        url = stateLocation ? `${url}&state=${stateLocation}` : url
        url = searchQuery ? `${url}&search=${searchQuery}` : url

        return this.lmApi.get(url).pipe(
            catchError((error) => this.lmApi.handleErrorForNonCritical(error)),
            catchError(() => of(new ApiResponse<ISchoolDistrict>()))
        )
    }

    getSchools(districtId = "", searchQuery = "", limit = 30): Observable<IApiResponse<ISchool>> {
        if (!districtId) return

        let url = `${this.baseUrl}/tools/schools-by-district/${districtId}/?limit=${limit}`
        url = searchQuery ? `${url}&search=${searchQuery}` : url

        return this.lmApi.get(url).pipe(
            catchError((error) => this.lmApi.handleErrorForNonCritical(error)),
            catchError(() => of(new ApiResponse<ISchool>()))
        )
    }

    getDataByNextUrl<T>(nextUrl): Observable<IApiResponse<T>> {
        if (!nextUrl) return
        return this.lmApi.get(nextUrl).pipe(
            catchError((error) => this.lmApi.handleErrorForNonCritical(error)),
            catchError(() => of(new ApiResponse<T>()))
        )
    }

    getStates(): Observable<IApiResponse<IState>> {
        if (!this.cachedStates$) {
            this.cachedStates$ = this.lmApi.get(`${this.baseUrl}/tools/states/`).pipe(
                catchError((error) => this.lmApi.handleErrorForNonCritical(error)),
                catchError(() => of(new ApiResponse<IState>())),
                shareReplay(1)
            )
        }

        return this.cachedStates$
    }

    getUserDataPrivacy(): Observable<UserDataPrivacyResponse> {
        if (!this.cachedUserDataPrivacy$) {
            this.cachedUserDataPrivacy$ = this.lmApi.get(`${this.baseUrl}/user_data_privacy/`).pipe(
                catchError((error) => this.lmApi.handleErrorForNonCritical(error)),
                shareReplay(1)
            )
        }

        return this.cachedUserDataPrivacy$
    }

    setDataPrivacy<T>(payload: T): Observable<any> {
        const url = `${this.baseUrl}/tools/set-data-privacy/`
        return this.lmApi.post(url, payload).pipe(
            tap(() => (this.cachedUserDataPrivacy$ = null)),
            catchError((error) => this.lmApi.handleErrorForNonCritical(error.error.detail))
        )
    }

    setPrivacyDoThisLater(doThisLater: boolean): Observable<any> {
        const url = `${this.baseUrl}/tools/data-privacy-do-this-later/`
        return this.lmApi.post(url, { do_this_later: doThisLater }).pipe(
            tap(() => (this.cachedUserDataPrivacy$ = null)),
            catchError((error) => this.lmApi.handleErrorForNonCritical(error.error.detail))
        )
    }

    getAssignments(): Observable<{ awaiting: number; assigned: number }> {
        return forkJoin([
            this.lmApi.get(`${this.baseUrl}/assignments/?status=awaiting&limit=1`),
            this.lmApi.get(`${this.baseUrl}/assignments/?status=assigned&limit=1`),
        ]).pipe(
            catchError((error) => this.lmApi.handleErrorForNonCritical(error)),
            map(([awaiting, assigned]) => ({ awaiting: awaiting.count, assigned: assigned.count }))
        )
    }

    createPayload({
        doThisLater: do_this_later,
        privacyCheck: sign_acknowledgement,
        classRoomSetting: { schoolDescription, teacherType },
    }): UserDataPrivacyPayload {
        const schoolDistrict = schoolDescription?.schoolDistrict ?? null
        const schoolName = schoolDescription?.schoolName ?? null

        return {
            sign_acknowledgement,
            teacher_type: SCHOOL_TYPE_DESCRIPTION[teacherType],
            ...(schoolDistrict?.district_guid && {
                district_guid: schoolDistrict.district_guid,
            }),
            ...(schoolName?.id && {
                school_id: schoolName.id,
            }),
            do_this_later,
        }
    }

    checkBoxValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return !control.value ? { required: true } : null
        }
    }

    openSuccessModal(): any {
        const modalRef = this.modalService.open(DataPrivacySuccessComponent, {
            centered: true,
            modalDialogClass: "dataPrivacySuccessModal",
        })

        of(modalRef)
            .pipe(
                take(1),
                delay(2000),
                finalize(() => modalRef.close())
            )
            .subscribe()
    }
}
