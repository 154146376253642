import ResourceUtils from "../utils/resource-utils"
import Utils from "../utils/utils"

export class LearningObject {
    // TODO create models for all the arrays
    public id: number
    public title: string
    public linkTitle: string
    public description: string
    public duration: string
    public grades: string[] = []
    public gradesDisplay: string
    public mediaType: string[] = []
    public posterImage: string
    public posterImages: any[] = []
    public posterImageUrl: string
    public searchDisplayImage: string
    public type: string
    public uri: string
    public languages: string[] = []
    public canonicalUrl: string
    public brand: string
    public subject: any
    public supportMaterials: string[] = []
    public resourcesCount: string
    public additionalFeatures: string[] = []
    public project: any
    public isCollection: boolean
    public routerLink: string[]
    public resourceCode: string
    public predictionEstimation: number
    public hasCautionFlags: boolean = false
    // TODO Only used for Lesson Builder Resource Cards. Not ok
    public scope: string[] = []

    public constructor(input: object, isCollection: boolean = false) {
        if (input) {
            Object.keys(input).map((key: string) => {
                const property: string = Utils.convertToCamelcase(key)
                this[property] = input[key]
            })
            this.linkTitle = input["debug"] ? `${this.title} - ${input["debug"]}` : this.title
        } else {
            this.linkTitle = this.title
        }
        this.isCollection = this.mediaType.indexOf("Collection") >= 0 || isCollection
        this.escapePosterImageUrls()
        this.routerLink = this.getRouterLink(this.isCollection)
        this.gradesDisplay = ResourceUtils.getGradesDisplay(this.grades)
    }

    public hasSupportMaterials(): boolean {
        // TODO: make an enum for this string and others like this in this file and in app in general
        if (this.additionalFeatures.indexOf("Support Materials") !== -1) {
            return true
        }
        return this.supportMaterials.length > 0
    }

    public getPosterImage(width?: number, height?: number, type?: string) {
        let url
        if (type) {
            url = this.getPosterImageIfExists(type, width, height)
            if (url) {
                return url
            }
        }
        url = this.getPosterImageIfExists("original", width, height)
        if (url) {
            return url
        }
        url = this.getPosterImageIfExists("default", width, height)
        if (url) {
            return url
        }
        if (this.posterImage) {
            return this.posterImage
        }
        return null
    }

    public getPosterImageIfExists(type: string, width?: number, height?: number) {
        return Utils.getITSImageIfExists(this.posterImages, type, width, height)
    }

    public isAvailableInLessonBuilder(): string {
        return this.scope.find((scope: string) => scope === "lesson")
    }

    private getRouterLink(isCollection: boolean): string[] {
        const canonicalURLParts: string[] = (this.canonicalUrl || "")
            .split("/")
            .filter((p) => p.length)
        if (canonicalURLParts.length < 2) {
            return ["/"]
        }
        if (isCollection) {
            const slugsPart: string = (this.canonicalUrl || "").split("/collection/").slice(-1)[0]
            const slugs: string[] = slugsPart.split("/").filter((p) => p.length)
            return ["/", "collection"].concat(slugs)
        }
        if (Utils.isAddOnExperienceLink(window.location.pathname, "/add-on/")) {
            const resourceURLParts: string[] = canonicalURLParts.slice(
                canonicalURLParts.indexOf("resource")
            )
            return ["/", "add-on"].concat(resourceURLParts)
        } else {
            const resourceURLParts: string[] = canonicalURLParts.slice(
                canonicalURLParts.indexOf("resource") + 1
            )
            return ["/", "resource"].concat(resourceURLParts)
        }
    }

    private escapePosterImageUrls() {
        this.posterImage = Utils.escapePosterImage(this.posterImage)
        this.posterImages = Utils.escapePosterImage(this.posterImages)
        this.posterImageUrl = Utils.escapePosterImage(this.posterImageUrl)
        this.searchDisplayImage = Utils.escapePosterImage(this.searchDisplayImage)
    }
}
