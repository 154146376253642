export class StudentAssignmentActivity {
    constructor(
        public name: string,
        public code: string,
        public description: string,
        public isILP: boolean,
        public teacherName: string,
        public teacherId: number,
        public previewURL: string
    ) {}

    public static fromData(data) {
        return new StudentAssignmentActivity(
            data.name,
            data.code,
            data.description,
            data.is_ilp,
            data.teacher_name,
            data.teacher_id,
            data.preview_url
        )
    }
}

export class StudentAssignmentItem {
    constructor(
        public id: number,
        public completed: boolean,
        public dateCreated: Date,
        public status: string,
        public score: string,
        public rosters: string,
        public studentResultURL: string,
        public activity: StudentAssignmentActivity
    ) {}

    public static fromData(data: any) {
        return new StudentAssignmentItem(
            data.id,
            data.completed,
            new Date(data.date_created),
            data.status,
            data.score,
            data.rosters,
            data.student_result_url,
            StudentAssignmentActivity.fromData(data.activity)
        )
    }
}

export class StudentAssignments {
    constructor(public items: StudentAssignmentItem[], public nextURL: string) {}

    public addFrom(loadedAssignments: StudentAssignments) {
        this.items.push(...loadedAssignments.items)
        this.nextURL = loadedAssignments.nextURL
    }

    public static fromData(data: any) {
        return new StudentAssignments(
            data.results.map((itemData) => StudentAssignmentItem.fromData(itemData)),
            data.next
        )
    }
}
