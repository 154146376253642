<div class="row">
    <div class="col col-md-6">
        <div class="mb-4 fw-bold font-size-16 text-navy-blue">
            Role <span class="required-field"></span>(Required)
        </div>
        <div
            ngbDropdown
            autoClose="outside"
            class="d-flex w-100 gtm-profile-select-role"
            [ngClass]="{ 'mb-4': !roleSelected?.length && !isDisabled }"
            #myDrop="ngbDropdown"
        >
            <button
                [disabled]="isDisabled"
                class="btn btn-outline-primary d-block w-100 d-flex justify-content-between align-items-center p-3"
                id="dropdownBasic1"
                ngbDropdownToggle
            >
                <div class="role-container">
                    {{ roleSelected?.trim().length ? roleSelected : "Choose Role" }}
                </div>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button
                    [ngClass]="{ 'selected-role': roleSelected === role }"
                    *ngFor="let role of roles"
                    ngbDropdownItem
                    (click)="onRoleClicked(role); myDrop.close(); $event.stopPropagation()"
                >
                    {{ role }}
                </button>
                <button
                    [ngClass]="{ 'selected-role': roleSelected === otherControl.value }"
                    class="py-5"
                    ngbDropdownItem
                    (click)="onOtherClicked(); $event.stopPropagation(); $event.preventDefault()"
                >
                    <label for="other">Other (Write in e.g., post-secondary instructor)</label
                    ><br />
                    <input
                        *ngIf="isVisible"
                        (click)="$event.stopPropagation(); $event.preventDefault()"
                        (keyup.space)="$event.preventDefault(); $event.stopPropagation()"
                        [formControl]="otherInputControl"
                        class="w-100"
                        type="text"
                        name="other"
                        id="other"
                    />
                </button>
            </div>
        </div>
    </div>
</div>
