<app-link
    class="collection-card selenium-collection-card {{ gtmPrefix }}"
    [appRouterLink]="learningObject.routerLink"
    [attr.data-gtmresultindex]="gtmDataCardNumber"
>
    <app-collection-card-poster
        [posterImage]="learningObject.getPosterImage(500, 500)"
        [title]="learningObject.title"
        [searchImage]="learningObject.getPosterImageIfExists('original-square', 500, 500)"
        [resourceCount]="learningObject.resourcesCount"
        [gtmPrefix]="gtmPrefix"
        [absoluteUrl]="getAbsoluteUrl()"
        [posterImageDimension]="posterImageDimension"
    >
    </app-collection-card-poster>
</app-link>
