<a
    *ngIf="href"
    class="app-link"
    [href]="href"
    [attr.title]="title || null"
    [attr.target]="target || null"
    [attr.rel]="(target && 'noopener noreferrer') || null"
    [appBadge]="badgeCount || null"
    [size]="badgeCount > 9 && 'large'"
    [tabIndex]="tabIndex"
>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>

<a
    *ngIf="appRouterLink"
    class="app-link"
    [ngClass]="{ active: isChildRoute }"
    [routerLink]="appRouterLink"
    [queryParams]="queryParams"
    [queryParamsHandling]="queryParamsHandling"
    [routerLinkActive]="routerLinkActive"
    [routerLinkActiveOptions]="{ exact: true }"
    [title]="title"
    [appBadge]="badgeCount || null"
    [size]="badgeCount > 9 ? 'medium' : 'small'"
    [tabIndex]="tabIndex"
>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</a>
<ng-template #contentTpl><ng-content></ng-content></ng-template>
