import { Component, OnInit } from "@angular/core"
import { UserService } from "../../services/user.service"
import { PromotionalAdsService } from "../../services/promotional-ads.service"
import { EduComponent } from "../../utils/eduComponent"

@Component({
    selector: "app-promotional-ads",
    templateUrl: "./promotional-ads.component.html",
    styleUrls: ["./promotional-ads.component.scss"],
})
export class PromotionalAdsComponent extends EduComponent implements OnInit {
    public promotionalAds: any[] = []
    public gtmPromoItemImageClass = "gtm-homepage-promo-item-image"
    public gtmPromoItemCallToActionClass = "gtm-homepage-promo-item-call-to-action-text"

    constructor(
        public userService: UserService,
        public promotionalAdsService: PromotionalAdsService
    ) {
        super()
    }

    ngOnInit() {
        this.addSub(
            this.promotionalAdsService.getAds().subscribe((promos) => {
                this.promotionalAds = promos.ads.sort(function (a, b) {
                    return a.spot - b.spot
                })
            })
        )
    }
}
