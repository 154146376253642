<div class="container">
    <div class="row">
        <div class="col">
            <h1>Login</h1>
            <p class="small-content">
                You must be logged in to view results for
                <i *ngIf="assignmentName">{{ assignmentName }}</i>
                <ng-container *ngIf="!assignmentName">your assignment</ng-container>
            </p>
            <div *ngIf="!userService.hasStudentExperience()" class="login">
                <app-register
                    [buttonLabelText]="'Teacher Sign In'"
                    [nextURL]="nextURL"
                ></app-register>
            </div>
            <span *ngIf="!userService.hasStudentExperience()" class="login-separator">or</span>
            <div class="login">
                <app-register
                    [buttonLabelText]="'Student Sign In'"
                    [isStudent]="true"
                    [nextURL]="nextURL"
                ></app-register>
            </div>
        </div>
        <div class="col">
            <img [src]="imageURL" alt="error image" class="error-img" />
        </div>
    </div>
</div>
