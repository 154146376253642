import Utils from "../utils/utils"

export class ITSImage {
    private imageFormat: string = Utils.shouldNotUseWebp() ? "jpg" : "webp"

    constructor(public itsURL: string, public altText: string) {}

    public resize(width, height, format = this.imageFormat): string {
        return `${this.itsURL}?resize=${width}x${height}&format=${format}`
    }

    public widthResize(width, format = this.imageFormat): string {
        return `${this.itsURL}?resize=${width}x&format=${format}`
    }

    public getSrcSetFormat(widths: number[]) {
        let srcSet: string = ""
        widths.forEach((width: number) => {
            srcSet += `${this.widthResize(width)} ${width}w, `
        })
        return srcSet
    }

    public getAltText() {
        if (this.altText) {
            return this.altText
        }

        return ""
    }
}
