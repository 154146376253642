<button
    class="button"
    (mouseover)="hover = true"
    (mouseleave)="hover = false"
    [ngStyle]="setColors()"
    [disabled]="disable"
    [ngClass]="buttonClasses"
    [attr.data-gtmlabel]="gtmLabel"
>
    <ng-content></ng-content>
</button>
