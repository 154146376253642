<app-link
    class="resource-featured-card selenium-resource-featured-card"
    [appRouterLink]="learningObject.routerLink"
>
    <div class="header">
        <a
            [routerLink]="headerURL"
            (click)="stopPropagation($event)"
            *ngIf="learningObject.subject !== null"
            class="header-subject gtm-{{ gtmPrefix }}-subject selenium-header-subject"
            [ngClass]="getSubjectClass()"
            [attr.data-gtmlabel]="learningObject.subject.displayName"
            [ngStyle]="{
                backgroundColor: ColorCodeService.SubjectColor[learningObject.subject.id]
            }"
            >{{ learningObject.subject.displayName }}
            <app-icon [type]="'Arrow-right'"></app-icon>
        </a>
        <app-resource-card-poster
            class="thumbnail"
            [title]="learningObject.title"
            [posterImage]="learningObject.getPosterImage(269, 151)"
            [duration]="learningObject.duration"
            [gtmPrefix]="gtmPrefix"
            [title]="learningObject.linkTitle"
            [mediaType]="learningObject.mediaType[0]"
            [absoluteUrl]="learningObject.canonicalUrl"
        >
        </app-resource-card-poster>
    </div>
    <div class="content">
        <a [class]="getResourceBodyClass()" [attr.data-gtmlabel]="learningObject.canonicalUrl">
            <app-card-title [height]="62">{{ learningObject.title }}</app-card-title>
        </a>

        <app-browse-card-meta
            [gradesDisplay]="learningObject.gradesDisplay"
            [contentProject]="learningObject.project"
            [brand]="learningObject.brand"
            [supportMaterialsLink]="learningObject.routerLink"
            [supportMaterialsCount]="learningObject.supportMaterials.length"
        ></app-browse-card-meta>
    </div>
</app-link>
