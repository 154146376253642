import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router"
import { UserService } from "../services/user.service"

@Injectable()
export class TeacherExperienceOnlyGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // We don't want to have a /?student=true URL if the page has only a teacher experience
        if (route.queryParams.student) {
            // ?student=true is the only case we need to worry about since /student/ URL will be handled from
            this.router.navigate(["/student"], {})
            return false
        }
        this.userService.setStudentExperience(false)
        return true
    }
}
