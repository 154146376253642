import { Component, OnDestroy, OnInit } from "@angular/core"
import { ModalRef } from "../../modal/modal-ref"
import { UserService } from "../../../services/user.service"
import { ReCaptchaV3Service } from "ng-recaptcha"
import { EduComponent } from "../../../utils/eduComponent"
import Utils from "../../../utils/utils"
import { ViewEnum } from "./student-signup-enum"
import {
    STATIC_IMAGES,
    studentSecretQuestionOptions,
    studentNounList,
    studentAdjectiveList,
} from "../../../models/constants"
import { ModalService } from "../../modal/modal.service"
import { Subject, throwError } from "rxjs"
import { catchError, takeUntil } from "rxjs/operators"
import { NotificationsService } from "angular2-notifications"

@Component({
    selector: "app-student-signup-modal",
    templateUrl: "./student-signup-modal.component.html",
    styleUrls: ["./student-signup-modal.component.scss"],
})
export class StudentSignupModalComponent extends EduComponent implements OnInit, OnDestroy {
    selectedQuestion: number
    secretQuestions: { id: number; name: string }[] = studentSecretQuestionOptions
    wordList1: string[] = studentNounList
    wordList2: string[] = studentAdjectiveList
    ViewEnum = ViewEnum
    questionAnswer: string = ""
    customWord: string = ""
    word1: string = ""
    word2: string = ""
    view: number = ViewEnum.chose
    tmpUsername: string = ""
    prevView: ViewEnum = ViewEnum.chose
    error: string = ""
    googleImage: string = null
    token: string = ""
    googleAuthUrl: string

    destroyed$ = new Subject()

    constructor(
        public activeModal: ModalRef,
        public userService: UserService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private modalService: ModalService,
        private notificationService: NotificationsService
    ) {
        super()
        this.googleImage = STATIC_IMAGES.auth_google
    }

    ngOnInit() {
        this.modalService.modalBackDrop.instance.clickOutsideToClose = false
        Utils.shuffleArray(this.wordList1)
        Utils.shuffleArray(this.wordList2)
        this.googleAuthUrl = this.userService.getGoogleAuthUrl(true)
    }

    public getQuestionText(id) {
        const question = this.secretQuestions.filter((question) => {
            return id === question.id
        })

        return question[0].name
    }

    public setWord1(word) {
        this.word1 = word
        this.updateUserName()
    }

    public setWord2(word) {
        this.word2 = word
        this.updateUserName()
    }

    public updateUserName() {
        this.tmpUsername = `${this.customWord}${this.word1}${this.word2}`
    }

    public nextPage() {
        if (this.isFormValid()) {
            this.view = ViewEnum.review
            this.error = ""
        }
    }

    public prevPage() {
        this.view = ViewEnum.create
    }

    public isFormValid() {
        return this.customWord && this.questionAnswer && this.selectedQuestion
    }

    public close() {
        this.prevView = this.view
        this.view = ViewEnum.close
    }

    public createStudent(): void {
        this.recaptchaV3Service
            .execute("studentCreate")
            .pipe(
                takeUntil(this.destroyed$),
                catchError((error) => {
                    this.activeModal.close()
                    this.notificationService.error(
                        "Error",
                        "ReCaptcha failed. Please try again",
                        Utils.alertConfiguration
                    )
                    window.location.reload()
                    return throwError(error)
                })
            )
            .subscribe(
                (token) => {
                    this.token = token
                    this.postData()
                },
                (error) => console.error(error)
            )
    }

    public postData() {
        this.addSub(
            this.userService
                .postCreateStudent({
                    custom_word: this.customWord,
                    first_word: this.word1,
                    second_word: this.word2,
                    secure_secret_question: this.getQuestionText(this.selectedQuestion),
                    secure_secret_answer: this.questionAnswer,
                    captcha: this.token,
                })
                .subscribe(
                    () => {
                        this.activeModal.close()
                        window.location.reload()
                    },
                    (err: any) => {
                        if (
                            err.error &&
                            err.error.non_field_errors &&
                            err.error.non_field_errors[0]
                        ) {
                            this.error = err.error.non_field_errors[0]
                            this.view = ViewEnum.create
                        }
                    }
                )
        )
    }

    ngOnDestroy() {
        super.ngOnDestroy()
        this.destroyed$.next()
        this.destroyed$.complete()
    }
}
