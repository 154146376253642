declare let window: any

export class MuxDataModel {
    public muxKey: string = window.PBSLM.NG.MUX_KEY
    public playerName: string = ""
    public playerInitTime: number = Date.now()
    public pageType: string = "watchpage"
    public title: string = ""
    public language: string = ""
    public mediaType: string = ""

    constructor(title: string = "", language: string = "", mediaType: string = "") {
        this.playerName = (window.PBSLM.NG.VERSION || "local") + " " + mediaType
        this.title = title
        this.language = language
        this.mediaType = mediaType
    }

    public getConfig() {
        // Full config for initializing MUX (first video/audio)
        return {
            debug: false,
            disableCookies: true,
            data: {
                env_key: this.muxKey,
                player_name: this.playerName,
                player_init_time: this.playerInitTime,
                page_type: this.pageType,
                video_title: this.title,
                video_language_code: this.language,
            },
        }
    }

    public getUpdateData() {
        // for source changes, when MUX was already initialized
        return {
            page_type: this.pageType,
            video_title: this.title,
            video_language_code: this.language,
        }
    }

    public setupForVideoJS(player, firstInitialize) {
        // https://docs.mux.com/docs/web-integration-guide
        if (!player.mux) {
            // unlikely if videojs-mux was imported next to the player
            return
        }
        if (firstInitialize) {
            player.mux(this.getConfig())
        } else {
            player.mux.emit("videochange", this.getUpdateData())
        }
    }
}
