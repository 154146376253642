export interface IApiResponse<T> {
    count: number
    next: string | null
    previous: string | null
    results: T[] | []
}

export class ApiResponse<T> implements IApiResponse<T> {
    constructor(
        public count = 0,
        public next = null,
        public previous = null,
        public results = []
    ) {}
}

export interface APIMetaResponse {
    total: string
    suggestions: string
    next_uri: string
    prev_uri: string
    mediaType: string
}

export interface APIResponse<T> {
    status: string
    meta: APIMetaResponse
    objects: T[]
}
