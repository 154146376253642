import { Component, HostListener } from "@angular/core"

@Component({
    selector: "app-back-to-top-button",
    templateUrl: "./back-to-top-button.component.html",
    styleUrls: ["./back-to-top-button.component.scss"],
})
export class BackToTopButtonComponent {
    public show: boolean = false

    constructor() {}

    @HostListener("window:scroll", [])
    onWindowScroll() {
        window.pageYOffset >= window.innerHeight ? (this.show = true) : (this.show = false)
    }

    public scrollToTop() {
        document.querySelector("body").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        })
    }
}
