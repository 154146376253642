import { Component, OnInit } from "@angular/core"
import { UserService } from "../../services/user.service"
import { EduComponent } from "../../utils/eduComponent"
import Utils from "../../utils/utils"
import { ModalService } from "../modal/modal.service"
import { InformationModalComponent } from "../modals/information-modal/information-modal.component"
import { FeedbackModalComponent } from "./feedback-modal/feedback-modal.component"
import { AuthModalComponent } from "../modals/auth-modal/auth-modal.component"
import { ActivatedRoute, Router, RouterEvent } from "@angular/router"
import { ResourceService } from "../../services/resource.service"
import { STATIC_IMAGES } from "../../models/constants"
import { AddOnViewService } from "../../services/add-on-view.service"
import { take, takeUntil, tap } from "rxjs/operators"
import { asyncScheduler, Observable, Subject } from "rxjs"
import { NewsletterService } from "../../newsletter/services/newsletter.service"

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent extends EduComponent implements OnInit {
    gtmPrefix: string = "gtm-footer-"
    pbsLogoWhite
    currentYear
    isAddonView: boolean
    isEmailValid: boolean = false
    isStudentExperience: boolean = false
    email: any = ""
    destroyed$: Subject<void> = new Subject()
    routerObservable: Observable<unknown> = this.router.events.pipe(
        takeUntil(this.destroyed$),
        tap(() => (this.isStudentExperience = Utils.hasStudentExperience()))
    )

    constructor(
        private modalService: ModalService,
        public userService: UserService,
        private resourceService: ResourceService,
        public addOnService: AddOnViewService,
        public router: Router,
        public route: ActivatedRoute,
        public newsletterService: NewsletterService
    ) {
        super()
        this.pbsLogoWhite = STATIC_IMAGES.pbs_logo_white
    }

    public ngOnInit() {
        this.routerObservable.subscribe()
        this.currentYear = Utils.getCurrentYear()
        this.isAddonView = this.addOnService.isAddonView
        this.handleScrollToAnchorTag()
    }

    public handleScrollToAnchorTag(): void {
        this.route.fragment.pipe(take(2)).subscribe((fragment: string) => {
            if (fragment === "email-iterable-newsletter")
                asyncScheduler.schedule(() => {
                    this.jumpTo()
                }, 1500)
        })
    }

    public teacherLogin() {
        if (window.location.pathname === "/student/") {
            this.router.navigate(["/"])
        } else {
            this.resourceService.switchResourceView()
        }
        this.modalService.open(AuthModalComponent)
    }

    public changeUserExperience(studentState: boolean, target?: string) {
        this.userService.switchUserExperience(studentState, target)
    }
    public openFeedbackForm(event) {
        event.preventDefault()
        this.modalService.open(FeedbackModalComponent, { size: "lg" })
    }

    validateEmail() {
        this.email = (document.getElementById(
            "email-iterable-newsletter"
        ) as HTMLInputElement).value
        this.isEmailValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.email)
    }

    public subscribe() {
        const payload = {
            email: this.email,
        }
        this.newsletterService
            .subscribeToNewsletter(payload)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                () => this.handleSubscribeSuccess(),
                () => this.handleSubscribeError()
            )
    }

    handleSubscribeSuccess() {
        const subscribeBtn = document.getElementById("iterable-newsletter-go")

        subscribeBtn.classList.remove("disabled")
        subscribeBtn.removeAttribute("disabled")
        this.modalService.open(InformationModalComponent, {
            data: {
                message: "You have been subscribed to the newsletter!",
                shouldDisplayHeaderCloseButton: true,
                shouldDisplayFooterCloseButton: true,
            },
        })
    }

    handleSubscribeError() {
        const subscribeBtn: HTMLElement = document.getElementById("iterable-newsletter-go")
        subscribeBtn.classList.remove("disabled")
        subscribeBtn.removeAttribute("disabled")
        this.modalService.open(InformationModalComponent, {
            data: {
                message: "Something went wrong. Please try again later.",
                shouldDisplayHeaderCloseButton: true,
                shouldDisplayFooterCloseButton: true,
            },
        })
    }

    public jumpTo(): void {
        const area: HTMLElement = document.getElementById("email-iterable-newsletter")

        if (area) {
            area.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            })
            area.focus()
        }
        this.router.onSameUrlNavigation = "ignore"
        this.router.navigate([], {
            fragment: `email-iterable-newsletter`,
            relativeTo: this.route,
        })
    }

    public goToNewsletterPage(): void {
        this.router.navigate(["/newsletter/"])
    }
}
