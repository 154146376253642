<div class="container-wrapper selenium-app-promotional-ads">
    <div class="container">
        <h2>News and Events</h2>
        <div class="row-negative-margin mb-4 d-flex flex-xs-column flex-md-row flex-md-wrap">
            <div
                class="col-sm-12 col-md-5 col-lg lm-event-column selenium-lm-event-column"
                *ngFor="let ad of promotionalAds"
            >
                <div>
                    <a
                        href="{{ ad.callToActionLink }}"
                        [attr.data-gtmlabel]="ad.callToActionLink"
                        [title]="ad.callToActionText"
                        [attr.aria-label]="ad.callToActionText"
                        [class]="gtmPromoItemImageClass"
                    >
                        <img
                            *ngIf="ad.image_its"
                            src="{{ ad.image_its }}?format=jpg"
                            [alt]="ad.callToActionText"
                        />
                    </a>
                </div>
                <div class="description">
                    {{ ad.description }}
                </div>
                <div class="action-link">
                    <a
                        href="{{ ad.callToActionLink }}"
                        [attr.data-gtmlabel]="ad.callToActionLink"
                        [class]="gtmPromoItemCallToActionClass"
                    >
                        {{ ad.callToActionText }}
                        <app-icon [type]="'Arrow-right'" class="icon"></app-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
