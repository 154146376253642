import { Injectable } from "@angular/core"
import { Resource } from "../models/resource/resource"
import Utils from "../utils/utils"

@Injectable({
    providedIn: "root",
})
export class GlobalStoreService {
    public currentResource: Resource = null
    public imageFormat: string = Utils.shouldNotUseWebp() ? "jpg" : "webp"
    constructor() {}
}
