import { CommonModule } from "@angular/common"
import { ModuleWithProviders, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { NgbDropdownModule, NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { NgSelectModule } from "@ng-select/ng-select"

// Shared Components
import { AvailableFeaturesComponent } from "../homepage/available-features/available-features.component"
import { AgreementModalComponent } from "../resource/shared-components/agreement-modal/agreement-modal.component"
import { AssignmentDuplicateModalComponent } from "./modals/assignment-duplicate-modal/assignment-duplicate-modal.component"
import { LanguageSelectorComponent } from "../resource/shared-components/language-selector/language-selector.component"
import { LtiContinueModalComponent } from "../resource/shared-components/lti-continue-modal/lti-continue-modal.component"
import { WindowSizeService } from "../services/window-size.service"
import { AccordionService } from "./accordion/accordion.service"
import { AdminHeaderComponent } from "./admin-header/admin-header.component"
import { AlertBarComponent } from "./alert-bar/alert-bar.component"
import { AppliedFiltersComponent } from "./applied-filters/applied-filters.component"
import { AttributesComponent } from "./attributes/attributes.component"
import { AudioComponent } from "./audio/audio.component"
import { BrowseByComponent } from "./browse-by/browse-by.component"
import { CardContentProjectNameComponent } from "./card/card-components/card-content-project-name/card-content-project-name.component"
import { CardDescriptionComponent } from "./card/card-components/card-description/card-description.component"
import { BrowseCardMetaComponent } from "./card/card-components/card-meta/browse-card-meta/browse-card-meta.component"
import { CardMetaComponent } from "./card/card-components/card-meta/card-meta.component"
import { MfrCardMetaComponent } from "./card/card-components/card-meta/mfr-card-meta/mfr-card-meta.component"
import { SearchCardMetaComponent } from "./card/card-components/card-meta/search-card-meta/search-card-meta.component"
import { YmalCardMetaComponent } from "./card/card-components/card-meta/ymal-card-meta/ymal-card-meta.component"
import { CardTitleComponent } from "./card/card-components/card-title/card-title.component"
import { CollectionCardPosterComponent } from "./card/card-components/collection-card-poster/collection-card-poster.component"
import { ResourceCardPosterComponent } from "./card/card-components/resource-card-poster/resource-card-poster.component"
import { CardComponent } from "./card/card.component"
import { CollectionBrowseCardComponent } from "./card/collection-browse-card/collection-browse-card.component"
import { CollectionCardComponent } from "./card/collection-card/collection-card.component"
import { CollectionSearchCardComponent } from "./card/collection-search-card/collection-search-card.component"
import { MfrCardComponent } from "./card/mfr-card/mfr-card.component"
import { ResourceBrowseCardComponent } from "./card/resource-browse-card/resource-browse-card.component"
import { ResourceFeaturedCardComponent } from "./card/resource-featured-card/resource-featured-card.component"
import { ResourceSearchCardComponent } from "./card/resource-search-card/resource-search-card.component"
import { YmalCardComponent } from "./card/ymal-card/ymal-card.component"
import { Carousel2Component } from "./carousel2/carousel2.component"
import { CarouselItem2Component } from "./carousel2/CarouselItem2Component/carousel-item2.component"
import { CollectionPosterComponent } from "./collection-poster/collection-poster.component"
import { ConfirmStationComponent } from "./confirm-station/confirm-station.component"
import { FeedbackModalComponent } from "./footer/feedback-modal/feedback-modal.component"

// Directives
import { ElementPlaceholderComponent } from "./element-placeholder/element-placeholder.component"
import { ExternalSearchInputComponent } from "./external-search-input/external-search-input.component"
import { FacetsModule } from "./facets/facets.module"
import { FavoriteButtonComponent } from "./favorite-button/favorite-button.component"
import { FooterComponent } from "./footer/footer.component"
import { HeaderComponent } from "./header/header.component"
import { MainNavigationComponent } from "./header/main-navigation/main-navigation.component"
import { InputBoxComponent } from "./input-box/input-box.component"
import { LanguageBadgeComponent } from "./language-badge/language-badge.component"
import { LinkButtonComponent } from "./link-button/link-button.component"
import { LocalizationComponent } from "./localization/localization.component"
import { MobileSearchTopSectionComponent } from "./mobile-search-top-section/mobile-search-top-section.component"
import { ModalBackdropComponent } from "./modal/modal-backdrop.component"
import { ModalService } from "./modal/modal.service"
import { AddSchoolModalComponent } from "./modals/add-school-modal/add-school-modal.component"
import { AuthModalContentComponent } from "./modals/auth-modal/auth-modal-content/auth-modal-content.component"
import { AuthModalComponent } from "./modals/auth-modal/auth-modal.component"
import { InformationModalComponent } from "./modals/information-modal/information-modal.component"
import { JoinRosterModalComponent } from "./modals/join-roster-modal/join-roster-modal.component"
import { LeaveRosterModalComponent } from "./modals/leave-roster-modal/leave-roster-modal.component"
import { LocalizationModalComponent } from "./modals/localization-modal/localization-modal.component"
import { MobileFilterModalComponent } from "./modals/mobile-filter-modal/mobile-filter-modal.component"
import { NotificationModalComponent } from "./modals/notification-modal/notification-modal.component"
import { TextDisplayModalComponent } from "./modals/text-display-modal/text-display-modal.component"
import { PaginationComponent } from "./pagination/pagination.component"

// Pipes
import { LoadScriptsPipe } from "./pipes/load-scripts.pipe"
import { PluralizeMediaTypePipe } from "./pipes/pluralize-media-type.pipe"
import { PrettyGradePipe } from "./pipes/pretty-grade.pipe"
import { BypassSecurityPipe } from "./pipes/bypass-security"
import { TruncatePipe } from "./pipes/truncate.pipe"
import { ResourceAttributesComponent } from "./resource-attributes/resource-attributes.component"
import { ResourcePosterComponent } from "./resource-poster/resource-poster.component"
import { FilterByDisciplineComponent } from "./results-group/filter-by-discipline/filter-by-discipline.component"
import { FilterByGradeComponent } from "./results-group/filter-by-grade/filter-by-grade.component"
import { FilterByTypeComponent } from "./results-group/filter-by-type/filter-by-type.component"
import { FilterClearComponent } from "./results-group/filter-clear/filter-clear.component"
import { RankByComponent } from "./results-group/rank-by/rank-by.component"
import { SearchInputComponent } from "./search/search-input/search-input.component"
import { SearchResultsGroupComponent } from "./search/search-results-group/search-results-group.component"
import { SideNavigationMobileComponent } from "./side-navigation/side-navigation-mobile/side-navigation-mobile.component"
import { SideNavigationNodesComponent } from "./side-navigation/side-navigation-nodes/side-navigation-nodes.component"
import { SideNavigationComponent } from "./side-navigation/side-navigation.component"
import { BackToTopButtonComponent } from "./small-components/back-to-top-button/back-to-top-button.component"
import { ButtonComponent } from "./small-components/button/button.component"
import { CheckboxComponent } from "./small-components/checkbox/checkbox.component"
import { ErrorMessageComponent } from "./small-components/error-message/error-message.component"
import { IconComponent } from "./small-components/icon/icon.component"
import { LogoComponent } from "./small-components/logo/logo.component"
import { PreloaderComponent } from "./small-components/preloader/preloader.component"
import { StickyNavbarTriggerDirective } from "./sticky-premium-navbar/sticky-navbar-trigger.directive"
import { StickyNavbarService } from "./sticky-premium-navbar/sticky-navbar.service"
import { VideoComponent } from "./video/video.component"
import { AccordionComponent } from "./accordion/accordion.component"
import { AccordionItemDirective } from "./accordion/accordion-item.directive"
import { ModalComponent } from "./modal/modal.component"
import { EditorComponent } from "./editor/editor.component"
import { EditorModule } from "@tinymce/tinymce-angular"
import { StudentSignupModalComponent } from "./modals/student-signup-modal/student-signup-modal.component"
import { InputCarouselComponent } from "./small-components/input-carousel/input-carousel.component"
import { AvatarComponent } from "./avatar/avatar.component"
import { LocalizationConfirmComponent } from "./localization-confirm/localization-confirm.component"
import { StickyNavbarComponent } from "./sticky-premium-navbar/sticky-navbar.component"
import { FacebookShareComponent } from "./social/facebook/facebook-share.component"
import { TwitterShareComponent } from "./social/twitter/twitter-share.component"
import { PinterestShareComponent } from "./social/pinterest/pinterest-share.component"
import { ToolsNavigationComponent } from "../tools/tools-navigation/tools-navigation.component"
import { EmbedMinComponent } from "../tools/embed-min/embed-min.component"
import { LinkComponent } from "./link/link.component"
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component"
import { SwiperModule } from "swiper/angular"
import { StudentAssignmentSubmitModalComponent } from "./modals/student-assignment-submit-modal/student-assignment-submit-modal.component"
import { ConfirmDeleteModalComponent } from "./modals/confirm-delete-modal/confirm-delete-modal.component"
import { GCIconComponent } from "./small-components/gc-icon/gc-icon.component"
import { AddToFolderComponent } from "./add-to-folder/add-to-folder.component"
import { PBSIconComponent } from "./small-components/pbs-icon/pbs-icon.component"
import { ShareLinkModalComponent } from "./modals/share-link-modal/share-link-modal.component"
import { ModalHeaderComponent } from "./modals/partials/modal-header/modal-header.component"
import { CopyLinkComponent } from "./copy-link/copy-link.component"
import { ShareLinkGoogleClassModalComponent } from "./modals/share-link-google-class-modal/share-link-google-class-modal.component"
import { SocialShareModalComponent } from "./modals/social-share-modal/social-share-modal.component"
import { ImagePreviewComponent } from "./image-preview/image-preview.component"
import { DocumentPreviewComponent } from "./document-preview/document-preview.component"
import { GenericInteractivePreviewComponent } from "./generic-interactive-preview/generic-interactive-preview.component"
import { IconTooltipComponent } from "./tooltip/icon-tooltip.component"
import { AssignLessonModalComponent } from "./modals/assign-lesson-modal/assign-lesson-modal.component"
import { ShareAssignmentCodeModalComponent } from "./modals/share-assignment-code-modal/share-assignment-code-modal.component"
import { ResourceStudentCardComponent } from "./card/resource-student-card/resource-student-card.component"
import { StudentCardMetaComponent } from "./card/card-components/card-meta/student-card-meta/student-card-meta.component"
import { CollectionStudentCardComponent } from "./card/collection-student-card.component/collection-student-card.component"
import { ExperienceToggleComponent } from "./experience-toggle/experience-toggle.component"
import { CodeInputComponent } from "./small-components/teacher-code-input/code-input.component"
import { RegisterComponent } from "./header/student-register/register.component"
import { BadgeDirective } from "./directives/badge.directive"
import { SpecialCharsDirective } from "./directives/special-chars.directive"
import { StudentMenuComponent } from "./header/student-menu/student-menu.component"
import { SubjectsDropdownComponent } from "./header/subjects-dropdown/subjects-dropdown.component"
import { GradesDropdownComponent } from "./header/grades-dropdown/grades-dropdown.component"
import { LogInAddOnComponent } from "../add-on/log-in-iframe/log-in-add-on.component"
import { DashboardMobileMenuComponent } from "../tools/lesson-builder/lesson-builder/dashboard-mobile-menu/dashboard-mobile-menu.component"
import { BurgerMenuComponent } from "../design-system/burger-menu/burger-menu.component"
import { ClickOutsideDirective } from "./directives/click-outside.directive"

import { DataPrivacyComponent } from "./data-privacy/data-privacy.component"

import { AddDirective } from "./directives/add.directive"
import { DataPrivacyAcknowledgmentComponent } from "./data-privacy/data-privacy-select/data-privacy-acknowledgment/data-privacy-acknowledgment.component"
import { DataPrivacyClassroomComponent } from "./data-privacy/data-privacy-select/data-privacy-classroom/data-privacy-classroom.component"
import { DataPrivacySchoolFormComponent } from "./data-privacy/data-privacy-select/data-privacy-school-form/data-privacy-school-form.component"
import { DataPrivacySuccessComponent } from "./data-privacy/data-privacy-select/data-privacy-success/data-privacy-success.component"
import { DataPrivacySelectComponent } from "./data-privacy/data-privacy-select/data-privacy-select.component"
import { DataPrivacyCompletedComponent } from "./data-privacy/data-privacy-completed/data-privacy-completed.component"
import { DataPrivacySupportComponent } from "./data-privacy/data-privacy-support/data-privacy-support.component"
import { DataPrivacyModalsComponent } from "./data-privacy-modals/data-privacy-modals.component"
import { DoLaterComponent } from "./data-privacy-modals/do-later/do-later.component"
import { ClassroomComponent } from "./data-privacy-modals/classroom/classroom.component"
import { SignInComponent } from "./data-privacy-modals/sign-in/sign-in.component"
import { StudentGateModalComponent } from "./modals/student-gate-modal/student-gate-modal.component"
import { StudentGateContainerComponent } from "./modals/student-gate-modal/student-gate-container/student-gate-container.component"
import { AutofocusDirective } from "./directives/autofocus.directive"
import { CdkTableModule } from "@angular/cdk/table"
import { MatSortModule } from "@angular/material/sort"
import { MatPaginatorModule } from "@angular/material/paginator"
import { SensitiveContentComponent } from "./sensitive-content-container/sensitive-content.component"
import { GenericTableComponent } from "./generic-table/generic-table.component"
import { MatMenuModule } from "@angular/material/menu"
import { SlideToggleComponent } from "./slide-toggle/slide-toggle.component"
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { StudentSignInModalComponent } from "./modals/student-sign-in-modal/student-sign-in-modal.component"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatInputModule } from "@angular/material/input"
import { MatOptionModule } from "@angular/material/core"
import { MatSelectModule } from "@angular/material/select"
import { RecaptchaModule } from "ng-recaptcha"

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        FormsModule,
        NgSelectModule,
        FacetsModule,
        EditorModule,
        ReactiveFormsModule,
        SwiperModule,
        CdkTableModule,
        NgbDropdownModule,
        MatSortModule,
        MatPaginatorModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        RecaptchaModule,
    ],
    declarations: [
        AssignmentDuplicateModalComponent,
        FilterByGradeComponent,
        FilterByDisciplineComponent,
        RankByComponent,
        BypassSecurityPipe,
        TruncatePipe,
        PluralizeMediaTypePipe,
        PrettyGradePipe,
        VideoComponent,
        HeaderComponent,
        FooterComponent,
        ExternalSearchInputComponent,
        AuthModalComponent,
        AgreementModalComponent,
        InformationModalComponent,
        JoinRosterModalComponent,
        LeaveRosterModalComponent,
        ElementPlaceholderComponent,
        BrowseByComponent,
        IconComponent,
        FilterByTypeComponent,
        FilterClearComponent,
        Carousel2Component,
        CarouselItem2Component,
        LocalizationComponent,
        ButtonComponent,
        AdminHeaderComponent,
        AudioComponent,
        CardComponent,
        LocalizationModalComponent,
        LoadScriptsPipe,
        ConfirmStationComponent,
        TextDisplayModalComponent,
        AvailableFeaturesComponent,
        CollectionPosterComponent,
        ResourcePosterComponent,
        CardTitleComponent,
        CardDescriptionComponent,
        ResourceCardPosterComponent,
        CardMetaComponent,
        BrowseCardMetaComponent,
        YmalCardMetaComponent,
        MfrCardMetaComponent,
        SearchCardMetaComponent,
        ResourceFeaturedCardComponent,
        ResourceBrowseCardComponent,
        CollectionCardComponent,
        CollectionBrowseCardComponent,
        CollectionCardPosterComponent,
        CollectionSearchCardComponent,
        ResourceSearchCardComponent,
        YmalCardComponent,
        MfrCardComponent,
        SideNavigationComponent,
        SideNavigationNodesComponent,
        SideNavigationMobileComponent,
        BackToTopButtonComponent,
        ResourceAttributesComponent,
        AttributesComponent,
        LogoComponent,
        PreloaderComponent,
        FavoriteButtonComponent,
        AuthModalContentComponent,
        PaginationComponent,
        InputBoxComponent,
        AddSchoolModalComponent,
        CheckboxComponent,
        ErrorMessageComponent,
        CardContentProjectNameComponent,
        AppliedFiltersComponent,
        SearchInputComponent,
        SearchResultsGroupComponent,
        MainNavigationComponent,
        LanguageSelectorComponent,
        MobileFilterModalComponent,
        MobileSearchTopSectionComponent,
        AlertBarComponent,
        LtiContinueModalComponent,
        NotificationModalComponent,
        LanguageBadgeComponent,
        LinkButtonComponent,
        AccordionComponent,
        AccordionItemDirective,
        ModalComponent,
        ModalBackdropComponent,
        EditorComponent,
        StudentSignupModalComponent,
        InputCarouselComponent,
        AvatarComponent,
        LocalizationConfirmComponent,
        StickyNavbarComponent,
        StickyNavbarTriggerDirective,
        FacebookShareComponent,
        TwitterShareComponent,
        PinterestShareComponent,
        ToolsNavigationComponent,
        EmbedMinComponent,
        LinkComponent,
        FeedbackModalComponent,
        VerticalMenuComponent,
        StudentAssignmentSubmitModalComponent,
        ConfirmDeleteModalComponent,
        GCIconComponent,
        PBSIconComponent,
        AddToFolderComponent,
        ShareLinkModalComponent,
        ModalHeaderComponent,
        CopyLinkComponent,
        ShareLinkGoogleClassModalComponent,
        SocialShareModalComponent,
        ImagePreviewComponent,
        DocumentPreviewComponent,
        GenericInteractivePreviewComponent,
        IconTooltipComponent,
        AssignLessonModalComponent,
        DashboardMobileMenuComponent,
        BurgerMenuComponent,
        ShareAssignmentCodeModalComponent,
        ResourceStudentCardComponent,
        StudentCardMetaComponent,
        CollectionStudentCardComponent,
        ExperienceToggleComponent,
        CodeInputComponent,
        RegisterComponent,
        BadgeDirective,
        SpecialCharsDirective,
        StudentMenuComponent,
        SubjectsDropdownComponent,
        GradesDropdownComponent,
        ClickOutsideDirective,
        AutofocusDirective,
        LogInAddOnComponent,
        DataPrivacyComponent,
        DataPrivacyClassroomComponent,
        DataPrivacyAcknowledgmentComponent,
        AddDirective,
        DataPrivacySchoolFormComponent,
        DataPrivacySuccessComponent,
        DataPrivacySelectComponent,
        DataPrivacyCompletedComponent,
        DataPrivacySupportComponent,
        DataPrivacyModalsComponent,
        DoLaterComponent,
        ClassroomComponent,
        SignInComponent,
        StudentGateModalComponent,
        StudentGateContainerComponent,
        GenericTableComponent,
        SensitiveContentComponent,
        SlideToggleComponent,
        StudentSignInModalComponent,
    ],
    exports: [
        AssignmentDuplicateModalComponent,
        CommonModule,
        FilterByGradeComponent,
        ReactiveFormsModule,
        NgbModule,
        FilterByDisciplineComponent,
        RankByComponent,
        BypassSecurityPipe,
        TruncatePipe,
        PluralizeMediaTypePipe,
        PrettyGradePipe,
        VideoComponent,
        HeaderComponent,
        FooterComponent,
        ExternalSearchInputComponent,
        ElementPlaceholderComponent,
        BrowseByComponent,
        IconComponent,
        FilterByTypeComponent,
        FilterClearComponent,
        Carousel2Component,
        CarouselItem2Component,
        AdminHeaderComponent,
        AudioComponent,
        LocalizationComponent,
        ButtonComponent,
        LoadScriptsPipe,
        CardComponent,
        ConfirmStationComponent,
        AvailableFeaturesComponent,
        CollectionPosterComponent,
        ResourcePosterComponent,
        CardTitleComponent,
        CardDescriptionComponent,
        ResourceCardPosterComponent,
        CardMetaComponent,
        BrowseCardMetaComponent,
        YmalCardMetaComponent,
        MfrCardMetaComponent,
        SearchCardMetaComponent,
        ResourceFeaturedCardComponent,
        ResourceBrowseCardComponent,
        CollectionCardComponent,
        CollectionBrowseCardComponent,
        CollectionCardPosterComponent,
        CollectionSearchCardComponent,
        ResourceSearchCardComponent,
        YmalCardComponent,
        MfrCardComponent,
        SideNavigationComponent,
        SideNavigationNodesComponent,
        SideNavigationMobileComponent,
        BackToTopButtonComponent,
        ResourceAttributesComponent,
        AttributesComponent,
        LogoComponent,
        PreloaderComponent,
        FavoriteButtonComponent,
        AuthModalContentComponent,
        PaginationComponent,
        InputBoxComponent,
        AddSchoolModalComponent,
        CheckboxComponent,
        ErrorMessageComponent,
        CardContentProjectNameComponent,
        AppliedFiltersComponent,
        SearchInputComponent,
        SearchResultsGroupComponent,
        FacetsModule,
        MainNavigationComponent,
        LanguageSelectorComponent,
        MobileSearchTopSectionComponent,
        AlertBarComponent,
        LanguageBadgeComponent,
        LinkButtonComponent,
        AccordionComponent,
        AccordionItemDirective,
        EditorComponent,
        InputCarouselComponent,
        AvatarComponent,
        LocalizationConfirmComponent,
        StickyNavbarComponent,
        StickyNavbarTriggerDirective,
        FacebookShareComponent,
        TwitterShareComponent,
        PinterestShareComponent,
        ToolsNavigationComponent,
        EmbedMinComponent,
        LinkComponent,
        VerticalMenuComponent,
        StudentAssignmentSubmitModalComponent,
        GCIconComponent,
        PBSIconComponent,
        AddToFolderComponent,
        ShareLinkModalComponent,
        ModalHeaderComponent,
        CopyLinkComponent,
        ShareLinkGoogleClassModalComponent,
        SocialShareModalComponent,
        ImagePreviewComponent,
        DocumentPreviewComponent,
        GenericInteractivePreviewComponent,
        IconTooltipComponent,
        AssignLessonModalComponent,
        DashboardMobileMenuComponent,
        BurgerMenuComponent,
        ShareAssignmentCodeModalComponent,
        ResourceStudentCardComponent,
        StudentCardMetaComponent,
        CollectionStudentCardComponent,
        ExperienceToggleComponent,
        BadgeDirective,
        SpecialCharsDirective,
        StudentMenuComponent,
        RegisterComponent,
        LogInAddOnComponent,
        DataPrivacyComponent,
        StudentGateModalComponent,
        StudentGateContainerComponent,
        DataPrivacyClassroomComponent,
        DataPrivacyModalsComponent,
        GradesDropdownComponent,
        GenericTableComponent,
        SensitiveContentComponent,
        SlideToggleComponent,
    ],
})
export class SharedComponentsModule {
    public static forRoot(): ModuleWithProviders<SharedComponentsModule> {
        return {
            ngModule: SharedComponentsModule,
            providers: [
                TruncatePipe,
                WindowSizeService,
                AccordionService,
                ModalService,
                StickyNavbarService,
            ],
        }
    }
}
