import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core"
import { NgModel } from "@angular/forms"

@Directive({
    selector: "[ngModel][appCustomRegExValidation]",
    providers: [NgModel],
})
export class SpecialCharsDirective {
    @Input() appCustomRegExValidation = false
    @Input() customRegEx: RegExp
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter()

    constructor(private ref: ElementRef) {}

    @HostListener("keypress", ["$event"]) onKeyPress(event) {
        if (!this.appCustomRegExValidation) return
        this.validateFields(event)
    }

    @HostListener("paste", ["$event"]) onEvent(event) {
        if (!this.appCustomRegExValidation) return
        this.validateFields(event)
    }

    validateFields(event) {
        if (!this.customRegEx) {
            this.customRegEx = new RegExp(/[a-zA-Z0-9 -]/)
        }
        setTimeout(() => {
            let clearedChars = ""
            let charsFromInput = this.ref.nativeElement.value.split("")
            for (let key in charsFromInput) {
                if (charsFromInput[key].match(this.customRegEx)) {
                    clearedChars += charsFromInput[key]
                }
            }
            this.ngModelChange.emit(clearedChars)
        }, 100)
    }
}
