import { EventEmitter } from "@angular/core"
import { URSService } from "../../services/urs.service"
import { ITSImage } from "../itsImage.model"
import { Language, LanguageUtil } from "../language.model"

declare var window: any

export class Media {
    public isAvailable = true
    public usedFile: VideoMediaFile | undefined
    /*
     Raises event when URS geo-restriction result is evaluated.
     If Media is not geo-restricted, this does not raise an event.
     */
    public geoRestrictionEvaluated = new EventEmitter<Media>()

    constructor(
        public language: Language,
        public posterImage: ITSImage,
        public download = {
            url: "",
            file_type: "",
            file_size: 0,
            is_downloadable: false,
            is_download_ready: false,
        }
    ) {}

    public hasTranscript(): boolean {
        return false
    }

    public isGeoRestrictionEvaluated(): boolean {
        return true
    }

    public isAvailableToUser(): boolean {
        return this.isGeoRestrictionEvaluated() && this.isAvailable
    }

    public evaluateURSRestriction(ursService: URSService) {
        if (this.isGeoRestrictionEvaluated()) {
            return
        }
        const checkedURL = this.getGeoRestrictedCheckURL()
        ursService.evaluateURSRestriction(checkedURL).subscribe((response) => {
            if (response.error) {
                this.setGeoRestrictionResponse(checkedURL, null, response.error)
            } else {
                this.setGeoRestrictionResponse(checkedURL, response.url, null)
            }
            this.geoRestrictionEvaluated.emit(this)
        })
    }

    public setGeoRestrictionResponse(checkedURL: string, url: string, errorCode: string) {}

    public getGeoRestrictedCheckURL(): string {
        return null
    }

    public getDefaultPosterImage() {
        return this.posterImage.itsURL
    }

    /**
     *
     * @param data API raw Data
     * @param ursService We always want to evaluate GEO-Restriction so we don't forget and the medias are ready
     * for display
     */
    public static fromData(data: any, ursService: URSService): Media {
        let media = Media._fromData(data)
        media.evaluateURSRestriction(ursService)
        return media
    }

    private static _fromData(data: any): Media {
        const image = new ITSImage(data.poster_image.url, data.poster_image.alt_text)
        const language = LanguageUtil.fromStringCode(data.language)
        const captions = (data.captions || []).map(
            (c) => new Caption(c.url, LanguageUtil.fromStringCode(c.language))
        )
        if (data.type === "video") {
            return new VideoMedia(
                language,
                image,
                data.download,
                data.transcript_print_url,
                data.transcript_text,
                data.transcript_url,
                captions,
                (data.files || []).map((fileData) => VideoMediaFile.fromData(fileData)),
                !!data.is_360_video,
                data.chapters_url
            )
        } else if (data.type === "image") {
            return new ImageMedia(
                language,
                image,
                data.download,
                data.file_url,
                data.image_description,
                data.canonical_url
            )
        } else if (data.type === "document") {
            return new DocumentMedia(language, image, data.download, data.canonical_url)
        } else if (data.type === "interactive") {
            return new InteractiveMedia(
                language,
                image,
                data.download,
                data.type,
                data.file_url,
                data.canonical_url,
                data.show_flash_warning
            )
        } else if (data.type === "pointer") {
            return new PointerMedia(
                language,
                image,
                data.download,
                data.type,
                data.file_url,
                data.canonical_url,
                data.show_flash_warning
            )
        } else if (data.type === "lti") {
            return new LTIMedia(
                language,
                image,
                data.download,
                data.type,
                data.file_url,
                data.canonical_url,
                data.guest_url
            )
        } else if (data.type === "audio") {
            return new AudioMedia(
                language,
                image,
                data.download,
                data.transcript_print_url,
                data.transcript_text,
                data.transcript_url,
                data.audio_url,
                captions,
                data.chapters_url,
                !!data.has_georestriction
            )
        } else if (data.type === "htmlfragment") {
            return new HTMLMedia(language, image, data.download, data.content)
        }
    }
}

export class DocumentMedia extends Media {
    constructor(
        public language: Language,
        public posterImage: ITSImage,
        public download,
        public canonicalURL: string
    ) {
        super(language, posterImage, download)
    }
}

export class TranscriptMedia extends Media {
    constructor(
        public language: Language,
        public posterImage: any,
        public download,
        public transcriptPrintURL: string,
        public transcriptText,
        public transcriptURL
    ) {
        super(language, posterImage, download)
    }

    public hasTranscript(): boolean {
        return this.transcriptURL || this.transcriptText
    }
}

export class PlayableMedia extends TranscriptMedia {
    constructor(
        public language: Language,
        public posterImage: any,
        public download,
        public transcriptPrintURL: string,
        public transcriptText,
        public transcriptURL,
        public captions: Caption[],
        public chaptersURL: string
    ) {
        super(language, posterImage, download, transcriptPrintURL, transcriptText, transcriptURL)
    }

    public getVideoJsTracks() {
        // https://docs.videojs.com/tutorial-text-tracks.html#track-attributes
        const tracks = []
        if (this.captions) {
            this.captions.forEach((caption) => {
                tracks.push({
                    kind: "captions",
                    srclang: LanguageUtil.toCode(caption.language),
                    label: caption.language,
                    src: caption.url,
                })
            })

            if (tracks.length) {
                tracks[0].default = true
            }
        }
        if (this.chaptersURL) {
            tracks.push({
                kind: "chapters",
                src: this.chaptersURL,
                label: "Chapters",
            })
        }
        return tracks
    }
}

export class VideoMediaFile {
    public validatedURL: string
    public showsStaticGeoRestrictionVideo = false

    constructor(
        public hasGeoRestriction: boolean,
        public role: string,
        public originalURL: string,
        public type: string,
        public primary: string
    ) {}

    public setValidatedURL(url: string) {
        this.validatedURL = url
    }

    public getURL(): string {
        if (this.validatedURL) {
            return this.validatedURL
        }
        return this.originalURL
    }

    public isGeoRestrictionEvaluated(): boolean {
        //todo: note that once jwplayer is removed this needs changing, validatedURL is no longer ok
        return !this.hasGeoRestriction || !!this.validatedURL
    }

    public getMediaType() {
        if (this.showsStaticGeoRestrictionVideo) {
            // All static videos are mp4
            return "video/mp4"
        }
        // TODO: consider other options, maybe extension check
        if (this.type === "HLS 16x9" || this.type === "HLS 4x3") {
            return "application/x-mpegURL"
        } else {
            return "video/mp4"
        }
    }

    public static fromData(data) {
        return new VideoMediaFile(
            data.has_georestriction,
            data.role,
            data.url,
            data.type,
            data.primary
        )
    }
}

export class Caption {
    constructor(public url: string, public language: Language) {}
}

export class VideoMedia extends PlayableMedia {
    public usedFile: VideoMediaFile
    public backup: VideoMediaFile

    constructor(
        public language: Language,
        public posterImage: ITSImage,
        public download,
        public transcriptPrintURL: string,
        public transcriptText,
        public transcriptURL,
        public captions: Caption[],
        public videoFiles: VideoMediaFile[],
        public is360: boolean,
        public chaptersURL: string
    ) {
        super(
            language,
            posterImage,
            download,
            transcriptPrintURL,
            transcriptText,
            transcriptURL,
            captions,
            chaptersURL
        )
        this.usedFile =
            this.videoFiles.find((file) => file.type === "HLS 16x9") ||
            // Legacy files
            this.videoFiles.find((file) => file.type === "HLS 4x3") ||
            // Transcoded MP4 (MP3 name is wrong in the backend)
            this.videoFiles.find((file) => file.type === "MP4 16x9") ||
            this.videoFiles.find((file) => file.type === "MP3 16x9") ||
            // Default, generally source file
            this.videoFiles[0]
        if (
            this.usedFile &&
            (this.usedFile.type === "HLS 16x9" || this.usedFile.type === "HLS 4x3")
        ) {
            this.backup =
                // Transcoded MP4 (MP3 name is wrong in the backend)
                this.videoFiles.find((file) => file.type === "MP4 16x9") ||
                this.videoFiles.find((file) => file.type === "MP3 16x9")
        }
    }

    public isGeoRestrictionEvaluated(): boolean {
        return this.usedFile.isGeoRestrictionEvaluated()
    }

    public setGeoRestrictionResponse(checkedURL: string, url: string, errorCode: string) {
        const file = this.videoFiles.find((possibleFile) => possibleFile.originalURL === checkedURL)
        if (errorCode) {
            // May also need to change poster image.
            this.isAvailable = false
            // TODO: once jwplayer is removed, we don't need this
            file.setValidatedURL(`${window.PBSLM.NG.CDN}player_error_videos/${errorCode}.mp4`)
            file.showsStaticGeoRestrictionVideo = true
        } else {
            file.setValidatedURL(url)
        }
    }

    public getGeoRestrictedCheckURL(): string {
        return this.usedFile.originalURL
    }

    public hasTranscript(): boolean {
        return this.transcriptText || this.transcriptURL
    }

    public getVideoJsSources() {
        if (this.backup) {
            return [
                {
                    type: this.usedFile.getMediaType(),
                    src: this.usedFile.getURL(),
                },
                {
                    type: this.backup.getMediaType(),
                    src: this.backup.getURL(),
                },
            ]
        }
        return {
            type: this.usedFile.getMediaType(),
            src: this.usedFile.getURL(),
        }
    }
}

export class HTMLMedia extends Media {
    constructor(
        public language: Language,
        public posterImage: any,
        public download,
        public htmlContent: any
    ) {
        super(language, posterImage, download)
    }
}

export class GenericInteractiveMedia extends Media {
    // Media that opens externally
    constructor(
        public language: Language,
        public posterImage: ITSImage,
        public download,
        public type: string,
        public url: string,
        public canonicalUrl: string
    ) {
        super(language, posterImage, download)
    }
}

export class LTIMedia extends GenericInteractiveMedia {
    constructor(
        public language: Language,
        public posterImage: any,
        public download,
        public type: string,
        public url: string,
        public canonicalUrl: string,
        public guestURL: string // no-login external link
    ) {
        super(language, posterImage, download, type, url, canonicalUrl)
    }

    public getGuestURL(currentURL: string) {
        // WGHB specific format
        return `${this.guestURL}?as_guest=True&next=${currentURL}`
    }
}

export class InteractiveMedia extends GenericInteractiveMedia {
    constructor(
        public language: Language,
        public posterImage: ITSImage,
        public download,
        public type: string,
        public url: string,
        public canonicalUrl: string,
        public showFlashWarning: boolean
    ) {
        super(language, posterImage, download, type, url, canonicalUrl)
    }
}

export class PointerMedia extends GenericInteractiveMedia {
    constructor(
        public language: Language,
        public posterImage: ITSImage,
        public download,
        public type: string,
        public url: string,
        public canonicalUrl: string,
        public showFlashWarning: boolean
    ) {
        super(language, posterImage, download, type, url, canonicalUrl)
    }
}

export class HTMLFragmentMedia extends Media {
    constructor(
        public language: Language,
        public posterImage: ITSImage,
        public download,
        public htmlContent: string
    ) {
        super(language, posterImage, download)
    }
}

export class ImageMedia extends Media {
    constructor(
        public language: Language,
        public posterImage: ITSImage,
        public download,
        public largeImageURL: string,
        public ARIADescription: string,
        public canonicalUrl: string
    ) {
        super(language, posterImage, download)
    }
}

export class AudioMedia extends PlayableMedia {
    public validatedURL: string = null

    constructor(
        public language: Language,
        public posterImage: any,
        public download,
        public transcriptPrintURL: string,
        public transcriptText,
        public transcriptURL,
        public audioURL: string,
        public captions: Caption[],
        public chaptersURL: string,
        public hasGeoRestriction: boolean
    ) {
        super(
            language,
            posterImage,
            download,
            transcriptPrintURL,
            transcriptText,
            transcriptURL,
            captions,
            chaptersURL
        )
    }

    public getTracks() {
        //implement captions ?
        if (this.chaptersURL) {
            return [
                {
                    file: this.chaptersURL,
                    kind: "chapters",
                },
            ]
        }
        return []
    }

    public isGeoRestrictionEvaluated(): boolean {
        if (!this.hasGeoRestriction) {
            return true
        }
        // based on the 2 possible outcomes, rather than having another isEvaluated flag
        return !!this.validatedURL || !this.isAvailable
    }

    public getURL() {
        if (this.validatedURL) {
            return this.validatedURL
        }
        return this.audioURL
    }

    public getGeoRestrictedCheckURL(): string {
        return this.audioURL
    }

    public setGeoRestrictionResponse(checkedURL: string, url: string, errorCode: string) {
        if (errorCode) {
            this.isAvailable = false
        }
        this.validatedURL = url
    }

    public getVideoJsSource() {
        return { type: "audio/mp3", src: this.getURL() }
    }
}
