import { throwError as observableThrowError } from "rxjs"

import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { catchError, map } from "rxjs/operators"
import { Roster } from "../models/roster.model"
import { Student } from "../models/student.model"
import { LmApiService } from "./lm-api.service"

@Injectable({
    providedIn: "root",
})
export class TeacherAssignService {
    constructor(private http: HttpClient, private lmApi: LmApiService) {}

    public getRosterSet(type: string) {
        const rosterType = type ? `?type=${type}` : ""
        return this.lmApi.get(`/api/v2/tools/roster/${rosterType}`).pipe(
            catchError((error) => observableThrowError(error)),
            map((data: any) => {
                const rosters: Roster[] = []
                if (data) {
                    data.map((roster) => {
                        rosters.push(new Roster(roster))
                    })
                }
                return rosters
            })
        )
    }

    public saveAssignment(assignCode: string = "", rosters: Roster[] = []) {
        const data = this.prepareRosterForPost(assignCode, rosters)
        return this.lmApi.post(`/api/v2/tools/assign/`, data)
    }

    private prepareRosterForPost(assignCode: string = "", rosters: Roster[] = []) {
        const postReadyRosters = []

        const assignableRosters = rosters.filter((roster: Roster) => {
            return roster.selected
        })

        assignableRosters.forEach((roster: Roster) => {
            const rosterObj = {
                rosterId: roster.rosterId,
                studentIds: [],
            }
            roster.students.forEach((student: Student) => {
                if (student.selected) {
                    rosterObj.studentIds.push(student.studentRosterId)
                }
            })
            postReadyRosters.push(rosterObj)
        })

        return {
            activityCode: assignCode,
            students: postReadyRosters,
        }
    }
}
