import { Component, Input } from "@angular/core"
import { CHNode } from "../../../models/node.model"
import { ColorCodeService } from "../../../services/color-code.service"

@Component({
    selector: "app-subject-list",
    templateUrl: "./subject-list.component.html",
    styleUrls: ["./subject-list.component.scss"],
})
export class SubjectListComponent {
    public ColorCode = ColorCodeService

    @Input()
    public subjects: CHNode[]
}
