<div
    ngbDropdown
    #localizationConfirmDropdown="ngbDropdown"
    class="dropdown"
    [open]="showPopup()"
    autoClose="outside"
    (openChange)="close()"
>
    <div class="d-none" ngbDropdownToggle></div>
    <div ngbDropdownMenu class="dropdown-menu">
        <div class="dropdown-wrapper p-4">
            <div class="btn-close" (click)="close()"></div>

            <div class="content">
                <span class="text">Is {{ organizationName }} your local station?</span>
                <span class="buttons">
                    <app-button (click)="close()" class="ps-sm-4 pe-4">Yes</app-button>
                    <app-button (click)="openLocalizationChangeModal()" class="border-btn"
                        >No, change</app-button
                    >
                </span>
            </div>
        </div>
    </div>
</div>
