import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    OnDestroy,
    Output,
} from "@angular/core"
import { DOCUMENT } from "@angular/common"
import { filter, takeUntil } from "rxjs/operators"
import { fromEvent, Subject } from "rxjs"

/**
 * Emit event when clicked outside the targeted element
 */
@Directive({
    selector: "[appClickOutside]",
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
    @Output() clickOutside = new EventEmitter<void>()

    private destroyed$ = new Subject()

    constructor(private element: ElementRef, @Inject(DOCUMENT) private document: Document) {}

    ngAfterViewInit(): void {
        fromEvent(this.document, "click")
            .pipe(
                takeUntil(this.destroyed$),
                filter((event) => {
                    return !this.isInside(event.target as HTMLElement)
                })
            )
            .subscribe(() => {
                this.clickOutside.emit()
            })
    }

    ngOnDestroy(): void {
        this.destroyed$.next()
        this.destroyed$.complete()
    }

    isInside(elementToCheck: HTMLElement): boolean {
        return (
            elementToCheck === this.element.nativeElement ||
            this.element.nativeElement.contains(elementToCheck)
        )
    }
}
