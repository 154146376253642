import { Component, Input } from "@angular/core"

/**
 * Add tooltip content to an icon
 */
@Component({
    selector: "app-icon-tooltip",
    templateUrl: "./icon-tooltip.component.html",
    styleUrls: ["./icon-tooltip.component.scss"],
})
export class IconTooltipComponent {
    @Input()
    tooltipClass: string

    @Input()
    appIconType: string

    @Input()
    set iconSizePx(iconSize: number) {
        this.iconSize = iconSize ? iconSize + "px" : "14px"
    }

    iconSize: string
}
