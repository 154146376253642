import { GradeBandEnum } from "../models/constants"

export default class ResourceUtils {
    public static predefinedSubjectsList: any = {
        8337: {
            colour: "#486CD8",
            name: "Science",
        },
        3026: {
            colour: "#6A1B9A",
            name: "Social Studies",
        },
        1184: {
            colour: "#B2528C",
            name: "Mathematics",
        },
        1880: {
            colour: "#D6383A",
            name: "English Language Arts and Literacy",
        },
        8353: {
            colour: "#262626",
            name: "Engineering & Technology",
        },
        2806: {
            colour: "#087B45",
            name: "Health and Physical Education",
        },
        2949: {
            colour: "#007B9B",
            name: "Preschool",
        },
        6708: {
            colour: "#60738A",
            name: "Professional Development",
        },
        2663: {
            colour: "#074D51",
            name: "The Arts",
        },
        6267: {
            colour: "#A31A51",
            name: "World Languages",
        },
    }

    /**
     * Get corresponding color based on subject id as a hex
     * @param id - subject id
     */
    public static getSubjectColor(id: number): string {
        if (!id) return
        return ResourceUtils.predefinedSubjectsList[id]["colour"]
    }

    public static getGradesDisplay(grades: string[]) {
        if (!grades) {
            return null
        }

        let gradesDisplay: string = ""

        const is13Plus = grades.length === 1 && grades[0] === GradeBandEnum.Band13Plus
        grades = grades.filter((g) => g !== GradeBandEnum.Band13Plus)
        const hasAllGrades =
            [
                GradeBandEnum.Band3to5,
                GradeBandEnum.Band6to8,
                GradeBandEnum.Band9to12,
            ].every((grade) => grades.includes(grade)) &&
            (grades.includes(GradeBandEnum.BandPreKtoK) || grades.includes(GradeBandEnum.BandKto2))

        if (is13Plus) {
            gradesDisplay = null
        } else if (hasAllGrades) {
            gradesDisplay = "All"
        } else {
            gradesDisplay = grades.join(", ")
        }

        return gradesDisplay
    }

    public static getMediaTypesDisplay(mediaTypes: { [mediaType: string]: number }): string {
        return Object.keys(mediaTypes)
            .map((mediaType) => `${mediaType} (${mediaTypes[mediaType]})`)
            .join(", ")
    }

    public static getResourcesByLanguage(languages: { [language: string]: number }): string {
        let resByLanguageText = Object.keys(languages)
            .map((language) => `Resources in ${language} (${languages[language]})`)
            .join(", ")
        return resByLanguageText ? ", " + resByLanguageText : ""
    }

    public static getResourceTypesDisplay(
        mediaTypes: { [mediaType: string]: number },
        grades: string[],
        resources_by_language: { [language: string]: number }
    ): string {
        let displayText = ""
        const mediaDisplay = ResourceUtils.getMediaTypesDisplay(mediaTypes)
        const gradeDisplay = ResourceUtils.getGradesDisplay(grades)
        if (mediaDisplay && gradeDisplay) {
            displayText = `${mediaDisplay} for Grades ${gradeDisplay}`
        }
        const resourcesByLanguage = ResourceUtils.getResourcesByLanguage(resources_by_language)
        if (resourcesByLanguage) {
            displayText = displayText + resourcesByLanguage
        }
        return displayText
    }

    public static getPremiumCollectionSlug() {
        if (window.location.href.split("/").length != 8) {
            return "" // False
        } else {
            return window.location.href.split("/").reverse().slice(1, 2).toString()
        }
    }
}
