<h5 class="pb-6 pt-6 text-center w-100 m-0">Similar Resources</h5>
<div class="d-flex flex-wrap justify-content-between w-100">
    <div
        class="resource-card-container col-12 col-lg-6"
        *ngFor="let learningObject of learningObjects"
    >
        <app-resource-search-card
            class="w-100 d-block h-100"
            [learningObject]="learningObject"
        ></app-resource-search-card>
    </div>
</div>
