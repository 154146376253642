import { Component } from "@angular/core"

@Component({
    selector: "app-data-privacy-success",
    templateUrl: "./data-privacy-success.component.html",
    styleUrls: ["./data-privacy-success.component.scss"],
})
export class DataPrivacySuccessComponent {
    headerTitle = "Student Privacy"
}
