<div class="gtmpage-profile">
    <ng-container *ngIf="userProfile$ | async as userProfile; else loading">
        <!--Mobile View-->
        <ng-container *ngIf="isMobile$ | async; else desktopView">
            <div class="px-4 pb-4 mt-8 bg-white gtmsection-profile-data-container pt-2">
                <ngb-accordion
                    #ngbAccordion
                    [destroyOnHide]="false"
                    [activeIds]="SECTION_FRAGMENT.USER_PROFILE"
                    (hidden)="onPanelHidden($event)"
                    [ngClass]="{ closed: !ngbAccordion.isExpanded(SECTION_FRAGMENT.USER_PROFILE) }"
                >
                    <ngb-panel [id]="SECTION_FRAGMENT.USER_PROFILE">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <ng-container *ngIf="{ isDirty: isDirty$ | async } as data">
                                <app-user-profile-action-buttons
                                    [isSaveDisabled]="!data.isDirty || userProfileForm.invalid"
                                    [isCancelVisible]="data.isDirty"
                                    (btnClick)="buttonClicked($event)"
                                ></app-user-profile-action-buttons>
                            </ng-container>

                            <button
                                ngbPanelToggle
                                class="d-flex toggle-button align-items-center btn fw-bold shadow-none mb-3 ps-4"
                            >
                                <app-icon
                                    type="Arrow-down"
                                    class="text-primary"
                                    [ngClass]="{ 'ngb-arrow-up': opened }"
                                ></app-icon>
                                <h3 class="text-navy-blue ms-xs-1">User Profile</h3>
                            </button>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <ng-container
                                *ngTemplateOutlet="
                                    userProfileTemplate;
                                    context: { $implicit: userProfile }
                                "
                            >
                            </ng-container>
                            <ng-container *ngIf="{ isDirty: isDirty$ | async } as data">
                                <app-user-profile-action-buttons
                                    [isSaveDisabled]="!data.isDirty || userProfileForm.invalid"
                                    [isCancelVisible]="data.isDirty"
                                    (btnClick)="buttonClicked($event)"
                                ></app-user-profile-action-buttons>
                            </ng-container>
                        </ng-template>
                    </ngb-panel>
                    <ng-container *ngIf="!addOnViewService.isAddonView">
                        <ngb-panel [id]="SECTION_FRAGMENT.STUDENT_PRIVACY">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <button
                                    ngbPanelToggle
                                    class="d-flex align-items-center btn fw-bold shadow-none toggle-button p-4"
                                >
                                    <app-icon
                                        type="Arrow-down"
                                        class="text-primary"
                                        [ngClass]="{ 'ngb-arrow-up': opened }"
                                    ></app-icon>
                                    <h3 class="text-navy-blue">Student Privacy</h3>
                                </button>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <app-data-privacy></app-data-privacy>
                            </ng-template>
                        </ngb-panel>
                    </ng-container>
                </ngb-accordion>
            </div>
        </ng-container>

        <!--Desktop View-->
        <ng-template #desktopView>
            <div id="sticky-start"></div>
            <div class="container py-8">
                <div class="row">
                    <aside *ngIf="isDesktop$ | async" class="col-3 pt-9 gtmsection-left-navigation">
                        <div [stickyStartId]="'sticky-start'" [appStickyClass]="'sticky'">
                            <h4 class="text-uppercase text-navy-blue py-4">profile</h4>

                            <nav class="nav d-flex flex-column fw-bold font-size-20">
                                <a
                                    href="#{{ SECTION_FRAGMENT.USER_PROFILE }}"
                                    class="text-decoration-none py-4"
                                    (click)="
                                        scrollToTargetAdjusted(SECTION_FRAGMENT.USER_PROFILE);
                                        $event.preventDefault()
                                    "
                                    >User Profile</a
                                >
                                <a
                                    href="#{{ SECTION_FRAGMENT.PROFESSIONAL_INFO }}"
                                    class="text-decoration-none py-4"
                                    (click)="
                                        scrollToTargetAdjusted(SECTION_FRAGMENT.PROFESSIONAL_INFO);
                                        $event.preventDefault()
                                    "
                                    >Professional Information</a
                                >

                                <ng-container *ngIf="!addOnViewService.isAddonView">
                                    <a
                                        href="#{{ SECTION_FRAGMENT.STUDENT_PRIVACY }}"
                                        class="text-decoration-none py-4"
                                        (click)="
                                            scrollToTargetAdjusted(
                                                SECTION_FRAGMENT.STUDENT_PRIVACY
                                            );
                                            $event.preventDefault()
                                        "
                                        >Student Privacy</a
                                    >
                                </ng-container>
                            </nav>
                        </div>
                    </aside>
                    <section
                        class="section-wrapper bg-white col py-6 rounded-3 gtmsection-profile-data-container"
                    >
                        <ng-container *ngIf="{ isDirty: isDirty$ | async } as data">
                            <app-user-profile-action-buttons
                                [isSaveDisabled]="!data.isDirty || userProfileForm.invalid"
                                [isCancelVisible]="data.isDirty"
                                (btnClick)="buttonClicked($event)"
                            ></app-user-profile-action-buttons>
                        </ng-container>

                        <header
                            [id]="SECTION_FRAGMENT.USER_PROFILE"
                            class="d-flex justify-content-between align-items-center mb-5"
                        >
                            <h3 class="text-navy-blue selenium-page-title">User Profile</h3>
                        </header>
                        <hr class="mb-6" />

                        <ng-container
                            *ngTemplateOutlet="
                                userProfileTemplate;
                                context: { $implicit: userProfile }
                            "
                        ></ng-container>

                        <ng-container *ngIf="{ isDirty: isDirty$ | async } as data">
                            <app-user-profile-action-buttons
                                [isSaveDisabled]="!data.isDirty || userProfileForm.invalid"
                                [isCancelVisible]="data.isDirty"
                                (btnClick)="buttonClicked($event)"
                            ></app-user-profile-action-buttons>
                        </ng-container>
                        <hr class="mt-2 mb-6" />
                        <ng-container *ngIf="!addOnViewService.isAddonView">
                            <section class="bg-almost-white px-5 pt-7 pb-5">
                                <header
                                    [id]="SECTION_FRAGMENT.STUDENT_PRIVACY"
                                    class="d-flex justify-content-between align-items-center mb-5 gtm-profile-select-school-type"
                                >
                                    <h3 class="text-navy-blue ms-xs-1">Student Privacy</h3>
                                </header>
                                <app-data-privacy></app-data-privacy>
                            </section>
                        </ng-container>
                    </section>
                </div>
            </div>
            <div id="sticky-bottom"></div>
        </ng-template>
    </ng-container>

    <!--Common user profile template for mobile and desktop-->
    <ng-template #userProfileTemplate let-userProfile>
        <form [formGroup]="userProfileForm">
            <app-full-name formControlName="fullName"></app-full-name>
            <app-user-profile-errors [control]="fullNameFormControl"></app-user-profile-errors>

            <app-account-info formControlName="accountInfo"></app-account-info>

            <!-- Local station -->
            <app-user-localization
                [localizeData$]="localizeData$"
                [isDirty$]="isDirty$"
                [showStateStandards]="false"
                (changeStation)="onChangeStation()"
            ></app-user-localization>

            <!-- Professional Information -->
            <header
                [id]="SECTION_FRAGMENT.PROFESSIONAL_INFO"
                class="d-flex justify-content-between align-items-center mb-2"
            >
                <h3 class="text-capitalize text-navy-blue">professional information</h3>
            </header>
            <hr class="mb-4" />
            <p>
                Providing this information will help us connect you to educators at your local PBS
                station and better understand your interests.
            </p>

            <!-- State Standards -->
            <app-user-localization
                [localizeData$]="localizeData$"
                [isDirty$]="isDirty$"
                [showStateStandards]="true"
                (changeStation)="onChangeStation()"
            ></app-user-localization>

            <!-- Role Selector -->
            <app-user-profile-role-selector
                formControlName="userRole"
            ></app-user-profile-role-selector>
            <app-user-profile-errors [control]="userRoleFormControl"></app-user-profile-errors>

            <!-- Grades Checkboxes -->
            <div class="p-4 mb-4 mt-6 bg-very-pale-blue selenium-grades-panel">
                <div class="mb-4 fw-bold font-size-16 text-navy-blue">
                    What grade(s) do you currently teach? Check all that apply<span
                        class="required-field"
                    ></span>
                    (Required)
                </div>
                <app-user-profile-checkbox-group
                    formControlName="grades"
                    [gtmClass]="'gtm-profile-select-grade-band'"
                    class="grades-checkbox-grid"
                >
                    <ng-container *ngFor="let grade of grades">
                        <app-user-profile-checkbox
                            [value]="grade"
                            [label]="grade"
                        ></app-user-profile-checkbox>
                    </ng-container>
                </app-user-profile-checkbox-group>
            </div>
            <app-user-profile-errors [control]="gradesFormControl"></app-user-profile-errors>

            <!-- Subjects Checkboxes -->
            <div class="border p-4 mb-4 bg-very-pale-blue selenium-subjects-panel">
                <div class="mb-4 fw-bold font-size-16 text-navy-blue">
                    What subject(s) do you currently teach? Check all that apply<span
                        class="required-field"
                    ></span>
                    (Required)
                </div>
                <app-user-profile-checkbox-group
                    [gtmClass]="'gtm-profile-select-subject'"
                    formControlName="subjects"
                    class="subject-checkbox-grid"
                >
                    <ng-container *ngFor="let subject of subjects">
                        <app-user-profile-checkbox
                            [value]="subject"
                            [label]="subject"
                        ></app-user-profile-checkbox>
                    </ng-container>
                </app-user-profile-checkbox-group>
            </div>
            <app-user-profile-errors [control]="subjectsFormControl"></app-user-profile-errors>
        </form>
    </ng-template>

    <ng-template #loading>
        <app-preloader></app-preloader>
    </ng-template>
</div>
