import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ErrorRoutingModule } from "./error-routing.module"
import { SharedComponentsModule } from "../shared-components/shared-components.module"
import { ErrorComponent } from "./error.component"
import { InternalServerErrorComponent } from "./internal-server-error/internal-server-error.component"
import { UnauthorizedErrorComponent } from "./unauthorized-error/unauthorized-error.component"
import { NotFoundErrorComponent } from "./not-found-error/not-found-error.component"
import { SubjectListComponent } from "./shared/subject-list/subject-list.component"
import { ResourceListComponent } from "./shared/resource-list/resource-list.component"

@NgModule({
    declarations: [
        ErrorComponent,
        InternalServerErrorComponent,
        UnauthorizedErrorComponent,
        NotFoundErrorComponent,
        SubjectListComponent,
        ResourceListComponent,
    ],
    imports: [CommonModule, ErrorRoutingModule, SharedComponentsModule],
    exports: [ErrorComponent],
})
export class ErrorModule {}
