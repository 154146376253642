<div class="container">
    <div class="row">
        <div class="col-sm-7 col-md-8 col-lg-8 col-xl-8 selenium-browse-by-subject">
            <div class="browse-title">Browse by Subject</div>
            <app-button
                *ngFor="let node of chNodes"
                id="selenium-homepage-chn-{{ node.id }}"
                [routerLink]="node.getBrowseURL()"
                class="gtm-homepage-browse-by-subject"
                [gtmLabel]="node.title"
                >{{ node.title }}</app-button
            >
        </div>
        <div class="col-sm-5 col-md-4 col-lg-4 col-xl-4 selenium-browse-by-grades">
            <div class="browse-title">Browse by Grade</div>
            <app-button
                *ngFor="let gradeBand of GRADE_BANDS"
                id="selenium-homepage-grade-{{ gradeBand.uri }}"
                [routerLink]="['grades', gradeBand.uri]"
                class="gtm-homepage-browse-by-grade"
                [gtmLabel]="gradeBand.gtmLabel"
                >{{ gradeBand.label }}</app-button
            >
        </div>
    </div>
</div>
