export class LessonContent {
    title: string
    type: string
    url: string
    contentFlags: []

    constructor(response: any) {
        this.title = response.title
        this.type = response.type
        this.url = response.url
        this.contentFlags = response.content_flags
    }
}
