<div class="subjects-selector-opener-wrapper">
    <button
        class="subjects-selector-opener-btn position-relative"
        [ngStyle]="{
            'background-color': isSubjectsPanelOpened ? '#0a145a' : 'transparent'
        }"
        (click)="toggleSubjectsPanel()"
    >
        Subjects
        <span [class]="isSubjectsPanelOpened ? 'arrow-up' : 'arrow-down'"></span>
        <span *ngIf="isSubjectsPanelOpened" class="arrow-indicator"></span>
    </button>

    <div class="subjects-panel">
        <div
            class="container subjects-wrapper"
            *ngIf="isSubjectsPanelOpened"
            (mouseleave)="isSubjectsPanelOpened = false"
        >
            <div class="subject-frame" *ngFor="let subject of subjects">
                <button
                    class="parent-subject selenium-discipline"
                    [attr.data-gtmlabel]="subject.title"
                    [ngStyle]="{
                        color: getSubjectColor(subject.id)
                    }"
                    (click)="
                        onSubjectSelected([
                            (isAddonView ? '/add-on/subjects/' : '/subjects/') + subject.slug
                        ]);
                        isSubjectsPanelOpened = false
                    "
                >
                    {{ subject.title }}
                </button>
                <button
                    class="child-subject"
                    [attr.data-gtmlabel]="child.title"
                    *ngFor="let child of getAvailableSubjects(subject)"
                    (click)="onSubjectSelected([child.getBrowseURL()])"
                >
                    {{ child.title }}
                </button>
            </div>
        </div>
    </div>
</div>
