<input
    type="checkbox"
    class="m-1 {{ gtmClass }}"
    [ngClass]="{ 'cursor-pointer': !disabled, checked: checkboxGroup.isSelected(value) }"
    [disabled]="disabled"
    [attr.id]="id"
    [checked]="checkboxGroup.isSelected(value)"
    (click)="checkboxGroup.toggleValue(value)"
/>
<label [ngClass]="{ 'cursor-pointer': !disabled }" [attr.for]="id">{{ label }}</label>
