import { Component, Input } from "@angular/core"
import { ModalRef } from "../../../modal/modal-ref"

@Component({
    selector: "app-modal-header",
    templateUrl: "./modal-header.component.html",
    styleUrls: ["./modal-header.component.scss"],
})
export class ModalHeaderComponent {
    @Input() title: string = ""

    constructor(public activeModal: ModalRef) {}
}
