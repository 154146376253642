import { Component, Input } from "@angular/core"
import { ModalRef } from "../../modal/modal-ref"

export enum AssignModalType {
    google = "google",
    pbslm = "lm",
    any = "",
}

@Component({
    selector: "app-assign-modal",
    templateUrl: "./assign-modal.component.html",
    styleUrls: ["./assign-modal.component.scss"],
})
export class AssignModalComponent {
    @Input() public assignmentCode: string = ""
    @Input() public assignmentName: string = ""
    @Input() public assignmentHasLti: boolean = false
    @Input() public type: AssignModalType = AssignModalType.any

    constructor(public activeModal: ModalRef) {}
}
