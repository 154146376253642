import { Component, OnInit } from "@angular/core"
import { FeaturedService } from "../../services/featured.service"
import { LearningObject } from "../../models/learningObject.model"
import { EduComponent } from "../../utils/eduComponent"
import { CHNode } from "../../models/node.model"
import { STATIC_IMAGES } from "../../models/constants"

@Component({
    selector: "app-featured",
    templateUrl: "./featured.component.html",
    styleUrls: ["./featured.component.scss"],
})
export class FeaturedComponent extends EduComponent implements OnInit {
    public featuredLearningObjects: LearningObject[] = []
    placeholderImg = null
    public gtmPrefix = "homepage-featured"

    constructor(private featuredService: FeaturedService) {
        super()
        this.placeholderImg = STATIC_IMAGES.placeholder_image
    }

    ngOnInit() {
        this.addSub(
            this.featuredService
                .getFeaturedResources()
                .subscribe((featuredResources: LearningObject[]) => {
                    this.featuredLearningObjects.push(...featuredResources)
                })
        )
    }

    getBrowseURL(resource) {
        if (!resource.subject || !resource.subject.slug) {
            return null
        }
        return CHNode.getBrowseURLForSlug(resource.subject.slug)
    }
}
