<section class="d-flex flex-wrap mb-6">
    <ng-container *ngIf="localizeData$ | async as localizeData; else loading">
        <ng-container *ngIf="showStateStandards; else localizationTmpl">
            <div class="d-flex flex-wrap align-items-center me-6 py-2">
                <span class="d-inline fw-bold font-size-16 me-2"> State Standards: </span>
                <span class="font-size-16"
                    >{{ localizeData.stateStandards }} (based on your local PBS station)</span
                >
            </div>
        </ng-container>

        <button
            (click)="onChangeStationClick()"
            [ngClass]="{ 'profile-form-dirty': isDirty$ | async }"
            type="button"
            class="border-0 p-0 text-primary font-size-18 fw-bold bg-transparent selenium-change-station gtm-profile-change-station"
        >
            <app-icon [type]="'Cog'"></app-icon>
            Change station
        </button>

        <ng-template #localizationTmpl>
            <div class="d-inline me-6 py-2">
                <span class="font-size-18 text-navy-blue fw-bold me-2">
                    Your local PBS station:
                </span>
                <span *ngIf="!localizeData.localizationLogo" class="font-size-20">
                    Not localized
                </span>
                <span>
                    <img
                        *ngIf="localizeData.localizationLogo"
                        [src]="localizeData.localizationLogo"
                        class="localization-logo"
                        alt="localization logo"
                    />
                </span>
            </div>
        </ng-template>
    </ng-container>
</section>

<ng-template #loading>
    <app-preloader></app-preloader>
</ng-template>
