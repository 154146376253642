import { Component, Input } from "@angular/core"
import ResourceUtils from "../../../../utils/resource-utils"

@Component({
    selector: "app-card-meta",
    templateUrl: "./card-meta.component.html",
    styleUrls: ["./card-meta.component.scss"],
})
export class CardMetaComponent {
    @Input() public gradesDisplay: string
    @Input() public mediaType: string
    @Input() public supportMaterialsCount: number = 0
    @Input() public supportMaterialsLink: string[] // routerLink format
    @Input() public brand?: string
    @Input() public contentProject
    @Input() public type: string = "vertical" // horizontal

    public getToolTipText() {
        if (this.supportMaterialsCount > 1) {
            return `This resource contains ${this.supportMaterialsCount} support materials`
        }
        return "This resource contains support materials"
    }

    public stopPropagation(event: MouseEvent) {
        event.stopPropagation()
        return false
    }
}
