import {
    Directive,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from "@angular/core"
import { DOCUMENT } from "@angular/common"

export type BadgeSizes = "small" | "medium" | "large"
export type BadgePositions = "top-right" | "top-left" | "bottom-right" | "bottom-left"

@Directive({
    selector: "[appBadge]",
})
export class BadgeDirective implements OnChanges, OnDestroy {
    @Input() appBadge = null
    @Input() size: BadgeSizes = "small"
    @Input() position: BadgePositions = "top-right"
    @Input() customBadgeClasses: string | null = null

    badgeElement: HTMLElement | null = null

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private elRef: ElementRef<HTMLElement>
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.appBadge || !changes.appBadge.currentValue) return

        const value = `${changes.appBadge.currentValue}`.trim()
        if (value?.length > 0) {
            this.updateBadgeText(value)
        }
    }

    ngOnDestroy() {
        if (this.badgeElement) {
            this.badgeElement.remove()
        }
    }

    private updateBadgeText(value: string) {
        if (!this.badgeElement) {
            this.createBadge(value)
        } else {
            this.badgeElement.textContent = value
        }
    }

    private createBadge(value: string): HTMLElement {
        const badgeElement = this.document.createElement("span")
        this.addClasses(badgeElement)
        badgeElement.textContent = value
        this.elRef.nativeElement.classList.add("app-badge-container")
        this.elRef.nativeElement.appendChild(badgeElement)
        return badgeElement
    }

    private addClasses(badgeElement: HTMLElement) {
        const [vPos, hPos] = this.position.split("-")
        badgeElement.classList.add("app-badge", vPos, hPos)
        if (this.customBadgeClasses) {
            const customClasses = this.customBadgeClasses.split(" ")
            badgeElement.classList.add(...customClasses)
        }
        badgeElement.classList.add(this.size)
    }
}
