import { Component } from "@angular/core"
import { ModalRef } from "../../modal/modal-ref"

@Component({
    selector: "app-feedback-modal",
    templateUrl: "./feedback-modal.component.html",
    styleUrls: ["./feedback-modal.component.scss"],
})
export class FeedbackModalComponent {
    constructor(public activeModal: ModalRef) {}
}
