<div class="preloader-wrapper">
    <div class="preloader">
        <svg class="circular" viewBox="25 25 50 50">
            <circle
                [ngClass]="{ 'path-white': !colors, path: colors }"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"
            />
        </svg>
    </div>
</div>
