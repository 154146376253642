import { UserService } from "../../services/user.service"
import { Component } from "@angular/core"
import { STATIC_IMAGES } from "../../models/constants"
import { AddOnViewService } from "../../services/add-on-view.service"

@Component({
    selector: "app-available-features",
    templateUrl: "./available-features.component.html",
    styleUrls: ["./available-features.component.scss"],
})
export class AvailableFeaturesComponent {
    googleClassroomIcon: string = ""

    constructor(public userService: UserService, public addOnService: AddOnViewService) {
        this.googleClassroomIcon = STATIC_IMAGES.google_classroom_gray_stroke_icon
    }
}
