import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { Subject } from "rxjs"

import { KeyValue } from "@angular/common"
import { AddDirective } from "../../../directives/add.directive"
import { takeUntil } from "rxjs/operators"
import { UserProfileService } from "../../../../services/user-profile.service"
import { DataPrivacySchoolFormComponent } from "../data-privacy-school-form/data-privacy-school-form.component"
import {
    SCHOOL_TYPE,
    SCHOOL_TYPE_TEACHER_DESCRIPTION,
} from "../../../../models/userDataPrivacy.model"

@Component({
    selector: "app-data-privacy-classroom",
    templateUrl: "./data-privacy-classroom.component.html",
    styleUrls: ["./data-privacy-classroom.component.scss"],
})
export class DataPrivacyClassroomComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    isFullWidth = false

    @ViewChild(AddDirective)
    appAddHost!: AddDirective

    SCHOOL_TYPE = SCHOOL_TYPE

    schools: Map<SCHOOL_TYPE, SCHOOL_TYPE_TEACHER_DESCRIPTION>

    classRoomForm!: FormGroup

    private destroyed$ = new Subject()

    constructor(private userProfileService: UserProfileService) {
        this.classRoomForm = new FormGroup({
            teacherType: new FormControl(null, Validators.required),
        })
    }

    ngOnInit(): void {
        this.schools = this.userProfileService.getTeacherTypes()
    }

    ngAfterViewInit(): void {
        this.teacherType.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((teacherType) => {
            this.displayDistrict(teacherType)
            this.classRoom.updateValueAndValidity()
        })
    }

    ngOnDestroy(): void {
        this.destroyed$.next()
        this.destroyed$.complete()
    }

    get teacherType(): FormControl {
        return this.classRoomForm.get("teacherType") as FormControl
    }

    get classRoom(): FormGroup {
        return this.classRoomForm as FormGroup
    }

    originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
        return 0
    }

    private displayDistrict(teacherType): void {
        const viewContainerRef = this.appAddHost.viewContainerRef
        viewContainerRef.clear()

        if (this.classRoomForm.contains("schoolDescription")) {
            this.classRoom.removeControl("schoolDescription")
        }

        // Add dropdown components only for public teacher type
        if (teacherType !== SCHOOL_TYPE.PUBLIC_SCHOOL) return

        const componentRef = viewContainerRef.createComponent<DataPrivacySchoolFormComponent>(
            DataPrivacySchoolFormComponent
        )

        this.classRoomForm.setControl("schoolDescription", componentRef.instance.schoolFormGroup)
    }
}
