import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"

import { AccessibilityFacetComponent } from "./accessibility/accessibility-facet.component"
import { FacetsComponent } from "./facets.component"
import { GenericFacetComponent } from "./generic-facet/generic-facet.component"
import { NestedFacetNodesComponent } from "./nested/nested-facet-nodes/nested-facet-nodes.component"
import { NestedFacetComponent } from "./nested/nested-facet.component"

@NgModule({
    declarations: [
        NestedFacetComponent,
        NestedFacetNodesComponent,
        GenericFacetComponent,
        AccessibilityFacetComponent,
        FacetsComponent,
    ],
    imports: [CommonModule, FormsModule],
    exports: [
        NestedFacetComponent,
        NestedFacetNodesComponent,
        GenericFacetComponent,
        AccessibilityFacetComponent,
        FacetsComponent,
    ],
})
export class FacetsModule {}
