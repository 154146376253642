export enum Subjects {
    Science = 8337,
    SocialStudies = 3026,
    Mathematics = 1184,
    ELAWriting = 1880,
    EngineeringAndTech = 8353,
    HealthAndPE = 2806,
    Preschool = 2949,
    ProfessionalDevelopment = 6708,
    TheArts = 2663,
    WorldLanguages = 6267,
}
