import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from "@angular/core"
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms"

@Component({
    selector: "app-account-info",
    templateUrl: "./account-info.component.html",
    styleUrls: ["./account-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccountInfoComponent),
            multi: true,
        },
    ],
})
export class AccountInfoComponent implements ControlValueAccessor, OnInit {
    accountInfo = this.fb.group({
        email: [""],
        password: [""],
        hasPublicMediaAccount: [false],
        publicMediaProfileLink: [null],
    })

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.accountInfo.disable()
    }

    writeValue(value: { email: string; password: string }): void {
        this.accountInfo.patchValue(value)
    }

    get hasPublicMediaAccount() {
        let hasPublicMediaAccount = this.accountInfo.get("hasPublicMediaAccount") as FormControl
        return !!hasPublicMediaAccount.value
    }

    get publicMediaProfileLink() {
        let publicMediaProfileLink = this.accountInfo.get("publicMediaProfileLink") as FormControl
        return publicMediaProfileLink.value
    }

    registerOnChange(fn: any): void {
        this.accountInfo.valueChanges.subscribe(fn)
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            return this.accountInfo.disable()
        }
        //this will enable the email and pass fields, for the moment those fields should not be editable
        // this.accountInfo.enable()
    }
}
