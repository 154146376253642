import { Component, Input } from "@angular/core"
import { ModalRef } from "../../modal/modal-ref"

@Component({
    selector: "app-notification-modal",
    templateUrl: "./notification-modal.component.html",
    styleUrls: ["./notification-modal.component.scss"],
})
export class NotificationModalComponent {
    @Input() message: string

    constructor(public activeModal: ModalRef) {}
}
