<div class="modal-header mb-3" *ngIf="displayTitle">
    <h5 class="modal-title">Sign in to student site</h5>
    <button
        (click)="toggleReCaptcha(false); activeModal.dismiss('Cross click')"
        aria-label="Close"
        class="btn-close"
        data-dismiss="modal"
        type="button"
    ></button>
</div>
<div class="modal-body">
    <form class="w-100" (focusin)="toggleReCaptcha(true)">
        <div class="form-row email-row mb-2">
            <mat-form-field
                [hideRequiredMarker]="true"
                appearance="outline"
                class="w-100 username-field-label"
            >
                <mat-label>username*</mat-label>
                <input
                    type="text"
                    class="selenium-student-username"
                    matInput
                    [formControl]="usernameFormControl"
                    placeholder=""
                />
                <mat-error
                    *ngIf="
                        usernameFormControl.hasError('pattern') &&
                        !usernameFormControl.hasError('required')
                    "
                >
                    username should contain at least 3 characters
                </mat-error>
                <mat-error *ngIf="usernameFormControl.hasError('required')">
                    username is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row mb-2">
            <mat-form-field appearance="outline" class="w-100 me-sm-3 question-field-label">
                <mat-label>select your secret question</mat-label>
                <mat-select
                    [formControl]="questionOptionsFormControl"
                    class="selenium-student-question"
                >
                    <mat-option *ngFor="let option of questionOptions" [value]="option.name"
                        >{{ option.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="questionOptionsFormControl.hasError('required')">
                    please select a question from the drop-down
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-row mb-2">
            <mat-form-field
                [hideRequiredMarker]="true"
                appearance="outline"
                class="w-100 response-field-label"
            >
                <mat-label>secret answer*</mat-label>
                <input
                    class="selenium-student-answer"
                    [type]="secretAnswerInputType"
                    matInput
                    [formControl]="secretQuestionFormControl"
                    placeholder=""
                />
                <app-icon
                    [type]="secretAnswerInputType === 'password' ? 'Eye' : 'Cut-Eye'"
                    (click)="changeSecretAnswerInputType()"
                    [ngClass]="{
                        'secret-question-eye': secretAnswerInputType === 'password',
                        'secret-question-cut-eye': secretAnswerInputType === 'text'
                    }"
                >
                </app-icon>
                <mat-error
                    *ngIf="
                        secretQuestionFormControl.hasError('pattern') &&
                        !secretQuestionFormControl.hasError('required')
                    "
                >
                    secret question should have at least 3 characters
                </mat-error>
                <mat-error *ngIf="secretQuestionFormControl.hasError('required')">
                    secret question response is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="response-error mb-4 d-flex flex-row" *ngIf="errorLogInMessage">
            <span class="warning-icon">
                <app-icon type="Exclamation-Circle"></app-icon>
            </span>
            <span class="error-message">{{ errorLogInMessage }}</span>
        </div>
        <div class="form-submit-button mt-1 w-100 text-center">
            <app-button
                class="mb-5 submit-button"
                [disable]="signInFormGroup.invalid"
                (click)="verifyCaptcha()"
                [buttonClasses]="'px-5 py-2'"
            >
                <span class="form-submit-button-text">Sign In</span>
            </app-button>
        </div>
    </form>
    <span class="mb-4 hr-class d-flex flex-row justify-content-center">
        <span class="text-center">or</span>
    </span>
    <div class="google-auth-wrapper py-4 px-3">
        <a
            [href]="loginRedirectLink"
            class="selenium-continue-google d-flex justify-content-center align-items-center"
        >
            <img [src]="googleImage" alt="Sign in with Google" class="auth-icon me-1" />
            <span class="d-flex justify-content-center align-items-center">
                Sign in with Google
            </span>
        </a>
    </div>
</div>
<div class="modal-footer justify-content-start">
    <div>
        <a (click)="openStudentSignUp()" class="sign-up">
            I need to sign up!
            <app-icon [type]="'Arrow-right'"></app-icon>
        </a>
        <div
            class="teacher-callout d-flex flex-row flex-wrap align-content-center justify-content-start"
        >
            <span class="pe-2"> Are you a teacher? </span>
            <a (click)="activeModal.close()" [routerLink]="['/']">
                Sign in on our teacher site
                <app-icon [type]="'Arrow-right'"></app-icon>
            </a>
        </div>
    </div>
</div>
