<div class="step-wrapper">
    <div class="description">
        Assign this resource to your students by selecting a class and then choosing all or a subset
        of students. <br />
        If a class is not shown below, go back to the previous screen to add another one. When done
        selecting students, click on Assign.
    </div>
    <div class="classes-wrapper">
        <ng-container *ngFor="let roster of rosters">
            <ng-container *ngIf="roster.selected">
                <div class="class-row">
                    <div class="class-name" (click)="toggleExpand(roster)">
                        <app-icon [type]="'Plus'" *ngIf="!roster.expanded"></app-icon>
                        <app-icon [type]="'Minus'" *ngIf="roster.expanded"></app-icon>
                        {{ roster.rosterName }}
                    </div>
                    <div class="select-all" (click)="selectAll(roster)">
                        <ng-container *ngIf="!checkIfAllStudentSelected(roster)">
                            Select All
                        </ng-container>

                        <ng-container *ngIf="checkIfAllStudentSelected(roster)">
                            Deselect All
                        </ng-container>
                    </div>
                </div>

                <div
                    class="student-wrapper"
                    *ngFor="let student of roster.students"
                    [ngClass]="{ show: roster.expanded }"
                >
                    <div class="student-row">
                        <app-checkbox [(checked)]="student.selected"></app-checkbox>
                        <div class="student-name">
                            {{ student.studentDisplayName }}
                        </div>
                        <img
                            class="google-class"
                            *ngIf="student.studentGoogleId"
                            [src]="googleClassImg"
                        />
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="buttons-wrapper">
        <button (click)="prev()" class="btn btn-outline-primary">
            <app-icon [type]="'Arrow-left'"></app-icon> Go Back
        </button>
        <app-button
            (click)="next()"
            [disable]="!isRosterSelected"
            [ngClass]="{ 'gtm-assign-to-class-students-button': isRosterSelected }"
            [buttonClasses]="'modal-padding-buttons'"
        >
            Assign
        </app-button>
    </div>
</div>
