import { Component, EventEmitter, Input, Output } from "@angular/core"
import { Observable } from "rxjs"

@Component({
    selector: "app-user-localization",
    templateUrl: "./user-localization.component.html",
    styleUrls: ["./user-localization.component.scss"],
})
export class UserLocalizationComponent {
    /**
     * Flag needed in order to track how many users are clicking the
     * 'Change Station' when the profile form has changes (is in edit mode)
     * TODO Remove this after the investigation
     */
    @Input()
    isDirty$: Observable<boolean>

    @Input()
    localizeData$: Observable<any>

    @Input()
    showStateStandards: boolean

    @Output()
    changeStation = new EventEmitter<string>()

    onChangeStationClick() {
        this.changeStation.emit()
    }
}
