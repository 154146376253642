import { NgModule } from "@angular/core"

import { SharedComponentsModule } from "../shared-components/shared-components.module"
import { MarketingDescriptionComponent } from "./marketing-description/marketing-description.component"
import { SignupRoutingModule } from "./signup-routing.module"
import { SignupComponent } from "./signup.component"
import { TestimonialComponent } from "./testimonial/testimonial.component"

@NgModule({
    declarations: [SignupComponent, MarketingDescriptionComponent, TestimonialComponent],
    imports: [SharedComponentsModule, SignupRoutingModule],
})
export class SignupModule {}
