<div class="container student-menu-wrapper gtmsection-student-menu">
    <nav class="student-menu-navbar">
        <div class="student-menu-nav">
            <!--Grades-->
            <app-grades-dropdown
                class="selenium-grades"
                [grades]="gradeBands"
                (toggleGradePanel)="onTogglePanel($event)"
            ></app-grades-dropdown>
            <!--Assignments-->
            <app-link
                class="student-menu-nav-item gtm-student-assignments selenium-assignments"
                [badgeCount]="badgeCount"
                [ngbTooltip]="getTooltipText('assignments')"
                placement="bottom"
                triggers="hover click"
                appRouterLink="/student/assignments/"
                routerLinkActive="student-active-link"
                >Assignments
            </app-link>

            <!--Classes-->
            <app-link
                class="student-menu-nav-item gtm-student-classes selenium-classes"
                [ngbTooltip]="getTooltipText('classes')"
                placement="bottom"
                triggers="hover click"
                appRouterLink="/student/classes/"
                routerLinkActive="student-active-link"
                >Classes
            </app-link>

            <!--Favorites-->
            <app-link
                class="student-menu-nav-item gtm-student-favorites selenium-favorites"
                [ngbTooltip]="getTooltipText('favorites')"
                placement="bottom"
                triggers="hover click"
                appRouterLink="/tools/favorites/"
                routerLinkActive="student-active-link"
            >
                <span id="classesTab">Favorites</span>
            </app-link>

            <!--Subjects-->
            <app-subjects-dropdown
                class="selenium-subjects"
                [subjects]="subjects"
                (subjectSelected)="navigateTo($event)"
                (toggleSubjectPanel)="onTogglePanel($event)"
            ></app-subjects-dropdown>
        </div>
        <div class="student-menu-spacer"></div>
        <div class="student-menu-code-input">
            <app-code-input></app-code-input>
        </div>
    </nav>
</div>
