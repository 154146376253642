<ng-container *ngIf="adminHeaderData$ | async as adminHeaderData">
    <section *ngIf="adminHeaderData.hasAdminDisplay" class="admin-header">
        <div class="container" *ngIf="adminHeaderData.isUserImpersonated">
            <p class="impersonate-msg">
                Impersonating
                <span *ngIf="!adminHeaderData.isImpersonatedUserActive"> inactive</span>
                <span *ngIf="adminHeaderData.isAdmin"> staff</span> user
            </p>
        </div>
        <div class="container" *ngIf="!adminHeaderData.isUserImpersonated">
            <a href="/admin">Admin Home</a>
            <a *ngFor="let link of adminHeaderData.links" [href]="link.url">{{ link.title }} </a>
            <p class="helptext">
                As an administrative user, you may access the Content Management System for PBS
                LearningMedia
            </p>
            <div class="float-end">
                <div *ngIf="adminHeaderData.version" class="version">
                    {{ adminHeaderData.version }}
                </div>
            </div>
        </div>
    </section>
</ng-container>
