import { Injectable } from "@angular/core"
import { CHNode } from "../models/node.model"
import { Subjects } from "../models/subject.model"

@Injectable()
export class ColorCodeService {
    public static SubjectColor = {
        [Subjects.Science]: "#486CD8",
        [Subjects.SocialStudies]: "#6A1B9A",
        [Subjects.Mathematics]: "#B2528C",
        [Subjects.ELAWriting]: "#D6383A",
        [Subjects.EngineeringAndTech]: "#262626",
        [Subjects.HealthAndPE]: "#087B45",
        [Subjects.Preschool]: "#007B9B",
        [Subjects.ProfessionalDevelopment]: "#60738A",
        [Subjects.TheArts]: "#074D51",
        [Subjects.WorldLanguages]: "#A31A51",
    }

    public collectionColors: any = {
        defaultBackgroundColor: "#02146a",
        defaultTextColor: "#ffffff",
        defaultDetailsColor: "#2b40cb",
    }

    public getColorForNode(node: CHNode): string {
        let discipline = node.getDiscipline()
        if (discipline) {
            return ColorCodeService.SubjectColor[discipline.id]
        }
        return this.getPrimaryColor()
    }

    public getPrimaryColor(): string {
        return "#2638C4"
    }

    /**
     * used to get a acceptable light color so that it can be used as background color for subcolletion blocks;
     * @param color example #236745
     * @param ratio
     */
    public shadeColor(color: string, ratio: number = 10): string {
        return this.convertHexToRgba(color, ratio)
    }

    public convertHexToRgba(hex, opacity): string {
        hex = hex.replace("#", "")
        let r = parseInt(hex.substring(0, 2), 16)
        let g = parseInt(hex.substring(2, 4), 16)
        let b = parseInt(hex.substring(4, 6), 16)

        return "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")"
    }

    public isWhite(color: string): boolean {
        return color === "#fff" || color == "#ffffff"
    }
}
