import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from "@angular/core"
import { DataPrivacyClassroomComponent } from "../../data-privacy/data-privacy-select/data-privacy-classroom/data-privacy-classroom.component"
import { DataPrivacyModalsService } from "../../../services/data-privacy-modals.service"
import { WindowSizeService } from "../../../services/window-size.service"

@Component({
    selector: "app-classroom",
    template: `
        <form [formGroup]="form">
            <app-data-privacy-classroom [isFullWidth]="true"></app-data-privacy-classroom>
            <div class="d-flex justify-content-between mt-6">
                <button
                    type="button"
                    class="btn btn-outline-primary py-2 px-4"
                    (click)="dataPrivacyModalsService.previousModal()"
                >
                    &lt; Previous
                </button>

                <ng-container
                    *ngIf="{
                        isFormInvalid: isFormInvalid$ | async
                    } as formStatus"
                >
                    <button
                        type="button"
                        class="btn btn-primary py-2 px-4"
                        [ngClass]="{ 'btn-disabled': formStatus.isFormInvalid }"
                        [disabled]="formStatus.isFormInvalid"
                        (click)="dataPrivacyModalsService.nextModal()"
                    >
                        Next &gt;
                    </button>
                </ng-container>
            </div>
        </form>
    `,
    styleUrls: ["./classroom.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassroomComponent implements AfterViewInit {
    @ViewChild(DataPrivacyClassroomComponent)
    dataPrivacyClassroomRef: DataPrivacyClassroomComponent

    isFormInvalid$ = this.dataPrivacyModalsService.isFormInvalid$

    form = this.dataPrivacyModalsService.getForm()

    isMobile = this.windowSize.isSmallDevice

    constructor(
        public dataPrivacyModalsService: DataPrivacyModalsService,
        private windowSize: WindowSizeService
    ) {}

    ngAfterViewInit(): void {
        this.form.setControl("classRoomSetting", this.dataPrivacyClassroomRef.classRoomForm)
    }
}
