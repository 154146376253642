<div class="wrapper">
    <div class="row-auto g-0">
        <div
            class="col-xs-12 col-md-12 col-lg-5 col-xl-6 text"
            [ngClass]="{
                'order-lg-3': imageSide === 'left',
                'order-lg-1': imageSide === 'right'
            }"
        >
            <ng-content></ng-content>
        </div>

        <div class="col-lg-1 order-lg-2 col-xl-1"></div>

        <div
            class="col-xs-12 col-md-12 col-lg-6 col-xl-5 image"
            [ngClass]="{
                'order-lg-1': imageSide === 'left',
                'order-lg-3 align-right': imageSide === 'right'
            }"
        >
            <img [src]="marketingImage" alt="Marketing Image" />
        </div>
    </div>
</div>
