import { Injectable } from "@angular/core"
import {
    HttpInterceptor,
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpParams,
} from "@angular/common/http"
import { Observable } from "rxjs"
import { catchError, retry } from "rxjs/operators"
import {
    STUDENT_GATE_COLLECTION_WHITELIST,
    STUDENT_GATE_INTERCEPTOR_WHITELIST,
} from "../models/constants"
import Utils from "../utils/utils"

@Injectable()
export class StudentGateInterceptor implements HttpInterceptor {
    private urlWhiteList: string[] = STUDENT_GATE_INTERCEPTOR_WHITELIST
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //userService.hasStudentExperience() is not instantiated at this moment, so the student experience always will be false
        let isStudentExperience: boolean = Utils.hasStudentExperience()
        let selectedGrade: string = localStorage.getItem("grade-gate") || null
        //based on a custom API's whitelist we will update the API's params.
        if (!(selectedGrade && isStudentExperience && this.isUrlWhitelisted(httpRequest.url))) {
            return next.handle(httpRequest)
        }
        const newReq = httpRequest.clone({
            params: (httpRequest.params ? httpRequest.params : new HttpParams()).append(
                "student_grade",
                selectedGrade
            ),
        })

        return next.handle(newReq).pipe(
            retry(2),
            // @ts-ignore
            catchError((err: any) => {
                console.log(
                    "An error (" +
                        err +
                        ") occurred during " +
                        httpRequest.url +
                        " API intercept on new param addition;"
                )
                throw err
            })
        )
    }

    isUrlWhitelisted(url: string): boolean {
        let urlPathname: string = url.startsWith("http") ? new URL(url).pathname : url
        let filteredList: string[] = this.urlWhiteList.filter(
            (value: string) => urlPathname.startsWith(value) || this.isCollectionPage(urlPathname)
        )
        return !!filteredList.length
    }

    isCollectionPage(url: string): boolean {
        let collectionFilter =
            JSON.parse(sessionStorage.getItem("collection-filter")) ||
            localStorage.getItem("grade-gate")
        return (
            STUDENT_GATE_COLLECTION_WHITELIST.filter(
                (value: string) =>
                    url.startsWith(value) &&
                    !!collectionFilter &&
                    collectionFilter.selectedFilter !== "All grades"
            ).length > 0
        )
    }
}
