export class UserProfile {
    email: string = ""
    publicMediaProfileLink: string | null
    firstName: string = ""
    lastName: string = ""
    username: string = ""
    userRole: string = ""
    userSuggestedRole: string | null
    zipCode: string = ""
    /*
    Represents the ID of the school stored in the database.
    The exception is the value -1 which signals that the school is a suggested one
     */
    userSchool: number | null = null
    gradeRange: string[] = []
    hasPublicMediaAccount: boolean = false
    preferredSubjects: string[] = []

    constructor(response: UserProfileAPI) {
        this.email = response.user.email
        this.publicMediaProfileLink = response.public_media_profile_link
        this.firstName = response.user.first_name
        this.lastName = response.user.last_name
        this.userRole = response.user_role
        this.username = response.user.username
        this.userSuggestedRole = response.user_suggested_role
        this.zipCode = response.postal_code
        this.userSchool = response.school
        this.gradeRange = response.grade_range
        this.preferredSubjects = response.preferred_subjects
        this.hasPublicMediaAccount = response.has_public_media_account
    }

    public reverseToApiResponse() {
        return {
            grade_range: this.gradeRange,
            postal_code: this.zipCode,
            preferred_subjects: this.preferredSubjects,
            school: this.userSchool,
            user: {
                email: this.email,
                first_name: this.firstName,
                last_name: this.lastName,
                username: this.username,
            },
            user_role: this.userRole,
            user_suggested_role: this.userSuggestedRole,
        }
    }
}

export class UserProfileAPI {
    grade_range: string[]
    has_public_media_account: boolean
    pk: number
    postal_code: string
    preferred_subjects: string[]
    public_media_profile_link: string
    school: number
    school_suggested: string
    user: { email: string; first_name: string; last_name: string; username: string }
    user_role: string
    user_suggested_role: string
}
