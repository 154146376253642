import { Injectable } from "@angular/core"

@Injectable()
export class StandardsService {
    constructor() {}

    public groupByGrades(objectArray, addOnView?: boolean) {
        let link = addOnView
            ? `/add-on/search/?q=*&selected_facet=standard:`
            : `/search/?q=*&selected_facet=standard:`
        const resultGradeBandStandards = {
            "PreK-K": [],
            "K-2": [],
            "3-5": [],
            "6-8": [],
            "9-12": [],
        }
        //new array created after the split in order to sort data by grades -  K-2, 3-5, 6-8, 9-12
        let standardsArray: any = [...objectArray]
        standardsArray.reduce(function (object, item) {
            for (let key = 0; key < item.grade_bands.length; key++) {
                const standardObject = Object.assign({}, item)
                standardObject.link = `${link}${item.id}`
                standardObject.grade_bands = item.grade_bands[key].toString()
                standardObject.expanded = false
                resultGradeBandStandards[item.grade_bands[key]].push(standardObject)
            }
        }, {})
        //array data structure is returned because in object the sort data order is not kept
        return Array.from(Object.values(resultGradeBandStandards))
    }
}
