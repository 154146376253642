<ng-template #rt let-r="result">
    <span [ngClass]="{ 'last-smart-suggestion': r.isLastSmart }">
        {{ r.displayTitle }}
        <b *ngIf="r.subject">in {{ r.subject.title }}</b>
        <b *ngIf="r.grade">for {{ r.grade | prettyGrade }}</b>
    </span>
</ng-template>

<form
    (ngSubmit)="onSearch(formObj)"
    #formObj="ngForm"
    class="gtm-search-external-form"
    [ngClass]="{
        'header-search selenium-search-header': type === 'for-header',
        'home-search': type === 'for-browse',
        'error-search': type === 'for-error'
    }"
>
    <input
        #formInput
        type="text"
        class="external-search-input form-control"
        placeholder="{{ placeholder || 'Start a new search' }}"
        name="q"
        aria-label="Start a new search"
        ngModel
        (selectItem)="onSelectItem(formObj, $event)"
        [ngbTypeahead]="onAutocomplete"
        [resultTemplate]="rt"
        [inputFormatter]="formatMatches"
        [resultFormatter]="formatMatches"
        [focusFirst]="false"
        (ngModelChange)="resetPressedEnter($event)"
        (focus)="$focus.next($event.target.value)"
        (click)="$click.next($event.target.value)"
    />
    <button
        #submitBtn
        class="btn btn-primary selenium-external-search-button"
        [ngClass]="{ 'button-text': buttonText }"
        aria-label="Search"
        type="submit"
    >
        <span *ngIf="buttonText">{{ buttonText || "" }}</span>
        <i class="fa fa-search fa-lg"></i>
    </button>
</form>
