import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router"

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    handlers: { [key: string]: DetachedRouteHandle } = {}

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.url.join("/") === curr.url.join("/") && future.fragment !== curr.fragment
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return undefined
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {}
}
