import { Component } from "@angular/core"
import Utils from "../../../utils/utils"
import { NotificationsService } from "angular2-notifications"
import { UserService } from "../../../services/user.service"
import { Router } from "@angular/router"

@Component({
    selector: "app-code-input",
    templateUrl: "./code-input.component.html",
    styleUrls: ["./code-input.component.scss"],
})
export class CodeInputComponent {
    constructor(
        private router: Router,
        private userService: UserService,
        private notificationService: NotificationsService
    ) {}

    enterCode(code: string) {
        if (!code) {
            return this.notificationService.error(
                "",
                "Please enter a code",
                Utils.alertConfiguration
            )
        }

        this.userService.enterStudentCode(code).subscribe(
            (res) => {
                if (res.error) {
                    return this.notificationService.error("", res.error, Utils.alertConfiguration)
                } else if (res.url) {
                    this.router.navigate([res.url], { queryParams: { student: true } })
                }
            },
            (err) => {
                this.notificationService.error(
                    "Uh oh!",
                    "Invalid code entered.",
                    Utils.alertConfiguration
                )
            }
        )
    }
}
