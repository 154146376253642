import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { RouterModule } from "@angular/router"
import { SharedComponentsModule } from "../../shared-components.module"
import { AssignModalContentComponent } from "./assign-modal-content/assign-modal-content.component"
import { AssignModalComponent } from "./assign-modal.component"
import { LoadingViewComponent } from "./loading-view/loading-view.component"
import { ReviewAssignmentsViewComponent } from "./review-assignments-view/review-assignments-view.component"
import { SelectClassViewComponent } from "./select-class-view/select-class-view.component"
import { SelectStudentViewComponent } from "./select-student-view/select-student-view.component"

@NgModule({
    declarations: [
        AssignModalComponent,
        AssignModalContentComponent,
        SelectClassViewComponent,
        SelectStudentViewComponent,
        LoadingViewComponent,
        ReviewAssignmentsViewComponent,
    ],
    imports: [SharedComponentsModule, CommonModule, RouterModule],
    exports: [
        AssignModalComponent,
        AssignModalContentComponent,
        SelectClassViewComponent,
        SelectStudentViewComponent,
        LoadingViewComponent,
        ReviewAssignmentsViewComponent,
    ],
})
export class AssignModalModule {}
