import { Component, Input, OnChanges } from "@angular/core"

@Component({
    selector: "app-language-badge",
    templateUrl: "./language-badge.component.html",
    styleUrls: ["./language-badge.component.scss"],
})
export class LanguageBadgeComponent implements OnChanges {
    @Input() public languages: string[]

    public displayText: string

    public updateDisplayText() {
        if (this.languages && this.languages.includes("Spanish")) {
            if (this.languages.length == 1) {
                this.displayText = " "
            } else {
                this.displayText = "Also in "
            }
        } else {
            this.displayText = null
        }
    }

    public ngOnChanges() {
        this.updateDisplayText()
    }
}
