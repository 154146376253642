<!--this textarea version is kept for the moment, is no need, will be removed in near future-->
<div *ngIf="!isButton">
    <div class="wrapper mb-4">
        <div class="link p-2">
            {{ shareLink }}
        </div>
        <a
            href="#"
            (click)="copyLink('hidden-link'); $event.preventDefault()"
            class="copy-link-button d-flex align-items-center justify-content-center text-decoration-none"
            [ngClass]="buttonClasses"
            >Copy link</a
        >
    </div>
    <span *ngIf="isCopied" class="fw-bold">Link copied to your clipboard</span>
</div>

<!--new copy button feature implemented, can be triggered with isButton boolean-->
<div *ngIf="isButton" class="wrapper-button">
    <div class="share-options mt-6">
        <span class="share-link">
            <label class="fw-bold mb-2">{{ label }}</label>
            <div
                class="copy-link d-flex flex-row flex-nowrap justify-content-start align-items-center"
            >
                <button
                    (click)="copyLink('hidden-link')"
                    [ngClass]="buttonClasses"
                    class="btn btn-primary fw-bold d-flex flex-row flex-nowrap align-items-center"
                >
                    <i class="fa-link fa-lg fas pe-2"></i>
                    <span class="copy-label"> Copy Link </span>
                </button>
                <span *ngIf="isCopied" class="ms-4">Link copied to your clipboard</span>
            </div>
        </span>
    </div>
</div>
