<ng-container *ngIf="areCookiesEnabled; else cookieDisabled">
    <div
        id="log-in-container"
        class="text-center d-flex flex-column align-items-center align-content-center justify-content-start gtmpage-add-on-login"
    >
        <div class="login-logo">
            <img
                src="https://static.pbslearningmedia.org/static/assets/static-images/pbslm-logo.svg"
                alt="pbslm logo"
            />
        </div>
        <div class="login-button mt-8" *ngIf="!isStudentAccountSelected">
            <a
                (click)="openLoginPopUp()"
                class="btn d-flex flex-row align-content-center align-items-center justify-content-between gtm-sign-in-button"
            >
                <img
                    src="https://static.pbslearningmedia.org/static/assets/static-images/auth-google.svg"
                    alt="google auth image"
                    class="p-2"
                />
                Sign in with Google
            </a>
        </div>
        <div class="logout-button mt-8" *ngIf="isStudentAccountSelected">
            <button
                (click)="logOut(); $event.preventDefault()"
                class="btn d-flex flex-row align-content-center align-items-center justify-content-between"
            >
                <img
                    src="https://static.pbslearningmedia.org/static/assets/static-images/auth-google.svg"
                    alt="google auth image"
                    class="p-2"
                />
                Sign out
            </button>
        </div>
        <div class="login-message p-5">
            {{ loginMessage }}
        </div>
        <div *ngIf="isSafariBrowser" class="p-5">
            Please make sure you disable <b><q>Prevent cross-site tracking</q></b> under Safari
            Preferences, Privacy Tab.
        </div>
        <div *ngIf="isFirefoxBrowser" class="p-5">
            If you are using Firefox, you may need to update your browser settings. <br />
            Click the shield icon to the left of the address bar and turn off
            <b><q>Enhanced Tracking Protection</q></b> for this site.
        </div>
    </div>
</ng-container>
<ng-template #cookieDisabled>
    <div
        class="no-cookies-allowed d-flex flex-nowrap justify-content-center align-items-center flex-column"
    >
        <img
            class="pbslm-logo my-8"
            src="https://static.pbslearningmedia.org/static/assets/static-images/pbslm-logo.svg"
            alt="pbslm logo"
        />
        <span class="text-center">
            Our site requires browser cookies to be enabled. <br />
            Please visit our
            <app-link
                class="link fw-bold"
                target="_blank"
                href="https://www.pbslearningmedia.org/help/google-classroom/#addon"
            >
                help page
            </app-link>
            to find instructions.
        </span>
    </div>
</ng-template>
