import { Component, Input, OnInit } from "@angular/core"
import { UserService } from "../../../services/user.service"
import { CardComponent } from "../card.component"

@Component({
    selector: "app-resource-search-card",
    templateUrl: "./resource-search-card.component.html",
    styleUrls: ["./resource-search-card.component.scss"],
})
export class ResourceSearchCardComponent extends CardComponent implements OnInit {
    @Input() gtmDataCardNumber: number
    @Input() resourceCode: string
    isStudentExperience: boolean = false

    constructor(public userService: UserService) {
        super(userService)
    }

    ngOnInit() {
        this.isStudentExperience = this.userService.hasStudentExperience()
    }
}
