import { Injectable } from "@angular/core"
import { AbstractDataPrivacyDropdown } from "../../abstract-dropdown.service"
import { IState } from "../../../../models/userDataPrivacy.model"
import { UserDataPrivacyService } from "../../../../services/user-data-privacy.service"

@Injectable()
export class StatesDropdownService extends AbstractDataPrivacyDropdown<IState> {
    constructor(public userDataPrivacyService: UserDataPrivacyService) {
        super()

        const getStatesCallback = () => this.userDataPrivacyService.getStates()
        this.registerDataStream(getStatesCallback)
    }

    trackByFn(value: IState): string | number {
        return value.name
    }
}
