import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

import { AppModule } from "./app/app.module"
import { environment } from "./environments/environment"
import "hammerjs"

declare let __webpack_public_path__: any

if (environment.local) {
    // Change this if you want the environment to be accessible over IP
    __webpack_public_path__ = "http://lvh.me:4200/"
    // Or over https with the nginx proxy
    // __webpack_public_path__ = "https://angular.lvh.me/"
} else {
    enableProdMode()
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err))
