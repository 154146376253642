import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import ResourceUtils from "../../../utils/resource-utils"
import { CHNode } from "../../../models/node.model"
import { AddOnViewService } from "../../../services/add-on-view.service"

@Component({
    selector: "app-subjects-dropdown",
    templateUrl: "./subjects-dropdown.component.html",
    styleUrls: ["./subjects-dropdown.component.scss"],
})
export class SubjectsDropdownComponent implements OnInit {
    @Input()
    subjects: CHNode[] = []

    @Output()
    subjectSelected: EventEmitter<string[]> = new EventEmitter<string[]>()

    @Output()
    toggleSubjectPanel: EventEmitter<string> = new EventEmitter<string>()

    getSubjectColor = ResourceUtils.getSubjectColor
    isSubjectsPanelOpened: boolean = false

    isAddonView: boolean = false

    constructor(private addOnViewService: AddOnViewService) {}

    ngOnInit() {
        this.isAddonView = this.addOnViewService.isAddonView
    }

    onSubjectSelected(url: string[]): void {
        if (!Array.isArray(url) || !url.length) return
        this.isSubjectsPanelOpened = false
        this.subjectSelected.emit([...url])
    }

    toggleSubjectsPanel(): void {
        this.isSubjectsPanelOpened = !this.isSubjectsPanelOpened
        this.toggleSubjectPanel.emit("subject")
    }

    getAvailableSubjects(discipline) {
        return discipline.children.filter((node): boolean => node.resultsCount > 0)
    }
}
