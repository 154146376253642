import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { FeaturedService } from "../../services/featured.service"
import { LearningObject } from "../../models/learningObject.model"

@Component({
    selector: "app-collections",
    templateUrl: "./collections.component.html",
    styleUrls: ["./collections.component.scss"],
})
export class CollectionsComponent implements OnInit, OnDestroy {
    public featuredCollections: LearningObject[] = []
    subscriptions: Subscription[] = []
    public gtmPrefix = "gtm-homepage-featured-collections"

    constructor(private featuredService: FeaturedService) {}

    ngOnInit() {
        this.featuredService
            .getFeaturedCollections()
            .subscribe((featuredCollections: LearningObject[]) => {
                this.featuredCollections = featuredCollections
            })
    }

    getAllCollectionsGTMClass() {
        return `gtm-${this.gtmPrefix}-see-all`
    }

    getAllCollectionsUrl() {
        const location = window.location
        return (
            location.protocol +
            "//" +
            location.host +
            "/search?q=&selected_facet=media_type:Collection"
        )
    }

    ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe())
    }
}
