import { BehaviorSubject, forkJoin, Observable, of, throwError } from "rxjs"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { catchError, map } from "rxjs/operators"
import { Cookie } from "../models/cookie.model"
import { Localize } from "../models/localize.model"
import { CookiesService } from "./cookies.service"
import { LmApiService } from "./lm-api.service"

@Injectable()
export class LocalizationService {
    private readonly organizationId: Cookie
    private readonly stationCallSign: Cookie
    initialData = new Localize([])
    localizeData = new BehaviorSubject<Localize>(this.initialData)

    constructor(
        private cookieService: CookiesService,
        private http: HttpClient,
        private lmApi: LmApiService
    ) {
        this.organizationId =
            this.cookieService.getCookie("organization") || new Cookie("organization", "")
        this.stationCallSign = this.cookieService.getCookie("organization_callsign")
        this.stationCallSign = this.stationCallSign || new Cookie("organization_callsign", "PBS")
        this.requestData()
    }

    public hasOrganization() {
        return !!this.organizationId && !!this.organizationId.value
    }

    public getOrganizationId() {
        return this.organizationId.value
    }

    private requestData() {
        forkJoin([
            this.getLMData().pipe(
                map((result) => {
                    return { lmData: result }
                })
            ),
            this.getStationManageData().pipe(
                map((result) => {
                    return { stationData: result }
                })
            ),
        ]).subscribe((result) => {
            this.localizeData.next(new Localize(result))
        })
    }

    public getLMData(): Observable<any> {
        let url
        if (this.hasOrganization()) {
            url = `/api/v2/local_data/${this.organizationId.value}/`
        } else {
            url = `/api/v2/local_data/`
        }
        return this.lmApi.getWithCookiesInParams(url).pipe(catchError((error) => throwError(error)))
    }

    public getStationManageData(stationCallSign?: string): Observable<{}> {
        let callSign = this.stationCallSign instanceof Cookie ? this.stationCallSign.value : ""

        if (stationCallSign) {
            callSign = stationCallSign
        }

        if (!callSign || callSign === "None") {
            return of({})
        }
        const url: string = `https://station.services.pbs.org/api/public/v1/stations/?call_sign=${callSign}`

        return this.http.get(url).pipe(catchError((error) => throwError(error)))
    }

    public getStationsByZipCode(zipcode: string = "0") {
        return this.lmApi
            .get(`/api/v2/orgs_by_zipcode/${zipcode}/`)
            .pipe(catchError((error) => throwError(error)))
    }

    public getStationsByState(state: string) {
        return this.lmApi
            .get(`/api/v2/orgs_by_state/${state}/`)
            .pipe(catchError((error) => throwError(error)))
    }

    public setStation(guid: string = "", zipCode?: string, standardSelected?: boolean) {
        let nextLink: string = location.href + (standardSelected ? "?selected_tab=standards" : "")
        if (zipCode) {
            location.href = encodeURI(
                "api/v2/change_station/" + guid + "/?zip_code=" + zipCode + "&next=" + nextLink
            )
        } else if (!guid) {
            // national station currently not used
            location.href = encodeURI(`/api/v2/change_station/?next=${nextLink}`)
        } else {
            location.href = encodeURI(`api/v2/change_station/${guid}/?next=${nextLink}`)
        }
    }

    public isNational(): boolean {
        if (this.stationCallSign && this.stationCallSign.value) {
            const nationalCallsigns = ["PBS", '""']
            return nationalCallsigns.indexOf(this.stationCallSign.value) > -1
        }
        return true
    }

    public isLocalized() {
        return !this.isNational()
    }

    public confirmStation() {
        const hostName = window.location.hostname
        const domain = hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1))
        this.cookieService.setCookie("station_confirmed", "true", 365, domain)
    }
}
