import { Component, Input } from "@angular/core"
import { ModalRef } from "../../modal/modal-ref"

@Component({
    selector: "app-information-modal",
    templateUrl: "./information-modal.component.html",
    styleUrls: ["./information-modal.component.scss"],
})
export class InformationModalComponent {
    @Input() message: string = ""
    @Input() title: string = ""
    @Input() shouldDisplayFooterCloseButton
    @Input() shouldDisplayHeaderCloseButton

    constructor(public activeModal: ModalRef) {}
}
