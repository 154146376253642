import { Injectable, OnDestroy } from "@angular/core"
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs"
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from "rxjs/operators"

@Injectable()
export abstract class AbstractDataPrivacyDropdown<T> implements OnDestroy {
    isLoading$ = new BehaviorSubject<boolean>(false)
    selectedItem$ = new BehaviorSubject<null | T>(null)

    fetchData$ = new ReplaySubject<any>(1)
    scrolledToEnd$ = new ReplaySubject<any>(1)

    searchTerm$ = new Subject<string>()
    count$ = new ReplaySubject<number>(1)

    protected data = new BehaviorSubject<T[] | []>([])
    readonly data$ = this.data.asObservable()

    protected destroyed$ = new Subject<boolean>()
    protected nextUrl = new ReplaySubject<string>(1)

    abstract trackByFn(value: T): string | number

    protected constructor() {}

    ngOnDestroy(): void {
        this.destroyed$.next()
        this.destroyed$.complete()
    }

    registerDataStream(callback): void {
        this.fetchData$
            .pipe(
                takeUntil(this.destroyed$),
                withLatestFrom(this.selectedItem$),
                filter(([_, selectedItem]) => {
                    this.data.next([selectedItem])
                    return !selectedItem
                }),
                tap(() => {
                    this.count$.next(0)
                    this.isLoading$.next(true)
                    this.data.next([])
                }),
                switchMap(([id, _]) => callback(id)),
                tap(({ next, count }) => {
                    this.count$.next(count)
                    this.isLoading$.next(false)
                    this.nextUrl.next(next)
                }),
                map(({ results }) => results)
            )
            .subscribe((results) => this.data.next([...results]))
    }

    registerTypeaheadStream(callback): void {
        this.searchTerm$
            .pipe(
                takeUntil(this.destroyed$),
                withLatestFrom(this.fetchData$),
                tap(() => {
                    this.isLoading$.next(true)
                    this.data.next([])
                }),
                debounceTime(700),
                distinctUntilChanged(),
                switchMap(([searchTerm, id]) => callback(id, searchTerm)),
                tap(({ next }) => {
                    this.isLoading$.next(false)
                    this.nextUrl.next(next)
                }),
                map(({ results }) => results)
            )
            .subscribe((results) => this.data.next([...results]))
    }

    registerOnScrollToEnd(callback): void {
        this.scrolledToEnd$
            .pipe(
                takeUntil(this.destroyed$),
                withLatestFrom(this.nextUrl),
                filter(([_, nextUrl]) => !!nextUrl),
                tap(() => this.isLoading$.next(true)),
                switchMap(([_, nextUrl]) => callback(nextUrl)),
                tap(({ next }) => {
                    this.nextUrl.next(next)
                    this.isLoading$.next(false)
                }),
                map(({ results }) => results)
            )
            .subscribe((results) => this.data.next([...this.data.value, ...results]))
    }
}
