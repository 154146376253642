<button
    (click)="closeAuthModal()"
    *ngIf="activeModal"
    aria-label="Close"
    class="btn-close"
    type="button"
></button>
<div id="auth-modal" class="px-2 py-5">
    <div class="modal-header">
        <h2 class="title modal-title-text">{{ title }}</h2>
    </div>
    <div class="modal-body">
        <div class="tabs-wrapper">
            <a (click)="activateTeacherTab()" [ngClass]="{ active: !isStudentTab }"> Teacher </a>
            <a
                (click)="activateStudentTab()"
                *ngIf="activeModal"
                [ngClass]="{ active: isStudentTab }"
                class="selenium-modal-student-link"
            >
                Student
            </a>
            <a
                (click)="openStudentSignUp()"
                *ngIf="!activeModal"
                class="selenium-modal-student-link"
            >
                Student
            </a>
        </div>
        <div class="description-wrapper modal-body-text">
            <p>
                {{ description }}
            </p>
        </div>
        <!-- Region: Teacher Auth Buttons -->
        <div *ngIf="!isStudentTab" class="auth-links-wrapper">
            <a [href]="googleAuthUrl" class="auth google">
                <img [src]="googleImage" alt="Google Auth" class="auth-icon" />
                Sign {{ isSignUp ? "up" : "in" }} with Google
            </a>
            <a [href]="pbsAuthUrl" class="auth pbs">
                <em class="auth-icon fas fa-envelope"></em>
                Sign {{ isSignUp ? "up" : "in" }} with Email
            </a>
        </div>
        <!-- /Region: Teacher Auth Buttons -->
        <div *ngIf="activeModal && isStudentTab" class="student-auth-wrapper">
            <app-student-sign-in-modal [displayTitle]="false"></app-student-sign-in-modal>
        </div>
        <div *ngIf="activeModal && !isStudentTab" class="register-caption-wrapper">
            <span>{{ registerDescription }}</span>
            <span>
                <a
                    (click)="closeAuthModal()"
                    class="selenium-modal-signup-link"
                    href="/signup/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {{ registerLinkText }}
                    <em class="fas fa-external-link-alt"></em>
                </a>
            </span>
        </div>
        <div *ngIf="!activeModal" class="register-caption-wrapper modal-body-text">
            <span class="modal-body-text">{{ registerDescription }}</span>
            <span>
                <a (click)="isStudent ? openStudentAuthModal() : openAuthModal()">{{
                    registerLinkText
                }}</a>
            </span>
        </div>
    </div>
</div>
