import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core"

export enum USER_PROFILE_BUTTON_TYPE {
    SAVE = "SAVE",
    CANCEL = "CANCEL",
}

@Component({
    selector: "app-user-profile-action-buttons",
    templateUrl: "./user-profile-action-buttons.component.html",
    styleUrls: ["./user-profile-action-buttons.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileActionButtonsComponent {
    @Input() isCancelVisible = false
    @Input() isSaveDisabled = true

    @Output() btnClick = new EventEmitter<USER_PROFILE_BUTTON_TYPE>()

    USER_PROFILE_BUTTON_TYPE = USER_PROFILE_BUTTON_TYPE

    btnClicked(btnType: USER_PROFILE_BUTTON_TYPE): void {
        this.btnClick.emit(btnType)
    }
}
