export class StandardsInformation {
    constructor(
        public resourceCode: string = "",
        public stateStandardsExist: boolean = false,
        public stateStandardsAvailable: boolean = false,
        public primaryStateStandardsName: string = "",
        public secondaryStateStandardsName: string = "",
        public nationalStandards: any = {},
        public primaryStateStandards: any = {},
        public secondaryStateStandards: any = {}
    ) {}

    static fromData(data: any, resourceCode: string): StandardsInformation {
        const standards = data.standards || {}
        return new StandardsInformation(
            resourceCode,
            !!data.meta.state_standards_exist,
            !!data.meta.state_standards_available,
            data.meta.primary_auth_name,
            data.meta.secondary_auth_name,
            standards.national || {},
            standards.primary_state_standards || {},
            standards.secondary_state_standards || {}
        )
    }

    public hasDisplayableStandards() {
        return Object.keys(this.nationalStandards).length > 0 || this.stateStandardsExist
    }
}
