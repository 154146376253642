import { Component, Input } from "@angular/core"
import { STATIC_IMAGES } from "../../../../models/constants"

@Component({
    selector: "app-collection-card-poster",
    templateUrl: "./collection-card-poster.component.html",
    styleUrls: ["./collection-card-poster.component.scss"],
})
export class CollectionCardPosterComponent {
    @Input() searchImage = null
    // Deprecated image
    @Input() posterImage = null
    @Input() resourceCount = null
    @Input() title = null
    @Input() gtmPrefix: string = ""
    @Input() absoluteUrl: string = ""
    @Input() posterImageDimension?: string = "184px"
    @Input() showType: boolean = true
    placeholderImg: string = null

    constructor() {
        this.placeholderImg = STATIC_IMAGES.placeholder_image
    }

    public getCollectionsPosterClass() {
        return `gtm-${this.gtmPrefix}-card`
    }
}
