export enum PLACEHOLDER_LABEL {
    "HAS_SCHOOLS" = "Enter school name (Optional)",
    "HAS_NO_SCHOOLS" = "No schools found for this district",
}

export enum SCHOOL_TYPE {
    PUBLIC_SCHOOL = "PUBLIC_SCHOOL",
    PRIVATE_SCHOOL = "PRIVATE_SCHOOL",
    HOME_SCHOOL = "HOME_SCHOOL",
}

export enum SCHOOL_TYPE_DESCRIPTION {
    PUBLIC_SCHOOL = "Public School Teacher",
    PRIVATE_SCHOOL = "Private School Teacher",
    HOME_SCHOOL = "Homeschool Teacher",
}

export enum SCHOOL_TYPE_TEACHER_DESCRIPTION {
    PUBLIC_SCHOOL = "I teach at a public school or schools in the US",
    PRIVATE_SCHOOL = "I teach at a private school or schools in the US",
    HOME_SCHOOL = "I homeschool/tutor",
}

export interface ISchoolDistrict {
    district_guid: string
    district_name: string
    state_location?: string
    zip_code?: string
}

export interface ISchool {
    name: string
    id: number
}

export interface IState {
    state_location: string
    name: string
}

export interface UserDataPrivacyResponse {
    school_district: ISchoolDistrict[]
    teacher_type: SCHOOL_TYPE_DESCRIPTION
    sign_acknowledgement: boolean
    do_this_later: boolean
    school_name?: string
}

export interface UserDataPrivacyPayload {
    sign_acknowledgement: boolean
    teacher_type: SCHOOL_TYPE_DESCRIPTION
    do_this_later: boolean
    district_guid?: string
    school_id?: number
}
