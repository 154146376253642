import { Component, Input } from "@angular/core"

@Component({
    selector: "app-card-title",
    templateUrl: "./card-title.component.html",
    styleUrls: ["./card-title.component.scss"],
})
export class CardTitleComponent {
    @Input() public height: number = null
    @Input() public rows: number = 3
}
