import Utils from "../../utils/utils"

export class PremiumColors {
    public primaryButtonColor: string = ""
    public secondaryButtonColor: string = ""
    public mainColor: string = ""
    public footerColor: string = ""
    public isCustomFooter: boolean = false

    constructor(colors) {
        Object.keys(colors).map((key) => {
            const property = Utils.convertToCamelcase(key)
            this[property] = colors[key]
        })
        this.footerColor = this.footerColor ? this.footerColor : this.mainColor
        this.isCustomFooter = this.footerColor !== this.mainColor
    }
}
