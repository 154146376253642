import { Injectable, isDevMode } from "@angular/core"
import { ActivatedRoute, Params } from "@angular/router"
import {
    BehaviorSubject,
    Observable,
    of as observableOf,
    ReplaySubject,
    throwError,
    throwError as observableThrowError,
} from "rxjs"
import { catchError } from "rxjs/operators"
import { LmApiService } from "./lm-api.service"
import { JoinedRoster } from "../models/student-site/joined-roster.model"
import { AddOnViewService } from "./add-on-view.service"
import { AdminHeaderData } from "../models/adminHeaderData.model"
import { StudentSignIn } from "../models/studentSignIn.model"

declare var window: any

@Injectable()
export class UserService {
    favoritesReady: ReplaySubject<any> = new ReplaySubject<any>()
    shouldShowVirginiaModal = window.PBSLM.SHOW_VIRGINIA_MODAL
    studentAlertData = window.PBSLM.NG.STUDENT_ALERT_BAR_DATA
    teacherAlertData = window.PBSLM.NG.TEACHER_ALERT_BAR_DATA
    private _loggedIn = window.PBSLM.NG.IS_AUTHENTICATED
    private _adminDisplay = window.PBSLM.NG.ADMIN_DISPLAY
    private _pbsAuthUrl = window.PBSLM.NG.PBS_AUTH_URL
    private _pbsAuthRegisterUrl = window.PBSLM.NG.PBS_AUTH_REGISTER_URL
    private _googleAuthUrl = window.PBSLM.NG.GOOGLE_AUTH_URL
    private _logoutUrl = window.PBSLM.NG.LOGOUT_URL
    private _firstName = window.PBSLM.NG.FIRST_NAME
    private _userName = window.PBSLM.NG.USER_NAME
    private _isStudent = window.PBSLM.NG.IS_STUDENT // true if you are logged in as a student
    private _hasStudentExperience = false
    private _userPK = window.PBSLM.NG.USER_PK
    private _isYoungStudent = window.PBSLM.NG.IS_YOUNG_STUDENT
    private _isSU = window.PBSLM.NG.IS_SU
    private _preloadedEndpoints = window.PBSLM.PRELOADED_ENDPOINTS
    private _favorites = []
    private executedQueryParams: string[] = []

    private _adminHeaderData: BehaviorSubject<AdminHeaderData> = new BehaviorSubject(
        new AdminHeaderData(null, null, true, window)
    )
    public readonly adminHeaderData$: Observable<
        AdminHeaderData
    > = this._adminHeaderData.asObservable()

    constructor(private lmApi: LmApiService, private addOnService: AddOnViewService) {
        this.getFavorites()
    }

    emitAdminHeaderData({ title = null, adminLink = null, isPublished = true }): void {
        this._adminHeaderData.next(new AdminHeaderData(title, adminLink, isPublished, window))
    }

    public hasStudentExperience() {
        return this._hasStudentExperience || this._isStudent
    }

    public setStudentExperience(isStudentExperience: boolean = true) {
        this._hasStudentExperience = this.isStudent() || isStudentExperience
        this.lmApi.studentExperience = this._hasStudentExperience
    }

    public hasAdminDisplay() {
        return this._adminDisplay || this._isSU
    }

    public isLoggedIn() {
        return this._loggedIn
    }

    public getPbsAuthUrl(nextURL, register: boolean = false) {
        if (register) {
            return this._pbsAuthRegisterUrl + this.nextUrlBuilder(nextURL)
        }
        return this._pbsAuthUrl + this.nextUrlBuilder(nextURL)
    }

    public getGoogleAuthUrl(isStudent: boolean = false): string {
        let nextURL: string = this.getCurrentPath(isStudent)
        let url: string = this._googleAuthUrl + this.nextUrlBuilder(nextURL)
        isStudent && (url += "&type=student")
        return url
    }

    public nextUrlBuilder(nextURL): string {
        return (
            "?next=" +
            encodeURIComponent(this.safeNextURL(nextURL ? nextURL : window.location.href))
        )
    }

    public switchUserExperience(isStudent: boolean, target: string) {
        //in this function window.location is used instead of router.navigate because router navigate
        //is not triggering onActive() router guard function
        let parsedURL: URL = new URL(window.location.href)
        if (isStudent && parsedURL.pathname !== "/") {
            parsedURL.searchParams.set("student", "true")
        } else if (isStudent && parsedURL.pathname === "/") {
            parsedURL.pathname = "/student/"
            parsedURL.search = ""
        } else if (
            (!isStudent && parsedURL.pathname === "/student/") ||
            parsedURL.pathname.startsWith("/student/")
        ) {
            parsedURL.pathname = "/"
            parsedURL.search = ""
        } else {
            if (!isStudent) {
                parsedURL.searchParams.delete("excluded_facet")
            }
            parsedURL.searchParams.delete("student")
        }
        window.open(parsedURL.toString(), target)
    }

    public getLogoutUrl() {
        if (!this.addOnService.isAddonView)
            return this.isStudent() ? this._logoutUrl + "student/" : this._logoutUrl
        return this._logoutUrl.replace("next=/", `next=/add-on/search/`)
    }

    public getFirstName() {
        return this._firstName
    }

    public getUserName() {
        return this._userName
    }

    public isStudent() {
        return this._isStudent
    }

    public getUserPK() {
        return this._userPK
    }

    public getPreloadedEndpoints(): any {
        return this._preloadedEndpoints
    }

    public getWithPreload(url) {
        if (this.getPreloadedEndpoints().hasOwnProperty(url)) {
            isDevMode() && console.log("Using pre-fetched API:", url)
            return observableOf(this.getPreloadedEndpoints()[url])
        }
        isDevMode() &&
            console.debug(
                "No pre-fetched for:",
                url,
                ", available:",
                Object.keys(this.getPreloadedEndpoints())
            )
        return this.lmApi
            .getWithCookiesInParams(url)
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public addFavorite(learningObjectId: string) {
        return this.lmApi
            .post(`/api/v2/favorite/`, { lo_id: learningObjectId })
            .pipe(catchError((error) => observableThrowError(error)))
            .subscribe((response: any) => {
                this._favorites.push({
                    loId: learningObjectId,
                    favoriteId: response.id,
                })
                this.favoritesReady.next(this._favorites)
            })
    }

    public deleteFavorite(favoriteId: number) {
        return this.lmApi
            .delete(`/api/v2/favorite/${favoriteId}/`)
            .pipe(catchError((error) => observableThrowError(error)))
            .subscribe(() => {
                this._favorites = this._favorites.filter((favorite) => {
                    return favorite.favoriteId !== favoriteId
                })
                this.favoritesReady.next(this._favorites)
            })
    }

    public getFavorites() {
        if (this.isLoggedIn()) {
            return this.lmApi
                .get("/api/v2/favorites/")
                .pipe(catchError((error) => observableThrowError(error)))
                .subscribe((favorites: any) => {
                    favorites.objects.forEach((favorite) => {
                        this._favorites.push({
                            loId: favorite.lo_id,
                            favoriteId: favorite.id,
                        })
                    })
                    this.favoritesReady.next(this._favorites)
                })
        } else {
            this.favoritesReady.next([])
        }
    }

    public studentLogIn(studentLogIn: StudentSignIn, nextUrl: string): Observable<any> {
        const apiUrl: string = "/student/signin/"
        return this.lmApi
            .post(apiUrl, studentLogIn, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .pipe(
                catchError((error) => {
                    return throwError(error)
                })
            )
    }

    public postCreateStudent(data): Observable<any> {
        const apiUrl: string = "/api/v2/student/"
        return this.lmApi
            .post(apiUrl, data)
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public isYoungStudent() {
        return this._isYoungStudent
    }

    public validateCode(code) {
        return this.lmApi
            .post("/student/assignment-validation/", { code })
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public updateResult(resultID, payload) {
        return this.lmApi
            .put(`/tools/api/v2/result/${resultID}/`, payload)
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public fetchClassDetails(code) {
        return this.lmApi
            .get(`/tools/rosters/api/v2/rosters/rostercode/${code}/`)
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public joinClass(rosterId, studentData) {
        return this.lmApi
            .post(`/tools/rosters/api/v2/rosters/${rosterId}/students/`, studentData)
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public leaveClass(joinedRoster: JoinedRoster) {
        return this.lmApi
            .delete(joinedRoster.leaveRosterURL)
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public enterStudentCode(studentCode) {
        return this.lmApi
            .post("/student/dispatch_code/", { code: studentCode })
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public getCurrentPath(isStudent: boolean = true): string {
        let url: URL = new URL(window.location.href, window.location.origin)
        if (url.pathname == "/" && isStudent) url.pathname = "/student/"
        //removed domain in order to avoid localisation issues
        return url.pathname + url.search + url.hash
    }

    public runOnceForQueryParam(route: ActivatedRoute, queryParam: string, callback: any) {
        return route.queryParams.subscribe((params: Params) => {
            if (params[queryParam] !== undefined) {
                if (this.executedQueryParams.indexOf(queryParam) === -1) {
                    this.executedQueryParams.push(queryParam)
                    callback(params[queryParam])
                }
            }
        })
    }

    public getStudentQueryParams() {
        if (this.hasStudentExperience()) {
            return { student: true }
        }
        return {}
    }

    private safeNextURL(nextURL) {
        if (nextURL) {
            return nextURL
        }
        return window.location.pathname
    }
}
