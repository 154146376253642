import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router"
import { AddOnViewService } from "../services/add-on-view.service"
import Utils from "../utils/utils"
import { Location } from "@angular/common"
import { UserService } from "../services/user.service"

@Injectable()
export class IframeGuard implements CanActivate {
    constructor(
        private addOnViewService: AddOnViewService,
        private router: Router,
        private location: Location,
        private userService: UserService
    ) {}
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (
            this.addOnViewService.isLoginRequired(
                this.userService.isLoggedIn(),
                this.userService.isStudent()
            )
        ) {
            this.router.navigate(["add-on/log-in"], {
                queryParams: { logOut: this.userService.isStudent() ? true : null },
            })
            return false
        }
        // if accessed page is not and add-on specific one, 'add-on' segment will be concatenated to initial url followed by redirect
        if (!Utils.isSegmentContainedInUrl("add-on") && this.addOnViewService.isAddonView) {
            this.router.navigate(
                ["add-on/" + Utils.extractUrlWithoutQueryParams(state.url) + "/"],
                {
                    queryParams: route.queryParams,
                }
            )
            return false
        }
        return true
    }
}
