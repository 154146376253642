import { Injectable } from "@angular/core"
import { Cookie } from "../models/cookie.model"

@Injectable()
export class CookiesService {
    private rawCookies: string
    private cookies: Cookie[] = []
    private readonly mainDomain: string

    constructor() {
        this.extractCookies()
        const hostName: string = window.location.hostname
        const firstDotIndex = hostName.indexOf(".")
        this.mainDomain = hostName.substring(firstDotIndex + 1)
    }

    public getCookie(key: string) {
        this.extractCookies()
        return this.cookies.filter((cookie) => {
            return cookie.key === key
        })[0]
    }

    public getCookieValue(key: string) {
        const cookie: Cookie = this.getCookie(key)
        let cookieValue = ""
        if (cookie) {
            cookieValue = cookie.value
        }
        return cookieValue
    }

    public setCookie(name: string, value: string, expireDays: number, path: string = "") {
        const d: Date = new Date()
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000)
        const expires: string = `expires=${d.toUTCString()}`
        const cpath: string = path ? `; path=${path}` : ""
        const domain: string = `domain=${this.mainDomain}`
        document.cookie = `${name}=${value}; ${expires}${cpath};${domain}`
    }

    public deleteCookie(name) {
        document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${this.mainDomain};`
    }

    private extractCookies() {
        this.cookies = []
        this.rawCookies = document.cookie
        const cookies: string[] = this.rawCookies.split(";")
        cookies.forEach((cookie) => {
            const cookieSplit = cookie.split("=")
            const key = cookieSplit[0].trim()
            const value = cookieSplit[1]
            this.cookies.push(new Cookie(key, value))
        })
    }
}
