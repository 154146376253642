import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { redirectToError } from "../../utils/error-handling"
import { UserService } from "../../services/user.service"
import { Location } from "@angular/common"

@Component({
    templateUrl: "./login-required.component.html",
})
export class LoginRequiredComponent implements OnInit {
    public errorCode: string = null
    public isStudent: boolean = false

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private location: Location
    ) {}

    ngOnInit() {
        let currentParams = this.route.snapshot.queryParams
        if (this.userService.isLoggedIn()) {
            this.router.navigate([currentParams.next || "/"])
        } else {
            redirectToError(this.router, this.location, "401", currentParams.next)
        }
    }
}
