import { Asset, AssetResponse } from "./resource/asset"
import { Attribute } from "./attribute.model"
import { URSService } from "../services/urs.service"

export class LessonBuilderItem {
    public name: string = ""
    public code: string = ""
    public description: string = ""
    public slides: LessonBuilderSlide[] = []

    constructor(data: any, ursService: URSService) {
        if (data) {
            this.name = data.name
            this.code = data.code
            this.description = data.description
            this.slides = (data.slides || []).map((s) => {
                if (s.type === LessonBuilderSlideType.Media) {
                    if (s.resource_guid) {
                        return InternalMediaSlide.fromData(s, ursService)
                    } else {
                        return UGCMediaSlide.fromData(s, ursService)
                    }
                } else if (s.type === LessonBuilderSlideType.Question) {
                    return QuestionSlide.fromData(s)
                } else if (s.type === LessonBuilderSlideType.Text) {
                    return TextSlide.fromData(s)
                }
                console.error("invalid slide type", s)
            })
        }
    }

    public toData() {
        return {
            code: this.code,
            name: this.name,
            description: this.description,
            slides: this.slides.map((slide: LessonBuilderSlide) => slide.toData()),
        }
    }
}

export abstract class LessonBuilderSlide {
    protected constructor(
        public slideId: number,
        public title: string,
        public description: string
    ) {}

    toData(): any {
        const data: any = {
            title: this.title,
            description: this.description,
        }
        if (this.slideId) {
            data.slide_id = this.slideId
        }
        return data
    }
}

export class TextSlide extends LessonBuilderSlide {
    constructor(public slideId: number, public title: string, public description: string) {
        super(slideId, title, description)
    }

    public toData() {
        const data = super.toData()
        data.type = LessonBuilderSlideType.Text
        return data
    }

    public static fromData(data: any): TextSlide {
        return new TextSlide(data.slide_id, data.title, data.description)
    }
}

export class MediaSlide extends LessonBuilderSlide {
    constructor(
        public slideId: number,
        public title: string,
        public description: string,
        public asset: Asset,
        public brand: string,
        public creditsLink: string
    ) {
        super(slideId, title, description)
    }

    public isValid() {
        return false
    }
}

export class InternalMediaSlide extends MediaSlide {
    constructor(
        public slideId: number,
        public title: string,
        public description: string,
        public asset: Asset,
        public brand: string,
        public creditsLink: string,
        public resourceGuid: string
    ) {
        super(slideId, title, description, asset, brand, creditsLink)
    }

    public isValid() {
        return !!(this.resourceGuid && this.asset && this.asset.guid)
    }

    public toData() {
        const data = super.toData()
        data.type = LessonBuilderSlideType.Media
        data.resource = this.resourceGuid
        data.asset = this.asset.guid
        return data
    }

    public static fromData(data: any, ursService: URSService): TextSlide {
        return new InternalMediaSlide(
            data.slide_id,
            data.title,
            data.description,
            Asset.fromData(data.asset, ursService),
            data.brand,
            data.credits_link,
            data.resource_guid
        )
    }
}

export class UGCMediaSlide extends MediaSlide {
    constructor(
        public slideId: number,
        public title: string,
        public description: string,
        public asset: Asset,
        public brand: string,
        public creditsLink: string,
        public ugcGuid: string
    ) {
        super(slideId, title, description, asset, brand, creditsLink)
    }

    public isValid() {
        return !!this.ugcGuid
    }

    public toData() {
        const data = super.toData()
        data.type = LessonBuilderSlideType.Media
        data.ugc = this.ugcGuid
        return data
    }

    public static fromData(data: any, ursService: URSService): TextSlide {
        return new UGCMediaSlide(
            data.slide_id,
            data.title,
            data.description,
            data.asset ? Asset.fromData(data.asset, ursService) : null,
            data.brand,
            data.credits_link,
            data.ugc_guid
        )
    }
}

export class QuestionSlide extends LessonBuilderSlide {
    constructor(
        public slideId: number = null,
        public title: string = "",
        public description: string = "",
        public questions: Question[] = []
    ) {
        super(slideId, title, description)
    }

    public toData() {
        const data = super.toData()
        data.type = LessonBuilderSlideType.Question
        data.questions = this.questions.map((question: Question) => question.toData())
        return data
    }

    public static fromData(data: any): QuestionSlide {
        const questions = data.questions
            ? data.questions.map((qData) => Question.fromData(qData))
            : []
        return new QuestionSlide(data.slide_id, data.title, data.description, questions)
    }
}

export class LessonBuilderMedia {
    public title: string
    public brand: MediaBrand
    public objectId: string
    public mediaFile: MediaFile

    constructor(data: any) {
        this.title = data.title
        this.brand = new MediaBrand(data.brand)
        this.mediaFile = new MediaFile(data.media_file)
        this.objectId = data.object_id
    }
}

export class MediaBrand {
    public name: string
    public creditsLink: string

    constructor(data: any) {
        this.name = data.name
        this.creditsLink = data.credits_link
    }
}

export class MediaFile {
    public externalLink: string
    public posterImage: PosterImage
    public type: MediaFileType
    public url: string

    constructor(data: any) {
        this.externalLink = data.external_link
        this.posterImage = data.poster_image
            ? {
                  altText: data.poster_image.alt_text,
                  url: data.poster_image.url,
              }
            : null
        this.type = data.type
        this.url = data.url
    }
}

export enum MediaFileType {
    Audiomedia = "audiomedia",
    Videomedia = "videomedia",
    Documentmedia = "documentmedia",
    Htmlfragmentmedia = "htmlfragmentmedia",
    Imagemedia = "imagemedia",
    Interactivemedia = "interactivemedia",
    Pointermedia = "pointermedia",
}

export class PosterImage {
    altText: string
    url: string
}

export class Question {
    constructor(
        public pk: number,
        public type: QuestionType,
        public text: string,
        public choices: QuestionChoice[]
    ) {}

    toData() {
        const data: any = {
            type: this.type,
            text: this.text,
            choices: this.choices.map((choice) => choice.toData()),
        }
        if (this.pk) {
            data.pk = this.pk
        }
        return data
    }

    static fromData(data): Question {
        const choices = (data.choices || []).map(
            (choiceData) => new QuestionChoice(choiceData.text, choiceData.correct)
        )
        let type
        if (data.type == "Fill") {
            type = QuestionType.FillInTheBlank
        } else if (data.type == "Short") {
            type = QuestionType.ShortAnswer
        } else if (data.type == "Multiple") {
            type = QuestionType.MultipleChoice
        } else if (data.type == "True_false") {
            type = QuestionType.TrueFalse
        } else {
            type = QuestionType.EssayQuestion
        }
        return new Question(data.pk, type, data.text, choices)
    }
}

export class QuestionChoice {
    constructor(public text: string, public correct: boolean) {}

    toData() {
        return {
            text: this.text,
            correct: this.correct,
        }
    }
}

export enum LessonBuilderSlideType {
    Text = "Text",
    Question = "Question",
    Media = "Media",
}

export enum QuestionType {
    FillInTheBlank = "Fill",
    MultipleChoice = "Multiple",
    ShortAnswer = "Short",
    TrueFalse = "True_false",
    EssayQuestion = "Essay",
}

export class LessonBuilderResource {
    constructor(
        public title: string = "",
        public guid: string = "",
        public brand: string = "",
        public fundersLine: string = "",
        public creditsLink: string = "",
        public link: string = "",
        public assets: Asset[] = []
    ) {}

    public static fromData(response: any, ursService: URSService) {
        const attributes = (response.attributions || []).map((data) => new Attribute(data))
        const brand = attributes.find((attr) => attr.role === "brand")?.name
        const fundersLine = attributes
            .filter((attr) => attr.role === "funder")
            .map((attr) => attr.name)
            .join(", ")
        const assets = (response.assets || []).map((data: AssetResponse) =>
            Asset.fromData(data, ursService)
        )
        const creditsLink = `/credits/${response.resource_code}`
        return new LessonBuilderResource(
            response.title,
            response.guid,
            brand,
            fundersLine,
            creditsLink,
            response.canonical_url,
            assets
        )
    }
}
