import { Component, Input } from "@angular/core"
import { ColorCodeService } from "../../../services/color-code.service"
import { UserService } from "../../../services/user.service"
import { CardComponent } from "../card.component"

@Component({
    selector: "app-resource-featured-card",
    templateUrl: "./resource-featured-card.component.html",
    styleUrls: ["./resource-featured-card.component.scss"],
})
export class ResourceFeaturedCardComponent extends CardComponent {
    public ColorCodeService = ColorCodeService

    @Input() public headerURL: string = null
    constructor(public userService: UserService) {
        super(userService)
    }

    public stopPropagation(event: MouseEvent) {
        event.stopPropagation()
        return false
    }
}
