<div
    [class]="getCollectionsPosterClass()"
    [attr.title]="title"
    [attr.aria-label]="title"
    [attr.data-gtmlabel]="absoluteUrl"
>
    <div
        class="poster-image selenium-card-poster-image"
        [ngClass]="{ blured: !searchImage }"
        [ngStyle]="{
            'background-image': 'url(\'' + (searchImage ? searchImage : posterImage) + '\')',
            height: posterImageDimension,
            width: posterImageDimension
        }"
    >
        <img *ngIf="!searchImage" class="inner-poster-image" [src]="posterImage" />
        <div class="media-type selenium-card-media-type" *ngIf="showType">
            <app-icon class="icon" [type]="'Collection'"></app-icon>
            <span class="text">Collection</span>
        </div>
        <div
            *ngIf="resourceCount"
            ngbTooltip="{{ 'There are ' + resourceCount + ' resources in this collection' }}"
            class="resource-count"
        >
            {{ resourceCount }}
        </div>
    </div>
</div>
