import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { GradeBand } from "../../../models/gradeBand.model"
import { AddOnViewService } from "../../../services/add-on-view.service"
import { ModalService } from "../../modal/modal.service"
import { StudentGateService } from "../../../services/student-gate.service"
import { UserService } from "../../../services/user.service"

@Component({
    selector: "app-grades-dropdown",
    templateUrl: "./grades-dropdown.component.html",
    styleUrls: ["./grades-dropdown.component.scss"],
})
export class GradesDropdownComponent implements OnInit {
    @Input()
    grades

    @Output()
    gradeSelected: EventEmitter<string[]> = new EventEmitter<string[]>()

    @Output()
    toggleGradePanel: EventEmitter<string> = new EventEmitter<string>()
    isGradesPanelOpened: boolean = false
    isStudentExperience: boolean = false
    constructor(
        public addOnService: AddOnViewService,
        protected modalService: ModalService,
        public studentGateService: StudentGateService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.isStudentExperience = this.userService.hasStudentExperience()
        this.studentGateService.isGateOpened.subscribe((value) => {
            this.isGradesPanelOpened = value
        })
    }

    public toggleGradesPanel() {
        this.studentGateService.isLgModalDisplayed = false
        this.isGradesPanelOpened = !this.isGradesPanelOpened
        this.studentGateService.isDropdownOpened =
            this.isGradesPanelOpened && this.userService.hasStudentExperience()
        this.toggleGradePanel.emit("grades")
    }

    public onGradeSelected(gradeBand: GradeBand) {
        if (this.userService.hasStudentExperience()) return
        this.isGradesPanelOpened = false
        this.gradeSelected.emit([
            this.addOnService.isAddonView
                ? `add-on/grades/${gradeBand.uri}/`
                : `/grades/${gradeBand.uri}/`,
        ])
    }
}
