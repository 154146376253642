import ResourceUtils from "../../utils/resource-utils"
import { Attribute } from "../attribute.model"
import { LearningObject } from "../learningObject.model"
import { LessonContent } from "../lessonContent.model"
import { Link } from "../link.model"
import { MuxDataModel } from "../muxData.model"
import { SupportMaterial, SupportMaterialByCategory } from "../supportMaterial.model"
import { Asset, AssetResponse, ContentFlags } from "./asset"
import { AudioMedia, DocumentMedia, GenericInteractiveMedia, ImageMedia, VideoMedia } from "./media"
import { URSService } from "../../services/urs.service"

export class Resource {
    // Will be null for lesson plan;
    public gradesDisplay: string
    public selectedAsset: Asset
    public adminLink: Link
    public isLessonPlan: boolean = false
    public studentViewURL: string = ""
    public focusViewURL: string = ""

    constructor(
        public id: string = "",
        public guid: string = "",
        public title: string = "",
        public mediaType: string = "", // gallery, lesson plan, audio, video
        public description: string = "",
        public expirationDate: string = "",
        public brand: Attribute = new Attribute({}),
        public attributions: Attribute[] = [],
        public resourceCode: string = "",
        public supportMaterialsForTeachersByCategory: SupportMaterialByCategory[] = [],
        public supportMaterialsForStudentsByCategory: SupportMaterialByCategory[] = [],
        public moreAboutThisResource: string = "",
        public hasStandards: boolean = false,
        public accessibility = null,
        public relatedResources: LearningObject[] = [],
        public grades: string[] = [],
        public project: any = {}, //TODO; type
        public collection: ResourceCollectionData = new ResourceCollectionData([], [], {}),
        public assets: Asset[] = [],
        public favoriteId: string = "",
        public curriculumTags: any[] = [],
        public lessonData: LessonData = null,
        public lessonContents: LessonContent[] = [],
        public contentProject: ContentProject = null,
        public isAssignable: boolean = true,
        public isAvailableToStudents: boolean = true,
        public canonicalURL: string = "",
        public creditsLink: string = ""
    ) {
        this.gradesDisplay = ResourceUtils.getGradesDisplay(this.grades)
        this.selectedAsset = this.assets.length ? this.assets[0] : new Asset()
        this.adminLink = {
            url: `/admin/cms/resource/resource_direct_change_code/${resourceCode}/`,
            title: "Resource",
        }
        this.isLessonPlan = !!lessonData
        if (this.canonicalURL) {
            const parsedURL = new URL(this.canonicalURL)
            parsedURL.searchParams.set("student", "true")
            this.studentViewURL = parsedURL.toString()
            parsedURL.searchParams.set("focus", "true")
            this.focusViewURL = parsedURL.toString()
        }
    }

    public static fromData(response: any, ursService: URSService) {
        const attributes = (response.attributions || []).map((data) => new Attribute(data))
        const brand = attributes.find((attr) => attr.role === "brand")
        const supportMaterialsForTeachers = Resource.getSupportMaterials(
            response.support_materials.for_teacher,
            ursService
        )
        const supportMaterialsForStudents = Resource.getSupportMaterials(
            response.support_materials.for_student,
            ursService
        )

        const relatedResources = (response.related_resources || []).map(
            (obj) => new LearningObject(obj)
        )
        const assets = (response.assets || []).map((data: AssetResponse) =>
            Asset.fromData(data, ursService)
        )
        const lessonData: LessonData = response.lesson_data
            ? LessonData.fromData(response.lesson_data)
            : null
        const cpData = response.content_project || {}
        const lessonContents = response.lesson_contents.map((lc) => {
            lc.type = lc.type === "HTMLFragment" ? "Text" : lc.type
            return new LessonContent(lc)
        })
        const creditsLink: string =
            response && response.resource_code && `/credits/${response.resource_code}`

        return new Resource(
            response.id,
            response.guid,
            response.title,
            response.media_type,
            response.description,
            response.expiration_date,
            brand,
            attributes.filter((attr) => attr.role !== "brand"),
            response.resource_code,
            supportMaterialsForTeachers,
            supportMaterialsForStudents,
            response.more_about_this_resource,
            response.has_standards,
            response.accessibility_indicators,
            relatedResources,
            response.grades,
            response.project,
            ResourceCollectionData.fromData(response.collection || {}),
            assets,
            response.favorite_id,
            response.curriculum_tags,
            lessonData,
            lessonContents,
            new ContentProject(cpData.title, cpData.type, cpData.organization),
            response.is_assignable,
            response.is_available_for_students,
            response.canonical_url,
            creditsLink
        )
    }

    public get isLoaded() {
        return !!this.title || !!this.mediaType
    }

    public selectAsset(asset: Asset) {
        if (this.selectedAsset) {
            // Backwards compatible. Reset previous asset.
            this.selectedAsset.selectLanguage()
        }
        if (!asset) {
            this.selectedAsset = this.assets[0]
            return
        }
        this.selectedAsset = this.assets.find((currentAsset: Asset) => currentAsset === asset)
        if (!this.selectedAsset) {
            this.selectedAsset = this.assets[0]
            console.warn("Invalid asset selection")
        }
    }

    public getMediaTypeTitle() {
        // TODO: Update on BE
        return this.mediaType
    }

    public getRootSubjectsValue(): string[] {
        const rootTags = []
        this.curriculumTags.forEach((tag) => {
            const rootNode = (tag.branch || {}).name
            if (rootTags.indexOf(rootNode) < 0) {
                rootTags.push(rootNode)
            }
        })
        return rootTags
    }

    public getProjectName(): string {
        return this.contentProject ? this.contentProject.title : ""
    }

    public getProjectType(): string {
        return this.contentProject ? this.contentProject.type : ""
    }

    public getProjectOrganization(): string {
        return this.contentProject ? this.contentProject.organization : ""
    }

    public getCollectionTitle(): string {
        if (!this.collection || !this.collection.firstCollection) {
            return ""
        }
        return this.collection.firstCollection.title || ""
    }

    public get opensAsVideo(): boolean {
        return (
            !this.isLessonPlan &&
            this.assets.length === 1 &&
            this.assets[0].selectedMedia instanceof VideoMedia
        )
    }

    public get opensAsImage(): boolean {
        return (
            !this.isLessonPlan &&
            this.assets.length === 1 &&
            this.assets[0].selectedMedia instanceof ImageMedia
        )
    }

    public get opensAsAudio(): boolean {
        return (
            !this.isLessonPlan &&
            this.assets.length === 1 &&
            this.assets[0].selectedMedia instanceof AudioMedia
        )
    }

    public get opensAsDocument(): boolean {
        return (
            !this.isLessonPlan &&
            this.assets.length === 1 &&
            this.assets[0].selectedMedia instanceof DocumentMedia
        )
    }

    public get opensAsInteractive(): boolean {
        return (
            this.assets.length === 1 && this.assets[0].medias[0] instanceof GenericInteractiveMedia
        )
    }

    public get opensAsMediaGallery(): boolean {
        return !this.isLessonPlan && this.assets.length > 1
    }

    public getSimilarResourcesURL() {
        let grades: string = ""
        let subjects: string = ""
        const subjectIds: number[] = this.getSubjectsIDByDepth(2)
        if (this.grades.length) {
            grades = `grades:${this.grades.join(",")}`
        }

        if (subjectIds.length) {
            subjects = `subject:${subjectIds.join(",")}`
        }

        return [grades, subjects]
    }

    public get shouldShowContentWarnings(): boolean {
        if (!!this.lessonContents.length) {
            return (
                this.lessonContents.filter(
                    (lessonContent: LessonContent) => lessonContent.contentFlags.length
                ).length > 0
            )
        } else {
            return (
                this.assets.filter((asset: Asset) =>
                    asset.contentFlags.some((contentFlag: ContentFlags) => contentFlag.description)
                ).length > 0
            )
        }
    }

    public getSensitiveWarning() {
        return "Sensitive: This resource contains material that may be sensitive for some students. Teachers should exercise discretion in evaluating whether this resource is suitable for their class."
    }

    public getCommentaryWarning() {
        return "Commentary: This resource contains material that reflects a particular point of view. Teachers may wish to provide students with relevant contrasting perspectives when using this resource in class."
    }

    public getSelectedMedia() {
        if (!this.selectedAsset) {
            return null
        }
        return this.selectedAsset.selectedMedia
    }

    public getMuxData(): MuxDataModel {
        return new MuxDataModel(
            this.selectedAsset.title,
            this.selectedAsset.getSelectedMediaLanguage(),
            this.selectedAsset.mediaType
        )
    }

    public hasIlpAsset() {
        return this.assets.find((asset: Asset) => asset.hasLTI())
    }

    private getSubjectsIDByDepth(depht: number = 1): number[] {
        return this.curriculumTags
            .filter((subject) => {
                return subject.ancestor_ids.length < depht
            })
            .map((subject) => subject.id)
    }

    private static getSupportMaterials(
        supportMaterialsByCategory: SupportMaterialByCategory[],
        ursService: URSService
    ) {
        return supportMaterialsByCategory.map((data) => ({
            category: data.category.toUpperCase(),
            items: data.items.map((sm) => SupportMaterial.fromData(sm, ursService)),
        }))
    }
}

export class ContentProject {
    constructor(public title: string, public type: string, public organization: string) {}
}

export class LessonData {
    constructor(public overviewData: LessonDataItem[], public procedureData: LessonDataItem[]) {}

    public static fromData(data) {
        return new LessonData(data.overview, data.procedure)
    }
}

export interface LessonDataItem {
    label: string
    value: string
}

export class ResourceCollectionData {
    //TODO: This needs to be refactored (BE+FE)
    constructor(
        public resources: LearningObject[],
        public collections: any,
        public firstCollection: any
    ) {}

    public static fromData(data) {
        const collData = data.firstCollection || {}

        return new ResourceCollectionData(
            (collData.resources || []).slice(0, 5).map((resource) => new LearningObject(resource)),
            data.collections,
            data.firstCollection
        )
    }
}
