<div
    *ngIf="featuredCollections && featuredCollections.length > 0"
    class="container-fluid selenium-app-collections"
>
    <div class="row-auto featured-collections">
        <div>
            <h2 class="title">Award Winning Series and Collections</h2>
            <span class="subtitle"
                >Timely, topical collections focused around learning objectives.
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    [routerLink]="['search']"
                    title="See all collections"
                    aria-label="See all collections"
                    [queryParams]="{
                        q: '',
                        selected_facet: 'media_type:Collection'
                    }"
                    [class]="getAllCollectionsGTMClass()"
                    [attr.data-gtmlabel]="getAllCollectionsUrl()"
                >
                    See All Collections &nbsp;&gt;
                </a>
            </span>
        </div>
    </div>
    <div class="row-auto">
        <app-carousel2
            [itemWidth]="210"
            [alwaysShowArrows]="false"
            [innerArrows]="true"
            height="280"
        >
            <app-carousel-item2
                class="carousel-item"
                *ngFor="let collection of featuredCollections"
            >
                <app-collection-browse-card
                    [learningObject]="collection"
                    [gtmPrefix]="gtmPrefix"
                ></app-collection-browse-card>
            </app-carousel-item2>
        </app-carousel2>
    </div>
</div>
