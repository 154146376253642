<ng-container *ngIf="vm$ | async as vm" class="mt-4">
    <ng-container *ngIf="(isLoading$ | async) === false">
        <ng-container *ngIf="vm.dataPrivacy.sign_acknowledgement; else dataPrivacyNotSigned">
            <app-data-privacy-completed [assignments]="vm"></app-data-privacy-completed>
        </ng-container>

        <ng-template #dataPrivacyNotSigned>
            <app-data-privacy-select
                [dataPrivacy]="vm.dataPrivacy"
                (submitBtnClicked)="setDataPrivacy($event)"
            ></app-data-privacy-select>
        </ng-template>
    </ng-container>
</ng-container>

<app-preloader *ngIf="isLoading$ | async"></app-preloader>
