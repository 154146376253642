import { Component, OnDestroy, OnInit } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { BehaviorSubject, Subject } from "rxjs"
import {
    ISchoolDistrict,
    IState,
    PLACEHOLDER_LABEL,
} from "../../../../models/userDataPrivacy.model"

import { filter, takeUntil, tap } from "rxjs/operators"
import { SchoolDistrictDropdownService } from "./school-districts-dropdown.service"
import { SchoolDropdownService } from "./schools-dropdown.service"
import { StatesDropdownService } from "./states-dropdown.service"
import { FORM_STACK_SUPPORT_URL } from "../../../../models/constants"

@Component({
    templateUrl: "./data-privacy-school-form.component.html",
    styleUrls: ["./data-privacy-school-form.component.scss"],
    providers: [SchoolDistrictDropdownService, SchoolDropdownService, StatesDropdownService],
})
export class DataPrivacySchoolFormComponent implements OnInit, OnDestroy {
    destroyed$ = new Subject()

    formStackUrl = FORM_STACK_SUPPORT_URL

    isSchoolDistrictDropdownShown = false
    isSchoolDropdownShown = false

    schoolForm!: FormGroup

    placeholderText$ = new BehaviorSubject<PLACEHOLDER_LABEL>(PLACEHOLDER_LABEL.HAS_SCHOOLS)

    constructor(
        public schoolDistrictService: SchoolDistrictDropdownService,
        public schoolService: SchoolDropdownService,
        public statesService: StatesDropdownService
    ) {
        this.schoolForm = new FormGroup({
            schoolState: new FormControl(null, Validators.required),
            schoolDistrict: new FormControl(null, Validators.required),
            schoolName: new FormControl(null),
        })
    }

    ngOnInit(): void {
        this.statesService.fetchData$.next()

        this.schoolState.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => {
                    this.isSchoolDistrictDropdownShown = false
                    this.isSchoolDropdownShown = false
                }),
                filter((value) => !!value),
                tap(() => {
                    this.isSchoolDistrictDropdownShown = true
                })
            )
            .subscribe((value: IState) => {
                this.schoolDistrict.setValue(null)

                this.schoolDistrictService.selectedItem$.next(null)
                this.schoolDistrictService.fetchData$.next(value.state_location)
            })

        this.schoolDistrict.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                tap(() => {
                    this.schoolName.disable()
                    this.placeholderText$.next(PLACEHOLDER_LABEL.HAS_NO_SCHOOLS)
                    this.isSchoolDropdownShown = false
                }),
                filter((value) => !!value),
                tap(() => {
                    this.isSchoolDropdownShown = true
                })
            )
            .subscribe((value: ISchoolDistrict) => {
                this.schoolName.setValue(null)

                this.schoolService.selectedItem$.next(null)
                this.schoolService.fetchData$.next(value.district_guid)
            })

        this.schoolService.count$.pipe(takeUntil(this.destroyed$)).subscribe((count) => {
            if (count > 0) {
                this.placeholderText$.next(PLACEHOLDER_LABEL.HAS_SCHOOLS)
                this.schoolName.enable()
            }
        })
    }

    ngOnDestroy(): void {
        this.destroyed$.next()
        this.destroyed$.complete()
    }

    get schoolFormGroup(): FormGroup {
        return this.schoolForm as FormGroup
    }

    get schoolState(): FormControl {
        return this.schoolForm.get("schoolState") as FormControl
    }

    get schoolDistrict(): FormControl {
        return this.schoolForm.get("schoolDistrict") as FormControl
    }

    get schoolName(): FormControl {
        return this.schoolForm.get("schoolName") as FormControl
    }
}
