import { Component, OnDestroy, OnInit } from "@angular/core"
import { DomSanitizer, Title } from "@angular/platform-browser"
import { Router } from "@angular/router"
import { PBSLM_TITLE } from "../models/constants"
import { GTMService } from "../services/gtm.service"
import { UserService } from "../services/user.service"
import { redirectToError } from "../utils/error-handling"
import { Location } from "@angular/common"

declare var window: any

@Component({
    templateUrl: "./embed.component.html",
    styleUrls: ["./embed.component.scss"],
})
export class EmbedComponent implements OnInit, OnDestroy {
    /*
    Scrolling=no is required because on some pages the scroll bars are displayed for 1px even if the content
    height does not need it.
     */
    public embedLocation = null
    private intervalInstance

    constructor(
        private sanitizer: DomSanitizer,
        public userService: UserService,
        private titleService: Title,
        private gtmService: GTMService,
        private router: Router,
        private location: Location
    ) {
        if (window.PBSLM.ERROR_CODE) {
            redirectToError(this.router, this.location, window.PBSLM.ERROR_CODE)
            window.PBSLM.ERROR_CODE = null
            return
        }

        const parsedURL = new URL(window.location.href)

        const firstRequestUrl = document.querySelector("link[rel='canonical']").getAttribute("href")
        const isFirstRouteRequest = firstRequestUrl.endsWith(parsedURL.pathname)
        if (!this.titleService.getTitle() || !isFirstRouteRequest) {
            this.titleService.setTitle(PBSLM_TITLE)
        }

        parsedURL.searchParams.append("embed", "1")
        this.embedLocation = sanitizer.bypassSecurityTrustResourceUrl(parsedURL.href)
        if (parsedURL.pathname.startsWith("/student")) {
            this.userService.setStudentExperience(true)
        }
        // TODO: we don't want to reset for collections because they should have correct backend-rendered dimensions
        // Any users should always reload for collection pages
        const shouldResetDimensions = !parsedURL.pathname.startsWith("/collection")
        if (shouldResetDimensions) {
            this.gtmService.resetDataLayer()
        }
        this.gtmService.pushPageView()
    }

    public ngOnInit() {
        this.detectModalInIframe()
    }

    public ngOnDestroy() {
        window.clearInterval(this.intervalInstance)
    }

    private detectModalInIframe() {
        let scrolled = false
        let openedModal = []
        this.intervalInstance = setInterval(() => {
            const iframe: any = document.querySelector("#asset-content")
            if (!iframe || !iframe.contentDocument) {
                return true
            }
            const modals = Array.from(iframe.contentDocument.querySelectorAll(".modal"))
            if (modals.length) {
                openedModal = modals.filter((modal: any) => {
                    if (window.getComputedStyle(modal).display !== "none") {
                        if (!openedModal.length) {
                            const modalDialog: any = modal.querySelector(".modal-dialog")
                            if (modalDialog) {
                                document.body.scrollTop = modalDialog.offsetTop // For Safari
                                document.documentElement.scrollTop = modalDialog.offsetTop
                                scrolled = false
                            }
                        }
                        return true
                    }
                })
            }
            // used to scroll on the bottom of the page if the drawer is opened.
            if (!openedModal.length) {
                const bottomStickyDrawer = iframe.contentDocument.querySelector(
                    "#bottomStickyDrawer"
                )
                if (bottomStickyDrawer && bottomStickyDrawer.classList.contains("open")) {
                    if (!scrolled) {
                        document.body.scrollTop = document.body.scrollHeight // For Safari
                        document.documentElement.scrollTop = document.body.scrollHeight
                        scrolled = true
                    }
                } else {
                    scrolled = false
                }
            }
        }, 300)
    }
}
