<a
    [class]="getResourcePosterClass()"
    [ngClass]="{ 'inline-card-header': isInline }"
    [attr.title]="title"
    [attr.aria-label]="title ? 'Open ' + title : null"
    [attr.data-gtmlabel]="absoluteUrl"
    [attr.data-gtmresultindex]="gtmDataCardNumber"
    class="gtm-resource-card-poster"
>
    <div
        class="poster-image selenium-card-poster-image"
        [ngStyle]="{ 'background-image': 'url(\'' + posterImage + '\')' }"
    ></div>
    <span *ngIf="mediaType" class="media-type selenium-card-media-type">
        <app-icon class="icon" [type]="mediaType"></app-icon>
        <span class="text">{{ mediaType }}</span>
    </span>
    <div *ngIf="duration" class="video-length selenium-card-video-length">
        {{ getFormattedDuration() }}
    </div>
</a>
