import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { LocalizationService } from "../../services/localization.service"
import { GlobalStoreService } from "../../services/global-store.service"

@Component({
    selector: "app-hero",
    templateUrl: "./hero.component.html",
    styleUrls: ["./hero.component.scss"],
})
export class HeroComponent implements OnInit, OnDestroy {
    public subscriptions: Subscription[] = []
    public stateName: string = null
    public stationDisplayName: string = null
    public isNational: boolean = true
    public heroAggregate: object = {}
    public heroGradient: string = "to left, rgba(10, 20, 90, 0), rgba(10, 20, 90, 0.6) 0%"

    constructor(
        private localizationService: LocalizationService,
        private globalStoreService: GlobalStoreService
    ) {}

    public ngOnInit() {
        this.isNational = this.localizationService.isNational()

        this.subscriptions.push(
            this.localizationService.localizeData.subscribe((response) => {
                if (response && response.homepageHeaderITSImage) {
                    this.heroAggregate = {
                        "background-image":
                            "linear-gradient(" +
                            this.heroGradient +
                            ")," +
                            'url("' +
                            response.homepageHeaderITSImage +
                            "?format=" +
                            this.globalStoreService.imageFormat +
                            '")',
                    }
                }
                if (response && response.stateName) {
                    this.stateName = response.stateName
                }
                this.stationDisplayName = response.getDisplayName()
            })
        )
    }

    public ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe())
    }
}
