import { Injectable, OnDestroy } from "@angular/core"
import { BehaviorSubject, Observable, Subscription, throwError, timer } from "rxjs"
import { catchError, switchMap } from "rxjs/operators"
import Utils from "../utils/utils"
import { LmApiService } from "./lm-api.service"
import { NotificationsService } from "angular2-notifications"

/**
 * Regular polling service for getting
 * the pending assignments that are not started by student
 */
@Injectable({
    providedIn: "root",
})
export class AssignmentsNotStartedService implements OnDestroy {
    private _assignmentsNotStarted = new BehaviorSubject<number | null>(null)
    public readonly assignmentsNotStarted = this._assignmentsNotStarted.asObservable()

    private _timerSubscription: Subscription

    constructor(private lmApi: LmApiService, private notificationService: NotificationsService) {
        this._timerSubscription = this.listenForAssignmentsNotStarted()
    }

    ngOnDestroy(): void {
        this._timerSubscription.unsubscribe()
    }

    public getAssignmentsNotStarted(): Observable<{ count: number }> {
        const url = "/api/v2/student/assignments/count_not_started/"
        return this.lmApi.get(url).pipe(
            catchError((error) => {
                const { name, message } = error
                this.notificationService.error(name, message, Utils.alertConfiguration)
                return throwError(error)
            })
        )
    }

    private listenForAssignmentsNotStarted(): Subscription {
        return timer(0, 90000)
            .pipe(switchMap(() => this.getAssignmentsNotStarted()))
            .subscribe(({ count }) => {
                this._assignmentsNotStarted.next(count)
            })
    }
}
