<app-admin-header *ngIf="!isAddonView"></app-admin-header>
<app-alert-bar *ngIf="!isAddonView"></app-alert-bar>

<header class="white-header gtmsection-header" [ngClass]="{ 'header-index-class': isGateOpened }">
    <div class="container">
        <div class="left-side">
            <div class="logo selenium-header-logo">
                <app-link
                    *ngIf="isTeacherPreview && homeLink"
                    [appRouterLink]="[homeLink]"
                    [queryParams]="isAddonView ? { q: '' } : null"
                    title="Homepage"
                >
                    <img
                        [src]="this.stateName ? this.logoImageState : this.logoImage"
                        alt="PBS LearningMedia logo"
                    />
                </app-link>
                <img
                    *ngIf="!isTeacherPreview || !homeLink"
                    [src]="this.stateName ? this.logoImageState : this.logoImage"
                    alt="PBS LearningMedia logo"
                />
            </div>
            <div [class]="isStudentExperience ? 'student-badge-mark' : 'teacher-badge-mark'">
                FOR {{ isStudentExperience ? "STUDENTS" : "TEACHERS" }}
            </div>
            <ng-container *ngIf="(isAddonView && localizationLogo) || !isAddonView">
                <div
                    *ngIf="
                        (userService.hasStudentExperience() && localizationLogo) ||
                        !userService.hasStudentExperience() ||
                        isHomePage
                    "
                    [ngStyle]="{
                        'background-color': !isHomePage ? 'rgba(0,0,0,0.3)' : ''
                    }"
                    class="vertical-bar"
                ></div>
                <div
                    *ngIf="
                        (userService.hasStudentExperience() && localizationLogo) ||
                        !userService.hasStudentExperience() ||
                        isHomePage
                    "
                    class="localization"
                >
                    <app-localization-confirm
                        *ngIf="!userService.hasStudentExperience()"
                    ></app-localization-confirm>
                    <div
                        #localizationDropdown="ngbDropdown"
                        (click)="closeRightHandMenus()"
                        [autoClose]="true"
                        [ngClass]="{
                            'is-student': userService.hasStudentExperience() && !isHomePage
                        }"
                        class="dropdown"
                        ngbDropdown
                    >
                        <div
                            *ngIf="!localizationLogo && !userService.hasStudentExperience()"
                            class="localize-dropdown selenium-localize-dropdown"
                            ngbDropdownToggle
                        >
                            <button class="text selenium-choose-station-button">
                                Choose Station
                            </button>
                        </div>
                        <div
                            *ngIf="localizationLogo && !userService.hasStudentExperience()"
                            class="localize-dropdown has-logo"
                            [ngClass]="{ 'hide-dropdown-icon': isAddonView }"
                            ngbDropdownToggle
                            tabindex="0"
                        >
                            <img
                                [alt]="fullCommonName + ' logo'"
                                [src]="localizationLogo"
                                class="localize-logo"
                            />
                        </div>
                        <div
                            *ngIf="localizationLogo && userService.hasStudentExperience()"
                            class="localize-dropdown has-logo"
                            tabindex="0"
                        >
                            <img
                                [alt]="fullCommonName + ' logo'"
                                [src]="localizationLogo"
                                class="localize-logo"
                            />
                        </div>
                        <div ngbDropdownMenu *ngIf="!isAddonView">
                            <div class="close-localization-dropdown-top-wrapper">
                                <button (click)="localizationDropdown.close()">
                                    <app-icon [type]="'X'"></app-icon>
                                </button>
                            </div>
                            <app-localization
                                [localizationDropdownRef]="localizationDropdown"
                            ></app-localization>
                            <div class="close-localization-dropdown-bottom-wrapper">
                                <button (click)="localizationDropdown.close()">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div
            class="right-side"
            *ngIf="(isTeacherPreview && !userService.hasStudentExperience()) || !isAddonView"
        >
            <!--Desktop Sign In/Sign Up Section-->
            <div class="auth">
                <ng-container *ngIf="!userService.isLoggedIn(); else userLoggedIn">
                    <app-register
                        *ngIf="userService.hasStudentExperience()"
                        [isStudent]="userService.hasStudentExperience()"
                        [nextURL]="nextUrl"
                    ></app-register>
                    <app-register
                        *ngIf="!userService.hasStudentExperience()"
                        [nextURL]="nextUrl"
                    ></app-register>
                </ng-container>
                <ng-template #userLoggedIn>
                    <div class="logged-in selenium-logged-in" ngbDropdown #myDrop="ngbDropdown">
                        <div class="auth-dropdown" ngbDropdownToggle *ngIf="hasAccessToRightMenu">
                            <span>
                                <app-icon [type]="'User'"></app-icon>
                                <span class="name">
                                    {{ getName() }}
                                </span>
                            </span>
                        </div>
                        <div class="selenium-user-loggedin-dropdown" ngbDropdownMenu>
                            <div *ngIf="!userService.hasStudentExperience()" class="item">
                                <app-link
                                    *ngIf="!isAddonView"
                                    (click)="$event.stopPropagation(); myDrop.close()"
                                    [attr.data-gtmlabel]="'Dashboard'"
                                    appRouterLink="/tools/dashboard/"
                                    class="{{ gtmPrefix + 'dashboard' }} selenium-dashboard"
                                    >Dashboard</app-link
                                >
                            </div>
                            <div
                                *ngIf="!userService.hasStudentExperience() && !isAddonView"
                                class="item"
                            >
                                <app-link
                                    (click)="$event.stopPropagation(); myDrop.close()"
                                    [attr.data-gtmlabel]="'Tools'"
                                    appRouterLink="/tools/lessonbuilder/"
                                    class="{{ gtmPrefix + 'tools' }} selenium-lesson-builder"
                                    >Lesson Builder</app-link
                                >
                            </div>

                            <div class="item" *ngIf="!isAddonView">
                                <app-link
                                    (click)="$event.stopPropagation(); myDrop.close()"
                                    [appRouterLink]="
                                        !userService.hasStudentExperience()
                                            ? '/tools/assignments/'
                                            : '/student/assignments'
                                    "
                                    [attr.data-gtmlabel]="'Assignments'"
                                    class="{{ gtmPrefix + 'assignments' }} selenium-assignments"
                                    >Assignments</app-link
                                >
                            </div>

                            <div class="item" *ngIf="!isAddonView">
                                <app-link
                                    (click)="$event.stopPropagation(); myDrop.close()"
                                    [appRouterLink]="
                                        !userService.hasStudentExperience()
                                            ? '/tools/classes'
                                            : '/student/classes/'
                                    "
                                    [attr.data-gtmlabel]="'Classes'"
                                    class="{{ gtmPrefix + 'rosters' }} selenium-classes"
                                    >Classes</app-link
                                >
                            </div>

                            <div class="item" *ngIf="!isAddonView">
                                <app-link
                                    (click)="$event.stopPropagation(); myDrop.close()"
                                    [attr.data-gtmlabel]="'Favorites'"
                                    appRouterLink="/tools/favorites/"
                                    [queryParams]="{ student: isStudentExperience ? true : null }"
                                    class="{{ gtmPrefix + 'favorites' }} selenium-favorites"
                                    >Favorites</app-link
                                >
                            </div>

                            <div class="item" *ngIf="!isAddonView">
                                <app-link
                                    (click)="$event.stopPropagation(); myDrop.close()"
                                    [attr.data-gtmlabel]="'Folders'"
                                    [queryParams]="{ student: isStudentExperience ? true : null }"
                                    appRouterLink="/tools/folders/"
                                    class="{{ gtmPrefix + 'folders' }} selenium-folders"
                                >
                                    Folders</app-link
                                >
                            </div>
                            <div *ngIf="!userService.hasStudentExperience()" class="item">
                                <app-link
                                    (click)="$event.stopPropagation(); myDrop.close()"
                                    [attr.data-gtmlabel]="'Profile'"
                                    [appRouterLink]="isAddonView ? '/add-on/profile' : '/profile/'"
                                    class="{{ gtmPrefix + 'profile' }} selenium-profile"
                                    >Profile</app-link
                                >
                            </div>
                            <div class="item">
                                <a
                                    (click)="$event.stopPropagation(); myDrop.close()"
                                    [attr.data-gtmlabel]="'Logout'"
                                    [href]="logOutUrl"
                                    class="{{ gtmPrefix + 'logout selenium-logout' }}"
                                    >Sign Out
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>

            <!--Search Section-->
            <div class="search" *ngIf="hasAccessToRightMenu()">
                <app-external-search-input
                    [placeholder]="'Search'"
                    [type]="'for-header'"
                ></app-external-search-input>
            </div>

            <!--Mobile Profile and Search Burger-->
            <div class="burger-menu-icon" *ngIf="isTeacherPreview">
                <app-icon
                    (click)="toggleMenu(); $event.stopPropagation()"
                    *ngIf="!showBurgerMenu"
                    [type]="'Menu'"
                    class="menu-icon"
                ></app-icon>
                <app-icon
                    (click)="toggleMenu(); $event.stopPropagation()"
                    *ngIf="showBurgerMenu"
                    [type]="'X'"
                ></app-icon>
            </div>
            <div class="auth-menu-icon" *ngIf="isTeacherPreview">
                <app-icon
                    (click)="toggleMenu('user-menu'); $event.stopPropagation()"
                    *ngIf="!showUserMenu"
                    [type]="'User'"
                ></app-icon>
                <app-icon
                    (click)="toggleMenu('user-menu'); $event.stopPropagation()"
                    *ngIf="showUserMenu"
                    [type]="'X'"
                ></app-icon>
            </div>
        </div>
    </div>

    <div class="mobile-menu">
        <div
            *ngIf="isStudentExperience"
            class="user-badge-bar d-flex justify-content-center gtm-change-grade-gate"
        >
            FOR STUDENTS
            <span
                class="grades-dropdown ms-2"
                (click)="toggleGate(false)"
                [ngClass]="{ 'closed-grade-modal': !isGateOpened }"
            >
                GRADES
                {{
                    studentGateService.selectedGradeValue
                        ? ": " + studentGateService.selectedGradeValue
                        : ""
                }}
                <span [class]="isGateOpened ? 'arrow-up' : 'arrow-down'"></span>
            </span>
        </div>

        <!--Auth User Menu-->
        <div
            *ngIf="showUserMenu && userService.isLoggedIn()"
            appClickOutside
            (clickOutside)="closeRightHandMenus()"
            class="user-menu container"
        >
            <div *ngIf="userService.hasStudentExperience()" class="pt-5">
                <app-code-input></app-code-input>
            </div>
            <div *ngIf="!userService.hasStudentExperience()" class="item">
                <app-link
                    [attr.data-gtmlabel]="'Profile'"
                    [appRouterLink]="isAddonView ? '/add-on/profile/' : '/profile/'"
                    class="gtm-header-dropdown-profile fw-bold"
                    >Profile</app-link
                >
            </div>
            <div *ngIf="!userService.hasStudentExperience() && !isAddonView" class="item">
                <app-link
                    [attr.data-gtmlabel]="'Dashboard'"
                    appRouterLink="/tools/dashboard/"
                    class="{{ gtmPrefix + 'dashboard' }} fw-bold"
                    >Dashboard</app-link
                >
            </div>
            <ng-container *ngIf="hasAccessToRightMenu() && userService.hasStudentExperience()">
                <div class="item student-item">
                    <app-link
                        [appRouterLink]="'/student/assignments'"
                        [attr.data-gtmlabel]="'Assignments'"
                        [badgeCount]="assignmentsNotStarted"
                        class="gtm-header-dropdown-assignments fw-bold"
                        >Assignments
                    </app-link>
                </div>

                <div class="item student-item">
                    <app-link
                        [appRouterLink]="'/student/classes/'"
                        [attr.data-gtmlabel]="'Classes'"
                        class="gtm-header-dropdown-rosters fw-bold"
                        >Classes</app-link
                    >
                </div>
                <div class="item student-item">
                    <app-link
                        [attr.data-gtmlabel]="'Favorites'"
                        appRouterLink="/tools/favorites/"
                        class="gtm-header-dropdown-favorites fw-bold"
                        >Favorites</app-link
                    >
                </div>

                <div class="item student-item">
                    <app-link
                        [attr.data-gtmlabel]="'Folders'"
                        appRouterLink="/tools/folders"
                        class="gtm-header-dropdown-folders fw-bold"
                        >Folders</app-link
                    >
                </div>
            </ng-container>
            <div class="item student-item" *ngIf="userService.hasStudentExperience()">
                <app-link [href]="logOutUrl" class="gtm-header-dropdown-folders fw-bold"
                    >Sign out</app-link
                >
            </div>

            <div *ngIf="!userService.hasStudentExperience()" class="item">
                <a
                    [attr.data-gtmlabel]="'Logout'"
                    [href]="logOutUrl"
                    class="gtm-header-dropdown-logout fw-bold"
                    >Sign out</a
                >
            </div>
        </div>

        <!--Burger Search Menu-->
        <div
            *ngIf="showBurgerMenu"
            appClickOutside
            (clickOutside)="closeRightHandMenus()"
            class="nav-menu container"
        >
            <div *ngIf="userService.hasStudentExperience()" class="mb-4">
                <app-code-input></app-code-input>
            </div>

            <div class="item nav-menu-search">
                <app-external-search-input
                    [placeholder]="'Search'"
                    [type]="'for-header'"
                ></app-external-search-input>
            </div>

            <div class="item">
                <app-browse-by></app-browse-by>
            </div>

            <div *ngIf="!userService.isLoggedIn()" class="pb-4">
                <p *ngIf="userService.hasStudentExperience()" class="text-center my-6">
                    Access your Assignments, Classes, and Favorites by signing in.
                </p>
                <!--Student Sign In/Sign Up-->
                <app-register
                    *ngIf="userService.hasStudentExperience()"
                    [isStudent]="userService.hasStudentExperience()"
                    [nextURL]="nextUrl"
                ></app-register>

                <!--Teacher Sign In/Sign Up-->
                <app-register
                    *ngIf="!userService.hasStudentExperience()"
                    [nextURL]="nextUrl"
                ></app-register>
            </div>
        </div>
    </div>
</header>

<!--Teacher and student desktop menus-->
<ng-container *ngIf="!userService.hasStudentExperience(); then teacherMenu; else studentMenu">
</ng-container>
<ng-template #teacherMenu>
    <div class="user-badge-bar">FOR TEACHERS</div>
    <app-main-navigation></app-main-navigation>
</ng-template>
<ng-template #studentMenu>
    <div class="user-badge-bar">FOR STUDENTS</div>
    <div class="student-menu-container gtmsection-student-menu-header">
        <app-student-menu *ngIf="!isAddonView" [badgeCount]="assignmentsNotStarted">
        </app-student-menu>
        <div class="student-divider" *ngIf="isAddonView"></div>
    </div>
</ng-template>
<ng-container>
    <div class="gate-container" *ngIf="isGateOpened && isTablet">
        <app-grade-gate-modal class="gtmsection-student-gate" (closeModal)="toggleGate(true)">
        </app-grade-gate-modal>
    </div>
</ng-container>
