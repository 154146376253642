import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from "@angular/core"
import { DataPrivacyAcknowledgmentComponent } from "../../data-privacy/data-privacy-select/data-privacy-acknowledgment/data-privacy-acknowledgment.component"
import { DataPrivacyModalsService } from "../../../services/data-privacy-modals.service"

@Component({
    selector: "app-sign-in",
    template: `
        <app-data-privacy-acknowledgment></app-data-privacy-acknowledgment>
        <section class="p-4 mt-4" [ngStyle]="{ background: '#EEF4F4' }">
            Note: If you do not acknowledge the privacy acknowledgement, you will still be able to
            share our resources with students and build Lesson Builder lessons without PBS
            collecting student personal information.
        </section>

        <div class="d-flex justify-content-start mt-6">
            <button
                type="button"
                class="btn btn-outline-primary py-2 px-4"
                (click)="dataPrivacyModalsService.previousModal()"
            >
                &lt; Previous
            </button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent implements AfterViewInit {
    @ViewChild(DataPrivacyAcknowledgmentComponent)
    dataPrivacyAcknowledgmentRef: DataPrivacyAcknowledgmentComponent

    form = this.dataPrivacyModalsService.getForm()

    constructor(public dataPrivacyModalsService: DataPrivacyModalsService) {}

    ngAfterViewInit(): void {
        this.form.addControl("privacyCheck", this.dataPrivacyAcknowledgmentRef.privacyCheckControl)
    }
}
