<div class="hero">
    <app-hero></app-hero>
</div>
<app-featured></app-featured>
<app-disciplines></app-disciplines>
<app-promotional-ads></app-promotional-ads>
<app-collections></app-collections>
<app-recommendations></app-recommendations>
<app-available-features></app-available-features>
<div class="container">
    <app-localization [addWrapper]="true"></app-localization>
</div>
