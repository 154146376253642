import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, of } from "rxjs"
import { Resource } from "../models/resource/resource"

@Injectable()
export class PreviewService {
    private _previewOnResource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    private _previewOnCollection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    private _previewOnSubCollection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    private _resource$: BehaviorSubject<Resource> = new BehaviorSubject<Resource>(null)
    public collectionId: number
    public subCollectionId: number
    public subCollectionType: string

    get previewOnResource$(): Observable<boolean> {
        return this._previewOnResource.asObservable()
    }

    get previewOnResource(): boolean {
        return this._previewOnResource.value
    }

    set previewOnResource(value: boolean) {
        this._previewOnResource.next(value)
    }

    get previewOnCollection$(): Observable<boolean> {
        return this._previewOnCollection.asObservable()
    }

    get previewOnCollection(): boolean {
        return this._previewOnCollection.value
    }

    set previewOnCollection(value: boolean) {
        this._previewOnCollection.next(value)
    }

    get previewOnSubCollection$(): Observable<boolean> {
        return this._previewOnSubCollection.asObservable()
    }

    get previewOnSubCollection(): boolean {
        return this._previewOnSubCollection.value
    }

    set previewOnSubCollection(value: boolean) {
        this._previewOnSubCollection.next(value)
    }

    get resource(): Resource {
        return this._resource$.value
    }

    get resource$(): Observable<Resource> {
        return this._resource$.asObservable()
    }

    set resource(value: Resource) {
        this._resource$.next(value)
    }
}
