<app-modal-header [title]="'Assignment code'"></app-modal-header>
<div class="modal-body pt-0">
    <div class="description mb-0">
        Using this Quick Assign code in the link, students can complete the assignment without
        signing in. They will be prompted to enter their name and their submitted work will be
        available under “Quick Assign.”
    </div>

    <app-copy-link
        [isButton]="true"
        [shareLink]="shareLink"
        class="d-inline-block copy-link-component py-3"
    ></app-copy-link>
    <div class="d-flex justify-content-end">
        <button (click)="navigateToAwaitingTab()" class="btn btn-primary px-5 py-3">
            View Assignments
        </button>
    </div>
</div>
