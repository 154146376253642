import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

@Component({
    selector: "app-input-carousel",
    templateUrl: "./input-carousel.component.html",
    styleUrls: ["./input-carousel.component.scss"],
})
export class InputCarouselComponent implements OnInit {
    @Input() public items: string[] = []
    @Output() public itemChanged = new EventEmitter<string>()

    public selectedItem = { idx: 0, item: "" }

    constructor() {}

    public ngOnInit() {
        if (this.items) {
            this.selectedItem.item = this.items[0]
            this.itemChanged.emit(this.selectedItem.item)
        }
    }

    public next() {
        if (this.hasNext()) {
            this.selectedItem.idx++
            this.selectedItem.item = this.items[this.selectedItem.idx]
            this.itemChanged.emit(this.selectedItem.item)
        }
    }

    public prev() {
        if (this.hasPrev) {
            this.selectedItem.idx--
            this.selectedItem.item = this.items[this.selectedItem.idx]
            this.itemChanged.emit(this.selectedItem.item)
        }
    }

    public hasNext() {
        return this.selectedItem.idx <= this.items.length
    }

    public hasPrev() {
        return this.selectedItem.idx > 0
    }
}
