import { FolderItem } from "../../../models/folders.model"

export class AssignmentListItem implements FolderItem {
    public detailLink
    public editLink

    constructor(
        public id: number,
        public title: string,
        public code: string,
        public assignedDate: Date,
        public createdDate: Date,
        public isAssigned: boolean,
        public isILP: boolean,
        public viewAsStudentLink: string
    ) {
        this.detailLink = isAssigned ? `/tools/assignments/${this.code}` : null
        this.editLink = `/tools/lessonbuilder/edit/${this.code}`
    }

    public static fromData(data, isAssigned): AssignmentListItem {
        return new AssignmentListItem(
            data.id,
            data.title,
            data.code,
            data.assigned ? new Date(data.assigned) : null,
            new Date(data.date_created),
            isAssigned,
            data.is_ilp,
            data.preview_link
        )
    }

    public makeCopyData() {
        return {
            name: "copy of " + this.title,
        }
    }

    get folderItemId() {
        return this.id
    }

    get folderItemType() {
        return 1
    }

    get folderItemTypeKeyName() {
        return "lesson_ids"
    }
}

export class AssignmentResults {
    constructor(
        public title: string,
        public code: string,
        public hasQuestions: boolean,
        public isILPAssignment: boolean,
        public assignmentURL: string,
        public classes: ClassAssignmentResult[]
    ) {}

    public static fromData(data): AssignmentResults {
        const classes = data.classes.map((classData) =>
            ClassAssignmentResult.fromData(classData, false)
        )
        if (data.qa_results?.results) {
            classes.push(ClassAssignmentResult.fromData(data.qa_results, true))
        }
        return new AssignmentResults(
            data.title,
            data.code,
            data.has_questions,
            data.is_ilp,
            data.assignment_url,
            classes
        )
    }
}

export class ClassAssignmentResult {
    constructor(
        public id: number,
        public name: string,
        public isQuickAssign: boolean,
        public downloadCSVLink: string,
        public downloadGoogleSheetLink: string,
        public results: StudentResult[]
    ) {
        if (isQuickAssign) {
            this.name = "No class (Quick Assign)"
        }
    }

    public static fromData(data, isQuickAssign): ClassAssignmentResult {
        return new ClassAssignmentResult(
            data.id,
            data.name,
            isQuickAssign,
            data.download_csv,
            data.download_google_sheet,
            data.results.map((result) => StudentResult.fromData(result))
        )
    }
}

export class StudentResult {
    constructor(
        public studentName: string,
        public assignedVia: AssignViaType,
        public status: ResultStatus,
        public submittedDate: Date,
        public score: number,
        public ILPWorkURL: string,
        public id: number
    ) {}

    public hasResults() {
        return this.status === ResultStatus.COMPLETE || this.status === ResultStatus.IN_PROGRESS
    }

    public static fromData(data): StudentResult {
        return new StudentResult(
            data.student_name,
            data.assigned_via === "GC" ? AssignViaType.GC : AssignViaType.LM,
            ResultStatusUtil.fromString(data.status),
            data.submission_date ? new Date(data.submission_date) : null,
            data.score,
            data.open_ilp_work_url,
            data.id
        )
    }
}

export class LessonBuilderStudentResult {
    constructor(
        public studentName: string,
        public assignmentTitle: string,
        public submitted: Date,
        public PDFDownloadLink: string,
        public questions: LessonBuilderQuestionAnswer[]
    ) {}

    public static fromData(data): LessonBuilderStudentResult {
        return new LessonBuilderStudentResult(
            data.student_name,
            data.assignment_title,
            new Date(data.submitted) ? data.submitted : null,
            data.download_link,
            (data.questions || []).map((qData) => LessonBuilderQuestionAnswer.fromData(qData))
        )
    }
}

export class LessonBuilderQuestionAnswer {
    constructor(public text: string, public answer: string, public correct: boolean) {}

    public static fromData(data): LessonBuilderQuestionAnswer {
        return new LessonBuilderQuestionAnswer(data.text, data.answer, data.is_correct)
    }
}

export enum AssignViaType {
    GC,
    LM,
}

export enum ResultStatus {
    NOT_STARTED,
    IN_PROGRESS,
    COMPLETE,
}

export class ResultStatusUtil {
    public static fromString(value) {
        switch (value) {
            case "not_started":
                return ResultStatus.NOT_STARTED
            case "started":
                return ResultStatus.IN_PROGRESS
            default:
                return ResultStatus.COMPLETE
        }
    }
}

export class StudentLessonResultResponse {
    assignmentResults: LessonBuilderStudentResult | null
    error: string | null
}

export class AssignmentResultsResponse {
    assignmentResults: AssignmentResults | null
    error: string | null
}
