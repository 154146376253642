<div class="carousel-wrapper" [ngStyle]="{ height: height + 'px' }">
    <div
        *ngIf="showLeftArrow()"
        [ngClass]="{ inner: innerArrows }"
        [class]="getLeftArrowClass()"
        data-gtmlabel="Left"
        (click)="prevItem(itemsToScrollOnClick)"
    >
        <app-icon [type]="'Arrow-left'"></app-icon>
    </div>

    <div
        class="canvas"
        #canvas
        (swipeleft)="nextItem(itemsToScrollOnSwipe)"
        (swiperight)="prevItem(itemsToScrollOnSwipe)"
    >
        <ng-content></ng-content>
    </div>

    <div
        *ngIf="showRightArrow()"
        [ngClass]="{ inner: innerArrows }"
        [class]="getRightArrowClass()"
        data-gtmlabel="Right"
        (click)="nextItem(itemsToScrollOnClick)"
    >
        <app-icon [type]="'Arrow-right'"></app-icon>
    </div>
</div>
