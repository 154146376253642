import { Injectable } from "@angular/core"
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Params,
    Router,
    RouterStateSnapshot,
} from "@angular/router"
import Utils from "../utils/utils"
import { StudentGateService } from "../services/student-gate.service"

@Injectable()
export class StudentGateSearchGuard implements CanActivate {
    constructor(private router: Router, private studentGateService: StudentGateService) {}
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const searchRoute: string = this.defineSearchRoute(route)
        const selectedGrade: string =
            sessionStorage.getItem("grade-gate") || localStorage.getItem("grade-gate")
        if (!Utils.hasStudentExperience()) return true
        //if the grade is5orSmaller the excluded facet param will persist
        // and this is entering an infinite loop from home page -> search page
        // and the next line index var is the solution
        const queryParams = this.buildQueryParams(route)
        if (selectedGrade?.length && Object.values(route.queryParams)?.length <= 2) {
            this.router.onSameUrlNavigation = "ignore"
            if (this.studentGateService.collectionPageChoice.value !== "All grades")
                this.router.navigate([searchRoute], {
                    queryParams: queryParams,
                    queryParamsHandling: "merge",
                })
            return true
        } else if (this.isDisplayModalRequired(selectedGrade, route)) {
            this.studentGateService.openStudentGateModal()
            return true
        } else {
            return true
        }
    }

    public buildQueryParams(route) {
        const selectedGrade: string = localStorage.getItem("grade-gate")
        const avoidSettingGradeBand: boolean = !!sessionStorage.getItem("avoid-setting-grade-band")
        let queryParams = {
            ...route.queryParams,
            q: route.queryParams.q ? route.queryParams.q : "",
            student: true,
            selected_facet: route.queryParams.selected_facet,
        }

        if (
            !avoidSettingGradeBand &&
            selectedGrade &&
            this.studentGateService.collectionPageChoice.value !== "All grades"
        ) {
            queryParams = {
                ...queryParams,
                selected_facet: route.queryParams.selected_facet
                    ? this.handleSelectedFacetArray(route.queryParams, selectedGrade)
                    : "grades:" + selectedGrade,
            }
        }

        if (!queryParams.selected_facet) {
            delete queryParams.selected_facet
        }

        sessionStorage.removeItem("avoid-setting-grade-band")
        return queryParams
    }

    handleSelectedFacetArray(selectedFacetParams: Params, selectedGrade: string): [] {
        return selectedFacetParams.selected_facet.includes("grades:" + selectedGrade)
            ? selectedFacetParams.selected_facet
            : [selectedFacetParams.selected_facet, "grades:" + selectedGrade]
    }

    public isDisplayModalRequired(
        selectedGrade: string | null,
        nextRoute: ActivatedRouteSnapshot
    ): boolean {
        return (
            !selectedGrade &&
            !this.studentGateService.isLgModalDisplayed &&
            (this.studentGateService.isEmptySearch(nextRoute.queryParams) ||
                this.router.getCurrentNavigation().id > 1)
        )
    }

    defineSearchRoute(route: ActivatedRouteSnapshot) {
        return route.routeConfig.path === ":slug/search"
            ? "/collection/" + route.params.slug + "/search/"
            : "/search/"
    }
}
