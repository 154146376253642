import { BaseNode } from "./node.model"

export class FacetNode {
    public checked: boolean = false
    public expanded: boolean = false
    public facetId: string
    public gtmLabel: string
    public hasParents: boolean
    public indeterminate: boolean = false
    public isNested: boolean

    constructor(public node: BaseNode) {
        this.initProps()
    }

    public initProps() {
        this.facetId = FacetNode.getFacetId(this.node)
        this.gtmLabel = `${this.node.title}-${this.node.id}`
        this.hasParents = !!this.node.parents.length
        this.isNested = !!this.node.children.length
    }

    public static getFacetId(node: BaseNode): string {
        return `${node.type}-${node.id}`
    }
}

export class Facet<T extends BaseNode> {
    private checkedNodes: string[] = []
    public facetNodes: Record<string, FacetNode> = {}
    public filterTypes: string[] = []

    constructor(public id: string, public name: string = "") {}

    public topLevel(): FacetNode[] {
        return Object.values(this.facetNodes).filter((fn) => !fn.hasParents)
    }

    public childrenOf(facetId: string): FacetNode[] {
        return this.facetNodes[facetId].node.children.map((child) => {
            const childFacetID: string = FacetNode.getFacetId(child)
            return this.facetNodes[childFacetID]
        })
    }

    public setNodes(nodes: T[]) {
        nodes.forEach((node: T) => {
            this.facetNodes[FacetNode.getFacetId(node)] = this.processTree(node)
        })
        const uniqueTypes: Set<string> = new Set(
            Object.values(this.facetNodes).map((fn) => fn.node.type)
        )
        this.filterTypes = Array.from(uniqueTypes)
    }

    private processTree(node: T): FacetNode {
        node.children.forEach((child: T) => {
            this.facetNodes[FacetNode.getFacetId(child)] = this.processTree(child)
        })
        return new FacetNode(node)
    }

    public checkNode(facetId: string) {
        const facetNode = this.facetNodes[facetId]
        if (!facetNode) {
            return
        }
        this.checkedNodes.push(facetId)
        facetNode.checked = true
        facetNode.node.parents.forEach((parent) => {
            this.expandNode(FacetNode.getFacetId(parent))
        })
    }

    public uncheckNodes() {
        this.checkedNodes.forEach((facetId: string) => {
            this.facetNodes[facetId].checked = false
            this.facetNodes[facetId].node.parents.forEach((parent) => {
                const parentFacetID = FacetNode.getFacetId(parent)
                const parentFacetNode = this.facetNodes[parentFacetID]
                if (!parentFacetNode) {
                    return
                }
                parentFacetNode.indeterminate = false
            })
        })
        this.checkedNodes = []
    }

    public isAnyNodeChecked(): boolean {
        return !!this.checkedNodes.length
    }

    public isNodeChecked(facetId: string): boolean {
        const facetNode = this.facetNodes[facetId]
        return !!(facetNode && facetNode.checked)
    }

    public expandNode(facetId: string) {
        const facetNode = this.facetNodes[facetId]
        if (!facetNode) {
            return
        }
        facetNode.expanded = true
        facetNode.indeterminate = facetNode.node.children.every((child) =>
            this.isNodeChecked(FacetNode.getFacetId(child))
        )
        facetNode.node.parents.forEach((parent) => {
            this.expandNode(FacetNode.getFacetId(parent))
        })
    }
}
