import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Observable, throwError } from "rxjs"
import { CookiesService } from "./cookies.service"
import { NotificationsService } from "angular2-notifications"
import Utils from "../utils/utils"
import { Injectable } from "@angular/core"
import { APIResponse } from "../models/apiResponse.model"
import { Resource } from "../models/resource/resource"

declare var window: any

@Injectable()
export class LmApiService {
    public optionalQueryParamCookies = ["user_content_permission", "logged_in"]
    public explicitQueryParamCookies = ["student"]
    public apiKey = window.PBSLM.NG.API_KEY
    public studentExperience = window.PBSLM.NG.IS_STUDENT
    private _adminDisplay = window.PBSLM.NG.ADMIN_DISPLAY
    private _isSU = window.PBSLM.NG.IS_SU

    constructor(
        private http: HttpClient,
        private cookieService: CookiesService,
        private notificationService: NotificationsService
    ) {}

    /*
     * We want to use the national subdomain for relative urls
     * to make sure the url hits the cache
     * */
    public makeAPIUrl(url) {
        if (!url) return window.PBSLM.NATIONAL_SITE_URL
        if (url.startsWith("http://") || url.startsWith("https://")) return url
        if (url.startsWith("/")) url = url.slice(1)
        return window.PBSLM.NATIONAL_SITE_URL + url
    }

    // TODO: We can accomplish this in an interceptor and reduce this abstraction.
    public get(url: string, options: any = {}): Observable<any> {
        this.setHeaders(options)
        url = this.addStudentInUrl(url)
        return this.http.get(url, options)
    }

    // TODO: The reason we have these functions is to put an auth header on the requests.
    public post(url: string, data, params: any = {}): Observable<any> {
        this.setHeaders(params, true)
        return this.http.post(url, data, params)
    }

    public put(url: string, data, params: any = {}) {
        this.setHeaders(params, true)
        return this.http.put(url, data, params)
    }

    public delete(url: string, params: any = {}) {
        this.setHeaders(params, true)
        return this.http.delete(url, params)
    }

    // cookies used for handling/caching on cdn - use this for large responses
    public getWithCookiesInParams(
        url: string,
        cookiesToSend: string[] = [],
        params: any = {},
        shouldSetUrlToDomain = true
    ) {
        if (shouldSetUrlToDomain) url = this.makeAPIUrl(url)
        url = this.sendCookiesAsQueryParams(url, cookiesToSend)
        this.setHeaders(params)
        url = this.addStudentInUrl(url)
        return this.http.get(url, params)
    }

    // cookies used for handling/caching on cdn - use this for large responses
    public getWithCookiesInParamsAll(url: string, params: any = {}): Observable<any> {
        let cookiesToSend = this.optionalQueryParamCookies

        url = this.makeAPIUrl(url)
        url = this.sendCookiesAsQueryParams(url, cookiesToSend)
        this.setHeaders(params)
        url = this.addStudentInUrl(url)
        return this.http.get(url, params)
    }

    public handleErrorForPageLoading(error) {
        let message, title
        switch (error.status) {
            case 403:
                title = "Invalid Access"
                message = "Please log in and refresh the page."
                break
            case 500:
                title = "Internal Error"
                message = "Please try again later or create a support ticket."
                break
            default:
                title = "Something went wrong"
                message = "Please try again later or contact us."
                break
        }
        this.notificationService.error(title, message, Utils.alertConfiguration)
        return throwError(error)
    }

    public handleErrorForDeleteItem(error) {
        this.notificationService.error(
            "Could not delete",
            "Please try again later or contact us.",
            Utils.alertConfiguration
        )
        return throwError(error)
    }

    public handleErrorForNonCritical(error) {
        this.notificationService.error(
            "Something went wrong",
            typeof error === "string" ? `${error}` : "Please try again later or contact us.",
            Utils.alertConfiguration
        )
        return throwError(error)
    }

    public showValidationErrors(errors, defaultMessage = "", title = "Error") {
        let message = Object.entries(errors)
            .map(([key, value]) => `${key}: ${value}`)
            .join(". ")
        this.notificationService.error(title, message || defaultMessage, Utils.alertConfiguration)
    }

    private userHasAdminDisplay() {
        // This is duplicate functionality of UserService.hasAdminDisplay() to avoiding circular dependency
        return this._adminDisplay || this._isSU
    }

    // if options contain headers they should be plain object because get converts them to HttpHeaders
    private setHeaders(options, includeCsrf: boolean = false) {
        const headAuth = {
            Authorization: "Bearer " + this.apiKey,
        }

        let headCsrf = {}
        if (includeCsrf) {
            headCsrf = {
                "X-CSRFTOKEN": this.cookieService.getCookieValue("csrftoken_v2"),
            }
        }

        if (options.headers) {
            options.headers = { ...headCsrf, ...headAuth, ...options.headers }
        } else {
            options.headers = { ...headCsrf, ...headAuth }
        }
        options.headers = new HttpHeaders(options.headers)
    }

    private sendCookiesAsQueryParams(url, cookies: string[]) {
        if (url.indexOf("?") == -1) {
            url += "?"
        }

        cookies.forEach((cookie_name) => {
            let cookie = this.cookieService.getCookie(cookie_name)
            if (cookie && cookie.value) {
                if (!url.endsWith("?")) {
                    url += "&"
                }
                url += "cookie:" + cookie.key + "=" + cookie.value
            } else if (this.explicitQueryParamCookies.indexOf(cookie_name) > -1) {
                if (cookie_name == "student") {
                    if (!url.endsWith("?")) {
                        url += "&"
                    }
                    url += "cookie:student=" + this.studentExperience
                }
            }
        })

        if (url.endsWith("?")) {
            // no actual cookie was added
            url = url.slice(0, -1)
        }

        return url
    }

    private addStudentInUrl(url) {
        if (this.studentExperience) {
            return url + (url.includes("?") ? "&student=true" : "?student=true")
        }
        return url
    }
}
