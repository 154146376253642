<form [formGroup]="schoolForm" class="pt-4">
    <!-- States Dropdown Selector -->
    <p class="font-size-14 text-navy-blue fw-bold mb-2">
        State <span class="required-field">(Required)</span>
    </p>
    <div class="row">
        <div class="col-12">
            <ng-select
                formControlName="schoolState"
                [items]="statesService.data$ | async"
                [loading]="statesService.isLoading$ | async"
                (change)="statesService.selectedItem$.next($event)"
                (open)="statesService.fetchData$.next()"
                (clear)="statesService.fetchData$.next()"
                class="custom-dropdown gtm-select-school-state"
                placeholder="Select state"
                bindLabel="name"
                loadingText="Loading..."
            ></ng-select>
        </div>
    </div>

    <br />

    <!-- School district selector -->
    <ng-container *ngIf="isSchoolDistrictDropdownShown">
        <p class="font-size-14 text-navy-blue fw-bold mb-2">
            School district <span class="required-field">(Required)</span>
        </p>
        <p class="font-size-16 mb-2">
            Enter your school district’s name (If you teach at a charter school, try entering its
            name).
        </p>
        <div class="row">
            <div class="col-12">
                <div class="mb-2">
                    <ng-select
                        formControlName="schoolDistrict"
                        [items]="schoolDistrictService.data$ | async"
                        [loading]="schoolDistrictService.isLoading$ | async"
                        [typeahead]="schoolDistrictService.searchTerm$"
                        [trackByFn]="schoolDistrictService.trackByFn"
                        (change)="schoolDistrictService.selectedItem$.next($event)"
                        (open)="
                            schoolDistrictService.fetchData$.next(schoolState.value.state_location)
                        "
                        (clear)="
                            schoolDistrictService.fetchData$.next(schoolState.value.state_location)
                        "
                        (scrollToEnd)="schoolDistrictService.scrolledToEnd$.next()"
                        class="custom-dropdown gtm-profile-select-district"
                        placeholder="Enter district name"
                        bindLabel="district_name"
                        loadingText="Loading..."
                    >
                    </ng-select>
                </div>
            </div>
        </div>

        <p class="font-size-12 light-grey mb-2">
            District data from Common Core of Data, via Education Data Portal v. 0.15.0, Urban
            Institute, under
            <a
                href="https://opendatacommons.org/licenses/by/1-0/"
                target="_blank"
                rel="noopener noreferrer"
            >
                ODC Attribution License.
            </a>
        </p>

        <app-data-privacy-support [formStackUrl]="formStackUrl"
            >Can’t find your school info?</app-data-privacy-support
        >
    </ng-container>

    <!-- School selector-->
    <ng-container *ngIf="isSchoolDropdownShown">
        <div class="row">
            <div class="col-12 col-md-8">
                <p class="font-size-14 text-navy-blue fw-bold mb-2">School</p>
                <div class="mb-2">
                    <ng-select
                        formControlName="schoolName"
                        class="gtm-profile-select-school"
                        [items]="schoolService.data$ | async"
                        [loading]="schoolService.isLoading$ | async"
                        [typeahead]="schoolService.searchTerm$"
                        [placeholder]="placeholderText$ | async"
                        [trackByFn]="schoolService.trackByFn"
                        (open)="schoolService.fetchData$.next(schoolDistrict.value.district_guid)"
                        (clear)="schoolService.fetchData$.next(schoolDistrict.value.district_guid)"
                        (change)="schoolService.selectedItem$.next($event)"
                        (scrollToEnd)="schoolService.scrolledToEnd$.next()"
                        bindLabel="name"
                    >
                    </ng-select>
                </div>
            </div>
        </div>
    </ng-container>
</form>
