import { ErrorCode } from "../../error-code.model"

export const standardErrorMessages = {
    [ErrorCode.Error404]:
        "We can't seem to find the page you're looking for. Check to see if that address is correct or try search again.",
    [ErrorCode.Error500]:
        "Something went wrong when trying to display this page. Sorry for the inconvenience. We are working to fix the issue.\n" +
        "In the meantime, explore new resources on our home page, or browse by subject below. You could also try searching for something similar.",
    [ErrorCode.Error401]: "You must be signed in to view this page. Please sign in below.",
    [ErrorCode.Error403]:
        "This page is not available. Try searching for something similar, or check out our home page for featured resources and collections..",
}
