import { Component, Input } from "@angular/core"
import { CardMetaComponent } from "../card-meta.component"

@Component({
    selector: "app-browse-card-meta",
    templateUrl: "./browse-card-meta.component.html",
    styleUrls: ["./browse-card-meta.component.scss"],
})
export class BrowseCardMetaComponent extends CardMetaComponent {
    @Input() public gtmDataCardNumber: number
}
