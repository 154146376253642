import { Injectable } from "@angular/core"
import { catchError, map } from "rxjs/operators"
import { LmApiService } from "./lm-api.service"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Folder, FolderItem, SelectableFolder } from "../models/folders.model"
import { AssignmentListItem } from "../tools/teacher-assignments/models/teacherAssignments.model"

@Injectable()
export class FoldersService {
    constructor(private lmApiService: LmApiService, private http: HttpClient) {}

    public getFoldersForItem(item: FolderItem, folderItemId: number) {
        return this.lmApiService
            .get(`/get_folders/${item.folderItemType}/${folderItemId}/`, {
                responseType: "json",
            })
            .pipe(
                map(
                    (response) => {
                        return {
                            folders: (response.folders || []).map(
                                (data) => new SelectableFolder(Folder.fromData(data), data.assigned)
                            ),
                            rootFolderId: response.root?.folder_id,
                        }
                    },
                    catchError((error) => this.lmApiService.handleErrorForNonCritical(error))
                )
            )
    }

    public createNewFolder(name: string, parent: number) {
        const formData = new FormData()
        formData.append("name", name)
        formData.append("description", "")
        formData.append("parent", parent.toString())
        return this.lmApiService
            .post("/new_folder/", formData)
            .pipe(catchError((error) => this.lmApiService.handleErrorForNonCritical(error)))
    }

    public addToFolder(folderId: number, keyName: string, itemId: number) {
        const data = { folder_pk: folderId, [keyName]: [itemId] }
        return this.lmApiService
            .post("/add_to_folder/", JSON.stringify(data))
            .pipe(catchError((error) => this.lmApiService.handleErrorForNonCritical(error)))
    }

    public removeFromFolder(folderId: number, keyName: string, itemId: number) {
        const data = { folder_pk: folderId, [keyName]: [itemId] }
        return this.lmApiService
            .post("/remove_from_folder/", JSON.stringify(data))
            .pipe(catchError((error) => this.lmApiService.handleErrorForNonCritical(error)))
    }
}
