import { Link } from "./link.model"

/**
 * Model used to map data needed for admin header component
 */
export class AdminHeaderData {
    public hasAdminDisplay: boolean
    public isUserImpersonated: boolean
    public isImpersonatedUserActive: boolean
    public isAdmin: boolean
    public version: string

    public links: Link[] | null

    constructor(
        public title: string | null,
        public adminLink: string | null,
        public isPublished: boolean,
        public window: Window
    ) {
        this.links = (title && adminLink && [new Link(title, adminLink)]) || null
        this.hasAdminDisplay = !!(
            this.window["PBSLM"].NG.ADMIN_DISPLAY || this.window["PBSLM"].NG.IS_SU
        )
        this.isUserImpersonated = !!this.window["PBSLM"].NG.IS_SU
        this.isImpersonatedUserActive = !!this.window["PBSLM"].NG.IS_SU_ACTIVE_USER
        this.isAdmin = !!this.window["PBSLM"].NG.ADMIN_DISPLAY
        this.version = this.window["PBSLM"].NG.VERSION || ""
    }
}
