import { Injectable } from "@angular/core"
import { ModalService } from "../shared-components/modal/modal.service"
import { AuthModalComponent } from "../shared-components/modals/auth-modal/auth-modal.component"
import { UserService } from "./user.service"
import { StudentSignInModalComponent } from "../shared-components/modals/student-sign-in-modal/student-sign-in-modal.component"

@Injectable({
    providedIn: "root",
})
export class LMModalService {
    constructor(private userService: UserService, private modalService: ModalService) {}

    public openLoginModal() {
        this.modalService.open(
            this.userService.hasStudentExperience()
                ? StudentSignInModalComponent
                : AuthModalComponent
        )
    }
}
