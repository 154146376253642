<span class="localization-modal">
    <button
        (click)="activeModal.dismiss('Cross click')"
        aria-label="Close"
        class="btn-close selenium-close-localization-modal"
        data-dismiss="modal"
        type="button"
    ></button>
    <div class="modal-body">
        <img [src]="logoImage" class="logo" alt="PBS LeaningMedia Logo" />
        <div class="view1" *ngIf="searchLocalStationsStep">
            <h5 class="modal-title">Find Your Local Station</h5>
            <p class="description modal-body-text">
                Using your ZIP code, choose a local PBS station to see educational resources and
                station-sponsored events near you. Sign in to see each resource aligned to your
                state's educational standards.
            </p>
            <div class="inputs">
                <input
                    [appCustomRegExValidation]="true"
                    [customRegEx]="zipRegExCheck"
                    [(ngModel)]="zipCode"
                    type="text"
                    class="zip-input"
                    placeholder="School ZIP Code"
                />
                <app-button
                    [disable]="!(zipCode.length >= 5)"
                    (click)="submitZipCode()"
                    class="confirm-btn"
                    buttonClasses="gtm-loc-modal-search-by-zipcode modal-button-padding"
                >
                    Search by ZIP Code
                </app-button>
            </div>
            <div class="states-selector-wrapper inputs">
                <ng-select
                    [items]="states"
                    bindLabel="name"
                    dropdownPosition="bottom"
                    [multiple]="false"
                    [hideSelected]="true"
                    [searchable]="true"
                    [clearable]="false"
                    [markFirst]="true"
                    placeholder="Select State"
                    [(ngModel)]="selectedState"
                    class="dropdown"
                >
                </ng-select>
                <app-button
                    [disable]="selectedState?.abbreviation === null"
                    (click)="submitState()"
                    buttonClasses="gtm-loc-modal-search-by-state modal-button-padding"
                    class="confirm-btn"
                    >Search by State</app-button
                >
            </div>
        </div>
        <div class="view2" *ngIf="displayLocalStationsStep">
            <div class="info-wrapper">
                <div>
                    <h5 class="modal-title">Choose Your Local Station</h5>
                    <div class="description modal-body-text">
                        Find resources designed for teachers in your area and discover nearby
                        station-sponsored events.
                    </div>
                </div>

                <div class="logo-wrapper">
                    <img
                        class="station-logo"
                        *ngIf="stationLogo"
                        [src]="stationLogo"
                        alt="station logo"
                    />
                    <div
                        class="city-state"
                        *ngIf="selectedStation && selectedStation.city && selectedStation.state"
                    >
                        {{ selectedStation?.city }}, {{ selectedStation?.state }}
                    </div>
                </div>
            </div>

            <p class="warning modal-body-text" *ngIf="searchedByState && !stations.length">
                <b>There are no stations available for your selected state.</b>
            </p>

            <p class="warning modal-body-text" *ngIf="!zipcodeHasMatched">
                There are no stations available for your selected zip code. You may choose one of
                the following.
            </p>
            <ng-container *ngIf="stations.length">
                <div class="stations-wrapper">
                    <div
                        class="station"
                        *ngFor="let station of stations"
                        (click)="selectStation(station)"
                        [ngClass]="{
                            selected: selectedStation === station || index
                        }"
                    >
                        <b>{{ station.organizationName }}</b>
                        <div class="standards" *ngIf="station.authorities.length">
                            State Standards: {{ station.authorities.join(",") }}
                        </div>
                    </div>
                    <div
                        class="station"
                        *ngIf="shouldShowVirginiaPrompt()"
                        (click)="selectEmediaVa()"
                        [ngClass]="{ selected: selectedEMediaVA }"
                    >
                        eMediaVA (external link)
                        <div class="standards">State Standards: Virginia</div>
                    </div>
                </div>
            </ng-container>

            <div class="note modal-body-text">
                Please note: You must be signed in to see state standards.
            </div>
            <div class="action-buttons-wrapper">
                <app-button
                    class="back-btn"
                    [buttonClasses]="'fw-bold modal-padding-buttons'"
                    (click)="goBackToZipCodeView()"
                >
                    <app-icon [type]="'Arrow-left'"></app-icon> Change ZIP Code / State
                </app-button>
                <app-button
                    (click)="submitStation()"
                    [buttonClasses]="'fw-bold modal-padding-buttons'"
                    class="confirm-btn"
                    >Confirm My Local Station</app-button
                >
            </div>
        </div>
    </div>
</span>
