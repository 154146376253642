import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "prettyGrade",
})
export class PrettyGradePipe implements PipeTransform {
    constructor() {}

    transform(value: string): string {
        switch (value) {
            case "K":
                return "Kindergarten"
            case "PreK":
                return "PreK"
            case "13+":
                return "13+"
            case "1":
                return "1st grade"
            case "2":
                return "2nd grade"
            case "3":
                return "3rd grade"
            default:
                return `${value}th grade`
        }
    }
}
