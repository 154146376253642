import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { LocalizationService } from "../../../services/localization.service"
import { Subscription } from "rxjs"
import { UserProfileService } from "../../../services/user-profile.service"
import { ModalRef } from "../../modal/modal-ref"
import { STATIC_IMAGES } from "../../../models/constants"

@Component({
    selector: "app-localization-modal",
    templateUrl: "./localization-modal.component.html",
    styleUrls: ["./localization-modal.component.scss"],
})
export class LocalizationModalComponent implements OnInit, OnDestroy {
    @Input() public selectedStandardsTab: boolean = false
    public zipCode: string = ""
    public stations: any[] = []
    public selectedStation: any = null
    public zipcodeHasMatched: boolean = false
    public virginiaLocalization: boolean = false
    public selectedEMediaVA: boolean = false
    public logoImage: string = null
    public stationLogo: string = ""
    public states: unknown
    public zipRegExCheck: RegExp = /[0-9-]/
    public selectedState: any
    private subscriptions: Subscription[] = []

    searchLocalStationsStep: boolean = true
    displayLocalStationsStep: boolean = false
    searchedByState: boolean = false

    constructor(
        public activeModal: ModalRef,
        private localizationService: LocalizationService,
        private userProfileService: UserProfileService
    ) {
        this.logoImage = STATIC_IMAGES.pbslm_logo
    }

    public ngOnInit() {
        this.states = this.userProfileService.getStates()
    }

    shouldShowVirginiaPrompt(): boolean {
        return this.virginiaLocalization
    }

    submitZipCode() {
        this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
        this.subscriptions.push(
            this.localizationService
                .getStationsByZipCode(this.zipCode)
                .subscribe((results: any) => {
                    if (
                        results &&
                        results.organizations &&
                        results.zipcodeHasMatched !== undefined
                    ) {
                        this.stations = results.organizations
                        this.zipcodeHasMatched = results.zipcodeHasMatched
                        this.virginiaLocalization = results.isInVirginia
                        if (this.virginiaLocalization) {
                            this.selectedEMediaVA = false
                        }
                        if (this.stations.length) {
                            this.selectStation(this.stations[0])
                        }
                        this.searchLocalStationsStep = false
                        this.displayLocalStationsStep = true
                    }
                })
        )
    }

    public submitState() {
        this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
        if (!this.selectedState?.abbreviation) return
        this.subscriptions.push(
            this.localizationService
                .getStationsByState(this.selectedState.abbreviation)
                .subscribe((results: any) => {
                    if (results && results.organizations) {
                        this.virginiaLocalization = results.isInVirginia
                        this.stations = results.organizations
                        this.zipcodeHasMatched = true
                    }
                    if (this.stations.length) {
                        this.selectStation(this.stations[0])
                    }
                    this.searchedByState = true
                    this.searchLocalStationsStep = false
                    this.displayLocalStationsStep = true
                })
        )
    }

    submitStation() {
        if (this.selectedEMediaVA) {
            window.location.href = "https://emediava.org/"
        } else {
            this.localizationService.setStation(
                this.selectedStation.organizationGuid,
                this.zipCode,
                this.isResourcePageSelectedTabStandards
            )
        }
    }

    public selectStation(station) {
        this.selectedEMediaVA = false
        this.selectedStation = station
        this.subscriptions.push(
            this.localizationService
                .getStationManageData(station.organizationCallSign)
                .subscribe((station: any) => {
                    if (station && station.data && station.data[0]) {
                        this.selectedStation.images = station.data[0].attributes.images
                        this.selectedStation.city = station.data[0].attributes.city
                        this.selectedStation.state = station.data[0].attributes.state
                    }
                    this.setStationLogo()
                })
        )
    }

    public selectEmediaVa() {
        this.selectedEMediaVA = true
        this.selectedStation = null
    }

    public setStationLogo() {
        let logoUrl = null
        if (this.selectedStation.images && this.selectedStation.images.length) {
            logoUrl = this.selectedStation.images.find((image): boolean => {
                return image.profile === "color-single-brand-logo"
            })

            if (!logoUrl) {
                logoUrl = this.selectedStation.images.find((image): boolean => {
                    return image.profile === "color-cobranded-logo"
                })
            }

            if (logoUrl && logoUrl.url) {
                this.stationLogo = logoUrl.url
            }
        } else {
            this.stationLogo = null
        }
    }

    public goBackToZipCodeView() {
        this.stations.length = 0
        this.virginiaLocalization = false
        this.searchLocalStationsStep = true
        this.displayLocalStationsStep = false
        this.searchedByState = false
    }

    private get isResourcePageSelectedTabStandards(): boolean {
        return window.location.pathname.includes("/resource/") && this.selectedStandardsTab
    }

    ngOnDestroy() {
        this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
    }
}
