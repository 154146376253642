import { Component, OnDestroy, OnInit } from "@angular/core"
import { EMPTY, of, ReplaySubject, Subject } from "rxjs"
import { UserDataPrivacyService } from "../../services/user-data-privacy.service"
import { finalize, map, switchMap, takeUntil, tap } from "rxjs/operators"
import { UserDataPrivacyPayload } from "../../models/userDataPrivacy.model"

@Component({
    selector: "app-data-privacy",
    templateUrl: "./data-privacy.component.html",
    styleUrls: ["./data-privacy.component.scss"],
})
export class DataPrivacyComponent implements OnInit, OnDestroy {
    destroyed$: Subject<any> = new Subject<any>()

    isLoading$ = new ReplaySubject(1)
    fetchData$ = new ReplaySubject(1)

    vm$ = this.fetchData$.pipe(
        takeUntil(this.destroyed$),
        tap(() => this.isLoading$.next(true)),
        switchMap(() => this.userDataPrivacyService.getUserDataPrivacy()),
        map((data) => ({
            dataPrivacy: {
                ...data,
                school_district: data.school_district[data.school_district.length - 1],
            },
            assignments: {},
        })),
        switchMap(({ dataPrivacy, assignments }) => {
            const sign_acknowledgement = dataPrivacy.sign_acknowledgement
            if (!sign_acknowledgement) return of({ dataPrivacy, assignments })

            return this.userDataPrivacyService.getAssignments().pipe(
                map((assignments) => {
                    return {
                        assignments: {
                            assigned: assignments?.assigned ?? 0,
                            awaiting: assignments?.awaiting ?? 0,
                        },
                        dataPrivacy,
                    }
                })
            )
        }),
        tap(() => {
            this.isLoading$.next(false)
        })
    )

    constructor(private userDataPrivacyService: UserDataPrivacyService) {}

    ngOnInit(): void {
        this.fetchData$.next()
    }

    ngOnDestroy(): void {
        this.destroyed$.next()
        this.destroyed$.complete()
    }

    setDataPrivacy(payload: any): void {
        of(EMPTY)
            .pipe(
                tap(() => this.isLoading$.next(true)),
                switchMap(() =>
                    this.userDataPrivacyService.setDataPrivacy<UserDataPrivacyPayload>(payload)
                ),
                finalize(() => {
                    this.fetchData$.next()
                })
            )
            .subscribe(() => this.userDataPrivacyService.openSuccessModal())
    }
}
