<h3 class="text-primary fw-bold"></h3>
<section>
    <p>You have acknowledged the Student Privacy Acknowledgment. We thank you.</p>

    <div class="school-info">
        <p><b>School district: </b>{{ schoolDistrictName }}</p>
        <p><b>School: </b>{{ schoolName }}</p>
    </div>

    <app-data-privacy-support [formStackUrl]="formStackUrl"
        >Need to make a change? Let us know.</app-data-privacy-support
    >

    <div class="d-flex justify-content-center">
        <button
            type="button"
            class="btn btn-primary fw-bold font-size-14 px-4 py-2 mt-5 gtm-go-to-assignments"
            (click)="router.navigate([navUrl])"
        >
            {{ label }}
        </button>
    </div>
</section>
