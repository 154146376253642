import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router"
import { Router } from "@angular/router"
import { UserService } from "../services/user.service"

@Injectable()
export class NotAuthGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userService.isLoggedIn() && this.userService.hasStudentExperience()) {
            this.router.navigate(["/student"])
            return false
        }

        if (!this.userService.isLoggedIn()) {
            return true
        } else {
            this.router.navigate(["/"])
            return false
        }
    }
}
