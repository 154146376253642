import Utils from "../utils/utils"
import { CHNode } from "./node.model"
import { CPFacet } from "./cpfacet.model"
import { PremiumColors } from "./collection/premiumColors.model"

export class SearchInfoModel {
    public chSectionName: string = ""
    public chFilter: CHNode[] = []
    public cpFilter: CPFacet[] = []
    public cpSectionName: string = ""
    public colorLogo: string = ""
    public customFilterObjects: any[] = []
    public whiteLogo: string = ""
    public slug: string = ""
    public title: string = ""
    public colors: PremiumColors = new PremiumColors({})

    constructor(input) {
        Object.keys(input).map((key) => {
            const property = Utils.convertToCamelcase(key)
            if (property === "styleContent") {
                this.colors = new PremiumColors(input[key])
            }
            if (
                property !== "chFilter" &&
                property !== "cpFilter" &&
                property !== "customFilters"
            ) {
                this[property] = input[key]
            }
        })
    }
}
