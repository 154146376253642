import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    HostListener,
    Input,
    ViewEncapsulation,
} from "@angular/core"
import { ModalRef } from "./modal-ref"
import { ModalService } from "./modal.service"

@Component({
    selector: "app-modal",
    template: ` <div
        [class]="
            'modal-dialog' +
            (size ? ' modal-' + size : '') +
            (scrollable ? ' modal-dialog-scrollable' : '')
        "
        role="document"
    >
        <div class="modal-content"><ng-content></ng-content></div>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    host: {
        style: "display: block;",
    },
})
export class ModalComponent {
    @HostBinding("role") role = "dialog"
    @HostBinding("tabindex") tabindex = "-1"
    @HostBinding("class") get class() {
        return `modal ${this.windowClass ? this.windowClass : ""}`
    }

    @HostListener("document:click", ["event"])
    clickedOut() {
        if (
            event.target["classList"].contains("modal") &&
            this.modalService.modalBackDrop.instance.clickOutsideToClose
        ) {
            this.modalService.dismissAllModals()
        }
    }

    public activeModal: ModalRef
    @Input() public id: string
    @Input() size: string
    @Input() scrollable: string
    @Input() windowClass: string

    constructor(public changeDetectorRef: ChangeDetectorRef, public modalService: ModalService) {}
}
