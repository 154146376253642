<div [ngClass]="{ 'preview-mode': isInPreviewMode$ | async }" class="main-container">
    <div *ngIf="isInPreviewMode$ | async" class="preview-header">
        <span class="preview-header-icon"><app-icon [type]="'Eye'" [scale]="1.6"></app-icon></span>
        <span class="preview-header-text">
            You are previewing an Unpublished
            {{ (isResourceInPreviewMode$ | async) ? "Resource" : "Collection" }}. FOR REVIEW
            PURPOSES ONLY.
        </span>
        <span>
            <app-button
                class="mb-5 preview-header-button"
                [buttonClasses]="'preview-header-button'"
                (click)="onExitPreview()"
            >
                <span class="preview-header-button-text">Exit preview</span>
            </app-button>
        </span>
    </div>
    <!--Header-->
    <app-header *ngIf="isDisplayed"></app-header>

    <!--Main Content-->
    <main *ngIf="!isNavigationStopped">
        <router-outlet (activate)="onActivate()"></router-outlet>
    </main>

    <app-preloader *ngIf="isNavigationStopped" class="navigation-stopped-preloader"></app-preloader>
    <!--Footer-->
    <app-footer class="footer" *ngIf="isDisplayed && !isNavigationStopped"></app-footer>

    <app-back-to-top-button></app-back-to-top-button>
    <simple-notifications></simple-notifications>
</div>
