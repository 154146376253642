<div class="selenium-app-localization" [ngClass]="{ 'localization-wrapper': addWrapper }">
    <div class="localization container">
        <div *ngIf="!isUserLoggedIn" class="row-auto">
            <div class="call-to-action-wrapper">
                <div class="advice">Sign in to see resources aligned to your state standards!</div>
                <div class="advice-mobile-version">
                    Sign in to see resources aligned to your state
                </div>
                <div class="sign-in">
                    <button (click)="openAuthModal()">Sign In</button>
                </div>
                <div class="create-an-account">
                    <a
                        [routerLink]="['/signup']"
                        (click)="closeLocalization(); $event.preventDefault()"
                        >Create An Account
                        <app-icon [type]="'Arrow-right'"></app-icon>
                    </a>
                </div>
            </div>
        </div>
        <div *ngIf="isUserLoggedIn && !localizeData.callSign" class="row-auto">
            <div class="call-to-action-wrapper choose-station-wrapper">
                <div class="choose-station-advice">
                    Choose your local station to see resources aligned to your state standards!
                </div>
                <button
                    class="{{ gtmPrefix + ' choose-station-classic-style' }} btn btn-primary ms-4"
                    (click)="openLocalizationChangeModal()"
                >
                    Choose Station
                </button>
            </div>
        </div>
        <div
            [ngStyle]="{
                'justify-content':
                    localizeData.events.length || localizeData.professionalDevelopmentNodes.length
                        ? 'initial'
                        : 'center'
            }"
            class="row-auto"
        >
            <div class="col-12 col-sm-12 col-md-4">
                <div class="brought-by">
                    PBS LearningMedia {{ localizeData.stateName }} is brought to you by
                </div>
                <div class="logo">
                    <img
                        [ngClass]="{ default: !localizeData.callSign }"
                        [src]="
                            localizeData.callSign
                                ? localizeData.getLogo()
                                : localizeData.defaultLogo
                        "
                        alt="PBS LearningMedia logo"
                    />
                </div>
                <div *ngIf="!localizeData.callSign" class="local-station-text">
                    Choose a station to find educational resources and station-sponsored events near
                    you.
                </div>
                <div class="social">
                    <span class="facebook" *ngIf="localizeData.facebookUrl">
                        <a
                            [href]="localizeData.facebookUrl"
                            class="{{ gtmPrefix + 'facebook' }}"
                            [attr.data-gtmlabel]="'Facebook'"
                        >
                            <app-icon [type]="'FacebookSecondVersion'"></app-icon>
                        </a>
                    </span>
                    <span *ngIf="localizeData.twitterUrl" class="twitter">
                        <a
                            [href]="localizeData.twitterUrl"
                            class="{{ gtmPrefix + 'twitter' }}"
                            [attr.data-gtmlabel]="'Twitter'"
                        >
                            <app-icon [type]="'Twitter'"></app-icon>
                        </a>
                    </span>
                    <span *ngIf="localizeData.pinterestUrl">
                        <a
                            [href]="localizeData.pinterestUrl"
                            class="{{ gtmPrefix + 'pinterest' }}"
                            [attr.data-gtmlabel]="'Pinterest'"
                        >
                            <app-icon [type]="'Pinterest'"></app-icon>
                        </a>
                    </span>
                </div>
                <div class="actions">
                    <a
                        *ngIf="localizeData.callSign && localizeData.donateUrl"
                        class="{{ gtmPrefix + 'donate' + ' donate' }}"
                        [attr.data-gtmlabel]="localizeData.donateUrl"
                        [href]="localizeData.donateUrl"
                        >Donate</a
                    >
                    <button
                        *ngIf="!localizeData.callSign"
                        class="{{ gtmPrefix + 'change-station choose-station' }} btn btn-primary"
                        (click)="openLocalizationChangeModal()"
                    >
                        Choose Station
                    </button>
                    <div *ngIf="localizeData.callSign" class="change-station">
                        <span (click)="openLocalizationChangeModal()">
                            <app-icon type="Cog"></app-icon>
                            Change your local station
                        </span>
                    </div>
                </div>
            </div>
            <div
                *ngIf="
                    localizeData.events.length || localizeData.professionalDevelopmentNodes.length
                "
                class="col-12 col-sm-12 col-md-4"
            >
                <h3 class="title" *ngIf="localizeData.events.length">
                    {{ localizeData.eventsTitle }}
                </h3>
                <div class="events" *ngFor="let event of localizeData.events">
                    <a
                        [href]="event.url"
                        class="{{ gtmPrefix + 'teacher-event' + ' event' }}"
                        [attr.data-gtmlabel]="event.url"
                    >
                        <div class="event-date" *ngIf="event.isToday">Today</div>
                        <div class="event-date" *ngIf="!event.isToday">
                            {{ event.month }} {{ event.day }}
                        </div>
                        <div class="event-title">
                            {{ event.name }}
                        </div>
                    </a>
                </div>
                <h3 class="title" *ngIf="localizeData.professionalDevelopmentNodes.length">
                    {{ localizeData.professionalDevelopmentTitle }}
                </h3>
                <div class="events" *ngFor="let node of localizeData.professionalDevelopmentNodes">
                    <a
                        [href]="node.url"
                        class="{{ gtmPrefix + 'professional-development' + ' event' }}"
                        [attr.data-gtmlabel]="node.url"
                    >
                        <div class="event-title">{{ node.name }}</div>
                    </a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4">
                <h3 class="title">
                    {{ localizeData.freeAreaTitle }}
                </h3>
                <div class="content">
                    <div *ngIf="!hasImage()">
                        {{ localizeData.freeAreaDescription }}
                    </div>

                    <div *ngIf="hasImage()">
                        <a
                            [href]="localizeData.freeAreaLink"
                            title="{{ localizeData.freeAreaTitle }}"
                            class="{{ gtmPrefix + 'free-area' }}"
                            [attr.data-gtmlabel]="localizeData.freeAreaLink"
                        >
                            <img
                                class="free-are-image"
                                alt="{{ localizeData.freeAreaTitle }} background"
                                [src]="localizeData.freeAreaITSImage"
                            />
                        </a>
                    </div>
                </div>
                <a
                    class="call-to-action {{ gtmPrefix + 'free-area-call-to-action' }}"
                    *ngIf="localizeData.freeAreaCallToActionText"
                    [attr.data-gtmlabel]="localizeData.freeAreaLink"
                    [href]="localizeData.freeAreaLink"
                >
                    {{ localizeData.freeAreaCallToActionText }}
                </a>
            </div>
        </div>
        <div
            class="row-auto localize-footer"
            [innerHtml]="localizeData.stationFooter"
            *ngIf="addWrapper && localizeData.stationFooter"
        ></div>
    </div>
</div>
