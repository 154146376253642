import { Component, Input, OnInit } from "@angular/core"
import { UserService } from "../../../../services/user.service"
import { ModalRef } from "../../../modal/modal-ref"
import { ModalService } from "../../../modal/modal.service"
import { AuthModalComponent } from "../auth-modal.component"
import { StudentSignupModalComponent } from "../../student-signup-modal/student-signup-modal.component"
import { STATIC_IMAGES } from "../../../../models/constants"
import { StudentSignInModalComponent } from "../../student-sign-in-modal/student-sign-in-modal.component"

@Component({
    selector: "app-auth-modal-content",
    templateUrl: "./auth-modal-content.component.html",
    styleUrls: ["./auth-modal-content.component.scss"],
})
export class AuthModalContentComponent implements OnInit {
    public isStudent: boolean = false
    public googleImage
    public isStudentTab: boolean = false
    googleAuthUrl: string
    pbsAuthUrl: string

    @Input() public nextURL: string = null
    @Input() public isSignUp: boolean = false
    @Input() public activeModal: ModalRef = null
    @Input() public title: string = "Welcome back!"
    @Input() public description: string
    @Input() public registerDescription: string = "Need an account?"
    @Input() public registerLinkText: string = "Click here to get started."
    @Input() public pbsEmailRegister: boolean = false

    constructor(public userService: UserService, private modalService: ModalService) {
        this.googleImage = STATIC_IMAGES.auth_google
    }

    public ngOnInit() {
        this.isStudent = this.userService.hasStudentExperience()
        this.setDescription()
        this.setGoogleAuthUrl()
        this.pbsAuthUrl = this.userService.getPbsAuthUrl(this.nextURL, this.pbsEmailRegister)
    }

    setDescription() {
        if (!this.description)
            this.description = "Use one of the services below to sign in to PBS LearningMedia:"
    }

    public setGoogleAuthUrl() {
        this.googleAuthUrl = this.userService.getGoogleAuthUrl(this.isStudentTab)
    }

    public activateStudentTab() {
        this.description = "Access your assignments, projects, and favorites by signing in."
        this.isStudentTab = true
        this.setGoogleAuthUrl()
    }

    public activateTeacherTab() {
        this.description = "Use one of the services below to sign in to PBS LearningMedia:"
        this.isStudentTab = false
        this.setGoogleAuthUrl()
    }

    public closeAuthModal() {
        if (this.activeModal) {
            this.activeModal.close()
        }
    }

    public openAuthModal() {
        this.modalService.open(AuthModalComponent, {
            data: {
                nextURL: this.nextURL,
            },
        })
    }

    public openStudentAuthModal() {
        this.modalService.open(StudentSignInModalComponent)
    }

    public openStudentSignUp() {
        this.closeAuthModal()
        this.modalService.open(StudentSignupModalComponent, { size: "lg" })
    }
}
