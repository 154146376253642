import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core"
import { UserService } from "../../services/user.service"
import { Params, QueryParamsHandling, Router } from "@angular/router"

@Component({
    selector: "app-link",
    templateUrl: "./link.component.html",
    styleUrls: ["link.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class LinkComponent implements OnInit {
    @Input() href = null
    @Input() appRouterLink: any[] | string | null | undefined = null
    @Input() tabIndex: number
    @Input() queryParams: Params
    @Input() routerLinkActive: string[] | string = ""
    @Input() queryParamsHandling: QueryParamsHandling = null
    @Input() title: string = ""
    @Input() target: string = ""
    @Input() badgeCount: number = 0

    constructor(private userService: UserService, private router: Router) {}

    public ngOnInit() {
        this.setStudentInQueryParam()
        this.checkRequiredInputs()
    }

    // Sets student=true in query params for appRouterLink and href inputs
    private setStudentInQueryParam(): void {
        const { student } = this.userService.getStudentQueryParams()
        if (!student) return

        if (this.appRouterLink && this.appRouterLink != "/student") {
            this.queryParams = {
                ...this.queryParams,
                student,
            }
            return
        }

        if (this.href) {
            this.href += this.href.includes("?") ? "&student=true" : "?student=true"
        }
    }

    get isChildRoute(): boolean {
        // @ts-ignore
        return this.router.url.includes(this.appRouterLink)
    }

    private checkRequiredInputs(): void {
        if (!this.href && !this.appRouterLink) {
            throw new Error("Attribute 'href' or 'appRouterLink' is required")
        }
    }
}
