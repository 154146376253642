import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    OnDestroy,
    OnInit,
} from "@angular/core"
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms"
import { takeUntil } from "rxjs/operators"
import { ReplaySubject } from "rxjs"

@Component({
    selector: "app-full-name",
    templateUrl: "./full-name.component.html",
    styleUrls: ["./full-name.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FullNameComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => FullNameComponent),
        },
    ],
})
export class FullNameComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    fullName = this.fb.group({
        firstName: ["", Validators.required],
        lastName: [""],
    })

    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)
    private onTouched: () => void = () => {}

    constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.cleanInputsOnChanges()
    }

    ngOnDestroy(): void {
        this.destroyed$.next()
        this.destroyed$.complete()
    }

    get firstName(): FormControl {
        return this.fullName.get("firstName") as FormControl
    }

    get lastName(): FormControl {
        return this.fullName.get("lastName") as FormControl
    }

    writeValue(value: { firstname: string; lastName: string }): void {
        this.fullName.patchValue(value)
        this.onTouched()
        this.changeDetectorRef.markForCheck()
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.fullName.valid
            ? null
            : {
                  error: {
                      message: "Please enter your first name",
                  },
              }
    }

    registerOnChange(fn: any): void {
        this.fullName.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(fn)
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    private cleanInputsOnChanges(): void {
        this.firstName.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
            this.firstName.setValue(this.cleanInput(data), { emitEvent: false })
        })

        this.lastName.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
            this.lastName.setValue(this.cleanInput(data), { emitEvent: false })
        })
    }

    private cleanInput(inputValue): string {
        return inputValue.replace(/[^a-zA-Z0-9 -]/g, "")
    }
}
