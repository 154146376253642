import { Component, OnInit } from "@angular/core"
import Utils from "../../utils/utils"
import { ActivatedRoute, Router } from "@angular/router"

@Component({
    selector: "app-login-iframe",
    templateUrl: "./log-in-add-on.component.html",
    styleUrls: ["./log-in-add-on.component.scss"],
})
export class LogInAddOnComponent implements OnInit {
    areCookiesEnabled = false
    isSafariBrowser = false
    isStudentAccountSelected = false
    loginMessage =
        "Please sign in with your Google Classroom account in order to access PBS LearningMedia’s free teaching resources."

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.isSafariBrowser = Utils.isSafari()
        this.areCookiesEnabled = Utils.areCookiesEnabled()

        const queryParam = this.activatedRoute.snapshot.queryParamMap.get("logOut")
        if (!queryParam) return
        this.isStudentAccountSelected = true
        this.loginMessage =
            "Your account does not have permission to access this service. Sign out and try signing in with a Google Classroom teacher account."
    }

    logOut() {
        window.location.href = decodeURIComponent("/logout/?next=/add-on/search/")
    }

    openLoginPopUp(): void {
        const loginUrl = "/profile/login-national/google-oauth2/?next=/add-on/close-login-popup/"
        Utils.openPopUp(loginUrl)
    }
}
