import { Component, Input, OnInit } from "@angular/core"
import { CHNode } from "../../models/node.model"
import { BrowsingService } from "../../services/browsing.service"
import { UserService } from "../../services/user.service"
import { STATIC_IMAGES } from "../../models/constants"
import { AddOnViewService } from "../../services/add-on-view.service"
import { ErrorCode } from "../error-code.model"

@Component({
    selector: "app-internal-server-error",
    templateUrl: "./internal-server-error.component.html",
    styleUrls: ["./internal-server-error.component.scss"],
})
export class InternalServerErrorComponent implements OnInit {
    @Input() subjects: CHNode[]
    @Input() customErrorMessage: string

    image: any
    isStudent: boolean
    isAddOnView: boolean
    errorCodes = ErrorCode

    constructor(
        private browsingService: BrowsingService,
        private userService: UserService,
        private addOnViewService: AddOnViewService
    ) {
        this.image = STATIC_IMAGES.on_the_go
    }

    public ngOnInit() {
        this.isAddOnView = this.addOnViewService.isAddonView
        this.isStudent = this.userService.hasStudentExperience()
    }
}
