import { Component, Input } from "@angular/core"
import { ModalRef } from "../../modal/modal-ref"

@Component({
    selector: "app-auth-modal",
    templateUrl: "./auth-modal.component.html",
    styleUrls: ["./auth-modal.component.scss"],
})
export class AuthModalComponent {
    @Input() public nextURL: string = null
    @Input() public description: string = ""

    constructor(public activeModal: ModalRef) {}
}
