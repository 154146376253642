import { Component, ElementRef, Renderer2 } from "@angular/core"

@Component({
    selector: "app-carousel-item2",
    templateUrl: "./carousel-item2.component.html",
    styleUrls: ["./carousel-item2.component.scss"],
})
export class CarouselItem2Component {
    public position: number = null
    public width: number = null

    constructor(public elem: ElementRef, private renderer: Renderer2) {}

    public setPosition(position: number): void {
        this.position = position
    }

    public setWidth(width: number): void {
        this.width = width
    }

    public render() {
        this.renderer.setStyle(this.elem.nativeElement, "left", this.position + "px")
        this.renderer.setStyle(this.elem.nativeElement, "width", this.width + "px")
    }
}
