import { Component, OnInit } from "@angular/core"
import { BrowsingService } from "../../services/browsing.service"
import { CHNode } from "../../models/node.model"
import { Observable } from "rxjs"
import { UserService } from "../../services/user.service"
import { animate, style, transition, trigger } from "@angular/animations"
import { BrowseByViews, GradeBandEnum } from "../../models/constants"
import { AddOnViewService } from "../../services/add-on-view.service"
import { ActivatedRoute, Router } from "@angular/router"

@Component({
    selector: "app-browse-by",
    templateUrl: "./browse-by.component.html",
    styleUrls: ["./browse-by.component.scss"],
    animations: [
        trigger("slideInLeft", [
            transition(":enter", [
                style({ transform: "translateX(-100%)" }),
                animate("250ms ease-in-out", style({ transform: "translateX(0%)" })),
            ]),
        ]),
    ],
})
export class BrowseByComponent implements OnInit {
    VIEWS = BrowseByViews
    activeViewId: BrowseByViews = BrowseByViews.Initial
    isAnimationDisabled = true
    mobileSubjectTitle = ""
    selectedDiscipline: CHNode | null = null
    disciplines$: Observable<CHNode[]> | null = null
    gtmPrefix = "gtm-browse-by-section-"
    isAddonView: boolean = false
    isStudentExperience: boolean = false

    disciplineIdColorClasses = new Map([
        [8337, "science-color"],
        [3026, "social-color"],
        [1184, "math-color"],
        [1880, "ela-color"],
        [8353, "tech-color"],
        [2806, "health-color"],
        [2949, "prek-color"],
        [6708, "prof-color"],
        [2663, "arts-color"],
        [6267, "languages-color"],
    ])

    gradeName = [
        [[GradeBandEnum.BandPreKtoK], "PreK"],
        [[GradeBandEnum.BandKto2], "Early Elementary"],
        [[GradeBandEnum.Band3to5], "Upper Elementary"],
        [[GradeBandEnum.Band6to8], "Middle School"],
        [[GradeBandEnum.Band9to12], "High School"],
    ]

    constructor(
        public userService: UserService,
        private browsingService: BrowsingService,
        private addOnViewService: AddOnViewService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.isAddonView = this.addOnViewService.isAddonView
        this.disciplines$ = this.browsingService.getSubjectsTree()
        this.isStudentExperience = this.userService.hasStudentExperience()
    }

    getAvailableSubjects(discipline: CHNode): CHNode[] {
        return discipline.children.filter((node) => node.resultsCount)
    }

    setActiveView(viewId: BrowseByViews): void {
        this.isAnimationDisabled && (this.isAnimationDisabled = false)
        this.activeViewId = viewId
        this.mobileSubjectTitle = this.VIEWS[viewId]
    }

    navigateToStudentSite() {
        const url = this.router.createUrlTree([], {
            queryParams: { student: true },
            relativeTo: this.route,
        })
        window.open(url.toString())
    }
}
