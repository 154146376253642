<div class="step-wrapper">
    <div class="description">
        Select one or more of your existing classes below, or
        <a
            href="/tools/classes/"
            (click)="activeModal.close()"
            target="_blank"
            rel="noopener noreferrer"
            >create a new class.</a
        >
        If you
        <a
            href="/tools/classes"
            target="_blank"
            rel="noopener noreferrer"
            (click)="activeModal.close()"
            >import a class from Google Classroom</a
        >, you can see student progress in both PBS LearningMedia and Google Classroom.
    </div>
    <div class="classes-wrapper">
        <div class="class-row" *ngFor="let roster of rosters">
            <div class="class-check-box">
                <app-checkbox [type]="'hallow'" [(checked)]="roster.selected"></app-checkbox>
                <div class="class-name">{{ roster.rosterName }}</div>
                <img class="google-class" *ngIf="roster.rosterGoogleID" [src]="googleClassImg" />
            </div>
        </div>

        <div class="class-row" *ngIf="type === assignType.pbslm">
            <a
                [href]="classesLink"
                target="_blank"
                rel="noopener noreferrer"
                (click)="activeModal.close()"
                >Create a new class</a
            >
        </div>

        <div class="class-row" *ngIf="type === assignType.google">
            <a
                [href]="classesLink"
                target="_blank"
                rel="noopener noreferrer"
                (click)="activeModal.close()"
                >Import class from Google Classroom</a
            >
        </div>
    </div>
    <div class="buttons-wrapper">
        <button (click)="goBack()" class="btn btn-outline-primary">
            <app-icon [type]="'Arrow-left'"></app-icon> Go Back
        </button>
        <button (click)="next()" [disabled]="!checkIfSelected()" class="btn btn-outline-secondary">
            Select Students <app-icon [type]="'Arrow-right'"></app-icon>
        </button>
    </div>
</div>
