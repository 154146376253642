<!-- Information Modal -->
<div class="modal-header">
    <app-button (click)="printText()" [buttonClasses]="'modal-button-padding'" *ngIf="printURL"
        >Print</app-button
    >
    <button
        (click)="modalRef.dismiss('Cross click')"
        aria-label="Close"
        class="btn-close"
        data-dismiss="modal"
        type="button"
    ></button>
</div>
<div class="modal-body">
    <div class="text-fragment">
        <h4 class="fragment-title">{{ role || "" }}</h4>
        <div [innerHtml]="text | bypassSecurity: 'html'"></div>
    </div>
</div>
