import { Injectable } from "@angular/core"
import { Resource } from "../models/resource/resource"
import { PageData } from "../utils/types"
import { GTMService } from "./gtm.service"

declare var window: any

@Injectable({
    providedIn: "root",
})
export class PendoService {
    private initialized = false

    constructor(private gtmService: GTMService) {}

    public initialize() {
        if (this.initialized) {
            return
        }
        this.initialized = true
        this.gtmService.subscribe((data) => this.newData(data))
    }

    private newData(pageData: PageData) {
        const { tags: _gtmTags, resource } = pageData
        // Multiple non page view events may come like this.
        if (resource && window.pendo && window.pendo.track && resource instanceof Resource) {
            // pendo doesn't like nulls; falling back on empty string *just* in case something breaks here
            window.pendo.track("resource", {
                resourceCode: resource.resourceCode || "",
                resourceTitle: resource.title || "",
                resourceSubject: resource.getRootSubjectsValue()
                    ? resource.getRootSubjectsValue().join(",")
                    : "",
                resourceGrades: resource.gradesDisplay || "",
            })
        }
    }
}
