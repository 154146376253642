import { Injectable } from "@angular/core"

export interface AccordionState {
    isExpanded: boolean
    allItemsVisible: boolean
}

@Injectable()
export class AccordionService {
    private accordionState: { [id: string]: AccordionState } = {}
    private observers: { [id: string]: Function[] } = {}

    setState(id: string, accordionState: AccordionState, notify = true) {
        this.accordionState[id] = accordionState
        if (notify) {
            this.notify(id)
        }
    }

    getState(id: string): AccordionState {
        const savedState = this.accordionState[id]
        return savedState ? savedState : null
    }

    register(id: string, observer: Function) {
        if (!this.observers[id]) {
            this.observers[id] = []
        }

        this.observers[id].push(observer)
    }

    deregister(id: string, observer: Function) {
        const index = this.observers[id].indexOf(observer)
        this.observers[id].splice(index)
    }

    notify(id: string) {
        this.observers[id].forEach((observer) => observer(this.accordionState[id]))
    }
}
