import { Injectable } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { Resource } from "../models/resource/resource"
import { Subject } from "rxjs"
import { GTMData, PageData, TagData } from "../utils/types"

declare var window: any

@Injectable()
export class GTMService {
    private lastPageTitle = "not set"
    private lastPageURL = "not set"

    private subject = new Subject<PageData>()

    constructor(private titleService: Title) {}

    pushEvent(eventName) {
        this.pushToDataLayer({ event: eventName })
    }

    pushPageView() {
        const currentTitle = this.titleService.getTitle()
        const currentURL = window.location.href
        this.pushToDataLayer({
            dimension22: this.lastPageTitle,
            dimension23: currentTitle,
            dimension24: this.lastPageURL,
            dimension25: currentURL,
        })
        this.pushToDataLayer({ event: "LMPageView" })
        this.lastPageTitle = currentTitle
        this.lastPageURL = currentURL
    }

    public setVideoData(data) {
        window.dataLayer.push({ dimension27: data })
    }

    resetDataLayer() {
        this.pushToDataLayer({
            dimension2: null,
            dimension3: null,
            dimension4: null,
            dimension11: null,
            dimension12: null,
            dimension13: null,
            dimension14: null,
            dimension28: null,
        })
    }

    pushCleanPageView() {
        this.resetDataLayer()
        this.pushPageView()
    }

    setDataLayer(gtmData: GTMData) {
        const {
            dimension2,
            dimension3,
            dimension4,
            dimension11,
            dimension12,
            dimension13,
            dimension14,
            dimension28,
            gtmObject,
        } = gtmData
        this.pushToDataLayer(
            {
                dimension2,
                dimension3,
                dimension4,
                dimension11,
                dimension12,
                dimension13,
                dimension14,
                dimension28,
            },
            gtmObject
        )
    }

    pushSearchEvent(eventName, searchText) {
        // Should be used for all future search events (e.g. filters).
        this.pushToDataLayer({
            searchQuery: searchText,
        })
        this.pushEvent(eventName)
    }

    pushToDataLayer(tags: TagData, resource?: Resource) {
        window.dataLayer.push(tags)
        this.subject.next({ tags, resource })
    }

    setVideoInfo(videoInfo) {
        window.dataLayer.push({
            videoData: videoInfo,
        })
    }

    setVideoTimePlayed(videoInfo) {
        window.dataLayer.push({
            videoTimePlayed: videoInfo,
        })
    }

    setMediaType(type) {
        window.dataLayer.push({
            videoJsMediaType: type,
        })
    }

    subscribe(
        next?: (value: PageData) => void,
        error?: (error: any) => void,
        complete?: () => void
    ) {
        return this.subject.subscribe(next, error, complete)
    }
}
