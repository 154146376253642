import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { StudentSignupModalComponent } from "../../modals/student-signup-modal/student-signup-modal.component"
import { ModalService } from "../../modal/modal.service"
import { WindowSizeService } from "../../../services/window-size.service"
import { EduComponent } from "../../../utils/eduComponent"
import { AuthModalComponent } from "../../modals/auth-modal/auth-modal.component"
import { Router } from "@angular/router"
import { StudentSignInModalComponent } from "../../modals/student-sign-in-modal/student-sign-in-modal.component"

/**
 * Opens up modals for Sign up/ Sign in functionality
 */
@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"],
    providers: [ModalService],
})
export class RegisterComponent extends EduComponent implements OnInit, OnDestroy {
    @Input()
    nextURL: string | null = null

    @Input()
    isStudent = false

    @Input()
    buttonLabelText: string | null = null

    buttonLabel: string = ""
    isTablet: boolean

    constructor(
        private modalService: ModalService,
        private windowSizeService: WindowSizeService,
        private router: Router
    ) {
        super()
    }

    ngOnInit(): void {
        this.addSub(
            this.windowSizeService.resize.subscribe((obs) => {
                this.buttonLabel =
                    (obs.isSmallDesktop && !obs.isTablet && "Sign up") || "Sign up for free!"
                this.isTablet = obs.isTablet
            })
        )

        this.buttonLabel =
            (this.windowSizeService.isSmallDesktop &&
                !this.windowSizeService.isTablet &&
                "Sign up") ||
            "Sign up for free!"

        this.isTablet = this.windowSizeService.isTablet
    }

    openSignInModal(): void {
        this.isStudent ? this.openStudentSignIn() : this.openTeacherSignIn()
    }

    openSignUpModal(): void {
        this.isStudent ? this.openStudentSignUp() : this.goToSignUpPage()
    }

    private goToSignUpPage(): void {
        this.router.navigate(["/signup"])
    }

    private openStudentSignUp(): void {
        this.modalService.open(StudentSignupModalComponent, { size: "lg" })
    }

    private openStudentSignIn(): void {
        this.modalService.open(StudentSignInModalComponent, {
            data: {
                nextURL: this.nextURL,
            },
        })
        this.modalService.modalBackDrop.instance.clickOutsideToClose = false
    }

    private openTeacherSignIn() {
        this.modalService.open(AuthModalComponent, {
            data: {
                nextURL: this.nextURL,
            },
        })
    }

    ngOnDestroy() {
        this.modalService.modalBackDrop.destroy()
    }
}
