import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core"
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { UserService } from "../../services/user.service"
import { DoLaterComponent } from "./do-later/do-later.component"
import Utils from "../../utils/utils"
import { UserDataPrivacyService } from "../../services/user-data-privacy.service"
import { map, take } from "rxjs/operators"
import { Observable } from "rxjs"
import {
    DATA_PRIVACY_MODALS,
    DataPrivacyModalsService,
    DISMISS_MODAL_REASON,
} from "../../services/data-privacy-modals.service"
import { WindowSizeService } from "../../services/window-size.service"

@Component({
    templateUrl: "data-privacy-modals.component.html",
    styleUrls: ["./data-privacy-modals.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DataPrivacyModalsService],
    host: { class: "gtmsection-modal-data-privacy" },
})
export class DataPrivacyModalsComponent implements OnInit {
    isMobile = this.windowSize.isSmallDevice
    firstName = this.userService.getFirstName()

    hasAwaitingAssignments$: Observable<boolean>

    isLoading$ = this.dataPrivacyModalsService.isLoading$
    activeModal$ = this.dataPrivacyModalsService.activeModal$

    DISMISS_MODAL_REASON = DISMISS_MODAL_REASON
    DATA_PRIVACY_MODALS = DATA_PRIVACY_MODALS

    constructor(
        public modalService: NgbModal,
        public activeModal: NgbActiveModal,
        public dataPrivacyModalsService: DataPrivacyModalsService,
        private userService: UserService,
        private windowSize: WindowSizeService,
        private userDataPrivacyService: UserDataPrivacyService
    ) {}

    ngOnInit(): void {
        // if on assignments route and has awaiting assignments show awaiting assignments message
        const isAssignmentsRoute = Utils.isSegmentContainedInUrl("assignments")
        if (!isAssignmentsRoute) return

        this.hasAwaitingAssignments$ = this.userDataPrivacyService.getAssignments().pipe(
            take(1),
            map(({ awaiting }) => !!awaiting)
        )
    }

    doLater(): void {
        this.activeModal.dismiss(DISMISS_MODAL_REASON.DO_LATER)
        this.modalService.open(DoLaterComponent, {
            ariaLabelledBy: "data-privacy-modal",
            size: "lg",
        })
    }
}
