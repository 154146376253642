import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { CHNode } from "../../../models/node.model"
import { BrowsingService } from "../../../services/browsing.service"
import { LocalizationService } from "../../../services/localization.service"
import { EduComponent } from "../../../utils/eduComponent"
import { SubjectsDropdownComponent } from "../subjects-dropdown/subjects-dropdown.component"
import { GradesDropdownComponent } from "../grades-dropdown/grades-dropdown.component"
import { GRADE_BANDS } from "../../../models/constants"
import { UserService } from "../../../services/user.service"
import { AddOnViewService } from "../../../services/add-on-view.service"
import { PreviewService } from "../../../services/preview.service"
import { BehaviorSubject, combineLatest, Subject } from "rxjs"
import { Resource } from "../../../models/resource/resource"
import { takeUntil, tap } from "rxjs/operators"
import { GradeBand } from "../../../models/gradeBand.model"

@Component({
    selector: "app-main-navigation",
    templateUrl: "./main-navigation.component.html",
    styleUrls: ["./main-navigation.component.scss"],
})
export class MainNavigationComponent extends EduComponent implements OnInit, OnDestroy {
    @ViewChild(SubjectsDropdownComponent) subjectsDropdown: SubjectsDropdownComponent
    @ViewChild(GradesDropdownComponent) gradesDropdown: GradesDropdownComponent

    gtmPrefix: string = "gtm-navigation-"
    gradeBands: GradeBand[] = GRADE_BANDS
    subjects: CHNode[]
    stateName: string = null
    isAddonView: boolean = false
    isTeacherPreview: boolean = false
    shouldDisableStudentSite$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)
    destroyed$: Subject<void> = new Subject()

    constructor(
        private browsingService: BrowsingService,
        private localizationService: LocalizationService,
        private addOnService: AddOnViewService,
        public router: Router,
        private userService: UserService,
        private previewService: PreviewService,
        private cdr: ChangeDetectorRef
    ) {
        super()
    }

    public ngOnInit() {
        this.handleShouldDisableStudentSite()
        this.isAddonView = this.addOnService.isAddonView
        this.isTeacherPreview = this.addOnService.isTeacherPreview

        this.addSub(
            this.browsingService.getSubjectsTree().subscribe(
                (data: CHNode[]) => {
                    this.subjects = data
                },
                (error) => console.error(`[CUSTOM Error]${error}`)
            )
        )

        this.addSub(
            this.localizationService.localizeData.subscribe((response) => {
                if (response && response.stateName) {
                    this.stateName = response.stateName
                } else {
                    this.stateName = null
                }
            })
        )
    }

    public getAvailableSubjects(discipline) {
        return discipline.children.filter((node) => node.resultsCount > 0)
    }

    public openStudentExperienceInNewTab() {
        if (this.shouldDisableStudentSite$.value) return
        this.userService.switchUserExperience(true, "_blank")
    }

    private handleShouldDisableStudentSite() {
        combineLatest([this.previewService.previewOnResource$, this.previewService.resource$])
            .pipe(
                takeUntil(this.destroyed$),
                tap(([previewOnResource, resource]: [boolean, Resource]) => {
                    this.shouldDisableStudentSite$.next(
                        previewOnResource && resource && !resource.isAvailableToStudents
                    )
                    this.cdr.detectChanges()
                })
            )
            .subscribe()
    }

    navigateTo(url: string[]): void {
        this.router.navigate(url)
    }

    onTogglePanel(panelName: string) {
        panelName === "subject"
            ? (this.gradesDropdown.isGradesPanelOpened = false)
            : (this.subjectsDropdown.isSubjectsPanelOpened = false)
    }

    ngOnDestroy() {
        this.destroyed$.next()
        this.destroyed$.complete()
        super.ngOnDestroy()
    }
}
