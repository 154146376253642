import { Observable, throwError as observableThrowError } from "rxjs"

import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { catchError } from "rxjs/operators"
import { Cookie } from "../models/cookie.model"
import { CookiesService } from "./cookies.service"
import { LmApiService } from "./lm-api.service"

@Injectable()
export class PromotionalAdsService {
    private organizationId: Cookie

    constructor(
        private cookieService: CookiesService,
        private http: HttpClient,
        private lmApi: LmApiService
    ) {
        this.organizationId =
            this.cookieService.getCookie("organization") || new Cookie("organization", "")
    }

    public getAds(): Observable<any> {
        let url = `/api/v2/get_adds/`
        if (this.organizationId.value) {
            url = `${url}${this.organizationId.value}/`
        }
        return this.lmApi
            .getWithCookiesInParams(url)
            .pipe(catchError((error) => observableThrowError(error)))
    }
}
