<h5 class="pb-6 pt-6 text-center m-0">Explore LearningMedia Resources by Subject</h5>
<div class="d-flex flex-wrap justify-content-center">
    <ng-container *ngIf="subjects">
        <app-link
            *ngFor="let subject of subjects"
            class="subject-item d-flex col-6 col-sm-4 col-md-3"
            [appRouterLink]="['/subjects/' + subject.slug]"
        >
            <app-avatar
                class="avatar"
                [avatarImage]="subject.posterImage"
                [avatarTitle]="subject.title"
                [titleColor]="ColorCode.SubjectColor[subject.id]"
            >
            </app-avatar>
        </app-link>
    </ng-container>
</div>
