<div class="code-input-wrapper">
    <input
        #input
        type="text"
        class="code-input placeholder-fix"
        placeholder="Enter code from your teacher"
        aria-label="Enter code from your teacher"
        (keyup.enter)="enterCode(input.value)"
    />
    <button
        type="button"
        class="code-input-btn gtm-student-menu-enter-code-go"
        (click)="enterCode(input.value)"
    >
        Go
    </button>
</div>
