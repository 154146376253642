import { PathLocationStrategy } from "@angular/common"
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http"
import { ErrorHandler, Injectable, NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig,
    HammerModule,
} from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"

import * as Sentry from "@sentry/browser"
import { NotificationAnimationType, SimpleNotificationsModule } from "angular2-notifications"
import * as Hammer from "hammerjs"
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha"

import { environment } from "../environments/environment"

// Swiper
import { SwiperModule } from "swiper/angular"

// Modules
import { AppRoutingModule } from "./app-routing.module"

// Components
import { AppComponent } from "./app.component"
import { EmbedModule } from "./embed/embed.module"
import { AssignmentResultsProxyComponent } from "./error/assignment-results-proxy/assignment-results-proxy.component"
import { ErrorModule } from "./error/error.module"
import { LaunchAssignmentProxyComponent } from "./error/launch-assignment-proxy/launch-assignment-proxy.component"
import { LoginRequiredComponent } from "./error/login-required/login-required.component"
import { NotAuthGuard } from "./guards/NotAuth.guard"
import { TeacherExperienceOnlyGuard } from "./guards/TeacherExperienceOnly.guard"
import { TeacherAuthGuard } from "./guards/TeacherAuth.guard"
import { HomepageModule } from "./homepage/homepage.module"

// Services
import { BrowsingService } from "./services/browsing.service"
import { ColorCodeService } from "./services/color-code.service"
import { CookiesService } from "./services/cookies.service"
import { FacetingService } from "./services/faceting.service"
import { FeaturedService } from "./services/featured.service"
import { GTMService } from "./services/gtm.service"
import { LessonBuilderService } from "./services/lesson-builder.service"
import { LmApiService } from "./services/lm-api.service"
import { LocalizationService } from "./services/localization.service"
import { PendoService } from "./services/pendo.service"
import { SearchingService } from "./services/searching.service"
import { UserAgentService } from "./services/user-agent.service"
import { UserService } from "./services/user.service"
import { AddOnViewService } from "./services/add-on-view.service"
import { AssignModalModule } from "./shared-components/modals/assign-modal/assign-modal.module"
import { SharedComponentsModule } from "./shared-components/shared-components.module"
import { SignupModule } from "./signup/signup.module"
import { UserProfileModule } from "./user-profile/user-profile.module"
import Utils from "./utils/utils"
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular"
import { StudentAssignmentService } from "./services/student-assignment.service"
import { TeacherAssignmentsService } from "./services/teacher-assignments.service"
import { FoldersService } from "./services/folders.service"
import { AuthRequiredGuard } from "./guards/AuthRequired.guard"
import { IframeGuard } from "./guards/Iframe.guard"
import { StudentService } from "./services/student.service"
import { PreviousRouteService } from "./services/previous-route.service"
import { MessageService } from "./services/message.service"
import { StandardsService } from "./services/standards.service"
import { DataPrivacyModalGuard } from "./guards/DataPrivacyModal.guard"
import { StudentGateInterceptor } from "./interceptors/StudentGateInterceptor"
import { StudentGateSearchGuard } from "./guards/StudentGateSearch.guard"
import { CautionFlagsService } from "./services/caution-flags.service"
import { PreviewService } from "./services/preview.service"
import { NewsletterService } from "./newsletter/services/newsletter.service"

declare var window: any
Sentry.init({
    dsn: window.PBSLM.SENTRY_JS_DSN,
    release: window.PBSLM.NG.VERSION,
    sampleRate: environment.sentry.sampleRate,
})

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}

    handleError(error) {
        if (!Utils.isIEBrowser() && !Utils.isEdgeBrowser() && environment.sentry.activate) {
            Sentry.captureException(error.originalError || error)
        }
        throw error
    }
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    overrides = <any>{
        swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
        pinch: { enable: false },
        rotate: { enable: false },
        pan: { enable: false },
    }
}

// Allow trailing slash in routes
// For details, see https://stackoverflow.com/questions/48425111/angular-5-allow-trailing-slash-in-routes/48657772#
const _orig_prepareExternalUrl = PathLocationStrategy.prototype.prepareExternalUrl

PathLocationStrategy.prototype.prepareExternalUrl = function (internal) {
    const url = _orig_prepareExternalUrl.call(this, internal)
    const /** @type {?} */ match = url.match(/#|\?|$/)
    const /** @type {?} */ pathEndIdx = (match && match.index) || url.length
    const /** @type {?} */ droppedSlashIdx = pathEndIdx - (url[pathEndIdx - 1] === "/" ? 1 : 0)
    const first = url.slice(0, droppedSlashIdx)
    const last = url.slice(pathEndIdx)

    if (url === "") {
        return url
    }

    // handle url params
    if (match) {
        return first + "/" + last
    }

    if (url.endsWith("/")) {
        return url
    }
    return url + "/"
}

@NgModule({
    declarations: [
        AppComponent,
        LoginRequiredComponent,
        LaunchAssignmentProxyComponent,
        AssignmentResultsProxyComponent,
    ],

    imports: [
        EditorModule,
        BrowserModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        NgbModule,
        AppRoutingModule,
        SharedComponentsModule.forRoot(),
        HomepageModule,
        EmbedModule,
        ErrorModule,
        BrowserAnimationsModule,
        SignupModule,
        UserProfileModule,
        AssignModalModule,
        EditorModule,
        SimpleNotificationsModule.forRoot({
            timeOut: 1500,
            animate: NotificationAnimationType.FromBottom,
            position: ["top", "right"],
        }),
        RecaptchaV3Module,
        SwiperModule,
        HammerModule,
    ],

    providers: [
        {
            provide: TINYMCE_SCRIPT_SRC,
            useValue:
                "https://s3.amazonaws.com/pbs.panda-prod.cdn/assets/tinyMCE/js/tinymce/tinymce.min.js",
        },
        { provide: HTTP_INTERCEPTORS, useClass: StudentGateInterceptor, multi: true },
        BrowsingService,
        SearchingService,
        FacetingService,
        UserService,
        AddOnViewService,
        PreviousRouteService,
        GTMService,
        FeaturedService,
        LocalizationService,
        CookiesService,
        ColorCodeService,
        CautionFlagsService,
        NotAuthGuard,
        TeacherAuthGuard,
        IframeGuard,
        AuthRequiredGuard,
        StudentGateSearchGuard,
        TeacherExperienceOnlyGuard,
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig,
        },
        PendoService,
        LmApiService,
        UserAgentService,
        { provide: Window, useValue: window ? window : {} },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: window.PBSLM.NG.CAPTCHA_SITE_KEY },
        LessonBuilderService,
        StudentAssignmentService,
        TeacherAssignmentsService,
        FoldersService,
        StudentService,
        AddOnViewService,
        StandardsService,
        PreviousRouteService,
        MessageService,
        DataPrivacyModalGuard,
        PreviewService,
        NewsletterService,
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
