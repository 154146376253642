import { Injectable, RendererFactory2 } from "@angular/core"
import { ReplaySubject } from "rxjs"

declare var window: any

export interface IWindowResizeEvent {
    isDesktop: boolean
    isSmallDesktop: boolean
    isTablet: boolean
    isSmallDevice: boolean
    isMobile: boolean
    innerWidth: number
}

export enum ScreenBreakpoints {
    DesktopSmall = 1199,
    Tablet = 991,
    SmallDevice = 767,
    Phone = 576,
}

// TODO this needs to be refactored, I found places where rendererFactory2 is not working as expected
// we can use https://material.angular.io/cdk/layout/overview instead
@Injectable()
export class WindowSizeService {
    resize = new ReplaySubject<IWindowResizeEvent>(1)
    isDesktop: boolean
    isSmallDesktop: boolean
    isTablet: boolean
    isSmallDevice: boolean
    isMobile: boolean

    innerWidth: number = 0

    constructor(private rendererFactory: RendererFactory2) {
        this.init()
    }

    private init() {
        this.updateBreakpointStatus()
        const renderer = this.rendererFactory.createRenderer(null, null)
        renderer.listen("window", "resize", () => {
            this.updateBreakpointStatus()
        })
    }

    private updateBreakpointStatus() {
        this.isDesktop = window.innerWidth > ScreenBreakpoints.DesktopSmall
        this.isSmallDesktop = window.innerWidth <= ScreenBreakpoints.DesktopSmall
        this.isTablet = window.innerWidth <= ScreenBreakpoints.Tablet
        this.isSmallDevice = window.innerWidth <= ScreenBreakpoints.SmallDevice
        this.isMobile = window.innerWidth <= ScreenBreakpoints.Phone
        this.innerWidth = window.innerWidth
        this.notifyListeners()
    }

    private notifyListeners() {
        this.resize.next({
            isDesktop: this.isDesktop,
            isSmallDesktop: this.isSmallDesktop,
            isTablet: this.isTablet,
            isSmallDevice: this.isSmallDevice,
            isMobile: this.isMobile,
            innerWidth: this.innerWidth,
        })
    }
}
