export class JoinedRoster {
    constructor(
        public displayName: string,
        public rosterName: string,
        public teacherName: string,
        public rosterCode: string,
        public dateCreated: Date,
        public leaveRosterURL: string
    ) {}

    public static fromData(data): JoinedRoster {
        return new JoinedRoster(
            data.display_name,
            data.roster?.name,
            data.roster?.teacher_name,
            data.roster?.roster_code,
            new Date(data.date_created),
            data.leave_roster_url
        )
    }
}
