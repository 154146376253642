import { Asset } from "../resource/asset"
import { QuestionModel } from "./question.model"
import { SlideTypeEnum } from "./slideType.enum"

export type Slide = SlideModel | MediaSlideModel | TextSlideModel | QuestionSlideModel

export class SlideModel {
    public active: boolean = false
    public type: SlideTypeEnum = null
    constructor(
        public id: number,
        public order: number,
        public name: string,
        public description: string
    ) {}
}

// tslint:disable-next-line:max-classes-per-file
export class MediaSlideModel extends SlideModel {
    public type: SlideTypeEnum = SlideTypeEnum.MEDIA
    constructor(
        public id: number,
        public order: number,
        public name: string,
        public description: string,
        public brand: string,
        public creditsLink: string,
        public asset: Asset
    ) {
        super(id, order, name, description)
    }
}

// tslint:disable-next-line:max-classes-per-file
export class TextSlideModel extends SlideModel {
    public type: SlideTypeEnum = SlideTypeEnum.TEXT
    constructor(
        public id: number,
        public order: number,
        public name: string,
        public description: string
    ) {
        super(id, order, name, description)
    }
}

// tslint:disable-next-line:max-classes-per-file
export class QuestionSlideModel extends SlideModel {
    public type: SlideTypeEnum = SlideTypeEnum.QUESTION
    public currentPage: number = 0 // used to keep the current page in the questions pagination
    constructor(
        public id: number,
        public order: number,
        public name: string,
        public description: string,
        public questions: QuestionModel[]
    ) {
        super(id, order, name, description)
    }

    public getUnansweredQuestions() {
        this.questions.filter((question) => !question.hasAnswer())
    }
}
