<div class="browse-card-meta">
    <div class="content-project selenium-card-content-project" *ngIf="contentProject">
        {{ contentProject.title }}
    </div>

    <div
        class="brand selenium-result-item-brand"
        *ngIf="brand && !(contentProject && contentProject.title)"
    >
        <span class="label">{{ brand }}</span>
    </div>

    <div class="grades selenium-result-item-grades" *ngIf="gradesDisplay">
        Grades&nbsp;{{ gradesDisplay }}
    </div>

    <a
        class="support-materials selenium-card-sm-icon gtm-results-browse-card"
        *ngIf="supportMaterialsCount"
    >
        <app-icon
            ngbTooltip="{{ getToolTipText() }}"
            type="SupportMaterials"
            class="support-materials-icon"
        ></app-icon>
    </a>
</div>
