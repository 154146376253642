import {
    BehaviorSubject,
    Observable,
    of as observableOf,
    Subject,
    throwError as observableThrowError,
} from "rxjs"

import { HttpParameterCodec, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { catchError, map } from "rxjs/operators"

import { Params } from "@angular/router"
import { CPFacet } from "../models/cpfacet.model"
import { SearchInfoModel } from "../models/searchInfo.model"
import { LmApiService } from "./lm-api.service"
import { UserService } from "./user.service"
import { CHNode } from "../models/node.model"
import { AddOnViewService } from "./add-on-view.service"
import { CookiesService } from "./cookies.service"

// Revert/Modify usage after fix in Angular: https://github.com/angular/angular/issues/18261
class NoopEncoder implements HttpParameterCodec {
    public encodeKey(key: string): string {
        return key
    }

    public encodeValue(value: string): string {
        return value
    }

    public decodeKey(key: string): string {
        return key
    }

    public decodeValue(value: string): string {
        return value
    }
}

@Injectable()
export class SearchingService {
    public availableFacetsChanged = new Subject<object>()
    public mediaTypes = ["resource", "collection"]
    public resultsAreLoading = new Subject<boolean>()
    public resultsPerPage: number = 10
    public searchInfo: BehaviorSubject<SearchInfoModel> = new BehaviorSubject(
        new SearchInfoModel({})
    )
    public resultsCount: BehaviorSubject<number> = new BehaviorSubject(0)
    public resultsFavoriteCount: BehaviorSubject<number> = new BehaviorSubject(0)

    constructor(
        private lmApi: LmApiService,
        private userService: UserService,
        private addOnViewService: AddOnViewService,
        private cookiesService: CookiesService
    ) {}

    public cleanFilterValue(filter) {
        if (filter.indexOf(":") === -1) {
            return filter
        }
        const [key, value] = filter.split(":")
        return `${key}:${encodeURIComponent(value)}`
    }

    public prepareParams(queryParams: Params, collectionSlug: string = ""): HttpParams {
        let params = new HttpParams({ encoder: new NoopEncoder() })

        if (Object.getOwnPropertyNames(queryParams).indexOf("q") === -1) {
            params = params.append("q", "")
        }

        Object.getOwnPropertyNames(queryParams).forEach((param) => {
            let paramValues = queryParams[param]

            if (!Array.isArray(paramValues)) {
                paramValues = [paramValues]
            }
            paramValues.forEach((value) => {
                let cleanedValue
                if (param === "q") {
                    cleanedValue = this.cleanSearchQuery(value)
                } else {
                    cleanedValue = this.cleanFilterValue(value)
                }

                if (param === "page") {
                    const start = (paramValues - 1) * 10
                    params = params.append("start", start.toString())
                } else {
                    params = params.append(param, cleanedValue)
                }
            })
        })

        params = params.append(
            "facet_by",
            "accessibility,additional_features,cp,cs,ct,grades,subject,language,media_type,duration"
        )

        if (this.cookiesService.getCookieValue("organization")) {
            let orgGuid = this.cookiesService.getCookieValue("organization")
            params = params.append("excluded_facet", `resource_opt_out_organizations:${orgGuid}`)
        }

        if (this.userService.hasStudentExperience() || this.addOnViewService.isAddonView) {
            params = params.append("selected_facet", "student_content:true")
        }

        if (this.addOnViewService.isAddonView) {
            params = params.append("excluded_facet", "media_type:Collection,Lesson Plan")
        }

        if (collectionSlug) {
            params = params.append("collection_slug", collectionSlug)
        }

        return params
    }

    public cleanSearchQuery(query) {
        return encodeURIComponent(query || "")
    }

    public getPremiumCollectionSearchInfo(collectionSlug) {
        return this.lmApi
            .get(`/api/v2/collection/search_info/${collectionSlug}`)
            .pipe(
                catchError((error) => observableThrowError(error)),
                map((response: any) => {
                    if (response && response.ch_filter) {
                        const tmpSearchInfo = new SearchInfoModel(response)
                        tmpSearchInfo.chFilter = response.ch_filter.map((obj) =>
                            CHNode.fromObject(obj)
                        )
                        tmpSearchInfo.cpFilter = response.cp_filter.map(
                            (cp) => new CPFacet(cp.id, cp.title)
                        )
                        tmpSearchInfo.customFilterObjects = response.custom_filters
                        return tmpSearchInfo
                    }
                })
            )
            .subscribe((response: SearchInfoModel) => {
                this.searchInfo.next(response)
            })
    }

    public getCollectionSearchResults(queryParams: Params, collectionSlug: string) {
        const params = this.prepareParams(queryParams, collectionSlug)
        return this.lmApi.get("/api/v2/search_collections/", { params }).pipe(
            catchError((error) => observableThrowError(error)),
            map((data: any) => {
                this.resultsCount.next(data.meta.total)
                return data
            })
        )
    }

    public getResults(queryParams: Params): Observable<any> {
        const params = this.prepareParams(queryParams)
        return this.lmApi.getWithCookiesInParamsAll("/api/v2/search/", { params }).pipe(
            map((data: any) => {
                this.resultsCount.next(data.meta.total)
                return data
            }),
            catchError((error) => observableThrowError(error))
        )
    }

    public getFavorites(queryParams: Params): Observable<any> {
        const params = this.prepareParams(queryParams)
        return this.lmApi.get("/api/v2/lesson-builder/search-favorites/?q=", { params }).pipe(
            map((data: any) => {
                this.resultsFavoriteCount.next(data && data.count)
                return data
            }),
            catchError((error) => observableThrowError(error))
        )
    }

    public getMoreResults(uri) {
        return this.lmApi
            .getWithCookiesInParamsAll(uri)
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public getAutocompleteResults(term: string) {
        if (term === "") {
            return observableOf([])
        }
        let params = new HttpParams()
        params = params.append("q", term)
        return this.lmApi
            .get("/api/v2/search_autocomplete/", {
                params,
            })
            .pipe(
                map((res: any) => {
                    res.objects = res.objects.map((suggestion) => ({
                        title: `\"${suggestion.title}\"`,
                    }))
                    return res
                }),
                catchError((error) => observableThrowError(error))
            )
    }

    public getStandardDetails(standardId: string) {
        return this.lmApi
            .get("/api/v2/standard_details/" + standardId)
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public getBrandDetails(brandId: string) {
        return this.lmApi
            .get("/api/v2/entity_details/" + brandId)
            .pipe(catchError((error) => observableThrowError(error)))
    }
}
