import { Component, Input } from "@angular/core"
import { FormControl } from "@angular/forms"

@Component({
    selector: "app-user-profile-errors",
    templateUrl: "./user-profile-errors.component.html",
    styleUrls: ["./user-profile-errors.component.scss"],
})
export class UserProfileErrorsComponent {
    @Input()
    control: FormControl
}
