import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { GRADE_BANDS } from "../../models/constants"
import { CHNode } from "../../models/node.model"
import { BrowsingService } from "../../services/browsing.service"

@Component({
    selector: "app-disciplines",
    templateUrl: "./disciplines.component.html",
    styleUrls: ["./disciplines.component.scss"],
})
export class DisciplinesComponent implements OnInit, OnDestroy {
    chNodes: CHNode[]
    GRADE_BANDS = GRADE_BANDS
    private subscriptions: Subscription[] = []

    constructor(private browsingService: BrowsingService) {}

    ngOnInit() {
        this.subscriptions.push(
            this.browsingService.tree.subscribe(
                (chNodes: CHNode[]) => (this.chNodes = chNodes),
                (error) => console.error(`[CUSTOM Error]${error}`)
            )
        )
    }

    ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe())
    }
}
