import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router"
import { UserService } from "../services/user.service"
import { redirectToError } from "../utils/error-handling"
import { Location } from "@angular/common"

@Injectable()
export class TeacherAuthGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private router: Router,
        private location: Location
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.userService.isLoggedIn()) {
            redirectToError(this.router, this.location, "401")
            return false
        }
        return !this.userService.isStudent()
    }
}
