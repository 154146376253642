import { Observable, throwError as observableThrowError } from "rxjs"

import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { catchError, map } from "rxjs/operators"
import { LearningObject } from "../models/learningObject.model"
import { LmApiService } from "./lm-api.service"
import { LocalizationService } from "./localization.service"
import { RecommendationsModel } from "../models/recommendations.model"

@Injectable()
export class FeaturedService {
    constructor(
        private http: HttpClient,
        private lmApi: LmApiService,
        private localizationService: LocalizationService
    ) {}

    public getFeaturedResources(): Observable<LearningObject[]> {
        return this.lmApi.getWithCookiesInParams("/api/v2/lm/homepage/resources/").pipe(
            map((response: any) => {
                const resources = response?.objects ?? []
                return resources.map((resource) => new LearningObject(resource))
            }),
            catchError((error) => observableThrowError(error))
        )
    }

    public getFeaturedCollections(): Observable<LearningObject[]> {
        let collectionsURL: string = "/api/v2/lm/homepage/collections/"
        if (this.localizationService.hasOrganization()) {
            collectionsURL += `${this.localizationService.getOrganizationId()}/`
        }
        return this.lmApi.getWithCookiesInParams(collectionsURL).pipe(
            map((response: any) =>
                response.objects.map((collection) => new LearningObject(collection))
            ),
            catchError((error) => observableThrowError(error))
        )
    }

    public getSubjectFeaturedCollections(ch_id: any): Observable<LearningObject[]> {
        let collectionsURL: string = `/api/v2/lm/homepage/subject_collections/${ch_id}/`
        return this.lmApi.getWithCookiesInParams(collectionsURL).pipe(
            map((response: any) =>
                response.objects.map((collection) => new LearningObject(collection))
            ),
            catchError((error) => observableThrowError(error))
        )
    }

    //not used, we remove it?
    public getFeaturedSeries(): Observable<any> {
        return this.lmApi.getWithCookiesInParams("/api/v2/lm/homepage/series/").pipe(
            map((response: any) => response.objects),
            catchError((error) => observableThrowError(error))
        )
    }

    getUserRecommendations(): Observable<LearningObject[]> {
        return this.http.get("/recommendation/").pipe(
            map((response: RecommendationsModel) => {
                return response.results.map(
                    (resource: LearningObject) => new LearningObject(resource)
                )
            })
        )
    }
}
