import { Injectable } from "@angular/core"
import { GradeBandEnum, ORDERED_GRADE_IMAGES, ORDERED_GRADE_KEYS } from "../models/constants"
import Utils from "../utils/utils"
import { UserService } from "./user.service"
import { BehaviorSubject, Observable } from "rxjs"
import { ModalService } from "../shared-components/modal/modal.service"
import { StudentGateModalComponent } from "../shared-components/modals/student-gate-modal/student-gate-modal.component"
import { Params } from "@angular/router"
import { ModalRef } from "../shared-components/modal/modal-ref"

@Injectable({
    providedIn: "root",
})
export class StudentGateService {
    private gradesImages: string[] = ORDERED_GRADE_IMAGES
    private grades: GradeBandEnum[] = ORDERED_GRADE_KEYS
    isNavigationStopped: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    selectedGradeState: BehaviorSubject<string> = new BehaviorSubject<string>(
        window.localStorage.getItem("grade-gate") || ""
    )
    isGateOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    selectedGrade: string = ""
    gradeModal: ModalRef
    utils = Utils
    isLgModalDisplayed: boolean = true
    isDropdownOpened: boolean = false
    collectionPageChoice: BehaviorSubject<string> = new BehaviorSubject<string>(
        this.sessionStorageCollectionFilter
    )
    constructor(private userService: UserService, private modalService: ModalService) {}

    get selectedGradeValue(): string {
        if (!this.userService.hasStudentExperience()) return ""
        return this.selectedGradeState.value
    }
    get selectedGradeBackground(): string {
        return this.selectedGrade
            ? this.gradesImages[this.grades.indexOf(<GradeBandEnum>this.selectedGrade)]
            : ""
    }

    get isDropdownActive(): boolean {
        return this.isDropdownOpened
    }

    get collectionFilterChoice(): Observable<string> {
        return this.collectionPageChoice.asObservable()
    }

    set collectionFilterChoice(nextValue: any) {
        this.collectionPageChoice.next(nextValue)
    }

    setSelectedCollectionGradeOption(gradeOption: string) {
        this.sessionStorageSaveCollectionsFilter = gradeOption
        this.collectionFilterChoice =
            gradeOption === "All grades" ? gradeOption : this.selectedGradeValue
    }

    isPageAllowingDisplayModal(url: string): boolean {
        let whiteListPages: string[] = ["/collection", "/resource/", "/subjects/"]
        return (
            whiteListPages.filter((value) =>
                //regex expression that checks if pathname is starting with
                // value => (segment url) and has 0 or more chars after
                new RegExp("" + value + ".{0,}$").test(url)
            ).length === 1
        )
    }

    get collectionGradeOptions(): any[] {
        // if no grade filter saved in session storage, apply the current selected student grade
        let gradeSelected: boolean =
            !!this.selectedGradeValue && this.sessionStorageCollectionFilter === "N/A"
        let gradeValue: string = !!this.selectedGradeValue ? this.selectedGradeValue : "N/A"
        if (this.selectedGradeValue && this.sessionStorageCollectionFilter === "N/A")
            this.setSelectedCollectionGradeOption(this.selectedGradeValue)
        return [
            { name: "Grade " + gradeValue, value: gradeValue, selected: gradeSelected },
            {
                name: "All grades",
                value: "All grades",
                selected: this.sessionStorageCollectionFilter === "All grades",
            },
        ]
    }

    openStudentGateModal() {
        this.gradeModal = this.modalService.open(StudentGateModalComponent, {
            size: "lg",
        })
        this.modalService.modalBackDrop.instance.clickOutsideToClose = false
        this.isLgModalDisplayed = true
    }

    closeStudentGateModal() {
        if (this.gradeModal) this.gradeModal.close()
    }

    isEmptySearch(params: Params) {
        return Object.values(params).length === 2 && params?.q.length === 0
    }

    isStudentHomePage(url: string): boolean {
        return (
            //link ends with /student/
            new RegExp("^.*/student/$").test(url) ||
            //link ends with /student
            new RegExp("^.*/student$").test(url) ||
            //link ends with /student/?student=true or /student?student=true
            url.replace("/", "").includes("student?student=true")
        )
    }

    isContentPage(urlSegment: string): boolean {
        return urlSegment.startsWith("/resource/") || urlSegment.startsWith("/collection/")
    }

    get isModalBackdropHidden(): boolean {
        return !this.isDropdownActive
    }

    set selectedGradeValue(selectedGrade: string) {
        //between setItem in localstorage and set next state, the interceptor
        // call is done with previous grade, the current order is important
        window.localStorage.setItem("grade-gate", selectedGrade)
        this.selectedGradeState.next(selectedGrade)
    }

    set isGateActive(gateOpenState: boolean) {
        this.isGateOpened.next(gateOpenState)
        this.modalService.toggleAuxiliaries(gateOpenState)
    }

    set navigationOnHold(state: boolean) {
        this.isNavigationStopped.next(state)
    }

    private set sessionStorageSaveCollectionsFilter(selectedFilterValue: string) {
        let jsonObject: string = JSON.stringify({
            collectionSlug: this.currentCollectionSlug,
            selectedFilter: selectedFilterValue,
        })
        sessionStorage.setItem("collection-filter", jsonObject)
    }

    private get sessionStorageCollectionFilter(): string {
        if (!sessionStorage.getItem("collection-filter")) return "N/A"
        let { collectionSlug, selectedFilter } = JSON.parse(
            sessionStorage.getItem("collection-filter")
        )
        if (collectionSlug === this.currentCollectionSlug) return selectedFilter
        return this.selectedGrade || "N/A"
    }

    private get currentCollectionSlug(): string {
        const segments: string[] = window.location.pathname.split("/")
        return `/${segments[1]}/${segments[2]}/`
    }
}
