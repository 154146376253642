import { AfterContentInit, Directive, ElementRef, Input } from "@angular/core"

@Directive({
    selector: "[appAutofocusDirective]",
})
export class AutofocusDirective implements AfterContentInit {
    @Input() autoFocus: boolean
    @Input() delayTime: number = 500

    constructor(private el: ElementRef) {}

    public ngAfterContentInit() {
        setTimeout(() => {
            this.el.nativeElement.focus()
        }, this.delayTime)
    }
}
