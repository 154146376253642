import { Component, Input } from "@angular/core"
import { UserService } from "../../../services/user.service"
import { CardComponent } from "../card.component"

@Component({
    selector: "app-collection-browse-card",
    templateUrl: "./collection-browse-card.component.html",
    styleUrls: ["./collection-browse-card.component.scss"],
})
export class CollectionBrowseCardComponent extends CardComponent {
    @Input() public gtmDataCardNumber: number
    @Input() posterImageDimension: string

    constructor(public userService: UserService) {
        super(userService)
    }
}
