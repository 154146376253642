<article>
    <section class="top-section ps-6 pe-6 d-flex flex-row align-items-end">
        <div class="col-12 col-sm col-lg-8 p-0 pe-6 info-text-container">
            <h1
                class="mb-6 text-sm-left selenium-page-title"
                [ngClass]="isResourceExpired ? 'text-left' : 'text-center'"
            >
                {{ isResourceExpired ? "This resource has expired (404)" : "404" }}
            </h1>
            <div class="description me-md-8">
                <span *ngIf="!isResourceExpired && !customErrorMessage">
                    We can’t seem to find the page you’re looking for. Check to see if that address
                    is correct {{ (!isAddOnView && "or try search.") || "." }}
                </span>
                <span *ngIf="isResourceExpired && expirationDate && !customErrorMessage">
                    It looks like the resource you're trying to access expired on
                    {{ expirationDate | date: "MM/dd/yyyy" }}
                    due to a limited license or other restrictions.
                    {{
                        (!isAddOnView &&
                            "Try a keyword search, or check out similar resources below.") ||
                            ""
                    }}
                </span>
                <span *ngIf="!isResourceExpired && customErrorMessage">
                    {{ customErrorMessage }}
                </span>
            </div>
            <app-external-search-input
                *ngIf="!isAddOnView"
                [placeholder]="'Search Keyword(s)'"
                [type]="'for-error'"
                class="d-block"
            ></app-external-search-input>
        </div>
        <div *ngIf="innerWidth >= 480">
            <img class="error-img d-sm-block" [src]="image" />
        </div>
    </section>
    <section class="bottom-section w-100 pb-2" *ngIf="!isAddOnView">
        <app-subject-list
            class="d-block ps-6 pe-6"
            [subjects]="subjects"
            *ngIf="!isResourceExpired"
        ></app-subject-list>
        <app-resource-list
            class="d-flex flex-wrap justify-content-between ms-2 me-2"
            *ngIf="isResourceExpired"
            [learningObjects]="learningObjects"
        ></app-resource-list>
    </section>
</article>
