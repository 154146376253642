import { Component, Input, OnInit } from "@angular/core"
import { ModalRef } from "../../modal/modal-ref"
import { NavigationExtras, Router } from "@angular/router"

@Component({
    selector: "app-share-assignment-code-modal",
    templateUrl: "./share-assignment-code-modal.component.html",
    styleUrls: ["./share-assignment-code-modal.component.scss"],
})
export class ShareAssignmentCodeModalComponent implements OnInit {
    @Input() code: string = ""
    public shareLink: string = ""
    constructor(public activeModal: ModalRef, private router: Router) {}

    public ngOnInit() {
        this.shareLink = `${window.location.origin}/student/code/${this.code}/`
    }

    navigateToAwaitingTab() {
        this.activeModal.close()
        this.router.navigate(["/tools/assignments/tab/", "awaiting"])
    }
}
