import { QuestionTypeEnum } from "./questionType.enum"
export type Question =
    | QuestionModel
    | FillQuestionModel
    | MultipleQuestionModel
    | ShortQuestionModel
    | TrueFalseQuestionModel
    | EssayQuestionModel

export class QuestionModel {
    public userInput: any
    public maxCount: number = 0
    constructor(public id: number, public order: number, public text: string, public userAnswer) {
        if (this.hasAnswer()) {
            this.userInput = this.getAnswer()
        }
    }

    public static fromData(data: any) {
        let question: QuestionModel
        const q = data.question
        if (q.type === QuestionTypeEnum.FILL) {
            question = new FillQuestionModel(q.pk, q.order, q.text, data.user_answer)
        }
        if (q.type === QuestionTypeEnum.MULTIPLE) {
            question = new MultipleQuestionModel(q.pk, q.order, q.text, data.user_answer, q.choices)
        }
        if (q.type === QuestionTypeEnum.SHORT) {
            question = new ShortQuestionModel(q.pk, q.order, q.text, data.user_answer)
        }
        if (q.type === QuestionTypeEnum.TRUE_FALSE) {
            question = new TrueFalseQuestionModel(
                q.pk,
                q.order,
                q.text,
                data.user_answer,
                q.choices
            )
        }
        if (q.type === QuestionTypeEnum.ESSAY) {
            question = new EssayQuestionModel(q.pk, q.order, q.text, data.user_answer)
        }
        return question
    }

    public hasAnswer() {
        return !!Object.keys(this.userAnswer).length
    }

    public hasInput() {
        return !!this.userInput
    }

    public getAnswerText() {
        if (this.hasAnswer() && this.userAnswer.text) {
            return this.userAnswer?.text[0]
        }
        return ""
    }

    public getAnswerOption() {
        if (this.hasAnswer() && this.userAnswer.options) {
            return this.userAnswer?.options[0]
        }
        return null
    }

    public getAnswer() {
        if (
            this instanceof ShortQuestionModel ||
            this instanceof EssayQuestionModel ||
            this instanceof FillQuestionModel
        ) {
            return this.getAnswerText()
        }
        return this.getAnswerOption()
    }

    getAnswerValidation() {
        if (!(this instanceof ShortQuestionModel) && !(this instanceof EssayQuestionModel)) {
            return this.userAnswer?.correct
        }
        return null
    }
}

// tslint:disable-next-line:max-classes-per-file
export class FillQuestionModel extends QuestionModel {
    constructor(public id: number, public order: number, public text: string, public userAnswer) {
        super(id, order, text, userAnswer)
    }
}

// tslint:disable-next-line:max-classes-per-file
export class MultipleQuestionModel extends QuestionModel {
    constructor(
        public id: number,
        public order: number,
        public text: string,
        public userAnswer,
        public options: any[]
    ) {
        super(id, order, text, userAnswer)
    }
}

// tslint:disable-next-line:max-classes-per-file
export class ShortQuestionModel extends QuestionModel {
    public maxCount = 250
    constructor(public id: number, public order: number, public text: string, public userAnswer) {
        super(id, order, text, userAnswer)
    }
}

// tslint:disable-next-line:max-classes-per-file
export class TrueFalseQuestionModel extends QuestionModel {
    constructor(
        public id: number,
        public order: number,
        public text: string,
        public userAnswer,
        public options: any[]
    ) {
        super(id, order, text, userAnswer)
    }
}

// tslint:disable-next-line:max-classes-per-file
export class EssayQuestionModel extends QuestionModel {
    public maxCount = 3000
    constructor(public id: number, public order: number, public text: string, public userAnswer) {
        super(id, order, text, userAnswer)
    }
}
