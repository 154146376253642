import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router"
import { Router } from "@angular/router"
import { UserService } from "../services/user.service"
import { redirectToError } from "../utils/error-handling"
import { Location } from "@angular/common"
import { LMModalService } from "../services/lm-modal.service"

@Injectable()
export class AuthRequiredGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private router: Router,
        private location: Location,
        private lmModalService: LMModalService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userService.isLoggedIn()) {
            return true
        } else if (this.userService.hasStudentExperience()) {
            this.lmModalService.openLoginModal()
            return false
        }

        redirectToError(this.router, this.location, "401")
    }
}
