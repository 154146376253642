import Utils from "../utils/utils"

export class Student {
    public studentRosterId: number = null
    public studentDisplayName: string = null
    public studentGoogleId: number = null
    public selected: boolean = false

    constructor(input) {
        Object.keys(input).map((key) => {
            const property = Utils.convertToCamelcase(key)
            this[property] = input[key]
        })
    }
}
