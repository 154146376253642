import { MediaSlideModel, QuestionSlideModel, SlideModel, TextSlideModel } from "./slide.model"
import { Asset } from "../resource/asset"
import { QuestionModel } from "./question.model"
import { SlideTypeEnum } from "./slideType.enum"
import { URSService } from "../../services/urs.service"

export class StudentAssignmentModel {
    constructor(
        public id: number = 0,
        public title: string = "",
        public description: string = "",
        public code: string = "",
        public resultId: number = null,
        public isILP: boolean = false,
        public slides: SlideModel[] = []
    ) {}

    public static fromData(response: any, ursService: URSService) {
        return new StudentAssignmentModel(
            response.id,
            response.name,
            response.description,
            response.code,
            response.result_id,
            response.is_ilp,
            this.getSlides(response.slides, ursService)
        )
    }

    private static getSlides(slides: any[], ursService: URSService) {
        return slides.map((slide) => {
            if (slide.type === SlideTypeEnum.MEDIA) {
                return new MediaSlideModel(
                    slide.id,
                    slide.order,
                    slide.title,
                    slide.description,
                    slide.brand,
                    slide.credits_link,
                    Asset.fromData(slide.asset, ursService)
                )
            }

            if (slide.type === SlideTypeEnum.TEXT) {
                return new TextSlideModel(slide.id, slide.order, slide.title, slide.description)
            }

            if (slide.type === SlideTypeEnum.QUESTION) {
                const questions = slide.questions.map((question) => {
                    return QuestionModel.fromData(question)
                })
                return new QuestionSlideModel(
                    slide.id,
                    slide.order,
                    slide.title,
                    slide.description,
                    questions
                )
            }
        })
    }
}
