import Utils from "../utils/utils"

export class Attribute {
    public id: number = null
    public logoColor = ""
    public name = ""
    public order: number = null
    public role = ""
    // Todo create roles model/enum
    public roles: string[] = []

    constructor(input) {
        Object.keys(input).map((key) => {
            const property = Utils.convertToCamelcase(key)
            this[property] = input[key]
        })
    }

    public getRoles() {
        return this.roles.join(", ")
    }

    public static fromDataArray(dataArray): Attribute[] {
        const attributions: Attribute[] = []
        dataArray.forEach((element) => {
            const existingEntity = attributions.find((x) => x.id === element.entity_id)
            if (!existingEntity) {
                attributions.push(
                    new Attribute({
                        ...element,
                        id: element.entity_id,
                        role: undefined,
                        roles: [element.role],
                    })
                )
            } else {
                existingEntity.roles.push(element.role)
            }
        })
        return attributions
    }

    getLogo() {
        return this.logoColor
    }
}
