import { Injectable } from "@angular/core"

import { LmApiService } from "./lm-api.service"

@Injectable()
export class MessageService {
    constructor(private lmApi: LmApiService) {}

    public getMessages() {
        return this.lmApi.get("/api/v2/messages/")
    }
}
