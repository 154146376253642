import { Injectable } from "@angular/core"
import { AlertData } from "../models/alertData.model"
import { WindowSizeService } from "./window-size.service"
import { EMPTY, Observable } from "rxjs"
import { ModalService } from "../shared-components/modal/modal.service"
import { catchError, map } from "rxjs/operators"
import Utils from "../utils/utils"
import { LmApiService } from "./lm-api.service"
import { NotificationsService } from "angular2-notifications"

@Injectable()
export class AddOnViewService {
    // iframe-add-on experience is defined by '/add-on/' url segment, based on this segment, the experience is true | false
    private isAddonExperience = Utils.isAddOnExperienceLink(window.location.pathname, "/add-on/")
    private alertData: AlertData = {
        classes: "search-page",
        text_color: "#000",
        page_regex: "(/add-on/resource/)",
        day_number: 90,
        mobile_background_color: "#81d4ed1f",
        background_color: "#81d4ed1f",
        call_to_action: "VISIT THE FULL SITE",
        link: "/",
        link_target: "_blank",
        title: "Bring the world to your Google Classroom",
        text:
            "PBS LearningMedia has curated FREE curriculum-aligned videos and a wealth of high-quality educational media for you and your students." +
            " Visit our full experience to see our full suite of lesson plans, curated collections, and professional development to enrich your classroom.",
    }
    isTeacherPreview: boolean = true

    constructor(
        private modalService: ModalService,
        private lmApiService: LmApiService,
        private notificationService: NotificationsService
    ) {
        this.modalService.modalBackDrop.instance.clickOutsideToClose = !this.isAddonExperience
        window.addEventListener("message", this.listenForPopUpClose.bind(this), false)
    }

    // the return state of this function will decide if log in is required or not and based on return state provided by
    // this function, the iframeGuard will redirect user to login page experience,
    //Function cases:
    // 1. Is resource page and url has params
    //    - check and set teacherPreview boolean state
    //    - is auto-assign param present - return false else return isTeacherPreview
    // 2. Is student and is loggedIn - return true, logOut is Done.
    // 3. Default - return !isUserLoggedIn - false
    public isLoginRequired(isUserLoggedIn: boolean, isStudent: boolean): boolean {
        let params = Utils.extractUrlQueryParams(window.location.href)
        // case for teachers loggedIn and params available
        if (!isStudent && isUserLoggedIn && params) {
            // teacher view is true if !attachmentId -> logIn not required
            this.isTeacherPreview = !params.hasOwnProperty("attachmentId")
        } else if (params && params.hasOwnProperty("attachmentId")) {
            // this check is intended to allow users (student/teacher) to preview resource without any log in data
            // this also is important for teacher-student toggle show/hide
            this.isTeacherPreview = false
            return false
        }
        return !isUserLoggedIn
    }

    get isAddonView() {
        return this.isAddonExperience
    }

    get classroomAlertData() {
        return this.alertData
    }

    public createAttachment(resourceCode): Observable<void> {
        const url = "/add-on/create_attachment/"
        const data = { resourceCode }

        // we open up the modal first,
        // to bypass safari trusted event issue (pop-up)
        const popUpWindowRef = Utils.openPopUp("")
        popUpWindowRef.document.write(
            "<div style='position:absolute; top:50%; left:50%; transform:translateX(-50%)translateY(-50%)' >Loading, Please wait...</div>"
        )

        // stop event listener from global dom
        return this.lmApiService.post(url, data).pipe(
            map((response) => {
                // set default for location, if not received from api response
                const { location = null } = response

                // if status 200 and no location, it means the user granted permission,
                // we close the popup
                if (!location) {
                    AddOnViewService.closeAddonIframe()
                    return popUpWindowRef.close()
                }

                // if status 200 and location, it means the user did not grant permission,
                // we should redirect to new location
                popUpWindowRef.location = location
            }),
            catchError((err) => {
                // if no status or any other status than 200 notify user and close the pop-up
                const msg = (err && err.error && err.error.msg) || ""
                popUpWindowRef.close()
                this.lmApiService.handleErrorForNonCritical(msg)
                return EMPTY
            })
        )
    }

    private static closeAddonIframe(): void {
        window.parent.postMessage(
            {
                type: "Classroom",
                action: "closeIframe",
            },
            "*"
        )
    }

    private listenForPopUpClose(event) {
        if (!event || !event.data) return

        const { message, type } = event.data
        if (message !== "close" || type !== "Classroom") return

        this.notificationService.info(
            "Permissions Granted",
            "Please click assign again.",
            Utils.alertConfiguration
        )
        window.removeEventListener("message", this.listenForPopUpClose, false)
    }
}
