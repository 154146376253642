<div class="collection-browse-card">
    <app-collection-card
        [learningObject]="learningObject"
        [gtmPrefix]="gtmPrefix"
        [gtmDataCardNumber]="gtmDataCardNumber"
        [posterImageDimension]="posterImageDimension"
    ></app-collection-card>
    <app-card-title
        [routerLink]="learningObject.routerLink"
        [height]="40"
        [rows]="2"
        [queryParams]="userService.getStudentQueryParams()"
        ><span [innerHtml]="learningObject.title"></span
    ></app-card-title>
</div>
