import { Component, EventEmitter, Input, Output } from "@angular/core"

@Component({
    selector: "app-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent {
    @Input() public gtmLabel: string = null
    @Input() public type?: string = ""
    @Input() public checked: boolean = false
    @Output() public checkedChange = new EventEmitter<boolean>()

    public toggle() {
        event.stopPropagation()
        this.checked = !this.checked
        this.checkedChange.emit(this.checked)
    }
}
