import { Component, Input } from "@angular/core"

@Component({
    selector: "app-data-privacy-support",
    templateUrl: "./data-privacy-support.component.html",
})
export class DataPrivacySupportComponent {
    @Input()
    formStackUrl = ""

    isFeedbackFormCollapsed = true
}
