import Utils from "../utils/utils"
import { STATIC_IMAGES } from "./constants"

interface ProfessionalDevelopmentNode {
    name: string
    url: string
}

export class Localize {
    public shortCommonName: string = ""
    public fullCommonName: string = ""
    public eventsTitle: string = ""
    public events: any[] = []
    public professionalDevelopmentTitle: string = ""
    public professionalDevelopmentNodes: ProfessionalDevelopmentNode[] = []
    public freeAreaTitle: string = ""
    public freeAreaDescription: string = ""
    public freeAreaCallToActionText: string = ""
    public freeAreaLink: string = ""
    public freeAreaITSImage: string = ""
    public callSign: string = ""
    public donateUrl: string = ""
    public facebookUrl: string = ""
    public pinterestUrl: string = ""
    public twitterUrl: string = ""
    public stationImages: any[] = []
    public defaultLogo: string = ""
    public stationFooter: string = ""
    public stateName: string = null
    public stateStandards: string = null
    public homepageHeaderITSImage: string = null
    public organizationName: string = ""

    constructor(data: any[]) {
        this.assignObjects(data)
        this.defaultLogo = STATIC_IMAGES.pbs_logo
    }

    private assignObjects(data: any[]) {
        data.forEach((result) => {
            if (result.lmData) {
                Object.keys(result.lmData).forEach((key) => {
                    let property = Utils.convertToCamelcase(key)
                    this[property] = result.lmData[key]
                })
            }

            if (result.stationData && result.stationData.data && result.stationData.data.length) {
                this.setStationData(result.stationData.data[0].attributes)
            }
        })
    }

    private setStationData(data) {
        this.shortCommonName = data.short_common_name || ""
        this.fullCommonName = data.full_common_name || data.short_common_name || ""
        this.facebookUrl = data.facebook_url || ""
        this.twitterUrl = data.twitter_url || ""
        this.pinterestUrl = data.pinterest_url || ""
        this.stationImages = data.images || []
        this.donateUrl = data.donate_url || ""
        // TODO: We handle no callSign as not localized, so we should rely that on
        //  on our LM API instead of station manager (external service).
        this.callSign = data.call_sign || ""
    }

    public getWhiteLogo() {
        return this.getImage("white-single-brand-logo") || this.getImage("white-cobranded-logo")
    }

    public getLogo() {
        return this.getImage("color-single-brand-logo") || this.getImage("color-cobranded-logo")
    }

    private getImage(profileName: string) {
        if (!this.stationImages) {
            return ""
        }
        let image = this.stationImages.find((img) => img.profile === profileName)
        return image ? image.url : ""
    }

    public hasFreeAreaImage(): boolean {
        return !!this.freeAreaITSImage
    }

    public hasFreeAreaText(): boolean {
        return !!this.freeAreaDescription
    }

    public getDisplayName() {
        return this.shortCommonName
    }
}
