<section>
    <p class="m-0">
        All of our tools are free. You can use our Lesson Builder to <b>create</b> and
        <b>save</b> as many lessons as you like. To assign lessons to students and manage classes
        and rosters, we need some additional information.
    </p>

    <ol>
        <li>
            <p class="m-0">Your school district (if applicable)</p>
            <em class="fw-light"
                >Providing your school district allows us to comply with student data privacy
                policies set by each state and/or district.</em
            >
        </li>
        <li>Your acknowledgment of the authority to share student data (required)</li>
    </ol>
</section>

<form [formGroup]="form">
    <app-data-privacy-classroom></app-data-privacy-classroom>
    <app-data-privacy-acknowledgment></app-data-privacy-acknowledgment>

    <div class="d-flex justify-content-center">
        <button
            type="submit"
            class="btn btn-primary px-6 py-3 mt-7 fw-bold font-size-14 shadow gtm-submit-student-privacy"
            [ngClass]="{ 'btn-disabled': isFormInvalid }"
            [disabled]="isFormInvalid"
            (click)="onSubmit()"
        >
            Submit
        </button>
    </div>
</form>
