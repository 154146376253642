import { Injectable } from "@angular/core"
import { LmApiService } from "./lm-api.service"
import { catchError, map } from "rxjs/operators"
import { Observable, of } from "rxjs"
import { StudentAssignments } from "../models/studentAssignmentItem.model"
import { JoinedRoster } from "../models/student-site/joined-roster.model"
import { HeroImage, HeroImagesResponse } from "../models/heroImage.model"
import { ITSImage } from "../models/itsImage.model"

@Injectable({
    providedIn: "root",
})
export class StudentService {
    constructor(private lmApi: LmApiService) {}

    getAssignments() {
        return this.lmApi.get("/api/v2/student/assignments/").pipe(
            map((response: any) => {
                return { assignments: StudentAssignments.fromData(response), error: null }
            }),
            catchError((error) => {
                return of({ assignments: null, error: error })
            })
        )
    }

    loadMoreAssignments(nextURL): Observable<StudentAssignments> {
        return this.lmApi
            .get(nextURL)
            .pipe(map((response: any) => StudentAssignments.fromData(response)))
            .pipe(catchError((error) => this.lmApi.handleErrorForNonCritical(error)))
    }

    getClasses() {
        return this.lmApi.get("/api/v2/student/joined_rosters/").pipe(
            map((response: any) => {
                // TODO we don't handle pagination since it's very unlikely situation, but we should
                return {
                    classes: response.results.map((result) => JoinedRoster.fromData(result)),
                    error: null,
                }
            }),
            catchError((error) => {
                return of({ classes: null, error: error })
            })
        )
    }

    /**
     * Get a list of images that are used as student hero backgrounds,
     * completely customizable from admin menu
     */
    getHeroImages(): Observable<any> {
        return this.lmApi.get("/api/v2/hero-images/").pipe(
            map((response: HeroImagesResponse) => {
                const heroImages: ITSImage[] = response.results.map(
                    (result: HeroImage) => new ITSImage(result.image_url, "Student Hero Image")
                )

                return { heroImages, error: null }
            }),
            catchError((error) => {
                return of({ heroImages: null, error: error })
            })
        )
    }
}
