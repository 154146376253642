import { BehaviorSubject, Observable, throwError } from "rxjs"
import { Injectable } from "@angular/core"
import { catchError, map, tap } from "rxjs/operators"
import { LmApiService } from "./lm-api.service"
import { GradeBandEnum, SubjectEnum } from "../models/constants"
import { SCHOOL_TYPE, SCHOOL_TYPE_TEACHER_DESCRIPTION } from "../models/userDataPrivacy.model"
import { UserProfile, UserProfileAPI } from "../models/userProfile.model"

@Injectable()
export class UserProfileService {
    private _userProfile: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null)
    readonly apiUrl = "/api/v2/user_profile/"

    constructor(private lmApi: LmApiService) {}

    get userProfile(): UserProfile {
        return this._userProfile.value
    }

    get userProfile$(): Observable<UserProfile> {
        return this._userProfile.asObservable()
    }

    set userProfile(value: UserProfile) {
        this._userProfile.next(value)
    }

    getUserProfile(): Observable<any> {
        if (!!this.userProfile) return this.userProfile$
        return this.lmApi.get(this.apiUrl).pipe(
            map((data: UserProfileAPI) => new UserProfile(data)),
            tap((userProfile: UserProfile) => (this.userProfile = userProfile)),
            catchError((error) => throwError(error))
        )
    }

    updateUserProfile(userProfile: any): Observable<any> {
        return this.lmApi
            .put(this.apiUrl, userProfile)
            .pipe(catchError((error) => throwError(error)))
    }

    getTeacherRoles(): string[] {
        return [
            "Early Childhood Educator",
            "K-12 Educator",
            "Administrator",
            "Librarian/Media Specialist",
            "Homeschooler/Parent",
            "Curriculum Specialist/Instructional Coach",
            "Teacher Educator",
            "Informal Educator (after-school, tutor, museum educator, etc.)",
        ]
    }

    getGrades(): string[] {
        return [
            GradeBandEnum.BandPreKtoK,
            GradeBandEnum.BandKto2,
            GradeBandEnum.Band3to5,
            GradeBandEnum.Band6to8,
            GradeBandEnum.Band9to12,
            "N/A",
        ]
    }

    getSubjects(): string[] {
        return [
            SubjectEnum.Science,
            SubjectEnum.SocialStudies,
            SubjectEnum.Mathematics,
            SubjectEnum.EnglishLanguageArts,
            SubjectEnum.EngineeringTechnology,
            SubjectEnum.HealthPhysicalEducation,
            SubjectEnum.Preschool,
            SubjectEnum.ProfessionalDevelopment,
            SubjectEnum.TheArts,
            SubjectEnum.WorldLanguages,
            SubjectEnum.NA,
        ]
    }

    getStates(): any[] {
        return [
            {
                name: "Alabama",
                abbreviation: "AL",
            },
            {
                name: "Alaska",
                abbreviation: "AK",
            },
            {
                name: "American Samoa",
                abbreviation: "AS",
            },
            {
                name: "Arizona",
                abbreviation: "AZ",
            },
            {
                name: "Arkansas",
                abbreviation: "AR",
            },
            {
                name: "California",
                abbreviation: "CA",
            },
            {
                name: "Colorado",
                abbreviation: "CO",
            },
            {
                name: "Connecticut",
                abbreviation: "CT",
            },
            {
                name: "Delaware",
                abbreviation: "DE",
            },
            {
                name: "District Of Columbia",
                abbreviation: "DC",
            },
            {
                name: "Federated States Of Micronesia",
                abbreviation: "FM",
            },
            {
                name: "Florida",
                abbreviation: "FL",
            },
            {
                name: "Georgia",
                abbreviation: "GA",
            },
            {
                name: "Guam",
                abbreviation: "GU",
            },
            {
                name: "Hawaii",
                abbreviation: "HI",
            },
            {
                name: "Idaho",
                abbreviation: "ID",
            },
            {
                name: "Illinois",
                abbreviation: "IL",
            },
            {
                name: "Indiana",
                abbreviation: "IN",
            },
            {
                name: "Iowa",
                abbreviation: "IA",
            },
            {
                name: "Kansas",
                abbreviation: "KS",
            },
            {
                name: "Kentucky",
                abbreviation: "KY",
            },
            {
                name: "Louisiana",
                abbreviation: "LA",
            },
            {
                name: "Maine",
                abbreviation: "ME",
            },
            {
                name: "Marshall Islands",
                abbreviation: "MH",
            },
            {
                name: "Maryland",
                abbreviation: "MD",
            },
            {
                name: "Massachusetts",
                abbreviation: "MA",
            },
            {
                name: "Michigan",
                abbreviation: "MI",
            },
            {
                name: "Minnesota",
                abbreviation: "MN",
            },
            {
                name: "Mississippi",
                abbreviation: "MS",
            },
            {
                name: "Missouri",
                abbreviation: "MO",
            },
            {
                name: "Montana",
                abbreviation: "MT",
            },
            {
                name: "Nebraska",
                abbreviation: "NE",
            },
            {
                name: "Nevada",
                abbreviation: "NV",
            },
            {
                name: "New Hampshire",
                abbreviation: "NH",
            },
            {
                name: "New Jersey",
                abbreviation: "NJ",
            },
            {
                name: "New Mexico",
                abbreviation: "NM",
            },
            {
                name: "New York",
                abbreviation: "NY",
            },
            {
                name: "North Carolina",
                abbreviation: "NC",
            },
            {
                name: "North Dakota",
                abbreviation: "ND",
            },
            {
                name: "Northern Mariana Islands",
                abbreviation: "MP",
            },
            {
                name: "Ohio",
                abbreviation: "OH",
            },
            {
                name: "Oklahoma",
                abbreviation: "OK",
            },
            {
                name: "Oregon",
                abbreviation: "OR",
            },
            {
                name: "Palau",
                abbreviation: "PW",
            },
            {
                name: "Pennsylvania",
                abbreviation: "PA",
            },
            {
                name: "Puerto Rico",
                abbreviation: "PR",
            },
            {
                name: "Rhode Island",
                abbreviation: "RI",
            },
            {
                name: "South Carolina",
                abbreviation: "SC",
            },
            {
                name: "South Dakota",
                abbreviation: "SD",
            },
            {
                name: "Tennessee",
                abbreviation: "TN",
            },
            {
                name: "Texas",
                abbreviation: "TX",
            },
            {
                name: "Utah",
                abbreviation: "UT",
            },
            {
                name: "Vermont",
                abbreviation: "VT",
            },
            {
                name: "Virgin Islands",
                abbreviation: "VI",
            },
            {
                name: "Virginia",
                abbreviation: "VA",
            },
            {
                name: "Washington",
                abbreviation: "WA",
            },
            {
                name: "West Virginia",
                abbreviation: "WV",
            },
            {
                name: "Wisconsin",
                abbreviation: "WI",
            },
            {
                name: "Wyoming",
                abbreviation: "WY",
            },
            {
                name: "No state selected",
                abbreviation: null,
            },
        ]
    }

    getTeacherTypes(): Map<SCHOOL_TYPE, SCHOOL_TYPE_TEACHER_DESCRIPTION> {
        return new Map([
            [SCHOOL_TYPE.PUBLIC_SCHOOL, SCHOOL_TYPE_TEACHER_DESCRIPTION.PUBLIC_SCHOOL],
            [SCHOOL_TYPE.PRIVATE_SCHOOL, SCHOOL_TYPE_TEACHER_DESCRIPTION.PRIVATE_SCHOOL],
            [SCHOOL_TYPE.HOME_SCHOOL, SCHOOL_TYPE_TEACHER_DESCRIPTION.HOME_SCHOOL],
        ])
    }
}
