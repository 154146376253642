<div class="wrapper selenium-homepage-hero" [ngStyle]="heroAggregate">
    <div class="container">
        <h1 class="title selenium-homepage-hero-title">Bring the World to Your Classroom</h1>
        <div
            class="description selenium-national-hero-subtitle"
            *ngIf="isNational || !stationDisplayName"
        >
            PBS and your local station have curated FREE, curriculum-aligned videos, interactives,
            lesson plans, and more just for teachers like you.
        </div>
        <div
            class="description selenium-national-hero-subtitle"
            *ngIf="!isNational && stationDisplayName"
        >
            {{ stationDisplayName }} and PBS have curated FREE, curriculum-aligned videos,
            interactives, lesson plans,
            <span *ngIf="stateName">and more just for {{ stateName }} teachers like you.</span>
            <span *ngIf="!stateName">and more for teachers like you.</span>
        </div>
        <div class="search-wrapper">
            <app-external-search-input
                [placeholder]="'Search classroom resources...'"
                [type]="'for-browse'"
            ></app-external-search-input>
        </div>
    </div>
</div>
<div class="oval-shape"></div>
