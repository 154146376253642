<form [formGroup]="classRoomForm" class="mb-2">
    <h4 class="text-medium-blue fw-bold font-size-20 mt-2">Classroom Setting</h4>

    <div class="fw-bold py-2">Select one <span class="required-field">(Required)</span></div>
    <div *ngFor="let type of schools | keyvalue: originalOrder" class="form-check pt-2">
        <input
            type="radio"
            formControlName="teacherType"
            class="form-check-input"
            [id]="type.key"
            [value]="type.key"
        />
        <label class="form-check-label form-label" [for]="type.key"> {{ type.value }} </label>

        <!-- Add dropdown components only for public teacher type -->
        <ng-container *ngIf="type.key === SCHOOL_TYPE.PUBLIC_SCHOOL">
            <div class="row">
                <div class="col-12" [ngClass]="{ 'col-md-8': !isFullWidth }">
                    <ng-template appAddHost></ng-template>
                </div>
            </div>
        </ng-container>
    </div>
</form>
