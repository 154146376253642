<div
    class="modal-body my-2 py-1 text-center"
    [tabindex]="0"
    appAutofocusDirective
    [delayTime]="700"
>
    <h1 class="fw-bold">{{ title }}</h1>
</div>
<div class="modal-footer m-0 d-flex align-items-center justify-content-center flex-wrap flex-row">
    <ul class="d-flex flex-row flex-wrap justify-content-center align-items-center ms-0 ps-0">
        <li
            *ngFor="let key of grades; let index = index"
            class="grade-container d-flex flex-column justify-content-center m-3 gtm-student-grade-gate
            {{ 'gtm-student-selected-grade-' + key }}"
            (click)="changeGradeGate(key, index, $event)"
            (mouseover)="hoverGradeGate(key, true, index)"
            (mouseleave)="hoverGradeGate(key, false, index)"
            [ngStyle]="{ 'background-image': 'url(' + gradesImages[index] + ')' }"
            [ngClass]="{
                'grade-background':
                    studentGateService.selectedGradeValue === key || hoverGrade === key,
                'hover-scale': hoverGrade === key && selectedGrade !== key
            }"
            [tabindex]="0"
        >
            <div
                [tabindex]="0"
                (click)="changeGradeGate(key, index, $event)"
                (keyup)="changeGradeGate(key, index, $event)"
                class="grade-value"
                [ngClass]="{ 'selected-grade': studentGateService.selectedGradeValue === key }"
            >
                {{ key }}
            </div>
        </li>
    </ul>
</div>
