<div class="modal-header">
    <h3 id="helpLabel" class="modal-title-text">Feedback Form</h3>
    <button
        (click)="activeModal.dismiss('Cross click')"
        aria-label="Close"
        class="close"
        data-dismiss="modal"
        type="button"
    >
        <i class="pbslm-close-thin-cross close-img"></i>
    </button>
</div>
<div class="modal-body">
    <iframe
        src="https://pbs-cjoef.formstack.com/forms/pbs_learningmedia"
        title="Feedback Form"
    ></iframe>
</div>
