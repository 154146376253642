import { throwError as observableThrowError } from "rxjs"
import { Component, Input, OnInit } from "@angular/core"
import { catchError } from "rxjs/operators"
import { Roster } from "../../../../models/roster.model"
import { TeacherAssignService } from "../../../../services/teacher-assign.service"
import { ModalRef } from "../../../modal/modal-ref"
import { AssignModalType } from "../assign-modal.component"

@Component({
    selector: "app-assign-modal-content",
    templateUrl: "./assign-modal-content.component.html",
    styleUrls: ["./assign-modal-content.component.scss"],
})
export class AssignModalContentComponent implements OnInit {
    @Input() public activeModal: ModalRef = null
    @Input() public assignmentCode: string = ""
    @Input() public assignmentName: string = ""
    @Input() public assignmentHasLti: boolean = false
    @Input() public type: AssignModalType = AssignModalType.any
    public rosters: Roster[] = null
    public assignedRosters: Roster[] = []
    public step = 1
    public loading: boolean = false
    public error: boolean = false

    constructor(private teacherAssignService: TeacherAssignService) {}

    public ngOnInit() {
        this.teacherAssignService.getRosterSet(this.type).subscribe((rosters) => {
            this.rosters = rosters
        })
    }

    public closeModal() {
        if (this.activeModal) {
            this.activeModal.close()
        }
    }

    public stepChange(step) {
        if (step === 3) {
            this.saveAssignment()
        }
        this.step = step
    }

    public saveAssignment() {
        this.loading = true
        this.teacherAssignService
            .saveAssignment(this.assignmentCode, this.rosters)
            .pipe(
                catchError((error) => {
                    this.error = true
                    this.loading = false
                    return observableThrowError(error)
                })
            )
            .subscribe((data) => {
                if (data.location) {
                    // Missing GC credentials so change the flow to get user permission first.
                    // Currently this breaks the flow because you don't get back to this step
                    // but it's better that shown an invalid success marker
                    window.location.href = data.location
                    return
                }
                this.assignedRosters = data
                this.loading = false
                setTimeout(() => {
                    this.step = 4
                }, 500)
            })
    }

    public resendRequest() {
        this.saveAssignment()
    }
}
