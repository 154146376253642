import { Injectable } from "@angular/core"

@Injectable()
export class UserAgentService {
    public isOnMobileDevice
    public isGoogleCrawler

    private readonly MobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i
    private readonly GoogleCrawlerRegex = /Google Web Preview/
    private readonly GoogleBotRegex = /Googlebot/

    constructor() {
        this.isOnMobileDevice = this.isMobileDeviceDetected()
        this.isGoogleCrawler = this.isGoogleCrawlerDetected()
    }

    private isMobileDeviceDetected() {
        return this.MobileRegex.test(navigator.userAgent)
    }

    private isGoogleCrawlerDetected() {
        return (
            this.GoogleCrawlerRegex.test(navigator.userAgent) ||
            this.GoogleBotRegex.test(navigator.userAgent)
        )
    }
}
