import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { NgSelectModule } from "@ng-select/ng-select"
import { SharedComponentsModule } from "../shared-components/shared-components.module"
import { UserProfileRoutingModule } from "./user-profile-routing.module"
import { UserProfileComponent } from "./user-profile.component"
import { UserProfileService } from "../services/user-profile.service"
import { StickyClassDirective } from "./sticky-class.directive"
import { FullNameComponent } from "./full-name/full-name.component"
import { AccountInfoComponent } from "./account-info/account-info.component"
import { UserLocalizationComponent } from "./user-localization/user-localization.component"
import { UserProfileCheckboxComponent } from "./user-profile-checkbox-group/user-profile-checkbox/user-profile-checkbox.component"
import { UserProfileCheckboxGroupComponent } from "./user-profile-checkbox-group/user-profile-checkbox-group.component"
import { UserProfileErrorsComponent } from "./user-profile-errors/user-profile-errors.component"
import { UserProfileRoleSelectorComponent } from "./user-profile-role-selector/user-profile-role-selector.component"
import { UserProfileActionButtonsComponent } from "./user-profile-action-buttons/user-profile-action-buttons.component"

@NgModule({
    declarations: [
        UserProfileComponent,
        StickyClassDirective,
        UserProfileActionButtonsComponent,
        FullNameComponent,
        AccountInfoComponent,
        UserLocalizationComponent,
        UserProfileCheckboxGroupComponent,
        UserProfileCheckboxComponent,
        UserProfileErrorsComponent,
        UserProfileRoleSelectorComponent,
    ],
    imports: [FormsModule, NgSelectModule, SharedComponentsModule, UserProfileRoutingModule],
    providers: [UserProfileService],
})
export class UserProfileModule {}
