import { Injectable } from "@angular/core"
import { UserDataPrivacyService } from "../../../../services/user-data-privacy.service"
import { ISchoolDistrict } from "../../../../models/userDataPrivacy.model"
import { AbstractDataPrivacyDropdown } from "../../abstract-dropdown.service"

@Injectable()
export class SchoolDistrictDropdownService extends AbstractDataPrivacyDropdown<ISchoolDistrict> {
    constructor(public userDataPrivacyService: UserDataPrivacyService) {
        super()

        const getSchoolDistrictsCallback = (...args) =>
            this.userDataPrivacyService.getSchoolDistricts(...args)
        const getDataNyNextUrlCallback = (nextUrl) =>
            this.userDataPrivacyService.getDataByNextUrl(nextUrl)

        this.registerDataStream(getSchoolDistrictsCallback)
        this.registerTypeaheadStream(getSchoolDistrictsCallback)
        this.registerOnScrollToEnd(getDataNyNextUrlCallback)
    }

    trackByFn(item): string {
        return item.guid
    }
}
