<div
    *ngIf="shouldShowRecommendations()"
    class="container-fluid recommendation-container selenium-app-recommendations"
>
    <div class="row-auto featured-recommendations">
        <div class="title-wrapper">
            <span class="title">What Similar Users Have Viewed:</span>
        </div>
        <app-carousel2
            [itemWidth]="240"
            [alwaysShowArrows]="false"
            [innerArrows]="true"
            height="410"
            [gtmPrefix]="recommendations"
        >
            <app-carousel-item2
                class="carousel-item"
                *ngFor="let recommendation of recommendations"
            >
                <app-resource-featured-card
                    [headerURL]="getBrowseURL(recommendation)"
                    [learningObject]="recommendation"
                    [gtmPrefix]="recommendations"
                    [predictionEstimation]="recommendation.predictionEstimation"
                ></app-resource-featured-card>
            </app-carousel-item2>
        </app-carousel2>
    </div>
</div>
