<div class="step-wrapper">
    <div class="loading" *ngIf="loading">
        <app-preloader></app-preloader>
        <div class="text">Assigning...</div>
    </div>
    <div class="loaded" *ngIf="!loading && !error">
        <app-icon [type]="'Check'"></app-icon>
        <div class="text">Success!</div>
    </div>

    <div class="error" *ngIf="!loading && error">
        <app-icon [type]="'Exclamation-triangle'"></app-icon>
        <div class="text">Something went wrong.</div>
        <div (click)="tryAgain()" class="text2">Please try again.</div>
    </div>
</div>
