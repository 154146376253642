import { Component, Input } from "@angular/core"
import { Roster } from "../../../../models/roster.model"
import { Student } from "../../../../models/student.model"
import { ModalRef } from "../../../modal/modal-ref"
import { TeacherAssignmentsService } from "../../../../services/teacher-assignments.service"
import { STATIC_IMAGES } from "../../../../models/constants"

@Component({
    selector: "app-review-assignments-view",
    templateUrl: "./review-assignments-view.component.html",
    styleUrls: ["./review-assignments-view.component.scss"],
})
export class ReviewAssignmentsViewComponent {
    @Input() public assignmentCode: string = ""
    @Input() public assignmentName: string = ""
    @Input() public rosters: Roster[] = []
    @Input() public activeModal: ModalRef = null

    public googleClassImg: string = ""

    public constructor(private teacherAssignmentService: TeacherAssignmentsService) {
        this.googleClassImg = STATIC_IMAGES.classroom_color_round
    }

    public toggleExpand(roster: Roster): void {
        if (roster) {
            if (roster.expanded) {
                roster.expanded = false
            } else {
                this.toggleOff()
                roster.expanded = true
            }
        }
    }

    public toggleOff() {
        this.rosters.map((r) => (r.expanded = false))
    }

    public hasClassStudents(roster: Roster) {
        return roster.students.find((student: Student) => {
            return student.selected
        })
    }

    public viewAssignments() {
        this.activeModal.close()
        this.teacherAssignmentService.viewLatestAssignedAssignments()
    }
}
