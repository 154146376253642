import { Component, Input } from "@angular/core"

@Component({
    selector: "app-avatar",
    templateUrl: "./avatar.component.html",
    styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent {
    @Input()
    public avatarTitle: string

    @Input()
    public avatarImage: string

    @Input()
    public avatarAltText: string

    @Input()
    public titleColor: string
}
