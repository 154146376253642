import { Component, HostBinding, Input, OnInit } from "@angular/core"
import { ErrorCode } from "../error-code.model"
import { CHNode } from "../../models/node.model"
import { LearningObject } from "../../models/learningObject.model"
import { UserService } from "../../services/user.service"
import { STATIC_IMAGES } from "../../models/constants"
import { AddOnViewService } from "../../services/add-on-view.service"
import { standardErrorMessages } from "../shared/models/standard-error-messages.model"

@Component({
    selector: "app-unauthorized-error",
    templateUrl: "./unauthorized-error.component.html",
    styleUrls: ["./unauthorized-error.component.scss"],
})
export class UnauthorizedErrorComponent implements OnInit {
    @HostBinding("style") hostBinding = "flex: 1"
    @Input() nextURL: string
    @Input() errorCode: ErrorCode
    @Input() customErrorMessage: string
    @Input() showContent: boolean = false
    @Input() subjects: CHNode[] = []
    @Input() learningObjects: LearningObject[] = []

    image: string
    isStudent: boolean
    isAddonView: boolean
    standardErrorMessages = standardErrorMessages
    errorCodes = ErrorCode

    constructor(private userService: UserService, private addOnViewService: AddOnViewService) {}

    ngOnInit(): void {
        this.isAddonView = this.addOnViewService.isAddonView
        this.image = STATIC_IMAGES.lost_in_space
        this.isStudent = this.userService.hasStudentExperience()
    }
}
