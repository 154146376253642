import {
    BehaviorSubject,
    Observable,
    ReplaySubject,
    Subject,
    Subscription,
    throwError as observableThrowError,
} from "rxjs"

import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { catchError, map, tap } from "rxjs/operators"
import { CHNode } from "../models/node.model"
import { Subjects } from "../models/subject.model"
import { LabeledValue, URLFilters } from "../utils/types"
import Utils from "../utils/utils"
import { LmApiService } from "./lm-api.service"
import { UserService } from "./user.service"
import { AddOnViewService } from "./add-on-view.service"
import { ORDERED_MEDIA_TYPES } from "../models/constants"
import { Resource } from "../models/resource/resource"
import { APIResponse } from "../models/apiResponse.model"
import { LearningObject } from "../models/learningObject.model"
import { CookiesService } from "./cookies.service"

@Injectable()
export class BrowsingService {
    public mediaTypes: string[] = ORDERED_MEDIA_TYPES

    public sortByOptions: LabeledValue[] = [
        { value: "relevance", label: "Relevance" },
        { value: "popularity", label: "Popularity" },
        { value: "recency", label: "Newest" },
    ]
    public activeNodeChanged: BehaviorSubject<CHNode> = new BehaviorSubject<CHNode>(CHNode.empty())
    public invalidNodeSelected: Subject<unknown> = new Subject<unknown>()
    public breadcrumbs: CHNode[] = []
    public tree: ReplaySubject<CHNode[]> = new ReplaySubject<CHNode[]>()
    public autocompleteSubjects: ReplaySubject<Record<string, CHNode>> = new ReplaySubject<
        Record<string, CHNode>
    >()
    private lastActiveNode: CHNode
    private treeMaxDepth: number = -1
    private loadedTreeMaxDepth: number = -1
    public pageSize: number = 12
    public pageNumber: number = 0
    public _nextPageURL$: BehaviorSubject<string> = new BehaviorSubject<string>(null)
    private _count$: BehaviorSubject<number> = new BehaviorSubject<number>(null)
    private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    private _resources$: BehaviorSubject<LearningObject[]> = new BehaviorSubject<LearningObject[]>(
        []
    )

    // TODO: The main observers need to be refactored for clarity + loading data only when needed
    constructor(
        private lmApi: LmApiService,
        private userService: UserService,
        private addOnService: AddOnViewService,
        private cookiesService: CookiesService
    ) {}

    public set loading(value: boolean) {
        this._loading$.next(value)
    }

    public get loading(): boolean {
        return this._loading$.value
    }

    public get loading$(): Observable<boolean> {
        return this._loading$
    }

    public set resources(value: LearningObject[]) {
        this._resources$.next(value)
    }

    public get resources(): LearningObject[] {
        return this._resources$.value
    }

    public get resources$(): Observable<LearningObject[]> {
        return this._resources$
    }

    public set count(value: number) {
        this._count$.next(value)
    }

    public get count$(): Observable<number> {
        return this._count$
    }

    public set nextPageURL(value: string) {
        this._nextPageURL$.next(value)
    }

    public get nextPageURL$(): Observable<string> {
        return this._nextPageURL$
    }

    public incrementPageNumber(): void {
        this.pageNumber++
    }

    public resetPagination(): void {
        this.pageNumber = 0
        this.resources = []
    }

    public getSubjectsTree(maxDepth: number = 4): ReplaySubject<CHNode[]> {
        if (this.treeMaxDepth !== maxDepth) {
            this.treeMaxDepth = maxDepth
            this.lmApi
                .getWithCookiesInParams(`/api/v2/expanded_subject_tree/?max_depth=${maxDepth}`)
                .pipe(
                    catchError((error) => observableThrowError(error)),
                    map((data: any) => {
                        if (
                            this.userService.hasStudentExperience() ||
                            this.addOnService.isAddonView
                        ) {
                            data.objects = data.objects.filter(
                                (node): boolean => node.title !== "Professional Development"
                            )
                        }
                        return data
                    })
                )
                .subscribe((data: any) => {
                    if (!data?.objects?.length) return
                    this.loadedTreeMaxDepth = maxDepth
                    this.tree.next(data?.objects.map((nodeObj) => CHNode.fromObject(nodeObj)))
                    this.autocompleteSubjects.next(CHNode.nodesByTitle)
                })
        }
        return this.tree
    }

    public getNode(nodeId: number): Observable<CHNode> {
        return this.getSubjectsTree().pipe(
            map((data: CHNode[]) => {
                return CHNode.byID(nodeId)
            })
        )
    }

    public selectNode(nodeSlug: string): Subscription {
        nodeSlug = CHNode.cleanSlug(nodeSlug)
        return this.getSubjectsTree().subscribe(
            (tree: CHNode[]) => {
                const node: CHNode = CHNode.bySlug(nodeSlug)
                if (!node) {
                    if (this.loadedTreeMaxDepth == 4) {
                        this.invalidNodeSelected.next({ slug: nodeSlug })
                    }
                    // Do not emit an invalid node selection yet,
                    // we still are waiting for the whole tree to load
                    return
                }
                this.lastActiveNode = node
                this.activeNodeChanged.next(node)
            },
            (error) => console.error(`[CUSTOM Error]${error.message}`)
        )
    }

    public emitLastNodeSection() {
        this.activeNodeChanged.next(this.lastActiveNode)
    }

    public getResultsByMedia(
        idOrSlug: string,
        mediaType: string,
        filters: URLFilters,
        grades: string = null
    ): Observable<APIResponse<Resource>> {
        let params: HttpParams = new HttpParams()
        params = params.append("q", "")
        params = this.setIdOrSlugInParams(idOrSlug, params)
        params = this.setMediaTypeInParams(filters, mediaType, params)
        params = this.setSelectedFacetsInParams(filters, params)
        params = this.setGradesInParams(grades, params)
        params = this.setSortInParams(filters, params)
        params = this.setStudentExperienceInParams(params)
        params = this.setExcludedFacetsInParams(params)
        params = this.setOptOutFacetInParams(params)
        params = this.setPagination(params)

        return this.requestResource(params, mediaType)
    }

    public requestResource(
        params: HttpParams,
        mediaType: string
    ): Observable<APIResponse<Resource>> {
        return this.lmApi
            .getWithCookiesInParamsAll("/api/v2/search/", {
                params,
            })
            .pipe(
                map((response: APIResponse<Resource>) => {
                    response.meta = response.meta || undefined
                    response.meta.mediaType = mediaType
                    return response
                }),

                catchError((error) => observableThrowError(error))
            )
    }

    setPagination(params: HttpParams): HttpParams {
        let paramsClone: HttpParams = params
        paramsClone = paramsClone.append("start", this.pageNumber * this.pageSize)
        paramsClone = paramsClone.append("count", this.pageSize)
        return paramsClone
    }

    public setIdOrSlugInParams(idOrSlug: string, params: HttpParams): HttpParams {
        return idOrSlug ? params.append("selected_facet", `subject:${idOrSlug}`) : params
    }

    public setMediaTypeInParams(
        filters: URLFilters,
        mediaType: string,
        params: HttpParams
    ): HttpParams {
        if (mediaType) return params.append("selected_facet", `media_type:${mediaType}`)
        return params
    }

    public setSelectedFacetsInParams(filters: URLFilters, params: HttpParams): HttpParams {
        let paramsClone: HttpParams = params
        if (filters.hasOwnProperty("selected_facet")) {
            if (Array.isArray(filters.selected_facet)) {
                filters.selected_facet.map((filter: string) => {
                    paramsClone = paramsClone.append("selected_facet", filter)
                })
            } else {
                paramsClone = paramsClone.append("selected_facet", filters.selected_facet)
            }
        }
        return paramsClone
    }

    public setGradesInParams(grades: string, params: HttpParams): HttpParams {
        if (grades) {
            return params.append("selected_facet", `grades:${grades}`)
        }
        return params
    }

    public setSortInParams(filters: URLFilters, params: HttpParams): HttpParams {
        if (filters.hasOwnProperty("rank_by")) {
            return params.append("rank_by", filters.rank_by)
        }
        return params
    }

    public setStudentExperienceInParams(params: HttpParams): HttpParams {
        if (this.userService.hasStudentExperience() || this.addOnService.isAddonView) {
            return params.append("selected_facet", "student_content:true")
        }
        return params
    }

    public setExcludedFacetsInParams(params: HttpParams): HttpParams {
        let excludedParams: string =
            "media_type:Lesson Plan" + (Utils.hasStudentExperience() ? "" : ",Collection")
        if (this.addOnService.isAddonView || Utils.hasStudentExperience()) {
            return params.append("excluded_facet", excludedParams)
        }
        return params
    }

    public setOptOutFacetInParams(params: HttpParams): HttpParams {
        if (this.cookiesService.getCookieValue("organization")) {
            let orgGuid: string = this.cookiesService.getCookieValue("organization")
            params = params.append("excluded_facet", `resource_opt_out_organizations:${orgGuid}`)
        }
        return params
    }

    public getSubjectsForErrorPage(): Observable<CHNode[]> {
        this.getSubjectsTree()
        return this.tree.pipe(
            map(
                (data: CHNode[]) => {
                    return data
                        .map((n: CHNode) => {
                            if (n.id === Subjects.TheArts) {
                                n.title = "Arts"
                            }
                            return n
                        })
                        .sort(Utils.getCompareFnByKey("title"))
                        .map((n: CHNode) => {
                            if (n.id === Subjects.TheArts) {
                                n.title = "The Arts"
                            }

                            return n
                        })
                },
                (error) => console.error(`[CUSTOM Error]${error}`)
            )
        )
    }

    private hasFiltersMediaType(filters: URLFilters) {
        let mediaType = null
        if (filters.selected_facet) {
            if (Array.isArray(filters.selected_facet)) {
                filters.selected_facet.forEach((facet: string) => {
                    if (facet.indexOf("media_type:") !== -1) {
                        mediaType = facet.split(":")[1]
                    }
                })
            } else {
                mediaType =
                    (filters.selected_facet as string).indexOf("media_type:") !== -1
                        ? (mediaType = (filters.selected_facet as string).split(":")[1])
                        : null
            }
        }
        return mediaType
    }

    public getResources(
        filters: URLFilters,
        idOrSlug?: string,
        grades?: string
    ): Observable<APIResponse<Resource>> {
        this.loading = true
        const mediaType = this.hasFiltersMediaType(filters)?.replace(/\s/g, "+")
        return this.getResultsByMedia(idOrSlug, mediaType, filters, grades).pipe(
            tap((result: APIResponse<Resource>) => (this.nextPageURL = result.meta.next_uri)),
            tap((result: APIResponse<Resource>) => (this.count = +result.meta.total)),
            tap(
                (result: APIResponse<Resource>) =>
                    (this.resources = [
                        ...this.resources,
                        ...result.objects.map((resource: Resource) => new LearningObject(resource)),
                    ])
            ),
            tap(() => (this.loading = false))
        )
    }
}
