import { HttpClient } from "@angular/common/http"
import { throwError as observableThrowError } from "rxjs"

import { Injectable } from "@angular/core"
import { catchError, map } from "rxjs/operators"

@Injectable({
    providedIn: "root",
})
export class URSService {
    constructor(private httpClient: HttpClient) {}

    public evaluateURSRestriction(ursURL: string) {
        ursURL += "?format=jsonp"
        return this.httpClient.jsonp(ursURL, "callback").pipe(
            map((res: any) => {
                if (res.status === "ok") {
                    return { url: res.url }
                } else {
                    return { error: res.http_code }
                }
            }),
            catchError((error) => observableThrowError(error))
        )
    }
}
