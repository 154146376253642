import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs"
import { catchError, map } from "rxjs/operators"
import {
    InternalMediaSlide,
    LessonBuilderItem,
    LessonBuilderResource,
} from "../models/lessonBuilderItem.model"
import { LessonBuilderListItem } from "../models/lessonBuilderListItem.model"
import { Paginated } from "../models/paginated.model"
import { LmApiService } from "./lm-api.service"
import { SearchingService } from "./searching.service"
import { LearningObject } from "../models/learningObject.model"
import { MediaSlideComponent } from "../tools/lesson-builder/lesson-builder/media-slide/media-slide.component"
import { HttpClient } from "@angular/common/http"
import { Asset } from "../models/resource/asset"
import { Resource } from "../models/resource/resource"
import { URSService } from "./urs.service"
import { DrawerTabTitle } from "../models/constants"
import { LessonResultsResponse } from "../tools/lesson-builder/models/lesson-builder.models"

export const defaultEditorSettings = {
    toolbar:
        "undo redo | formatselect | backcolor | bold italic underline | \
        hr removeformat | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent table link | charmap",
    block_formats: "Paragraph=p; Title=h5; Subtitle=h6;",

    plugins: "hr lists table link charmap",
    charmap_append: [
        "8722", // Minus sign
        "215", // Multiplication sign
        "247", // Division sign
        "8730", // Square root sign
        "178", // Superscript 2
        "179", // Superscript 3
    ],
}

export interface LessonBuilderSearchData {
    learningObjects: LearningObject[]
    total: number
    prevUri: string | null
    nextUri: string | null
    facetSelection: FacetSelectionType
    isInfiniteScroll: boolean
    fields: object
}

export interface FacetSelectionType {
    text: string
    resourceType: string[]
    gradeType: string[]
    showUnavailableResources: boolean
}

@Injectable()
export class LessonBuilderService {
    private openedPreviewResource: LessonBuilderResource
    public selectedAssetSubject: BehaviorSubject<Asset> = new BehaviorSubject<Asset>(null)

    public mediaSlideSource: MediaSlideComponent
    public prefilledResource: BehaviorSubject<Resource> = new BehaviorSubject<Resource | null>(null)

    private addExtraMediaSlide: Subject<InternalMediaSlide> = new Subject<InternalMediaSlide>()
    public readonly addExtraMediaSlide$: Observable<
        InternalMediaSlide
    > = this.addExtraMediaSlide.asObservable()

    private mediaSelectDrawerOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public readonly isMediaSelectDrawerOpen$: Observable<
        boolean
    > = this.mediaSelectDrawerOpen.asObservable()

    private searchResults: BehaviorSubject<
        LessonBuilderSearchData
    > = new BehaviorSubject<LessonBuilderSearchData | null>(null)
    public readonly searchResults$: Observable<
        LessonBuilderSearchData
    > = this.searchResults.asObservable()

    private searchFavoritesResults: BehaviorSubject<
        LessonBuilderSearchData
    > = new BehaviorSubject<LessonBuilderSearchData | null>(null)
    public readonly searchFavoritesResults$: Observable<
        LessonBuilderSearchData
    > = this.searchFavoritesResults.asObservable()

    private isAnySearchLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    public readonly isAnySearchLoading$: Observable<
        boolean
    > = this.isAnySearchLoading.asObservable()

    private _checkQuestionsForErrors: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false)
    public readonly checkQuestionsForErrors$: Observable<
        Boolean
    > = this._checkQuestionsForErrors.asObservable()

    private _showILPInDropDown: boolean = true

    public activeTabId: BehaviorSubject<number> = new BehaviorSubject(DrawerTabTitle.MyFavorites)

    public scrollOffset = {
        [DrawerTabTitle.MyFavorites]: 0,
        [DrawerTabTitle.EntireSite]: 0,
    }

    private _tabsFilterData = {
        [DrawerTabTitle.MyFavorites]: new BehaviorSubject(null),
        [DrawerTabTitle.EntireSite]: new BehaviorSubject(null),
    }

    constructor(
        private lmApiService: LmApiService,
        private searchingService: SearchingService,
        private http: HttpClient,
        private ursService: URSService
    ) {}

    public resetScrollOffset() {
        this.scrollOffset = {
            [DrawerTabTitle.MyFavorites]: 0,
            [DrawerTabTitle.EntireSite]: 0,
        }
    }

    set selectedAsset(state: Asset) {
        this.selectedAssetSubject.next(state)
    }

    get selectedAsset() {
        return this.selectedAssetSubject.value
    }

    public set previewResource(resource: LessonBuilderResource) {
        this.openedPreviewResource = resource
    }

    public get previewResource() {
        return this.openedPreviewResource
    }

    public set showILPInDropDown(isAllowed: boolean) {
        this._showILPInDropDown = isAllowed
    }

    public get showILPInDropDown(): boolean {
        return this._showILPInDropDown
    }

    public checkQuestionsForErrors(hasErrors: boolean) {
        this._checkQuestionsForErrors.next(hasErrors)
    }

    public openMediaSelectDrawer(mediaSlideSource: MediaSlideComponent) {
        this.mediaSlideSource = mediaSlideSource
        this.mediaSelectDrawerOpen.next(true)
    }

    public closeMediaSelectDrawer() {
        this.mediaSlideSource = null
        this.mediaSelectDrawerOpen.next(false)
    }

    public static makeDefaultFacetSelection(): FacetSelectionType {
        return {
            text: "",
            resourceType: ["All"],
            gradeType: ["All"],
            showUnavailableResources: false,
        }
    }

    public static makeDefaultSearchData(): LessonBuilderSearchData {
        return {
            learningObjects: [],
            total: null,
            prevUri: null,
            nextUri: null,
            facetSelection: this.makeDefaultFacetSelection(),
            isInfiniteScroll: false,
            fields: {},
        }
    }

    public getLessons(limit: number, offset: number): Observable<Paginated<LessonBuilderListItem>> {
        const params = limit !== undefined && offset !== undefined ? { limit, offset } : {}
        return this.lmApiService
            .get("/api/v2/lesson-builder/", { responseType: "json", params })
            .pipe(
                map((response: Paginated<any>) => {
                    response.results = response.results.map((d) =>
                        LessonBuilderListItem.fromData(d)
                    )
                    return response
                }),
                catchError((error) => throwError(error))
            )
    }

    public getLesson(id: string): Observable<LessonResultsResponse> {
        return this.lmApiService
            .get(`/api/v2/lesson-builder/${id}/`, { responseType: "json" })
            .pipe(
                map((response: any) => {
                    return {
                        lessonBuilder: new LessonBuilderItem(response, this.ursService),
                        error: null,
                    }
                }),
                catchError((error) => {
                    return of({ lessonBuilder: null, error: error })
                })
            )
    }

    public saveLesson(lesson: LessonBuilderItem) {
        const request = lesson.code
            ? this.lmApiService.put(`/api/v2/lesson-builder/${lesson.code}/`, lesson.toData())
            : this.lmApiService.post(`/api/v2/lesson-builder/`, lesson.toData())
        return request.pipe(
            map((response: any) => {
                return new LessonBuilderItem(response, this.ursService)
            }),
            catchError((error) => throwError(error))
        )
    }

    public delete(id: string) {
        return this.lmApiService.delete(`/api/v2/lesson-builder/${id}/`)
    }

    public searchFavorites(
        facetSelection: FacetSelectionType,
        limit: string = "4",
        isInfiniteScroll = false
    ): void {
        const {
            text,
            resourceType: media_type,
            gradeType: grades,
            showUnavailableResources,
        } = facetSelection

        let facets = []
        this.isAnySearchLoading.next(true)

        if (!showUnavailableResources) facets.push("scope:lesson")

        if (!media_type.includes("All") && media_type.length > 0) {
            facets = [...facets, ...["media_type:" + media_type.join()]]
        }
        if (!grades.includes("All") && grades.length > 0) {
            facets = [...facets, ...["grades:" + grades.join()]]
        }

        this.searchingService
            .getFavorites({
                q: text,
                limit,
                object_type: "resource",
                selected_facet: [...facets],
            })
            .subscribe(
                (res: any) => {
                    const {
                        count: total,
                        next: nextUri,
                        previous: prevUri,
                        meta: { fields },
                    } = res

                    const learningObjects = res.results.map((obj) => new LearningObject(obj))
                    this.searchFavoritesResults.next({
                        learningObjects,
                        total,
                        prevUri,
                        nextUri,
                        facetSelection,
                        isInfiniteScroll,
                        fields,
                    })

                    this.isAnySearchLoading.next(false)
                },
                (error) => console.error(`[CUSTOM Error]${error.message}`)
            )
    }

    /**
     * Used for getting data by using the URL,
     * for example when using pagination and we are getting the next and prev URL from keys like (next_uri, prev_uri)
     * @param url - string with all the data needed (facets, filters, limit, etc...)
     * @param facetSelection user selected filters
     * @param isInfiniteScroll add to infinite scroll the results
     */
    public searchByURL(
        url: string,
        facetSelection: FacetSelectionType,
        isInfiniteScroll = false
    ): void {
        if (!url) return
        this.isAnySearchLoading.next(true)

        this.lmApiService.get(url).subscribe(
            (data: any) => {
                let learningObjects, total, prevUri, nextUri, fields
                if (url.includes("/search-favorites/")) {
                    total = data.count
                    prevUri = data.previous || null
                    nextUri = data.next || null
                    fields = data.meta.fields
                    learningObjects = data.results.map((obj) => new LearningObject(obj))
                    this.searchFavoritesResults.next({
                        learningObjects,
                        total,
                        prevUri,
                        nextUri,
                        facetSelection,
                        isInfiniteScroll,
                        fields,
                    })

                    this.isAnySearchLoading.next(false)
                } else {
                    total = data.meta.total
                    prevUri = data.meta.prev_uri || null
                    nextUri = data.meta.next_uri || null
                    fields = data.meta.facets.fields
                    learningObjects = data.objects.map((obj) => new LearningObject(obj))

                    this.searchResults.next({
                        learningObjects,
                        total,
                        prevUri,
                        nextUri,
                        facetSelection,
                        isInfiniteScroll,
                        fields,
                    })

                    this.isAnySearchLoading.next(false)
                }
            },
            (error) => console.error(`[CUSTOM Error]${error.message}`)
        )
    }

    public search(
        facetSelection: FacetSelectionType,
        count: string = "4",
        isInfiniteScroll = false
    ): void {
        let { text, resourceType: media_type, gradeType: grades } = facetSelection
        let facets = []
        this.isAnySearchLoading.next(true)

        if (!grades.includes("All") && grades.length > 0) {
            facets = [...facets, ...["grades:" + grades.join()]]
        }
        if (!media_type.includes("All") && media_type.length > 0) {
            facets = [...facets, ...["media_type:" + media_type.join()]]
        }

        // Even if no media filter is selected, we want to exclude Lesson Plans
        let excluded_facet = "media_type:Lesson Plan"
        if (!this.showILPInDropDown) {
            excluded_facet = excluded_facet + ",Interactive Lesson"
        }

        this.searchingService
            .getResults({
                q: text,
                count,
                object_type: "resource",
                ...(media_type.includes("All") && { excluded_facet }),
                selected_facet: [
                    // Always search for resources that are available in tools thus keep scope:lesson
                    "scope:lesson",
                    ...facets,
                ],
            })
            .subscribe(
                (data: any) => {
                    const {
                        meta: {
                            total,
                            next_uri: nextUri,
                            prev_uri: prevUri,
                            facets: { fields },
                        },
                    } = data

                    const learningObjects = data.objects.map((obj) => new LearningObject(obj))
                    this.searchResults.next({
                        learningObjects,
                        total,
                        prevUri,
                        nextUri,
                        facetSelection,
                        isInfiniteScroll,
                        fields,
                    })

                    this.isAnySearchLoading.next(false)
                },
                (error) => console.error(`[CUSTOM Error]${error.message}`)
            )
    }

    public addToSlide(learningObject: LearningObject) {
        return this.getResource(
            learningObject.resourceCode
        ).subscribe((resource: LessonBuilderResource) =>
            this.setResourceInSelectedMediaSlide(resource)
        )
    }

    public setResourceInSelectedMediaSlide(resource: LessonBuilderResource) {
        if (this.mediaSlideSource) {
            this.mediaSlideSource.setSelectedInternalContent(
                resource.assets[0],
                resource.guid,
                resource.brand,
                resource.creditsLink
            )
        }
        for (let i = 1; i < resource.assets.length; i++) {
            this.addExtraMediaSlide.next(
                new InternalMediaSlide(
                    null,
                    resource.assets[i].title,
                    "",
                    resource.assets[i],
                    resource.brand,
                    resource.creditsLink,
                    resource.guid
                )
            )
        }
        this.closeMediaSelectDrawer()
    }

    public addResourceToLessonBuilder(resource: Resource) {
        resource.assets.forEach((asset) => {
            this.addExtraMediaSlide.next(
                new InternalMediaSlide(
                    null,
                    resource.title,
                    resource.description,
                    asset,
                    resource.brand.name,
                    `/credits/${resource.resourceCode}/`,
                    resource.guid
                )
            )
        })
    }

    public previewForSlide(resourceCode): Observable<LessonBuilderResource> {
        return this.getResource(resourceCode)
    }

    public getResource(resourceCode): Observable<LessonBuilderResource> {
        const resourceURL = `/api/v2/resource/${resourceCode}/`
        return this.lmApiService
            .getWithCookiesInParams(resourceURL, ["user_content_permission", "student"])
            .pipe(map((response: any) => LessonBuilderResource.fromData(response, this.ursService)))
            .pipe(catchError((error) => this.lmApiService.handleErrorForNonCritical(error)))
    }

    public uploadFile(file: File): Observable<any> {
        if (!file) return
        const formData: FormData = new FormData()
        formData.append("images", file)

        return this.http.post("/api/v2/ugc/", formData, {
            reportProgress: true,
            observe: "events",
            responseType: "json",
        })
    }

    public addUGCToSlide(ugcData: any) {
        const asset = Asset.fromData(ugcData.data, this.ursService)
        this.mediaSlideSource.setSelectedUGCContent(asset, ugcData.guid, null)
        this.closeMediaSelectDrawer()
    }

    // Logic for drawer tabs filter data
    public addFilter(filter: string): void {
        const selectedTab = this.activeTabId.value
        const selectedTabValue = this._tabsFilterData[selectedTab].value

        if (!selectedTabValue) {
            this._tabsFilterData[selectedTab].next([filter])
            return
        }

        this._tabsFilterData[selectedTab].next([...selectedTabValue, filter])
    }

    public clearFilters(): void {
        const selectedTab = this.activeTabId.value
        this._tabsFilterData[selectedTab].next([])
    }

    public removeFilter(filter: string): void {
        const selectedTab = this.activeTabId.value
        const selectedTabValue = this._tabsFilterData[selectedTab].value

        this._tabsFilterData[selectedTab].next([
            ...selectedTabValue.filter((facet) => facet !== filter),
        ])
    }

    public resetFilters(): void {
        for (const key in this._tabsFilterData) {
            this._tabsFilterData[key].next(null)
        }
    }

    public subscribeToTabFilterData(tabId: number | null = null): Observable<[]> {
        if (!tabId) {
            const selectedTab = this.activeTabId.value
            return this._tabsFilterData[selectedTab].asObservable()
        }

        return this._tabsFilterData[tabId]
    }

    public subscribeToSearchResults(): Observable<LessonBuilderSearchData> {
        const selectedTab = this.activeTabId.value
        return selectedTab === 1 ? this.searchFavoritesResults$ : this.searchResults$
    }

    public resetSearchResults() {
        this.searchFavoritesResults.next(null)
        this.searchResults.next(null)
    }
}
