<!-- Information Modal -->
<div class="modal-header px-0 py-2">
    <h5 class="modal-title">{{ title ? title : "Information" }}</h5>
    <button
        *ngIf="shouldDisplayHeaderCloseButton"
        (click)="activeModal.dismiss('Cross click')"
        aria-label="Close"
        class="close"
        data-dismiss="modal"
        type="button"
    >
        <i class="pbslm-close-thin-cross close-img"></i>
    </button>
</div>
<div class="modal-body modal-body-text px-0 py-2">
    {{ message }}
</div>
<div class="modal-footer px-0 pt-2 pb-0">
    <button
        (click)="activeModal.dismiss('Cross click')"
        class="btn btn-primary"
        type="button"
        *ngIf="shouldDisplayFooterCloseButton"
    >
        OK
    </button>
</div>
