<app-modal-header
    [title]="'Choose an option for assigning this lesson'"
    class="assign-modal"
></app-modal-header>
<div class="modal-body pt-0 mt-6">
    <ng-container>
        <div class="row-auto mb-3">
            <div class="col">
                <h4>To a class on PBS LearningMedia</h4>
            </div>
        </div>
        <div class="row">
            <div class="info-text col-lg-6 mb-3">
                <app-icon [type]="'file-chart'" class="me-3 green"></app-icon> Student work results
                available
            </div>
            <div class="info-text col-lg-6 mb-3">
                <app-icon [type]="'lock-close'" class="me-3 red"></app-icon> Requires student sign
                in to PBSLM
            </div>
        </div>
        <div class="row-auto mb-5">
            <div class="col">
                <button (click)="openAssignModal(assignType.pbslm)" class="btn btn-primary">
                    Assign to a PBS LearningMedia class »
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container>
        <div class="row-auto mb-3">
            <div class="col">
                <h4>To an imported Google Classroom class</h4>
            </div>
        </div>
        <div class="row">
            <div *ngIf="!hasLti" class="info-text col-lg-6 mb-3">
                <app-icon [type]="'file-chart'" class="me-3 green"></app-icon> Student work results
                available
            </div>
            <div *ngIf="hasLti" class="info-text col-lg-6 mb-3">
                <app-icon [type]="'file-chart'" class="me-3 yellow"></app-icon> Student work results
                may be available
                <app-icon-tooltip
                    [iconSizePx]="18"
                    appIconType="question-circle"
                    tooltipClass="student-work-result-text"
                >
                    <p>Students must log into PBS LearningMedia for results to be available.</p>
                </app-icon-tooltip>
            </div>
            <div class="info-text col-lg-6 mb-3">
                <app-icon [type]="'lock-close'" class="me-3 red"></app-icon> Requires student sign
                in TO GOOGLE
            </div>
        </div>
        <div class="row-auto mb-7">
            <div class="col">
                <button (click)="openAssignModal(assignType.google)" class="btn btn-primary">
                    Assign to a Google Classroom class »
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container>
        <div class="row-auto mb-3">
            <div class="col">
                <h4>Using a “Quick Assign” code</h4>
            </div>
        </div>
        <div class="row">
            <div class="info-text col-lg-6 mb-3">
                <app-icon [type]="'file-chart'" class="me-3 green"></app-icon> Student work results
                available
            </div>
            <div *ngIf="!hasLti" class="info-text col-lg-6 mb-3">
                <app-icon [type]="'lock-open'" class="me-3 green"></app-icon> doesn’t Require
                student sign in to PBSLM
            </div>
            <div *ngIf="hasLti" class="info-text col-lg-6 mb-3">
                <app-icon [type]="'lock-close'" class="me-3 red"></app-icon> Requires student sign
                in to PBSLM
            </div>
        </div>
        <div class="row-auto mb-7">
            <div class="col">
                <button (click)="openAssignmentShareModal()" class="btn btn-primary">
                    Get assignment code »
                </button>
            </div>
        </div>
    </ng-container>

    <div class="d-flex justify-content-end">
        <button (click)="activeModal.close()" class="btn btn-outline-secondary">Cancel</button>
    </div>
</div>
