import { ComponentRef, ViewRef } from "@angular/core"
import { Subject } from "rxjs"
import { ModalComponent } from "./modal.component"
import { ModalConfig } from "./modal.service"

export enum ModalEvent {
    Close,
    Dismiss,
}

export interface ModalResult {
    type: ModalEvent
    result: ModalResultPayload
}

export type ModalResultPayload = Object | boolean | string

export class ModalRef {
    public id: string
    public modalComponentRef: ComponentRef<ModalComponent>
    public result = new Subject<ModalResult>()
    public persisted: boolean
    public componentInstance: any
    public config: ModalConfig

    constructor() {}

    close(result?: ModalResultPayload) {
        this.result.next({ type: ModalEvent.Close, result })
    }

    dismiss(reason?: ModalResultPayload) {
        this.result.next({ type: ModalEvent.Dismiss, result: reason })
    }
}

export class ModalContentRef {
    constructor(public nodes: any[], public componentRef?: ComponentRef<any>) {}
}
