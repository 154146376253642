import { Component } from "@angular/core"

@Component({
    styleUrls: ["./student-gate-modal.component.scss"],
    template: `<app-grade-gate-modal
        class="d-flex flex-column align-content-center align-items-center justify-content-center gtmsection-student-gate"
    >
    </app-grade-gate-modal>`,
})
export class StudentGateModalComponent {}
