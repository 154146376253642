export class LessonBuilderListItem {
    constructor(
        public name: string,
        public code: string,
        public dateCreated: Date,
        public isEditable: boolean
    ) {}

    public static fromData(data): LessonBuilderListItem {
        return new LessonBuilderListItem(
            data.name,
            data.code,
            new Date(data.date_created),
            data.is_editable
        )
    }
}
