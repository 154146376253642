<h4 class="text-medium-blue mb-4">Privacy Policy Acknowledgement</h4>
<div class="form-check">
    <input
        type="checkbox"
        id="sign-in"
        name="'sign-in'"
        class="form-check-input gtm-data-privacy-modal-sign"
        [formControl]="privacyCheck"
    />
    <label class="required-field form-check-label" for="sign-in">
        I have read and acknowledge the PBS LearningMedia
        <a
            href="https://florida.pbslearningmedia.org/help/student-privacy-notice/"
            target="_blank"
            rel="noopener noreferrer"
            >Student Privacy Notice</a
        >
        , which explains what data we collect. By creating class rosters and creating assignments
        for my students, I consent to the collection, use, and disclosure of students’ personal
        information consistent with the Student Privacy Notice and the PBS LearningMedia
        <a
            href="https://florida.pbslearningmedia.org/help/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            >Privacy Policy</a
        >
        .
    </label>
</div>
