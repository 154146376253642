import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { Localize } from "../../models/localize.model"
import { CookiesService } from "../../services/cookies.service"
import { LocalizationService } from "../../services/localization.service"
import { UserService } from "../../services/user.service"
import { ModalService } from "../modal/modal.service"
import { AuthModalComponent } from "../modals/auth-modal/auth-modal.component"
import { LocalizationModalComponent } from "../modals/localization-modal/localization-modal.component"

@Component({
    selector: "app-localization",
    templateUrl: "./localization.component.html",
    styleUrls: ["./localization.component.scss"],
})
export class LocalizationComponent implements OnInit, OnDestroy {
    @Input() addWrapper: boolean = false
    @Input() localizationDropdownRef = null

    subscriptions: Subscription[] = []
    localizeData: Localize = new Localize([])
    logoUrl: string = ""
    gtmPrefix: string = "gtm-homepage-station-module-"
    isUserLoggedIn: boolean = false

    constructor(
        private cookieService: CookiesService,
        private localizationService: LocalizationService,
        private modalService: ModalService,
        private userService: UserService
    ) {}

    public ngOnInit() {
        this.isUserLoggedIn = this.userService.isLoggedIn()

        this.subscriptions.push(
            this.localizationService.localizeData.subscribe((data) => {
                this.localizeData = data
                this.logoUrl = this.localizeData.getLogo()
            })
        )
    }

    public hasImage() {
        return this.localizeData.hasFreeAreaImage()
    }

    public ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe())
    }

    public openLocalizationChangeModal() {
        this.modalService.open(LocalizationModalComponent, {
            size: "lg",
            windowClass: "change-station-modal",
        })
    }

    public openAuthModal() {
        this.modalService.open(AuthModalComponent)
    }

    public closeLocalization() {
        if (!this.localizationDropdownRef) return
        this.localizationDropdownRef.close()
    }
}
