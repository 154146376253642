import { ChangeDetectionStrategy, Component } from "@angular/core"
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap"

@Component({
    selector: "app-do-later",
    templateUrl: "./do-later.component.html",
    styleUrls: ["./do-later.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoLaterComponent {
    constructor(public activeModal: NgbActiveModal) {}
}
