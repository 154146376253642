import { Component, Input } from "@angular/core"
import { ModalRef } from "../../modal/modal-ref"
import { ModalService } from "../../modal/modal.service"
import { ShareAssignmentCodeModalComponent } from "../share-assignment-code-modal/share-assignment-code-modal.component"
import { AssignModalComponent, AssignModalType } from "../assign-modal/assign-modal.component"

@Component({
    selector: "app-assign-lesson-modal",
    templateUrl: "./assign-lesson-modal.component.html",
    styleUrls: ["./assign-lesson-modal.component.scss"],
})
export class AssignLessonModalComponent {
    @Input() code: string = ""
    @Input() name: string = ""
    @Input() hasLti: boolean = false
    public assignType = AssignModalType
    constructor(public activeModal: ModalRef, public modalService: ModalService) {}

    public openAssignmentShareModal() {
        this.activeModal.close()
        this.modalService.open(ShareAssignmentCodeModalComponent, {
            size: "md",
            data: { code: this.code },
        })
    }

    public openAssignModal(type: AssignModalType) {
        this.activeModal.close()
        this.modalService.open(AssignModalComponent, {
            size: "lg",
            windowClass: "full-screen-modal",
            data: {
                assignmentCode: this.code,
                assignmentName: this.name,
                assignmentHasLti: this.hasLti,
                type,
            },
        })
    }
}
