import { Injectable } from "@angular/core"
import { ActivatedRoute, Params, Router } from "@angular/router"
import { BehaviorSubject, Subject } from "rxjs"
import { BaseNode, CHNode } from "../models/node.model"
import { Facet } from "../models/facet.model"
import {
    ADD_ON_ALLOWED_MEDIA_TYPES,
    CH_FACET_ID,
    ORDERED_GRADE_KEYS,
    SEARCH_FILTERS,
} from "../models/constants"
import { CPFacet } from "../models/cpfacet.model"
import { facetType } from "../utils/types"
import { SearchingService } from "./searching.service"
import { UserService } from "./user.service"
import { SearchInfoModel } from "../models/searchInfo.model"
import { AddOnViewService } from "./add-on-view.service"

@Injectable()
export class FacetingService {
    appliedFilters: string[] = []
    filtersChanged: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
    toggleFilter: Subject<facetType> = new Subject<facetType>()

    // Grades
    grades_title = "Grade"
    grades: string[] = ORDERED_GRADE_KEYS

    subject: string[] = []
    subject_prefix: string = "subject"

    // Media types
    media_type_title: string = "Resource Type"
    media_type: string[] = []

    // Audio/Video duration
    duration_title: string = "Length of Video/Audio"
    duration: string[] = []

    // Content Project
    cp_title: BehaviorSubject<string> = new BehaviorSubject<string>("")
    cp: BehaviorSubject<CPFacet[]> = new BehaviorSubject<CPFacet[]>([])

    // Language
    language_title: string = "Language"
    language: string[] = []

    // Accessibility
    accessibility_title: string = "Accessibility"
    accessibility: string[] = []

    // Additional Features
    additional_features_title: string = "Additional Features"
    additional_features: string[] = []

    // Custom Filters
    private nestedFacets: Record<string, Facet<BaseNode>> = {}
    public nestedFacetsChanged: BehaviorSubject<
        Record<string, Facet<BaseNode>>
    > = new BehaviorSubject<Record<string, Facet<BaseNode>>>({})

    constructor(
        private searchingService: SearchingService,
        private userService: UserService,
        private router: Router,
        private addOnService: AddOnViewService
    ) {
        this.media_type = this.addOnService.isAddonView
            ? ADD_ON_ALLOWED_MEDIA_TYPES
            : SEARCH_FILTERS

        if (this.userService.hasStudentExperience()) {
            this.media_type = this.media_type.filter((type) => type !== "Lesson Plan")
        }
        this.filtersChanged.subscribe((newFilters) => {
            Object.keys(this.nestedFacets).forEach((nfId) => {
                this.setFilters(nfId)
            })
        })
        this.searchingService.searchInfo.subscribe((searchInfo: SearchInfoModel) => {
            this.cp.next(searchInfo.cpFilter)
            this.cp_title.next(searchInfo.cpSectionName)

            searchInfo.customFilterObjects.forEach((filterObj) => {
                const nodes: BaseNode[] = filterObj.children.map((obj) => BaseNode.fromObject(obj))
                this.setupNestedFacet(filterObj.filter_id, filterObj.filter_name, nodes)
            })
            if (searchInfo.chSectionName) {
                this.setupNestedFacet(CH_FACET_ID, searchInfo.chSectionName, searchInfo.chFilter)
            }
            this.nestedFacetsChanged.next(this.nestedFacets)
        })

        // TODO having a subscriber here in the service and in the components for the same thing is confusing and can lead to bugs
        // a refactoring may be a good idea
        this.searchingService.availableFacetsChanged.subscribe((data) => {
            ;["language", "accessibility", "additional_features", "duration"].map((facet) => {
                if (!data.hasOwnProperty(facet)) {
                    return
                }
                this[facet] = data[facet].map((elem) => ({
                    id: elem.id ? elem.id : null,
                    label: elem.title,
                    checked: false,
                    count: elem.count,
                }))
            })
        })
    }

    public setFilters(facetId: string) {
        const facet: Facet<BaseNode> = this.nestedFacets[facetId]

        if (facet) {
            facet.uncheckNodes()
            this.appliedFilters
                .filter((filter) => facet.filterTypes.find((ft) => filter.startsWith(ft)))
                .forEach((filter) => {
                    const [filterType, nodeId] = filter.split(":")
                    facet.checkNode(`${filterType}-${nodeId}`)
                })
        }
    }

    public setupNestedFacet(facetId: string, name: string, nodes: BaseNode[], emit?: boolean) {
        const facet =
            facetId === CH_FACET_ID
                ? new Facet<CHNode>(facetId, name)
                : new Facet<BaseNode>(facetId, name)
        facet.setNodes(nodes)
        this.nestedFacets[facetId] = facet
        this.setFilters(facetId)

        if (emit) {
            this.nestedFacetsChanged.next(this.nestedFacets)
        }
    }

    //GENERAL

    public getSelectedFacets(route: ActivatedRoute): string[] {
        const selectedFacet = route.snapshot.queryParams.selected_facet || []
        return Array.isArray(selectedFacet) ? selectedFacet.slice() : [selectedFacet]
    }

    public updateFacetsFromQueryParams(queryParams: Params) {
        const selectedFacets = queryParams.selected_facet || []
        const newFilters = Array.isArray(selectedFacets) ? selectedFacets.slice() : [selectedFacets]

        this.appliedFilters = this.expandFacets(newFilters)
        this.filtersChanged.next(this.appliedFilters)
    }

    public addFilter(filter: string, route: ActivatedRoute, replace: boolean = false) {
        const selectedFacets = this.getSelectedFacets(route).concat([filter])
        const newQueryParams = {
            selected_facet: this.groupFacets(selectedFacets, replace),
            page: null,
        }
        this.router.navigate([], {
            queryParams: newQueryParams,
            queryParamsHandling: "merge",
            relativeTo: route,
        })
    }

    public removeFilter(filter: string, route: ActivatedRoute) {
        const modifiedFacets = this.expandFacets(this.getSelectedFacets(route)).filter(
            (e) => e !== filter
        )
        const newQueryParams = {
            selected_facet: this.groupFacets(modifiedFacets),
            page: null,
        }
        this.router.navigate([], {
            queryParams: newQueryParams,
            queryParamsHandling: "merge",
            relativeTo: route,
        })
    }

    public clearFilters(route: ActivatedRoute) {
        const newQueryParams = { selected_facet: [] }
        this.router.navigate([], {
            queryParams: newQueryParams,
            queryParamsHandling: "merge",
            relativeTo: route,
        })
    }

    public makeSubjectQueryParam(nodeId) {
        return `${this.subject_prefix}:${nodeId}`
    }

    public makeGradeQueryParam(grade) {
        return `grades:${grade}`
    }

    public broadcastOnToggle(state: boolean, type: string): void {
        const facet: facetType = {
            state,
            type,
        }
        this.toggleFilter.next(facet)
    }

    private groupFacets(expandedFacets: string[], replace: boolean = false) {
        /** ['grades:5', 'grades:6', 'subject:1234'] -> ['grades:5,6', 'subject:1234'] */

        const facets = {}
        expandedFacets.map((facet) => {
            const [facetName, facetValue] = facet.split(":")

            if (facets.hasOwnProperty(facetName) && !replace) {
                facets[facetName].push(facetValue)
            } else {
                facets[facetName] = [facetValue]
            }
        })

        return Object.keys(facets).map((key) => `${key}:${facets[key].join(",")}`)
    }

    private expandFacets(groupedFacets: string[]) {
        /** ['grades:5,6', 'subject:1234'] -> ['grades:5', 'grades:6', 'subject:1234'] */
        const expandedFacets = groupedFacets.map((facet: string) => {
            const [facetName, facetValue] = facet.split(":")
            return facetValue.split(",").map((value) => `${facetName}:${value}`)
        })
        return [].concat(...expandedFacets)
    }
}
