<div class="step-wrapper">
    <div class="description">
        <em>{{ assignmentName }}</em> has been assigned to the following classes and students:
    </div>
    <div class="classes-wrapper">
        <ng-container *ngFor="let roster of rosters">
            <ng-container *ngIf="roster.selected && hasClassStudents(roster)">
                <div class="class-row">
                    <div class="class-name" (click)="toggleExpand(roster)">
                        <app-icon [type]="'Plus'" *ngIf="!roster.expanded"></app-icon>
                        <app-icon [type]="'Minus'" *ngIf="roster.expanded"></app-icon>
                        {{ roster.rosterName }}
                    </div>
                </div>

                <div
                    class="student-wrapper"
                    *ngFor="let student of roster.students"
                    [ngClass]="{ show: roster.expanded }"
                >
                    <div *ngIf="student.selected" class="student-row">
                        <div class="student-name">
                            {{ student.studentDisplayName }}
                        </div>
                        <img
                            class="google-class"
                            *ngIf="student.studentGoogleId"
                            [src]="googleClassImg"
                        />
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="buttons-wrapper">
        <app-button (click)="viewAssignments()" [buttonClasses]="'modal-button-padding'"
            >View Assignments</app-button
        >
    </div>
</div>
