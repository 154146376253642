import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { HomepageRoutingModule } from "./homepage-routing.module"
import { HomepageComponent } from "./homepage.component"
import { DisciplinesComponent } from "./disciplines/disciplines.component"
import { FeaturedComponent } from "./featured/featured.component"
import { HeroComponent } from "./hero/hero.component"
import { CollectionsComponent } from "./collections/collections.component"
import { RecommendationsComponent } from "./recommendations/recommendations.component"

import { SharedComponentsModule } from "../shared-components/shared-components.module"
import { PromotionalAdsComponent } from "./promotional-ads/promotional-ads.component"
import { PromotionalAdsService } from "../services/promotional-ads.service"
import { FormsModule } from "@angular/forms"

@NgModule({
    declarations: [
        HomepageComponent,
        DisciplinesComponent,
        FeaturedComponent,
        HeroComponent,
        CollectionsComponent,
        RecommendationsComponent,
        PromotionalAdsComponent,
    ],
    imports: [CommonModule, HomepageRoutingModule, SharedComponentsModule, FormsModule],
    providers: [PromotionalAdsService],
})
export class HomepageModule {}
