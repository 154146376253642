<div class="wrapper" [ngClass]="{ hideBar: !stateName, 'static-height': !isTeacherPreview }">
    <div class="container">
        <div class="main-nav">
            <div *ngIf="stateName" class="for-educators-notice">
                Brought to you by {{ stateName }} PBS Stations
            </div>
            <div class="buttons-group-wrapper" *ngIf="isTeacherPreview">
                <!--Subjects-->
                <app-subjects-dropdown
                    [subjects]="subjects"
                    (subjectSelected)="navigateTo($event)"
                    (toggleSubjectPanel)="onTogglePanel($event)"
                ></app-subjects-dropdown>

                <!--Grades-->
                <app-grades-dropdown
                    [grades]="gradeBands"
                    (gradeSelected)="navigateTo($event)"
                    (toggleGradePanel)="onTogglePanel($event)"
                ></app-grades-dropdown>

                <!--For Students Link-->
                <a
                    *ngIf="!isAddonView"
                    [ngClass]="{
                        preview: shouldDisableStudentSite$.value
                    }"
                    class="for-students py-2 px-7"
                    href="#"
                    (click)="openStudentExperienceInNewTab(); $event.preventDefault()"
                    rel="noopener noreferrer"
                >
                    Student site
                    <em class="fas fa-external-link-alt"></em>
                </a>
            </div>
        </div>
    </div>
</div>
