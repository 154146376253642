import { Injectable } from "@angular/core"
import {
    ActivatedRouteSnapshot,
    CanActivateChild,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router"
import { Observable } from "rxjs"

import Utils from "../utils/utils"
import { AddOnViewService } from "../services/add-on-view.service"
import { UserDataPrivacyService } from "../services/user-data-privacy.service"
import { map } from "rxjs/operators"
import { DataPrivacyModalsComponent } from "../shared-components/data-privacy-modals/data-privacy-modals.component"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { DISMISS_MODAL_REASON } from "../services/data-privacy-modals.service"

@Injectable()
export class DataPrivacyModalGuard implements CanActivateChild {
    constructor(
        private addOnViewService: AddOnViewService,
        private modalService: NgbModal,
        private userDataPrivacyService: UserDataPrivacyService
    ) {}

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // Show data privacy info modals only for a teacher in Share Tier except google add-on view
        if (Utils.isCurrentURLForStudents() || this.addOnViewService.isAddonView) return true

        return this.userDataPrivacyService.getUserDataPrivacy().pipe(
            map(({ sign_acknowledgement, do_this_later }) => {
                if (!sign_acknowledgement && !do_this_later) {
                    this.openPrivacyModal()
                }
                return true
            })
        )
    }

    openPrivacyModal() {
        this.modalService
            .open(DataPrivacyModalsComponent, {
                windowClass: "gtmsection-modal-data-privacy gtm-backdrop",
                ariaLabelledBy: "data-privacy-modal",
                size: "lg",
            })
            .result.then(
                () => {},
                (reason) => {
                    this.dismissModal(reason)
                }
            )
    }

    private dismissModal(reason: DISMISS_MODAL_REASON): void {
        switch (reason) {
            case DISMISS_MODAL_REASON.ESC:
                this.userDataPrivacyService.setPrivacyDoThisLater(true).subscribe()
                break
            case DISMISS_MODAL_REASON.BACKDROP_CLICK:
                this.userDataPrivacyService.setPrivacyDoThisLater(true).subscribe()
                break
            case DISMISS_MODAL_REASON.CLOSE:
                this.userDataPrivacyService.setPrivacyDoThisLater(true).subscribe()
                break
            case DISMISS_MODAL_REASON.DO_LATER:
                this.userDataPrivacyService.setPrivacyDoThisLater(true).subscribe()
                break
        }
    }
}
