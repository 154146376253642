import { Injectable } from "@angular/core"
import { catchError } from "rxjs/operators"
import { throwError as observableThrowError } from "rxjs/internal/observable/throwError"
import { LmApiService } from "../../services/lm-api.service"

@Injectable()
export class NewsletterService {
    constructor(private lmAPI: LmApiService) {}

    public subscribeToNewsletter(payload) {
        return this.lmAPI
            .post("/api/v2/iterable/subscribe-to-newsletter/", payload, {
                responseType: "text",
            })
            .pipe(catchError((error) => observableThrowError(error)))
    }
}
