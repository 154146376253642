<div class="feedback-btn">
    <button
        type="button"
        class="text-primary font-size-14 fw-bold bg-transparent p-0 border-0 mb-4"
        (click)="collapse.toggle()"
        [attr.aria-expanded]="!isFeedbackFormCollapsed"
        aria-controls="schoolDistrictHelp"
    >
        <ng-content></ng-content>
    </button>
</div>

<div #collapse="ngbCollapse" [(ngbCollapse)]="isFeedbackFormCollapsed">
    <iframe
        [src]="formStackUrl | bypassSecurity: 'resourceUrl'"
        title="Feedback Form"
        style="width: 100%; height: 1100px; border: none"
    ></iframe>
</div>
