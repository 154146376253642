import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import { CHNode } from "../../models/node.model"
import { LearningObject } from "../../models/learningObject.model"
import { FeaturedService } from "../../services/featured.service"

declare var window: any

@Component({
    selector: "app-recommendations",
    templateUrl: "./recommendations.component.html",
    styleUrls: ["./recommendations.component.scss"],
})
export class RecommendationsComponent implements OnInit, OnDestroy {
    public recommendations: LearningObject[] = []
    public gtmPrefix = "gtm-homepage-recommendations"
    private subscriptions: Subscription[] = []

    constructor(private featuredService: FeaturedService) {}

    public ngOnInit() {
        if (!!window.PBSLM.FEATURE_FLAGS.RECOMMENDATION_ENGINE_SHOW_TO_USERS) {
            this.featuredService
                .getUserRecommendations()
                .subscribe((recommendations: LearningObject[]) => {
                    this.recommendations = recommendations.map((obj) => new LearningObject(obj))
                })
        }
    }

    getBrowseURL(resource) {
        if (!resource.subject || !resource.subject.slug) {
            return null
        }
        return CHNode.getBrowseURLForSlug(resource.subject.slug)
    }

    public ngOnDestroy() {
        this.subscriptions.map((subscription) => subscription.unsubscribe())
    }

    public shouldShowRecommendations() {
        return (
            !!window.PBSLM.FEATURE_FLAGS.RECOMMENDATION_ENGINE_SHOW_TO_USERS &&
            this.recommendations &&
            this.recommendations.length > 0
        )
    }
}
