import { Component, OnInit } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { UserService } from "../../services/user.service"
import { PBSLM_TITLE, STATIC_IMAGES } from "../../models/constants"
import { GTMService } from "../../services/gtm.service"

declare var window: any

@Component({
    templateUrl: "./assignment-results-proxy.component.html",
    styleUrls: ["../error.component.scss"],
})
export class AssignmentResultsProxyComponent implements OnInit {
    public imageURL: string = null
    public assignmentName: string = null
    public nextURL: string = "/"
    public isStudent: boolean

    constructor(
        public userService: UserService,
        private gtmService: GTMService,
        private titleService: Title
    ) {
        this.imageURL = STATIC_IMAGES.error_401
    }

    ngOnInit() {
        this.loadWindowData()
        this.nextURL = window.location.pathname
        this.gtmService.resetDataLayer()
        this.gtmService.pushPageView()
    }

    private loadWindowData() {
        let launchAssignmentProxy = window.PBSLM?.NG?.CUSTOM_DATA?.launch_assignment_proxy
        if (!launchAssignmentProxy) {
            return
        }

        this.imageURL = launchAssignmentProxy.poster_url || this.imageURL
        this.assignmentName = launchAssignmentProxy.name
        if (launchAssignmentProxy.name) {
            this.titleService.setTitle(`${launchAssignmentProxy.name} | ${PBSLM_TITLE}`)
        } else {
            this.titleService.setTitle(`${PBSLM_TITLE}`)
        }
    }
}
