<div *ngIf="view !== ViewEnum.close" class="modal-header">
    <h5 class="modal-title">Sign up as a student</h5>
    <button
        (click)="close()"
        aria-label="Close"
        class="btn-close"
        data-dismiss="modal"
        type="button"
    ></button>
</div>

<div *ngIf="view === ViewEnum.chose" class="modal-body">
    <div class="row">
        <div class="col-12 mb-4">
            <div class="modal-body-text">
                Would you like to create a new PBS LearningMedia account, or use your Google account
                to sign up?
            </div>
            <div class="note modal-body-text">
                (Note: You will not be able to change this in the future.)
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 mb-6">
            <app-button (click)="view = ViewEnum.create" class="pbs-create-btn"
                >Create PBS LearningMedia Account</app-button
            >
        </div>

        <div class="col-lg-6">
            <div class="google-auth-wrapper">
                <a [href]="googleAuthUrl" class="selenium-continue-google">
                    <img [src]="googleImage" alt="Sign in with Google" class="auth-icon" />
                    Sign in with Google
                </a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p class="modal-body-text">
                Are you a teacher?
                <a (click)="activeModal.close()" [routerLink]="['/signup']">Sign up here ></a>
            </p>
        </div>
    </div>
</div>

<div *ngIf="view === ViewEnum.create" class="modal-body">
    <div class="row">
        <div class="col-12">
            <p>
                Are you a teacher?
                <a (click)="activeModal.close()" [routerLink]="['/signup']">Sign up here ></a>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h4>Create Your Username!</h4>
        </div>
    </div>

    <div *ngIf="error" class="row">
        <div class="col-12">
            <div class="alert alert-danger" role="alert">
                {{ error }}
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>
                Choose carefully! Your username will follow you around the site, and your teacher
                will see it on your assignments.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 mb-6">
            <div class="label mb-2"><span class="me-2">1</span>What is your first name?</div>
            <input
                (keydown.space)="$event.preventDefault()"
                [(ngModel)]="customWord"
                (ngModelChange)="updateUserName()"
                class="w-100 p-2"
                placeholder="Enter first name"
                type="text"
            />
        </div>
        <div class="col-lg-4 mb-6">
            <div class="label mb-2"><span class="me-2">2</span>Choose a description!</div>
            <app-input-carousel
                (itemChanged)="setWord1($event)"
                [items]="wordList1"
            ></app-input-carousel>
        </div>
        <div class="col-lg-4 mb-6">
            <div class="label mb-2"><span class="me-2">3</span>Choose a thing!</div>
            <app-input-carousel
                (itemChanged)="setWord2($event)"
                [items]="wordList2"
            ></app-input-carousel>
        </div>
    </div>

    <div *ngIf="customWord" class="row-auto mb-6">
        <div class="col-12">
            <span>Your username is:</span> <span class="fw-bold"> {{ tmpUsername }}</span>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h4>Pick a Question and Set Your Answer</h4>
        </div>
        <div class="col-12">
            <p>
                Your secret question and username are for logging into your account. You will need
                to remember your secret question and answer to log in.
            </p>
        </div>
    </div>

    <div class="row-auto mb-6">
        <div class="col-12">
            <ng-select
                [(ngModel)]="selectedQuestion"
                [items]="secretQuestions"
                bindLabel="name"
                bindValue="id"
            >
            </ng-select>
        </div>
    </div>

    <div *ngIf="selectedQuestion" class="row-auto mb-4">
        <div class="col-12">
            <input
                [(ngModel)]="questionAnswer"
                class="w-100 p-2"
                placeholder="Enter answer here"
                type="text"
            />
        </div>
    </div>

    <div class="row-auto recaptcha-text">
        <div class="col-12">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
    </div>
</div>

<div *ngIf="view === ViewEnum.review" class="modal-body">
    <div class="row-auto mb-6">
        <div class="col-12">Write this down or print it out. Keep it somewhere safe.</div>
    </div>

    <div class="row mb-6">
        <div class="col-12">
            <div class="info-review">
                Username: <span class="fw-bold">{{ tmpUsername }}</span>
            </div>
            <div class="info-review">
                <div>Secret Question: {{ getQuestionText(selectedQuestion) }}</div>
                <div>Answer: {{ questionAnswer.toLowerCase() }}</div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="view === ViewEnum.close" class="modal-body cancel-view">
    <div class="row mb-2">
        <div class="col-12">
            <h3 class="modal-title">Cancel sign up?</h3>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12 modal-body-text">
            <p>Closing this window will discard your changes. Are you sure?</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 d-flex flex-row justify-content-end exit-btns">
            <button
                (click)="activeModal.dismiss('Cross click')"
                type="button"
                class="btn btn-outline-secondary mx-2 discard-button"
            >
                Yes, discard changes
            </button>
            <button
                (click)="view = prevView"
                class="btn btn-outline-primary continue-button mx-2"
                type="button"
            >
                No, Continue sign up
            </button>
        </div>
    </div>
</div>

<div *ngIf="view !== ViewEnum.close && view !== ViewEnum.chose" class="modal-footer p-0 pt-2">
    <app-button
        (click)="prevPage()"
        *ngIf="view === ViewEnum.review"
        [btnHoverColor]="'#fff'"
        [buttonClasses]="'modal-padding-buttons'"
        class="me-auto back-btn"
    >
        Back
    </app-button>

    <app-button
        (click)="nextPage()"
        *ngIf="view === ViewEnum.create"
        [buttonClasses]="'modal-padding-buttons'"
        [disable]="!isFormValid()"
    >
        Next
    </app-button>

    <app-button
        (click)="createStudent()"
        *ngIf="view === ViewEnum.review"
        [disable]="!isFormValid()"
        [buttonClasses]="'modal-padding-buttons'"
    >
        Create my account
    </app-button>
</div>
