export enum Language {
    AR = "Arabic",
    DE = "German",
    EN = "English",
    ES = "Spanish",
    FR = "French",
    IT = "Italian",
    JA = "Japanese",
    LA = "Latin",
    NV = "Navajo",
    ZH = "Chinese",
    YUR = "Yurok",
    PT = "Portuguese",
    KEA = "Creole",
    TIB = "Tibetan",
    HMN = "Hmong",
    NA = "Descriptive Video",
    ASL = "American Sign Language",
}

export class LanguageUtil {
    public static fromString(value) {
        value = (value || "").toLowerCase()
        switch (value) {
            case "arabic":
                return Language.AR
            case "german":
                return Language.DE
            case "english":
                return Language.EN
            case "spanish":
                return Language.ES
            case "french":
                return Language.FR
            case "italian":
                return Language.IT
            case "japanese":
                return Language.JA
            case "latin":
                return Language.LA
            case "navajo":
                return Language.NV
            case "chinese":
                return Language.ZH
            case "yurok":
                return Language.YUR
            case "portuguese":
                return Language.PT
            case "creole":
                return Language.KEA
            case "tibetan":
                return Language.TIB
            case "hmong":
                return Language.HMN
            case "descriptive video":
                return Language.NA
            case "american sign language":
                return Language.ASL
            default:
                return Language.EN
        }
    }

    public static fromStringCode(value): Language {
        value = (value || "").toLowerCase()
        switch (value) {
            case "ar":
                return Language.AR
            case "de":
                return Language.DE
            case "en":
                return Language.EN
            case "es":
                return Language.ES
            case "fr":
                return Language.FR
            case "it":
                return Language.IT
            case "ja":
                return Language.JA
            case "la":
                return Language.LA
            case "nv":
                return Language.NV
            case "zh":
                return Language.ZH
            case "na":
                return Language.NA
            case "yur":
                return Language.YUR
            case "pt":
                return Language.PT
            case "kea":
                return Language.KEA
            case "tib":
                return Language.TIB
            case "hmn":
                return Language.HMN
            case "asl":
                return Language.ASL
            default:
                return Language.EN
        }
    }

    public static toCode(language: Language): string {
        switch (language) {
            case Language.AR:
                return "ar"
            case Language.DE:
                return "de"
            case Language.EN:
                return "en"
            case Language.ES:
                return "es"
            case Language.FR:
                return "fe"
            case Language.IT:
                return "it"
            case Language.JA:
                return "ja"
            case Language.LA:
                return "la"
            case Language.NV:
                return "nv"
            case Language.ZH:
                return "zh"
            case Language.NA:
                return "na"
            case Language.YUR:
                return "yur"
            case Language.PT:
                return "pt"
            case Language.KEA:
                return "kea"
            case Language.TIB:
                return "tib"
            case Language.HMN:
                return "hmn"
            case Language.ASL:
                return "asl"
            default:
                return "en"
        }
    }
}
