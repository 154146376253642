import { Injectable, Inject } from "@angular/core"
import { DOCUMENT } from "@angular/common"

@Injectable({ providedIn: "root" })
export class ScrollBarService {
    private userSetPaddingStyle: string
    constructor(@Inject(DOCUMENT) private document: Document) {}

    public compensate() {
        const width = this.getWidth()
        if (this.isPresent(width)) {
            this.adjustBody(width)
        }
    }

    public revertCompensation() {
        this.document.body.style["padding-right"] = this.userSetPaddingStyle
    }

    private adjustBody(scrollbarWidth: number) {
        const { body } = this.document
        this.userSetPaddingStyle = body.style.paddingRight
        const actualPadding = parseFloat(window.getComputedStyle(body)["padding-right"])
        body.style["padding-right"] = `${actualPadding + scrollbarWidth}px`
    }

    private isPresent(scrollbarWidth: number): boolean {
        const rect = this.document.body.getBoundingClientRect()
        const bodyToViewportGap = window.innerWidth - (rect.left + rect.right)
        const uncertainty = 0.1 * scrollbarWidth
        return bodyToViewportGap >= scrollbarWidth - uncertainty
    }

    private getWidth(): number {
        const measurer = this.document.createElement("div")
        measurer.className = "modal-scrollbar-measure"

        const body = this.document.body
        body.appendChild(measurer)
        const width = measurer.getBoundingClientRect().width - measurer.clientWidth
        body.removeChild(measurer)

        return width
    }
}
