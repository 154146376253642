import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import {
    GradeBandEnum,
    ORDERED_GRADE_IMAGES,
    ORDERED_GRADE_KEYS,
    STUDENT_GATE_FILTERED_PAGES,
    UserInputEvents,
} from "../../../../models/constants"
import { StudentGateService } from "../../../../services/student-gate.service"
import { Router } from "@angular/router"
import Utils from "../../../../utils/utils"

@Component({
    selector: "app-grade-gate-modal",
    templateUrl: "./student-gate-container.component.html",
    styleUrls: ["./student-gate-container.component.scss"],
})
export class StudentGateContainerComponent implements OnInit {
    @Input() title: string = "Choose your grade to start exploring!"
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>()
    grades: GradeBandEnum[] = ORDERED_GRADE_KEYS
    gradesImages: string[] = ORDERED_GRADE_IMAGES
    selectedGrade: string = ""
    hoverGrade: string = ""
    selectedGradeImage: string = ""
    hoverGradeImage: string = ""

    constructor(public studentGateService: StudentGateService, private router: Router) {}

    ngOnInit() {
        if (this.studentGateService.selectedGrade) {
            this.selectedGrade = this.studentGateService.selectedGradeValue
            this.selectedGradeImage = this.studentGateService.selectedGradeBackground
        }
    }

    hoverGradeGate(grade, state, key) {
        if (state) {
            this.hoverGrade = grade
            this.hoverGradeImage = this.gradesImages[key]
        } else {
            this.hoverGrade = ""
            this.hoverGradeImage = ""
        }
    }

    changeGradeGate(grade: string, key: number, event: KeyboardEvent | MouseEvent) {
        if (
            (event as KeyboardEvent).key !== UserInputEvents.ENTER &&
            (event as MouseEvent).type !== UserInputEvents.CLICK
        )
            return
        if (this.isCurrenPageInWhiteList() && this.selectedGrade !== grade) {
            this.selectedGrade = grade
            let selectedFacets: string | string[] =
                this.updateUrlSelectedFacetsQueryStructure || "grades:" + grade
            this.selectedGradeImage = this.gradesImages[key]
            this.studentGateService.selectedGradeValue = this.selectedGrade
            this.router.navigate([], {
                queryParams: {
                    selected_facet: this.isSelectedFacetParamRequired ? selectedFacets : null,
                },
                queryParamsHandling: "merge",
            })
        }
        if (this.studentGateService.isDropdownActive) {
            this.studentGateService.isGateActive = false
            this.studentGateService.isDropdownOpened = false
        } else {
            this.closeModal.emit(false)
        }
        this.studentGateService.navigationOnHold = false
        this.studentGateService.closeStudentGateModal()
    }

    get isSelectedFacetParamRequired(): boolean {
        if (this.isPremiumCollectionSearchPage) return true
        let refreshPagesList = ["/search/", "/subjects/"]
        return (
            refreshPagesList.filter((value) =>
                //regex expression that checks if pathname is starting with
                // value => (segment url) and has 3 or more chars after
                Utils.testIfPathnameFitRegEx("^" + value + ".{0,}$")
            ).length === 1
        )
    }

    get updateUrlSelectedFacetsQueryStructure(): string[] | string {
        let currentUrlParams = Utils.extractUrlQueryParams(window.location.href)
        if (!currentUrlParams?.selected_facet) return null
        if (currentUrlParams.selected_facet instanceof Array) {
            for (let key = 0; key < currentUrlParams.selected_facet.length; key++) {
                if (currentUrlParams.selected_facet[key].includes("grades")) {
                    // @ts-ignore
                    currentUrlParams.selected_facet[key] = "grades:" + this.selectedGrade
                }
            }
        } else if (currentUrlParams.selected_facet.includes("grades")) {
            currentUrlParams.selected_facet = "grades:" + this.selectedGrade
        } else if (!currentUrlParams.selected_facet.includes("grades")) {
            currentUrlParams.selected_facet = [
                currentUrlParams.selected_facet,
                "grades:" + this.selectedGrade,
            ]
        }
        return currentUrlParams.selected_facet
    }

    isCurrenPageInWhiteList(): boolean {
        let isCurrentPageAffected: boolean = false
        STUDENT_GATE_FILTERED_PAGES.forEach((page) => {
            if (window.location.pathname.includes(page)) {
                isCurrentPageAffected = true
            }
        })
        return isCurrentPageAffected || this.isPremiumCollectionSearchPage
    }

    get isPremiumCollectionSearchPage() {
        return /\/collection\/.*\/search\//.test(window.location.pathname)
    }
}
