<div class="container-fluid">
    <div class="image">
        <img alt="Sign Up Image" [src]="singUpHeading" />
    </div>
    <div class="container heading-wrapper">
        <div class="row g-0">
            <div class="content col-lg-12 col-xl-6">
                <div class="title-wrapper">
                    <h2 class="heading-title mb-xl-3">
                        PBS LearningMedia has curated FREE, curriculum-aligned videos, interactives,
                        lesson plans, and more for teachers like you!
                    </h2>
                    <span class="heading-description">
                        Sign up for a free account to explore thousands of teaching resources that
                        help amplify your lessons, kickstart classroom conversations, and spark your
                        students’ sense of curiosity.
                    </span>
                </div>

                <div class="brands">
                    <div class="pbs-logo">
                        <img alt="PBS Logo" [src]="pbsLogo" />
                    </div>
                    <div class="wgbh-logo">
                        <img alt="WGBH Image" [src]="wgbhLogo" />
                    </div>
                    <div class="pbs-kids-logo">
                        <img alt="PBS Kids Logo" [src]="psbKidsLogo" />
                    </div>
                </div>
            </div>
            <div class="col-lg-0 col-xl-1"></div>
            <div class="col-lg-12 col-xl-5">
                <app-auth-modal-content
                    [isSignUp]="true"
                    [nextURL]="nextUrl"
                    [pbsEmailRegister]="true"
                    [description]="description"
                    [registerDescription]="authSectionDescription"
                    [registerLinkText]="authSectionLinkText"
                    [title]="authSectionTitle"
                    class="auth-section"
                ></app-auth-modal-content>
            </div>
        </div>
    </div>
</div>
<div class="container marketing-wrapper">
    <app-marketing-description [imageSide]="'left'" [marketingImage]="pbsClassroomReadyResources">
        <h2 class="title">Uncover Award-Winning Content</h2>
        <p class="description">
            We’ve curated a wide range of classroom-ready resources, including state and national
            standards-aligned content from PBS, your local PBS station, and trusted educational
            partners like the Library of Congress and the Smithsonian. With just a few clicks, you
            can access firsthand accounts from veterans, footage from the depths of the ocean,
            science lessons from space, historical letters and photographs, multimedia lesson plans
            perfect for helping students form deeper, more meaningful connections to the curriculum,
            and so much more. Here are a few of our favorite collections:
        </p>
        <ul>
            <li>
                <a routerLink="/collection/us-history-collection/home/">U.S. History</a>
            </li>
            <li>
                <a routerLink="/collection/celebrating-black-history-culture/"
                    >Celebrating Black History and Culture</a
                >
            </li>
            <li>
                <a routerLink="/collection/kenburnsclassroom/home/">Ken Burns in the Classroom</a>
            </li>
            <li>
                <a routerLink="/collection/universe/home/"
                    >Bringing the Universe to America’s Classrooms</a
                >
            </li>
            <li>
                <a routerLink="/collection/pbs-digital-studios/">PBS Digital Studios</a>
            </li>
            <li>
                <a routerLink="/collection/newshour-classroom/">PBS NewsHour Classroom</a>
            </li>
            <li>
                <a routerLink="/collection/shakespeare-uncovered/">Shakespeare Uncovered</a>
            </li>
            <li>
                <a routerLink="/collection/nova/">NOVA</a>
            </li>
        </ul>
    </app-marketing-description>
    <app-marketing-description [marketingImage]="pbsKidsImage">
        <h2 class="title">Inspire Your Youngest Learners</h2>
        <p class="description">
            PBS KIDS applies a whole-child approach to learning with media, supporting all of the
            key developmental areas an early learner needs to be successful in school — and life. We
            work hand-in-hand with early childhood education experts, advisors, classroom
            practitioners, and PBS KIDS producers to create developmentally-appropriate educational
            content. You’ll find free videos, games, hands-on activities, printables, lesson plans,
            and more featuring beloved characters and engaging, child-friendly resources. Teach Your
            Way with some of our most popular collections:
        </p>
        <ul>
            <li>
                <a routerLink="/collection/ready-to-learn/home/">Ready To Learn</a>
            </li>
            <li>
                <a routerLink="/collection/daniel-tigers-neighborhood/"
                    >Daniel Tiger’s Neighborhood</a
                >
            </li>
            <li>
                <a routerLink="/collection/wild-kratts/">Wild Kratts</a>
            </li>
            <li>
                <a routerLink="/collection/super-why/">SuperWHY!</a>
            </li>
            <li>
                <a routerLink="/collection/All-About-Me/">All About Me</a>
            </li>
            <li>
                <a routerLink="/collection/molly-of-denali/">Molly of Denali™</a>
            </li>
        </ul>
    </app-marketing-description>
    <app-marketing-description [imageSide]="'left'" [marketingImage]="teacherAndStudentClassroom">
        <h2 class="title">Access Teacher-Friendly Features</h2>
        <p class="description">
            PBS LearningMedia offers ways to share educational content directly with your students
            using several external platforms, including:
        </p>
        <ul>
            <li>
                <b>Google Classroom:</b> Import your class roster directly to PBS LearningMedia and
                create a seamless experience between both services.
            </li>
            <li>
                <b>Remind:</b> Share your favorite PBS LearningMedia resources using the Remind text
                messaging service.
            </li>
            <li>
                <b>Schoology:</b> Add PBS LearningMedia to your school’s Schoology account through
                the App Center to enable instant single-sign-on integration, so that you can sign in
                with your existing Schoology account.
            </li>
            <li>
                <b>Clever:</b> Already using Clever? Sign in to PBS LearningMedia using your Clever
                log-in.
            </li>
            <span class="text-center w-100 d-block">
                Visit our
                <a href="/help/sharing-content-across-platforms/" target="_blank">
                    <b>Help page</b>
                </a>
                to learn more about our teacher-friendly features.
            </span>
        </ul>
    </app-marketing-description>
</div>
<div class="container-fluid testimonials-section">
    <div class="container">
        <div class="testimonials-wrapper">
            <h2 class="title">What Teachers Are Saying</h2>
            <h5 class="description">
                From St. Louis, Missouri to Durham, North Carolina, educators across the country
                trust PBS LearningMedia for its trusted, high-quality content, user-friendly
                interface, and its ability to engage students in the classroom. Here’s what they
                have to say:
            </h5>
            <div class="row-auto row-eq-height g-0">
                <div class="col-lg-12 col-xl-4 testimonial">
                    <app-testimonial>
                        <div class="name">Mallory Mbalia</div>
                        <div class="profession">Kindergarten, Wake County, NC</div>
                        <div class="opinion">
                            "PBS LearningMedia empowers students to explore independently, at their
                            own pace which helps build their confidence and desire to succeed."
                        </div>
                    </app-testimonial>
                </div>
                <div class="col-lg-12 col-xl-4 testimonial">
                    <app-testimonial>
                        <div class="name">Don Goble</div>
                        <div class="profession">
                            Broadcast Technology, Film & Multimedia Instructor, St. Louis, MO
                        </div>
                        <div class="opinion">
                            "As teachers, we are constantly bombarded with new tools, new devices,
                            and new apps. Finding trustworthy resources can be daunting so having a
                            resource like PBS LearningMedia is very valuable."
                        </div>
                    </app-testimonial>
                </div>
                <div class="col-lg-12 col-xl-4 testimonial">
                    <app-testimonial>
                        <div class="name">Mig Hayes</div>
                        <div class="profession">
                            6th-12th Grade Teacher & Media Specialist, Durham, NC
                        </div>
                        <div class="opinion">
                            "...your challenge as a teacher is to serve a range of students - from
                            the most accelerated to those struggling to succeed. By having tools
                            like PBS LearningMedia, I can give my students a video to view at night
                            and by the time they come back to school, they will have had time to
                            digest the information on their own."
                        </div>
                    </app-testimonial>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="call-to-action-wrapper">
        <h5 class="title">
            Ready to inspire your students with thousands of high-quality, trusted resources?
        </h5>
        <app-button (click)="scrollToTop()" class="call-to-action-button">
            Create an Account Now
            <app-icon class="ms-2 mb-1" type="Arrow-down"></app-icon>
        </app-button>
    </div>
</div>
