import { Pipe, PipeTransform } from "@angular/core"
import Utils from "../../utils/utils"

@Pipe({
    name: "safeTruncate",
})
export class TruncatePipe implements PipeTransform {
    public transform(value: string, limit: number, disabled = false): string {
        if (!value || limit <= 0) {
            return ""
        }

        const trail = "…"
        let cleanText = Utils.removeHtmlTags(value)
        cleanText = new DOMParser().parseFromString(cleanText, "text/html").documentElement
            .textContent
        const trimmedString = cleanText.substr(0, limit)

        if (cleanText.length > limit && !disabled) {
            return (
                cleanText.substring(
                    0,
                    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
                ) + trail
            )
        }
        return cleanText
    }
}
