import { Component, Input } from "@angular/core"
import { LearningObject } from "../../../models/learningObject.model"

@Component({
    selector: "app-resource-list",
    templateUrl: "./resource-list.component.html",
    styleUrls: ["./resource-list.component.scss"],
})
export class ResourceListComponent {
    @Input()
    public learningObjects: LearningObject[]
}
