import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core"
import { DataPrivacyAcknowledgmentComponent } from "./data-privacy-acknowledgment/data-privacy-acknowledgment.component"
import { DataPrivacyClassroomComponent } from "./data-privacy-classroom/data-privacy-classroom.component"
import { FormGroup } from "@angular/forms"
import { BehaviorSubject, Subject } from "rxjs"
import { UserDataPrivacyService } from "../../../services/user-data-privacy.service"
import { takeUntil } from "rxjs/operators"
import {
    UserDataPrivacyPayload,
    UserDataPrivacyResponse,
} from "../../../models/userDataPrivacy.model"

@Component({
    selector: "app-data-privacy-select",
    templateUrl: "./data-privacy-select.component.html",
    styleUrls: ["./data-privacy-select.component.scss"],
})
export class DataPrivacySelectComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DataPrivacyAcknowledgmentComponent)
    dataPrivacyAcknowledgmentRef: DataPrivacyAcknowledgmentComponent

    @ViewChild(DataPrivacyClassroomComponent)
    dataPrivacyClassroomRef: DataPrivacyClassroomComponent

    @Input()
    dataPrivacy: UserDataPrivacyResponse

    @Output()
    submitBtnClicked = new EventEmitter<UserDataPrivacyPayload>()

    isFormInvalid = true
    form = new FormGroup({})

    private destroyed$ = new Subject()

    isLoading = new BehaviorSubject<boolean>(false)

    constructor(private userDataPrivacyService: UserDataPrivacyService) {}

    ngOnInit(): void {
        this.form.statusChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((formStatus) => (this.isFormInvalid = formStatus === "INVALID"))
    }

    ngAfterViewInit(): void {
        this.form.addControl("classRoomSetting", this.dataPrivacyClassroomRef.classRoom)
        this.form.addControl("privacyCheck", this.dataPrivacyAcknowledgmentRef.privacyCheckControl)
        this.form.get("privacyCheck").setValidators(this.userDataPrivacyService.checkBoxValidator())
        this.form.get("privacyCheck").updateValueAndValidity()
    }

    ngOnDestroy(): void {
        this.destroyed$.next()
        this.destroyed$.complete()
    }

    onSubmit(): void {
        const preparePayload = { ...this.form.value, doThisLater: this.dataPrivacy.do_this_later }
        // @ts-ignore
        const payload = this.userDataPrivacyService.createPayload(preparePayload)
        this.submitBtnClicked.next(payload)
    }
}
