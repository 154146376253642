<article>
    <section class="top-section ps-6 pe-6 d-flex flex-row align-items-end">
        <div class="col-12 col-sm-6 align-self-start">
            <h1 class="mb-6">{{ errorCodes.Error500 }}</h1>
            <div class="description">
                <ng-container *ngIf="!customErrorMessage">
                    <div class="mb-6">
                        Something went wrong when trying to display this page. Sorry for the
                        inconvenience. We are working to fix the issue.
                    </div>
                    <div *ngIf="!isAddOnView">
                        In the meantime, explore new resources on our
                        <a [routerLink]="[isStudent ? '/student' : '/']" title="Homepage"
                            >home page</a
                        >, or browse by subject below. You could also try searching for something
                        similar.
                    </div>
                </ng-container>
                <div *ngIf="customErrorMessage" class="debug-error-message mt-2">
                    {{ customErrorMessage }}
                </div>
            </div>
        </div>
        <div>
            <img class="error-img d-none d-sm-block" [src]="image" />
        </div>
    </section>
    <section class="bottom-section w-100 ps-6 pe-6 pb-4" *ngIf="!isAddOnView">
        <app-subject-list [subjects]="subjects"></app-subject-list>
    </section>
</article>
