import { NgModule } from "@angular/core"
import { ExtraOptions, RouteReuseStrategy, RouterModule, Routes } from "@angular/router"
import { LoginRequiredComponent } from "./error/login-required/login-required.component"
import { NotAuthGuard } from "./guards/NotAuth.guard"
import { TeacherAuthGuard } from "./guards/TeacherAuth.guard"
import { LaunchAssignmentProxyComponent } from "./error/launch-assignment-proxy/launch-assignment-proxy.component"
import { AssignmentResultsProxyComponent } from "./error/assignment-results-proxy/assignment-results-proxy.component"
import { TeacherExperienceOnlyGuard } from "./guards/TeacherExperienceOnly.guard"
import { CustomRouteReuseStrategy } from "./services/custom-reuse-strategy"
import { IframeGuard } from "./guards/Iframe.guard"
import { LogInAddOnComponent } from "./add-on/log-in-iframe/log-in-add-on.component"
import { StudentGateSearchGuard } from "./guards/StudentGateSearch.guard"
import { AuthRequiredGuard } from "./guards/AuthRequired.guard"
import { FeatureFlagsService } from "./services/feature-flags.service"

const appRoutes: Routes = [
    {
        path: "",
        loadChildren: () => import("./homepage/homepage.module").then((m) => m.HomepageModule),
    },
    {
        path: "admin/design-system",
        loadChildren: () =>
            import("./design-system/design-system.module").then((m) => m.DesignSystemModule),
    },
    {
        path: "about",
        loadChildren: () => import("./about/about.module").then((m) => m.AboutModule),
    },
    {
        path: "tools",
        loadChildren: () => import("./tools/tools.module").then((m) => m.ToolsModule),
        canActivate: [AuthRequiredGuard],
    },
    { path: "subjects", pathMatch: "full", redirectTo: "/" },
    {
        path: "subjects",
        loadChildren: () => import("./browsing/browsing.module").then((m) => m.BrowsingModule),
    },
    {
        path: "add-on/subjects",
        loadChildren: () => import("./browsing/browsing.module").then((m) => m.BrowsingModule),
        canActivate: [IframeGuard],
    },
    { path: "grades", pathMatch: "full", redirectTo: "/" },
    {
        path: "grades",
        loadChildren: () =>
            import("./browse-by-grade/browse-by-grade.module").then((m) => m.BrowseByGradeModule),
    },
    {
        path: "add-on/grades",
        loadChildren: () =>
            import("./browse-by-grade/browse-by-grade.module").then((m) => m.BrowseByGradeModule),
        canActivate: [IframeGuard],
    },
    {
        path: "resource",
        loadChildren: () => import("./resource/resource.module").then((m) => m.ResourceModule),
        data: { shouldReuse: false },
    },
    {
        path: "add-on/resource",
        loadChildren: () => import("./resource/resource.module").then((m) => m.ResourceModule),
        data: { shouldReuse: false },
        canActivate: [IframeGuard],
    },
    {
        path: "add-on/log-in",
        component: LogInAddOnComponent,
        pathMatch: "full",
    },
    {
        path: "search",
        loadChildren: () =>
            import("./search-page/search-page.module").then((m) => m.SearchPageModule),
        canActivate: [StudentGateSearchGuard],
    },
    {
        path: "add-on",
        pathMatch: "full",
        redirectTo: "add-on/search/",
    },
    {
        path: "add-on/search",
        loadChildren: () =>
            import("./search-page/search-page.module").then((m) => m.SearchPageModule),
        canActivate: [IframeGuard],
    },
    {
        path: "error",
        loadChildren: () => import("./error/error.module").then((m) => m.ErrorModule),
    },
    {
        path: "student/launch_assignment_proxy/:assignmentCode/:rosterCode",
        component: LaunchAssignmentProxyComponent,
    },
    {
        path:
            "tools/rosters/results/assignment_results_proxy/:assignmentCode/:rosterCode/:resultId",
        component: AssignmentResultsProxyComponent,
    },
    {
        path: "student",
        loadChildren: () => import("./student/student.module").then((m) => m.StudentModule),
    },
    { path: "loginRequired", component: LoginRequiredComponent },
    {
        path: "signup",
        loadChildren: () => import("./signup/signup.module").then((m) => m.SignupModule),
        canActivate: [NotAuthGuard, TeacherExperienceOnlyGuard],
    },
    {
        path: "tools",
        loadChildren: () => import("./tools/tools.module").then((m) => m.ToolsModule),
        canActivate: [TeacherAuthGuard, TeacherExperienceOnlyGuard],
    },

    //TODO: redirect for old dashboard routes - remove after release?
    { path: "dashboard", redirectTo: "tools", pathMatch: "full" },
    { path: "favorites", redirectTo: "tools/favorites", pathMatch: "full" },
    { path: "folders", redirectTo: "tools/folders", pathMatch: "full" },

    {
        path: "profile",
        loadChildren: () =>
            import("./user-profile/user-profile.module").then((m) => m.UserProfileModule),
        canActivate: [TeacherAuthGuard, TeacherExperienceOnlyGuard],
    },
    {
        path: "add-on/profile",
        loadChildren: () =>
            import("./user-profile/user-profile.module").then((m) => m.UserProfileModule),
        canActivate: [TeacherAuthGuard, TeacherExperienceOnlyGuard, IframeGuard],
    },

    {
        path: "collection",
        loadChildren: () =>
            import("./collection/collection.module").then((m) => m.CollectionModule),
    },
    {
        path: "newsletter",
        loadChildren: () =>
            import("./newsletter/newsletter.module").then((m) => m.NewsletterModule),
    },
    {
        path: "**",
        loadChildren: () => import("./embed/embed.module").then((m) => m.EmbedModule),
    },
]

const extraRouteConfig: ExtraOptions = {
    onSameUrlNavigation: "reload",
    // Search ERROR-ROUTING-WORKAROUND for more info
    // In the case of error pages, we want to show the incorrect URL. To do that we need the URL to be set in
    // the browser state before guard calls.
    // This only affects Angular router link navigation
    urlUpdateStrategy: "eager",
}

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, extraRouteConfig)],
    exports: [RouterModule],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy,
        },
        FeatureFlagsService,
    ],
})
export class AppRoutingModule {}
