<article>
    <section class="top-section ps-6 pe-6 d-flex flex-row flex-wrap align-items-end my-4">
        <div class="col-12 col-sm col-lg-8 p-0 pe-sm-6 pe-0">
            <ng-container
                *ngTemplateOutlet="errorCode === errorCodes.Error403 ? template403 : template401"
            ></ng-container>
        </div>
        <div class="d-none d-sm-block">
            <img class="error-img" [src]="image" alt="error image" />
        </div>
    </section>
    <section class="bottom-section w-100 pb-2" *ngIf="showContent && !isAddonView">
        <app-subject-list
            class="d-block ps-6 pe-6"
            [subjects]="subjects"
            *ngIf="learningObjects.length === 0"
        ></app-subject-list>
        <app-resource-list
            class="d-flex flex-wrap justify-content-between ms-2 me-2"
            *ngIf="learningObjects.length > 0"
            [learningObjects]="learningObjects"
        ></app-resource-list>
    </section>
</article>
<ng-template #template401>
    <h1 class="mb-6 text-center text-sm-left selenium-page-title">{{ errorCodes.Error401 }}</h1>
    <div class="description">
        <div *ngIf="!customErrorMessage">{{ standardErrorMessages[errorCodes.Error401] }}</div>
        <div *ngIf="customErrorMessage">{{ customErrorMessage }}</div>
    </div>

    <div class="d-flex flex-nowrap justify-content-center justify-content-sm-start">
        <app-register [buttonLabelText]="'Teacher Sign In'" [nextURL]="nextURL"></app-register>
        <app-register
            [isStudent]="isStudent"
            [buttonLabelText]="'Student Sign In'"
            [nextURL]="nextURL"
        ></app-register>
    </div>
</ng-template>
<ng-template #template403>
    <!-- TODO: customErrorMessage is used as a Boolean right now -->
    <!-- TODO: Clean this up when we get the new error pages designs-->
    <ng-container
        *ngTemplateOutlet="customErrorMessage ? customTemplate : defaultTemplate"
    ></ng-container>
    <ng-template #defaultTemplate>
        <h1 class="mb-6 text-center text-sm-left selenium-page-title">{{ errorCodes.Error403 }}</h1>
        <div class="description">
            <div class="mb-6">This page is not available.</div>
            <div *ngIf="!isAddonView">
                Try searching for something similar, or check out our
                <a [routerLink]="[isStudent ? '/student' : '/']" title="Homepage">home page</a>
                for featured resources and collections..
            </div>
        </div>
        <app-external-search-input
            *ngIf="!isAddonView"
            class="d-block"
            [placeholder]="'Search Keyword(s)'"
            [type]="'for-error'"
        ></app-external-search-input>
    </ng-template>
    <ng-template #customTemplate>
        <h1 class="mb-6 text-left text-sm-left selenium-page-title">
            This resource is currently unavailable (403)
        </h1>
        <div class="description">
            <div class="mb-6">
                {{ customErrorMessage }}
            </div>
        </div>
    </ng-template>
</ng-template>
