import { Component, OnInit } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { GTMService } from "../services/gtm.service"
import { ActivatedRoute } from "@angular/router"
import { CookiesService } from "../services/cookies.service"
import { ModalService } from "../shared-components/modal/modal.service"
import { AuthModalComponent } from "../shared-components/modals/auth-modal/auth-modal.component"
import { STATIC_IMAGES } from "../models/constants"
import { StudentSignInModalComponent } from "../shared-components/modals/student-sign-in-modal/student-sign-in-modal.component"

@Component({
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
    //SignUp CDN static images
    readonly pbsKidsImage: string = STATIC_IMAGES.pbs_kids_pink
    readonly psbKidsLogo: string = STATIC_IMAGES.pbs_kids_2023_logo
    readonly pbsLogo: string = STATIC_IMAGES.pbs_logo_white
    readonly wgbhLogo: string = STATIC_IMAGES.wgbh_gray
    readonly singUpHeading: string = STATIC_IMAGES.singup_heading
    readonly pbsClassroomReadyResources: string = STATIC_IMAGES.pbs_classroom_ready_resources
    readonly teacherAndStudentClassroom: string = STATIC_IMAGES.teacher_and_student_classroom

    authSectionTitle: string = "Join PBS LearningMedia as a"
    authSectionDescription: string = "Already have an account?"
    authSectionLinkText: string = "Sign in here."
    description: string =
        "Use one of the services below to create an account with PBS LearningMedia:"
    nextUrl: string

    constructor(
        private titleService: Title,
        private gtmService: GTMService,
        private route: ActivatedRoute,
        private cookieService: CookiesService,
        private modalService: ModalService
    ) {
        this.titleService.setTitle(
            "Educational Resources For Teachers And Students | PBS LearningMedia"
        )
        this.gtmService.resetDataLayer()
        this.gtmService.pushPageView()
    }

    public ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.nextUrl = params["next"]
            let open = params["open"]
            if (this.nextUrl) {
                this.cookieService.setCookie("next", this.nextUrl, 1, "/")
            }

            if (open) {
                if (open === "teacher") {
                    setTimeout(() => this.openAuthModal())
                } else if (open === "student") {
                    setTimeout(() => this.openStudentAuthModal())
                }
            }
        })
    }

    public openAuthModal() {
        this.modalService.open(AuthModalComponent, {
            data: {
                nextURL: this.nextUrl,
            },
        })
    }

    public openStudentAuthModal() {
        this.modalService.open(StudentSignInModalComponent, {
            data: {
                nextURL: this.nextUrl,
            },
        })
    }

    public scrollToTop() {
        document.querySelector("body").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        })
    }
}
