export class Folder {
    constructor(
        public name: string,
        public description: string,
        public pk: number,
        public parentID: number
    ) {}

    public static fromData(data): Folder {
        return new Folder(
            data.name,
            data.description,
            data.pk,
            data.parent
            // data.assigned,
        )
    }
}

export class SelectableFolder {
    public wasInitiallySelected

    constructor(public folder: Folder, public isSelected: boolean) {
        this.wasInitiallySelected = isSelected
    }
}

export type FolderItem = {
    folderItemType: number
    folderItemTypeKeyName: string
    folderItemId: number
}
