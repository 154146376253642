import { Injectable } from "@angular/core"
import { StickyNavbar, StickyNavbarTrigger } from "./sticky-navbar.model"

@Injectable()
export class StickyNavbarService {
    private stickyNavbar: StickyNavbar
    private triggers: StickyNavbarTrigger[] = []
    private activeTrigger: StickyNavbarTrigger
    private isInitiated = false
    private ticking = false
    private scrollListener = () => {
        if (!this.ticking) {
            this.window.requestAnimationFrame(() => {
                if (!this.triggers.length) {
                    return
                }

                const activeTrigger = this.activeTrigger

                this.activeTrigger = this.triggers.find((trigger) => {
                    const triggerElement = trigger.triggerElementRef.nativeElement
                    const bb = triggerElement.getBoundingClientRect()

                    const topOfTriggerHit = bb.y <= this.stickyNavbar.height
                    const bottomOfTriggerHit = bb.bottom >= this.stickyNavbar.height

                    return topOfTriggerHit && bottomOfTriggerHit
                })
                const isNewTrigger = this.activeTrigger !== activeTrigger
                if (this.activeTrigger && isNewTrigger) {
                    this.stickyNavbar.update(this.activeTrigger)
                }

                this.activeTrigger ? this.stickyNavbar.show() : this.stickyNavbar.hide()

                this.ticking = false
            })

            this.ticking = true
        }
    }

    constructor(private window: Window) {}

    public registerNavbar(stickyNavbar: StickyNavbar) {
        this.stickyNavbar = stickyNavbar
    }

    public deregisterNavbar() {
        this.stickyNavbar = null
    }

    public registerTrigger(trigger: StickyNavbarTrigger) {
        if (this.triggers.indexOf(trigger) === -1) {
            this.triggers.push(trigger)
        }

        if (!this.isInitiated) {
            this.setupScrollListener()
        }
    }

    public deregisterTrigger(trigger: StickyNavbarTrigger) {
        this.triggers = this.triggers.filter((e) => e !== trigger)
        if (this.triggers.length === 0) {
            this.removeScrollListener()
        }
    }

    private setupScrollListener() {
        this.window.addEventListener("scroll", this.scrollListener)
        this.isInitiated = true
    }

    private removeScrollListener() {
        this.window.removeEventListener("scroll", this.scrollListener)
        this.isInitiated = false
    }
}
