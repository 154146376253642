<ng-container *ngIf="!userService.isLoggedIn() && !userService.hasStudentExperience()">
    <div
        class="container selenium-app-available-features"
        [ngClass]="{ 'mt-8': addOnService.isAddonView }"
    >
        <div class="row-auto description-side">
            <div>
                <div class="featured-header mt-md-8 mt-xs-5 mb-6">
                    <div>
                        <h2>Unlock the Power of PBS LearningMedia</h2>
                        <h5>Create a free account to gain full access to the website</h5>
                    </div>
                </div>
                <div class="row-auto items">
                    <div class="col-md-6 col-lg-6">
                        <div class="item">
                            <app-icon [type]="'Favorite'"></app-icon>
                            <span>Save & Organize Resources</span>
                        </div>
                        <div class="item">
                            <app-icon [type]="'Standards'"></app-icon>
                            <span>See State Standards</span>
                        </div>
                        <div class="item">
                            <app-icon [type]="'Assignments'"></app-icon>
                            <span>Manage Classes & Assignments</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <div class="item">
                            <img [src]="googleClassroomIcon" alt="google classroom icon" />
                            <span>Sync with Google Classroom</span>
                        </div>
                        <div class="item">
                            <app-icon [type]="'Puzzle'"></app-icon>
                            <span>Create Lessons</span>
                        </div>
                        <div class="item">
                            <app-icon [type]="'Dashboard'"></app-icon>
                            <span>Customized Dashboard</span>
                        </div>
                    </div>
                </div>
                <div class="row-auto button-row">
                    <div class="col-xs-12 col-md-12 col-lg-12">
                        <div class="get-more-features">
                            <app-register [buttonLabelText]="'Get More Features Free'">
                            </app-register>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="d-flex flex-xs-wrap flex-lg-nowrap">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 description-container"></div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 image">
                <div class="circle position-relative"></div>
            </div>
        </div>
    </div>
</ng-container>
