import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { EmbedRoutingModule } from "./embed-routing.module"
import { SharedComponentsModule } from "../shared-components/shared-components.module"
import { EmbedComponent } from "./embed.component"

@NgModule({
    declarations: [EmbedComponent],
    imports: [CommonModule, EmbedRoutingModule, SharedComponentsModule],
})
export class EmbedModule {}
