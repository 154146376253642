import Utils from "../utils/utils"
import { Student } from "./student.model"

export class Roster {
    public rosterId: number = null
    public rosterName: string = null
    public rosterGoogleID: number = null
    public students: Student[] = []
    public selected: boolean = false
    public expanded: boolean = false

    constructor(input) {
        Object.keys(input).map((key) => {
            const property = Utils.convertToCamelcase(key)
            if (property === "students") {
                this[property] = this.populateStudents(input[key])
            } else {
                this[property] = input[key]
            }
        })
    }

    private populateStudents(students) {
        const studentsArr: Student[] = []
        Object.keys(students).map((key) => {
            const property = Utils.convertToCamelcase(key)
            studentsArr.push(new Student(students[property]))
        })
        return studentsArr
    }
}
