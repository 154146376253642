<form
    [formGroup]="fullName"
    class="row-md-auto d-flex justify-content-between flex-xs-column flex-md-row mx-n3 mb-3 mt-xs-3 selenium-personal-info"
>
    <div class="py-xs-3 px-md-0 input-container">
        <div class="p-2 border rounded-3 border-dark bg-transparent selenium-first-name">
            <label for="firstName" class="m-0 form-label text-primary fw-bold font-size-14"
                >First Name</label
            >
            <input
                type="text"
                class="form-control font-size-18 text-darker-grey border-0 ps-0"
                id="firstName"
                formControlName="firstName"
            />
        </div>
    </div>
    <div class="py-xs-3 px-md-0 input-container">
        <div class="p-2 border rounded-3 border-dark bg-transparent selenium-last-name">
            <label for="lastName" class="m-0 text-primary form-label fw-bold font-size-14"
                >Last Name</label
            >
            <input
                type="text"
                class="form-control font-size-18 text-darker-grey border-0 ps-0"
                id="lastName"
                formControlName="lastName"
            />
        </div>
    </div>
</form>
