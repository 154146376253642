import { Component, OnInit } from "@angular/core"
import { FormControl } from "@angular/forms"

@Component({
    selector: "app-data-privacy-acknowledgment",
    templateUrl: "./data-privacy-acknowledgment.component.html",
    styleUrls: ["./data-privacy-acknowledgment.component.scss"],
})
export class DataPrivacyAcknowledgmentComponent implements OnInit {
    privacyCheck: FormControl

    ngOnInit(): void {
        this.privacyCheck = new FormControl(false)
    }

    get privacyCheckControl(): FormControl {
        return this.privacyCheck
    }
}
