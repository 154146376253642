import { HTMLMedia, Media } from "./resource/media"
import { URSService } from "../services/urs.service"

export interface SupportMaterialByCategory {
    category: string
    items: SupportMaterial[]
}

export class SupportMaterial {
    constructor(
        public title: string,
        public role: string,
        public printUrl: string,
        public order: number,
        public mediaType: string,
        public guid: string,
        public medias: Media[],
        public canonicalURL: string
    ) {}

    public isHTMLFragment(): boolean {
        return this.mediaType === "HTMLFragment"
    }

    public hasImage(): boolean {
        return (
            this.medias.length >= 1 &&
            !!this.medias.find((media) => media.posterImage && !!media.posterImage.itsURL)
        )
    }

    public getMediaType(): string {
        return this.mediaType
    }

    public getContent(): string {
        // This gets the content from the first media. Business requirements will change here
        // so the appropriate language media is selected
        if (!this.medias || this.medias.length < 1) {
            return ""
        }
        const media = this.medias.find((media) => media instanceof HTMLMedia) as HTMLMedia
        if (!media) {
            return ""
        }
        return media.htmlContent
    }

    public getPosterImage() {
        if (!this.medias || this.medias.length < 1) {
            return ""
        }
        const media = this.medias.find((media) => media.posterImage && !!media.posterImage.itsURL)
        if (!media) {
            return ""
        }
        return media.posterImage.itsURL
    }

    public static fromData(element: any, ursService: URSService) {
        return new SupportMaterial(
            element.title,
            element.role,
            element.print_url,
            element.order,
            element.media_type,
            element.guid,
            (element.medias || []).map((mediaData) => Media.fromData(mediaData, ursService)),
            element.canonical_url
        )
    }
}
