import {
    AfterContentInit,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChild,
} from "@angular/core"
import { CarouselItem2Component } from "./CarouselItem2Component/carousel-item2.component"
import Utils from "../../utils/utils"

@Component({
    selector: "app-carousel2",
    templateUrl: "./carousel2.component.html",
    styleUrls: ["./carousel2.component.scss"],
})
export class Carousel2Component implements AfterContentInit, OnInit {
    @Input() public itemWidth: number = 300
    @Input() public alwaysShowArrows: boolean = false
    @Input() public innerArrows: boolean = false
    @Input() public height: number = 250
    @Input() public itemsToScrollOnClick: number = 1
    @Input() public itemsToScrollOnSwipe: number = 3
    @Input() public gtmPrefix: string = ""

    @ViewChild("canvas", { static: true }) public canvas: ElementRef
    @ContentChildren(CarouselItem2Component) public carouselItems: QueryList<CarouselItem2Component>

    private canvasWidth: number
    private items: CarouselItem2Component[] = []

    constructor() {}

    public ngOnInit() {
        Utils.resize(() => {
            this.canvasWidth = this.canvas.nativeElement.clientWidth
            this.repositionItems()
        })
    }

    public ngAfterContentInit() {
        this.canvasWidth = this.canvas.nativeElement.clientWidth
        this.items = this.carouselItems.toArray()
        this.prepareItems()

        this.carouselItems.changes.subscribe((items: QueryList<CarouselItem2Component>) => {
            this.items = items.toArray()
            this.prepareItems()
        })
    }

    public prepareItems() {
        this.items.forEach((item: CarouselItem2Component, index: number) => {
            item.setWidth(this.itemWidth)
            item.setPosition(this.itemWidth * index)
            item.render()
        })
    }

    public prevItem(itemsToScroll: number = 1) {
        let scrollItems = this.normalizeItemsToScroll(itemsToScroll)
        if (!this.isFirstItemVisible()) {
            const firstItem = this.items[0]
            while (scrollItems && firstItem.position + firstItem.width <= 0) {
                this.items.forEach((item: CarouselItem2Component) => {
                    item.setPosition(item.position + item.width)
                })
                scrollItems--
            }
            this.renderItems()
        }
    }

    public nextItem(itemsToScroll: number = 1) {
        let scrollItems = this.normalizeItemsToScroll(itemsToScroll)
        if (!this.isLastItemVisible()) {
            const lastItem = this.items[this.items.length - 1]
            while (scrollItems && lastItem.position + lastItem.width > this.canvasWidth) {
                this.items.forEach((item: CarouselItem2Component) => {
                    item.setPosition(item.position - item.width)
                })
                scrollItems--
            }
            this.renderItems()
        }
    }

    public showLeftArrow() {
        return this.alwaysShowArrows || !this.isFirstItemVisible()
    }

    public showRightArrow() {
        return this.alwaysShowArrows || !this.isLastItemVisible()
    }

    public getLeftArrowClass() {
        return `prev carousel-btn gtm-${this.gtmPrefix}-left-arrow selenium-controls-left`
    }

    public getRightArrowClass() {
        return `next carousel-btn gtm-${this.gtmPrefix}-right-arrow selenium-controls-right`
    }

    private isLastItemVisible() {
        if (this.items.length) {
            return this.items[this.items.length - 1].position + this.itemWidth <= this.canvasWidth
        }
        return false
    }

    private isFirstItemVisible() {
        if (this.items.length) {
            return this.items[0].position >= 0
        }
        return false
    }

    // on resize, it will reposition the items based on the free space on the right
    private repositionItems() {
        const totalItemsWidth = this.items.reduce((acc, item) => acc + item.width, 0)
        const lastItem = this.items[this.items.length - 1]
        while (
            totalItemsWidth >= this.canvasWidth &&
            this.canvasWidth - lastItem.position > this.itemWidth * 1.5
        ) {
            this.items.forEach((item: CarouselItem2Component) => {
                item.setPosition(item.position + item.width)
            })
        }
        this.renderItems()
    }

    private normalizeItemsToScroll(maximumItems) {
        const itemsThatFitCanvas = Math.max(1, Math.floor(this.canvasWidth / this.itemWidth))
        return Math.min(maximumItems, itemsThatFitCanvas)
    }

    private renderItems() {
        this.items.forEach((item: CarouselItem2Component) => {
            item.render()
        })
    }
}
