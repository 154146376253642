import { DOCUMENT } from "@angular/common"
import {
    ApplicationRef,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Inject,
    Injectable,
    Injector,
    Renderer2,
    RendererFactory2,
    Type,
} from "@angular/core"
import { ScrollBarService } from "../../services/scrollbar.service"
import { ModalBackdropComponent } from "./modal-backdrop.component"
import { ModalContentRef, ModalRef } from "./modal-ref"
import { ModalComponent } from "./modal.component"

export class ModalConfig {
    id?: string
    size?: "sm" | "lg" | "xl" | string
    windowClass?: string
    backdropClass?: string
    persisted?: boolean
    data?: Object
    clickOutsideToClose?: boolean
}

@Injectable()
export class ModalService {
    public activeModals: Map<string, ModalRef> = new Map()
    private renderer: Renderer2
    private modalAttributes = ["size", "windowClass"]
    public modalBackDrop: ComponentRef<ModalBackdropComponent>
    private defaultConfig: ModalConfig = {
        clickOutsideToClose: true,
    }

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private applicationRef: ApplicationRef,
        private injector: Injector,
        private rendererFactory: RendererFactory2,
        private scrollBar: ScrollBarService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null)
        this.modalBackDrop = this.attachBackdrop()
    }

    /**
     * Opens an existing persisted modal
     */
    public open(id: string): ModalRef

    /**
     * Opens a new modal
     */
    public open(component: any, config?: ModalConfig): ModalRef

    open(component: any, modalConfig: ModalConfig = {}): ModalRef {
        if (typeof component === "string") {
            this.showPersistedModal(component)
        } else {
            if (this.activeModals.has(modalConfig.id)) {
                return this.activeModals.get(modalConfig.id)
            }

            const config = Object.assign({}, this.defaultConfig, modalConfig)
            const modalRef = new ModalRef()
            const modalContentRef: ModalContentRef = this.createModalContent(
                component,
                config.data,
                modalRef
            )
            const modalComponentRef = this.createModal(modalContentRef, config)

            this.applyModalOptions(modalComponentRef.instance, config)

            modalRef.componentInstance = modalContentRef.componentRef.instance
            modalRef.modalComponentRef = modalComponentRef
            modalRef.id = config.id ? config.id : this.activeModals.size.toString()
            modalRef.result.subscribe(this.onModalConclusion.bind(this, modalRef))
            modalRef.persisted = config.persisted
            modalRef.config = config

            this.activeModals.set(modalRef.id, modalRef)
            if (config.persisted) {
                this.hidePersistedModal(modalComponentRef)
            } else {
                this.updateBackdrop(modalRef)
                this.toggleAuxiliaries(true)
            }

            return modalRef
        }
    }

    /**
     * Closes all persisted modals
     *
     * This should be called when persisted modals are not needed anymore
     * e.g. in the OnDestroy lifecycle hook
     */
    public closeAllById(ids: string[]) {
        ids.forEach((id) => {
            const activeModal = this.activeModals.get(id)
            if (activeModal) {
                activeModal.modalComponentRef.destroy()
                this.activeModals.delete(id)
            }
        })
    }

    /**
     * Hides all persisted modals and destroy non-persistent one
     *
     * This should be called on click outside modals
     */
    public dismissAllModals() {
        this.activeModals.forEach((activeModal) => {
            if (activeModal?.persisted) {
                this.hidePersistedModal(activeModal.modalComponentRef)
            } else {
                activeModal.modalComponentRef.destroy()
                this.activeModals.delete(activeModal.id)
            }
            activeModal.close()
        })
    }

    public onModalConclusion(modalRef: ModalRef) {
        this.closeModal(modalRef)
    }

    public toggleAuxiliaries(isShowModal: boolean) {
        if (isShowModal) {
            this.scrollBar.compensate()
            this.renderer.addClass(this.document.body, "modal-open")
            this.toggleComponentVisibility(this.modalBackDrop, true)
        } else {
            this.scrollBar.revertCompensation()
            this.renderer.removeClass(this.document.body, "modal-open")
            this.toggleComponentVisibility(this.modalBackDrop, false)
        }
    }

    private createModal(
        modalContentRef: ModalContentRef,
        config: ModalConfig
    ): ComponentRef<ModalComponent> {
        let modalComponentFactory = this.componentFactoryResolver.resolveComponentFactory(
            ModalComponent
        )

        let modalComponentRef = modalComponentFactory.create(this.injector, modalContentRef.nodes)
        modalComponentRef.instance.id = config.id
        this.applicationRef.attachView(modalComponentRef.hostView)
        this.document.body.appendChild(modalComponentRef.location.nativeElement)
        return modalComponentRef
    }

    private createModalContent(
        component: Type<unknown>,
        data: Object,
        modalRef: ModalRef
    ): ModalContentRef {
        const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(
            component
        )

        const modalContentComponentInjector = Injector.create({
            providers: [{ provide: ModalRef, useValue: modalRef }],
            parent: this.injector,
        })
        const componentRef = componentFactory.create(modalContentComponentInjector)

        if (data) {
            Object.keys(data).forEach((key) => {
                componentRef.instance[key] = data[key]
            })
        }

        const componentNativeEl = componentRef.location.nativeElement

        this.applicationRef.attachView(componentRef.hostView)
        return new ModalContentRef([[componentNativeEl]], componentRef)
    }

    private toggleComponentVisibility(modalComponentRef: ComponentRef<any>, toVisible: boolean) {
        this.renderer.setStyle(
            modalComponentRef.location.nativeElement,
            "display",
            toVisible ? "block" : "none"
        )
    }

    private applyModalOptions(modalInstance: ModalComponent, options: Object) {
        this.modalAttributes.forEach((optionName: string) => {
            if (options[optionName] !== undefined) {
                modalInstance[optionName] = options[optionName]
            }
        })
    }

    private attachBackdrop(): ComponentRef<ModalBackdropComponent> {
        let backdropFactory = this.componentFactoryResolver.resolveComponentFactory(
            ModalBackdropComponent
        )
        let backdropComponentRef = backdropFactory.create(this.injector)
        this.applicationRef.attachView(backdropComponentRef.hostView)
        this.document.body.appendChild(backdropComponentRef.location.nativeElement)
        this.toggleComponentVisibility(backdropComponentRef, false)
        return backdropComponentRef
    }

    private closeModal(modalRef: ModalRef) {
        if (modalRef.persisted) {
            this.hidePersistedModal(modalRef.modalComponentRef)
        } else {
            this.activeModals.delete(modalRef.id)
            modalRef.modalComponentRef.destroy()
        }
        this.toggleAuxiliaries(false)
        this.scrollBar.revertCompensation()
    }

    private showPersistedModal(id: string) {
        const modalRef = this.activeModals.get(id)
        this.toggleComponentVisibility(modalRef.modalComponentRef, true)
        modalRef.modalComponentRef.instance.changeDetectorRef.reattach()
        this.updateBackdrop(modalRef)
        this.toggleAuxiliaries(true)
    }

    private hidePersistedModal(modalComponentRef: ComponentRef<ModalComponent>) {
        this.toggleComponentVisibility(modalComponentRef, false)
        modalComponentRef.instance.changeDetectorRef.detach()
    }

    private updateBackdrop(modalRef: ModalRef) {
        this.modalBackDrop.instance.clickOutsideToClose = modalRef.config.clickOutsideToClose
        this.modalBackDrop.instance.activeModal = modalRef
    }
}
