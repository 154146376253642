import {
    AfterContentInit,
    Component,
    ContentChildren,
    forwardRef,
    Input,
    QueryList,
} from "@angular/core"
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from "@angular/forms"
import { UserProfileCheckboxComponent } from "./user-profile-checkbox/user-profile-checkbox.component"
import { SubjectEnum } from "../../models/constants"

@Component({
    selector: "app-user-profile-checkbox-group",
    templateUrl: "./user-profile-checkbox-group.component.html",
    styleUrls: ["./user-profile-checkbox-group.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UserProfileCheckboxGroupComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => UserProfileCheckboxGroupComponent),
        },
    ],
})
export class UserProfileCheckboxGroupComponent
    implements ControlValueAccessor, Validator, AfterContentInit {
    @ContentChildren(UserProfileCheckboxComponent)
    contentChildren!: QueryList<UserProfileCheckboxComponent>

    @Input() gtmClass: string = ""
    value = []
    isDisabled = false
    onValidatorChange: () => void = () => {}
    onChange = (value: string[]) => {}
    onTouch = () => {}

    ngAfterContentInit(): void {
        this.contentChildren.forEach((el: UserProfileCheckboxComponent) => {
            el.isDisabled = this.isDisabled
            el.gtmClass = this.gtmClass
        })
    }

    writeValue(value: string[]): void {
        this.value = value
        this.onValidatorChange()
    }

    registerOnChange(fn: (value: string[]) => void): void {
        this.onChange = fn
    }

    registerOnTouched(fn: () => void): void {
        this.onTouch = fn
    }

    validate(control: AbstractControl): ValidationErrors {
        return this.value.length <= 0
            ? {
                  error: {
                      message: "Please select at least one option!",
                  },
              }
            : null
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled

        if (!this.contentChildren) return
        isDisabled
            ? this.contentChildren.forEach(
                  (el: UserProfileCheckboxComponent) => (el.isDisabled = true)
              )
            : this.contentChildren.forEach(
                  (el: UserProfileCheckboxComponent) => (el.isDisabled = false)
              )
    }

    toggleValue(selectedValue: any): void {
        // if selected value is N/A, deselect all the other checkboxes
        if (selectedValue === "N/A") {
            let naValueFound = this.value.find((el) => el === selectedValue)
            if (naValueFound) {
                this.value = []
            } else {
                this.value = [selectedValue]
            }

            this.onTouch()
            this.onChange(this.value)

            return
        }

        // toggle checkbox logic, uncheck the item if is found in list, else add it
        let itemFound = this.value.find((el) => el === selectedValue)
        if (itemFound) {
            this.value = this.value.filter((el) => el !== itemFound)
        } else {
            // if N/A checkbox is already selected, uncheck N/A
            let naValue = this.value.find((el) => el === SubjectEnum.NA)
            if (naValue) this.value = []

            this.value = [...this.value, selectedValue]
        }
        this.onChange(this.value)
    }

    isSelected(valueToCheck: string): boolean {
        return this.value.includes(valueToCheck)
    }
}
