import { Component, Input, OnChanges, SimpleChanges } from "@angular/core"

@Component({
    selector: "app-icon",
    templateUrl: "./icon.component.html",
})
export class IconComponent implements OnChanges {
    @Input() scale: number = 1
    @Input() rotate: number = null
    @Input() type: string
    public transformValue: string

    public static getIconFor(type) {
        switch (type) {
            case "Audio":
                return "pbslm-icon-audio"
            case "Video":
                return "pbslm-icon-video"
            case "Image":
                return "pbslm-icon-image"
            case "Document":
            case "HTMLFragment":
            case "Text":
                return "pbslm-icon-document"
            case "Interactive":
                return "pbslm-icon-interactive"
            case "Interactive Lesson":
            case "Interactive+Lesson":
                return "pbslm-icon-interactive-lesson"
            case "Webpage":
                return "pbslm-icon-website"
            case "Collection":
                return "pbslm-icon-collection"
            case "Media Gallery":
            case "Media+Gallery":
                return "pbslm-icon-media-gallery"
            case "Self-Paced Lesson":
                return "fas fa-clipboard fa-lg"
            case "Lesson Plan":
            case "Lesson+Plan":
                return "pbslm-icon-lesson-plan"
            case "FocusView":
                return "pbslm-focus-view"
            case "FullView":
                return "pbslm-full-view"
            case "Transcript":
                return "pbslm-transcript"
            case "Bookmark":
                return "far fa-heart"
            case "Bookmark-filled":
                return "fas fa-heart"
            case "Info":
                return "pbslm-ico_info"
            case "Lightbulb":
                return "pbslm-ico_support"
            case "Arrow-left":
                return "pbslm-icon-arrow-left"
            case "Arrow-right":
                return "pbslm-icon-arrow-right"
            case "Arrow-down":
                return "pbslm-icon-arrow-down"
            case "Arrow-right2":
                return "fas fa-arrow-right"
            case "Arrow-left2":
                return "fas fa-arrow-left"
            case "Trash":
                return "far fa-trash-alt fa-lg"
            case "User":
                return "fas fa-user-circle fa-lg"
            case "Search":
                return "fas fa-search fa-lg"
            case "Menu":
                return "pbslm-icon-menu"
            case "X":
                return "fas fa-times fa-lg"
            case "Expand":
                return "fas fa-expand-arrows-alt fa-lg"
            case "Eye":
                return "fas fa-eye fa-lg"
            case "Cut-Eye":
                return "pbslm-cut-eye"
            case "Facebook":
                return "fab fa-facebook fa-lg"
            case "Facebook-square":
                return "fab fa-facebook-square fa-lg"
            case "Google":
                return "fab fa-google fa-lg"
            case "Twitter":
                return "fab fa-twitter fa-lg"
            case "Pinterest":
                return "fab fa-pinterest fa-lg"
            case "Print":
                return "pbslm-z-printer"
            case "Share":
                return "pbslm-icon-share-and-assign"
            case "Share-Social":
                return "pbslm-share-social"
            case "AssignClass":
                return "pbslm-uni74"
            case "Email":
                return "pbslm-icon-email"
            case "Code":
                return "pbslm-icon-link"
            case "SocialMedia":
                return "pbslm-icon-social"
            case "Exclamation":
                return "fas fa-exclamation"
            case "Favorite":
                return "pbslm-icon-favorite"
            case "FavoriteFilled":
                return "pbslm-icon-favorited"
            case "SupportMaterials":
                return "pbslm-icon-support-materials"
            case "Standards":
                return "pbslm-icon-standards"
            case "Assignments":
                return "pbslm-icon-assignments"
            case "Puzzle":
                return "pbslm-icon-puzzle"
            case "Dashboard":
                return "pbslm-icon-dashboard"
            case "Link":
                return "fas fa-link"
            case "External-link":
                return "pbslm-external-link"
            case "Check":
                return "fas fa-check"
            case "Plus":
                return "fas fa-plus"
            case "Minus":
                return "fas fa-minus"
            case "Exclamation-triangle":
                return "fas fa-exclamation-triangle"
            case "FacebookSecondVersion":
                return "fab fa-facebook-f"
            case "Pencil":
                return "fas fa-pencil-alt"
            case "Cog":
                return "fas fa-cog"
            case "Exclamation-Circle":
                return "pbslm-exclamation-warning-icon"
            case "Close":
                return "pbslm-close-circle"
            case "Globe":
                return "pbslm-globe"
            case "MinusCircle":
                return "pbslm-z-minus-circle"
            case "PlusCircle":
                return "pbslm-z-plus-circle"
            case "BackToTopArrow":
                return "pbslm-zz-back-to-top-arrow"
            case "ThinCross":
                return "pbslm-close-thin-cross"
            case "classes":
                return "pbslm-classes"
            case "dashboard2":
                return "pbslm-dashboard2"
            case "folder":
                return "pbslm-folder"
            case "lesson-builder":
                return "pbslm-lesson-builder"
            case "smile-face":
                return "pbslm-smile-face"
            case "dots":
                return "pbslm-dots"
            case "duplicate":
                return "pbslm-duplicate"
            case "file-chart":
                return "pbslm-file-chart"
            case "file-dots":
                return "pbslm-file-dots"
            case "lock-open":
                return "pbslm-lock-open"
            case "lock-close":
                return "pbslm-lock-closed"
            case "brain":
                return "pbslm-brain"
            case "facebook-simple":
                return "pbslm-facebook-simple"
            case "pinterest-simple":
                return "pbslm-pinterest-simple"
            case "question-circle":
                return "far fa-question-circle"
            case "Checked-checkbox":
                return "pbslm-checked-checkbox"
            default:
                return "fas fa-file fa-lg"
        }
    }

    public getIcon() {
        return IconComponent.getIconFor(this.type)
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.transformValue = "scale(" + this.scale + ")"
        if (this.rotate) {
            this.transformValue += ` rotate(${this.rotate}deg)`
        }
    }
}
