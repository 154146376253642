import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Roster } from "../../../../models/roster.model"
import { Student } from "../../../../models/student.model"
import { STATIC_IMAGES } from "../../../../models/constants"

@Component({
    selector: "app-select-student-view",
    templateUrl: "./select-student-view.component.html",
    styleUrls: ["./select-student-view.component.scss"],
})
export class SelectStudentViewComponent implements OnInit {
    @Input() public rosters: Roster[] = []
    @Output() public step = new EventEmitter<number>()

    public googleClassImg: string = ""

    public constructor() {
        this.googleClassImg = STATIC_IMAGES.classroom_color_round
    }

    public ngOnInit() {
        this.selectStudentsOfSelectedClasses()
        this.expendFirstClass()
    }

    public get isRosterSelected(): boolean {
        return !!this.rosters.find((roster: Roster) => {
            return roster.selected && !!roster.students.find((student) => student.selected)
        })
    }

    public next(): void {
        this.step.emit(3)
        this.toggleOff()
    }

    public prev(): void {
        this.step.emit(1)
        this.toggleOff()
    }

    public selectAll(roster: Roster): void {
        if (roster) {
            if (this.checkIfAllStudentSelected(roster)) {
                roster.students.map((student: Student) => {
                    student.selected = false
                })
            } else {
                roster.students.map((student: Student) => {
                    student.selected = true
                })
            }
            if (!roster.expanded) {
                this.toggleExpand(roster)
            }
        }
    }

    public toggleExpand(roster: Roster): void {
        if (roster) {
            if (roster.expanded) {
                roster.expanded = false
            } else {
                this.toggleOff()
                roster.expanded = true
            }
        }
    }

    public toggleOff() {
        this.rosters.map((r) => (r.expanded = false))
    }

    public checkIfAllStudentSelected(roster: Roster): boolean {
        if (roster) {
            return !roster.students.find((student) => !student.selected)
        }
        return false
    }

    private selectStudentsOfSelectedClasses() {
        this.rosters.map((roster: Roster) => {
            if (roster.selected) {
                roster.students.map((student: Student) => {
                    student.selected = true
                })
            }
        })
    }

    private expendFirstClass() {
        this.rosters.find((roster: Roster) => roster.selected).expanded = true
    }
}
