import { ChangeDetectorRef, Component, Host, Input, OnInit } from "@angular/core"
import { UserProfileCheckboxGroupComponent } from "../user-profile-checkbox-group.component"
import Utils from "../../../utils/utils"

@Component({
    selector: "app-user-profile-checkbox",
    templateUrl: "./user-profile-checkbox.component.html",
    styleUrls: ["./user-profile-checkbox.component.scss"],
})
export class UserProfileCheckboxComponent implements OnInit {
    @Input() value: string
    @Input() label: string

    @Input() gtmClass: string = ""

    @Input()
    set isDisabled(disabled) {
        this.disabled = disabled
        this.cd.detectChanges()
    }

    id = ""
    disabled = false

    constructor(
        @Host() public checkboxGroup: UserProfileCheckboxGroupComponent,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.id = Utils.generateUniqueId()
    }
}
