import { Subscription } from "rxjs"
import { Directive, OnDestroy } from "@angular/core"

@Directive()
export class EduComponent implements OnDestroy {
    private subscriptions: Subscription[] = []

    protected addSub(subscription: Subscription) {
        this.subscriptions.push(subscription)
        return subscription
    }

    protected clearSubs() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }

    ngOnDestroy() {
        this.clearSubs()
    }
}
