import { Router } from "@angular/router"
import { Location } from "@angular/common"
import Utils from "./utils"

export function redirectToError(
    router: Router,
    location: Location,
    status,
    customErrorMessage = null,
    next = null,
    data = null
) {
    let queryParams: any = { status: status }
    if (next) {
        queryParams.next = next
    }
    if (customErrorMessage) {
        queryParams.customErrorMessage = customErrorMessage
    }
    if (Utils.isCurrentURLForStudents()) {
        queryParams.student = true
    }
    // Gets the current URL which we want to display
    // This needs to be correct for both Angular navigation and direct browser access
    const currentPath = router.getCurrentNavigation()?.extractedUrl.toString()
    return router
        .navigate(["error"], {
            queryParams: queryParams,
            queryParamsHandling: "merge",
            skipLocationChange: true,
            state: { data },
        })
        .then(() => {
            /* TODO ERROR-ROUTING-WORKAROUND
         https://github.com/angular/angular/issues/17004 https://github.com/angular/angular/issues/27148 will fix it.
         We can't say "keep URL, but navigate to X" from resolvers or guards since navigation is not finished.

         We enforce a URL change previous to the redirectToError() call. Manually entered URLs already trigger a
         location change. extraRouteConfig.urlUpdateStrategy `eager` handles this for Angular router link navigation.

         When navigation to error page finishes, replace the URL with the one we want to display.
         This way browser back/forward works well.
         Disadvantage: URL will flash slightly.

         Technically, we don't need this workaround when calling redirectToError in a component or service since there
         is no navigation there. The URL won't flash in this case.
         Location is left as a required parameter so we don't mess up by accident in guards/resolvers.
        */
            if (currentPath) {
                location.replaceState(currentPath)
            }
        })
}

export function getDRFErrorMessages(errorResponse, fields: string[] = []): string[] {
    if (!errorResponse || !errorResponse.error) {
        return []
    }
    let errors = []
    if (errorResponse.error.non_field_errors) {
        errors = errorResponse.error.non_field_errors
    }
    fields.forEach((field) => {
        if (errorResponse.error[field]) {
            errors.push(errorResponse.error[field])
        }
    })
    return errors
}
