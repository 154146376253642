<div
    [formGroup]="accountInfo"
    class="row-md-auto justify-content-between d-flex flex-xs-column flex-md-row mx-n3 mb-3"
>
    <div class="input-container py-3">
        <label class="text-navy-blue font-size-18 fw-bold mb-2" for="email">Email address</label>
        <input
            type="email"
            class="form-control font-size-16 p-0 border-0 bg-transparent"
            id="email"
            formControlName="email"
        />
        <a
            *ngIf="hasPublicMediaAccount"
            [href]="publicMediaProfileLink"
            class="open-user-info-edit"
            rel="noopener noreferrer"
            target="_blank"
        >
            Change Email
            <app-icon [type]="'External-link'"></app-icon>
        </a>
    </div>
    <div class="input-container py-3">
        <label class="text-navy-blue font-size-18 fw-bold mb-2" for="password">Password</label>
        <input
            type="password"
            class="form-control font-size-16 p-0 border-0 bg-transparent"
            id="password"
            autocomplete="off"
            formControlName="password"
        />
        <a
            *ngIf="hasPublicMediaAccount"
            [href]="publicMediaProfileLink"
            class="open-user-info-edit"
            rel="noopener noreferrer"
            target="_blank"
        >
            Change Password
            <app-icon [type]="'External-link'"></app-icon>
        </a>
    </div>
</div>
