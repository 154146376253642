import { BehaviorSubject, Observable, Subscription, throwError as observableThrowError } from "rxjs"
import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { catchError, map } from "rxjs/operators"
import { Router } from "@angular/router"
import { LearningObject } from "../models/learningObject.model"
import { TranscriptMedia } from "../models/resource/media"
import { Resource } from "../models/resource/resource"
import { StandardsInformation } from "../models/resource/standards"
import { ModalService } from "../shared-components/modal/modal.service"
import { TextDisplayModalComponent } from "../shared-components/modals/text-display-modal/text-display-modal.component"
import { getDRFErrorMessages, redirectToError } from "../utils/error-handling"
import { LmApiService } from "./lm-api.service"
import { LocalizationService } from "./localization.service"
import { URSService } from "./urs.service"
import { Location } from "@angular/common"
import Utils from "../utils/utils"
import { PreviewService } from "./preview.service"
import { UserService } from "./user.service"

@Injectable({
    providedIn: "root",
})
export class ResourceService {
    initialData: Resource = new Resource()
    initialStandardsInfo: StandardsInformation = new StandardsInformation()
    resourceResponse: BehaviorSubject<Resource> = new BehaviorSubject<Resource>(this.initialData)
    isResourceGeoRestricted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)
    standardsResponse: BehaviorSubject<StandardsInformation> = new BehaviorSubject<
        StandardsInformation
    >(this.initialStandardsInfo)
    private subscriptions: Subscription[] = []
    private _isFocusView: boolean = false
    private selectedTab: string = null

    constructor(
        private http: HttpClient,
        private lmApi: LmApiService,
        private localizationService: LocalizationService,
        private router: Router,
        private ursService: URSService,
        private modalService: ModalService,
        private location: Location,
        private window: Window,
        private previewService: PreviewService,
        private userService: UserService
    ) {}

    public get focusView(): boolean {
        return this._isFocusView
    }

    public set focusView(focusViewState: boolean) {
        this._isFocusView = focusViewState
    }

    set updateGeoRestrictedState(state: boolean) {
        this.isResourceGeoRestricted.next(state)
    }

    getResourceGeRestrictedState(): Observable<boolean> {
        return this.isResourceGeoRestricted.asObservable()
    }

    public switchResourceView(isStudent: boolean = null, isFocus: boolean = null) {
        let url: string = window.location.pathname
        this.router.navigate([url], {
            queryParams: { student: isStudent, focus: isFocus },
            queryParamsHandling: "merge",
        })
        window.scrollTo(0, 0)
        this.focusView = !!isFocus
    }

    public resetData() {
        // Reset, so we don't display obsolete data
        this.resourceResponse.next(this.initialData)
        this.standardsResponse.next(this.initialStandardsInfo)
    }

    public set activeTab(activeTab: string) {
        this.selectedTab = activeTab
        localStorage.setItem("activeTab", this.selectedTab)
    }

    public get activeTab(): string {
        return localStorage.getItem("activeTab")
    }

    public deleteActiveTab() {
        localStorage.removeItem("activeTab")
    }

    public getResource(resourceCode) {
        this.resetData()
        this.cancelSubscriptions()
        let resourceURL = `/api/v2/resource/${resourceCode}/`
        // TO DO: Needs to be investigated post 184.0.0 release
        if (this.previewService.previewOnResource) {
            resourceURL = this.userService.isLoggedIn()
                ? `/api/v2/resource_preview/${resourceCode}/`
                : `/api/v2/resource/${resourceCode}/`
        }
        this.subscriptions.push(
            this.lmApi
                .getWithCookiesInParams(
                    resourceURL,
                    ["user_content_permission", "student"],
                    this.previewService.previewOnResource
                        ? {
                              params: {
                                  is_preview: true,
                              },
                          }
                        : {},
                    !this.previewService.previewOnResource
                )
                .pipe(map((response: any) => Resource.fromData(response, this.ursService)))
                .subscribe(
                    (resource: Resource) => {
                        this.resourceResponse.next(resource)
                        if (resource.hasStandards) {
                            this.setStandardsInformation(resource.resourceCode)
                        }
                    },
                    (error) => {
                        const relatedResources = error.error?.related_resources
                            ? error.error["related_resources"].map((r) => new LearningObject(r))
                            : []
                        const expirationDate = error.error?.expiration_date || null
                        let errors: string[] = getDRFErrorMessages(error, ["detail"])
                        redirectToError(
                            this.router,
                            this.location,
                            error.status,
                            errors[0] ? errors : null,
                            null,
                            { expirationDate, relatedResources }
                        )
                    }
                )
        )
    }

    public incrementViewCount(resourceCode: string) {
        this.lmApi
            .get(`/resource/view-count/${resourceCode}/`)
            .pipe(catchError((error) => observableThrowError(error)))
            .subscribe()
    }

    public getLessonPlanContent(resourceCode) {
        // This method will be used from lesson plan layout component
        return this.lmApi.get(`/api/v2/lesson_plan_html/${resourceCode}/`)
    }

    public getLocalStorageShareAssignModalKey() {
        return "shouldOpenShareAssignModal"
    }

    public openGoogleClassroomSharableLink(sharedURL: string) {
        return window.open(
            "https://classroom.google.com/share?url=" + encodeURIComponent(sharedURL),
            "_blank",
            "width=640, height=450,toolbar=no, scrollbars=yes"
        )
    }

    public getAssetDownloadUrl(url: string) {
        return this.lmApi.get(url).pipe(catchError((error) => observableThrowError(error)))
    }

    public getLessonPlanExportUrl(resource: Resource) {
        return this.lmApi
            .get(`/api/v2/lesson_plan_word/${resource.resourceCode}/`)
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public rateResource(resourceCode: string, rating: 0 | 1 | 2 | 3 | 4 | 5) {
        const url = "/recommendation/user-rating/"
        this.lmApi
            .post(url, { resource_code: resourceCode, rating })
            .pipe(catchError((error) => observableThrowError(error)))
    }

    public getRecommendationQuestion() {
        return this.http.get("recommendation/resource-question/")
    }

    public downloadTranscript(media: TranscriptMedia) {
        if (media.transcriptURL) {
            const win = window.open(media.transcriptURL, "_blank")
            win.focus()
        } else {
            this.modalService.open(TextDisplayModalComponent, {
                size: "lg",
                windowClass: "text-display-modal",
                data: {
                    text: media.transcriptText,
                    printURL: media.transcriptPrintURL,
                },
            })
        }
    }

    shouldShowCautionTab(shouldShowContentWarnings: boolean) {
        return (
            shouldShowContentWarnings && this.activeTab === "Cautions" && !Utils.wasPageReloaded()
        )
    }

    private cancelSubscriptions() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }

    private getResourceStandards(resourceCode) {
        let standardsURL = `/api/v2/lo/standards/r/${resourceCode}/`
        if (this.localizationService.hasOrganization()) {
            standardsURL += `${this.localizationService.getOrganizationId()}/`
        }
        return this.lmApi
            .getWithCookiesInParams(standardsURL, ["logged_in"])
            .pipe(catchError((error) => observableThrowError(error)))
    }

    private setStandardsInformation(resourceCode) {
        this.subscriptions.push(
            this.getResourceStandards(resourceCode).subscribe((resStandards: any) => {
                if (this.resourceResponse.getValue().resourceCode != resourceCode) {
                    return
                }
                const standardsInfo = StandardsInformation.fromData(resStandards, resourceCode)
                this.standardsResponse.next(standardsInfo)
            })
        )
    }
}
