import { Injectable, OnDestroy } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { BehaviorSubject, Subject } from "rxjs"
import {
    distinctUntilChanged,
    filter,
    finalize,
    map,
    switchMap,
    takeUntil,
    tap,
} from "rxjs/operators"
import { UserDataPrivacyService } from "./user-data-privacy.service"
import { UserDataPrivacyPayload } from "../models/userDataPrivacy.model"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"

export enum DATA_PRIVACY_MODALS {
    GENERIC_INFO = 1,
    CLASSROOM_SETTINGS = 2,
    SIGN_ACKNOWLEDGMENT = 3,
}

export enum DISMISS_MODAL_REASON {
    BACKDROP_CLICK,
    ESC,
    CLOSE,
    DO_LATER,
}

@Injectable()
export class DataPrivacyModalsService implements OnDestroy {
    private destroyed$ = new Subject()

    private isLoading = new BehaviorSubject<boolean>(false)
    public readonly isLoading$ = this.isLoading.asObservable()

    private dataPrivacyForm = this.fb.group({})

    private activeModal = new BehaviorSubject<DATA_PRIVACY_MODALS>(DATA_PRIVACY_MODALS.GENERIC_INFO)
    public readonly activeModal$ = this.activeModal.asObservable()

    private isFormInvalid = new BehaviorSubject(true)
    public readonly isFormInvalid$ = this.isFormInvalid.asObservable()

    constructor(
        private fb: FormBuilder,
        private userDataPrivacyService: UserDataPrivacyService,
        private modalService: NgbModal
    ) {
        this.dataPrivacyForm.valueChanges
            .pipe(
                takeUntil(this.destroyed$),
                distinctUntilChanged(),
                filter((formValue: any) => formValue?.privacyCheck),
                map((formValue) => {
                    return this.userDataPrivacyService.createPayload(formValue)
                }),
                tap(() => this.isLoading.next(true)),
                switchMap((payload) =>
                    this.userDataPrivacyService.setDataPrivacy<UserDataPrivacyPayload>(payload)
                ),
                finalize(() => this.isLoading.next(false))
            )
            .subscribe(() => {
                this.modalService.dismissAll()
                this.userDataPrivacyService.openSuccessModal()
            })

        this.dataPrivacyForm.statusChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((formStatus) => {
                this.isFormInvalid.next(formStatus === "INVALID")
            })
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }

    getForm(): FormGroup {
        return this.dataPrivacyForm
    }

    nextModal(): void {
        this.activeModal.next(this.activeModal.value + 1)
    }

    previousModal(): void {
        this.activeModal.next(this.activeModal.value - 1)
    }
}
